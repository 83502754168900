import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Card,
  Skeleton,
  Box,
  Grid,
  Tab,
  Zoom,
  Tabs,
  AppBar,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import { AnimatePresence, motion } from "framer-motion";
import ProductsMenuSection from "./ProductsMenuSection";
import { getContrastColors } from "../../Functions/Colors";
import { calculateVisibilityCounts, filterSectionsWithAvailableElements } from "./MenuFunctions";


export default function MenuTab({
  basket,
  setBasket,
  getProductCover,
  getSectionCover,
  section_names,
  sectionsOrders = [],
  productsIdHidden = [],
  menuData = {},
  isStaff = false,
  card_variant_selected = "default",
  card_animation_variant_selected,
  menuCardShadow,
  menuAddProductDialogFullscreen,
  menuAddProductDialogAnimation,
}) {
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const [tabDirection, setTabDirection] = useState(1);

  const handleChangeTab = (event, newValue) => {
    setTabDirection(tabValue < newValue ? 1 : -1);
    setTabValue(newValue);
  };

  const [responsiveStyle, setResponsiveStyle] = useState({
    xs: 12,
    sm: 12,
    md: 6,
    lg: 4,
    xl: 3,
  });
  useEffect(() => {
    if (card_variant_selected === "image") {
      setResponsiveStyle({ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 });
    } else {
      setResponsiveStyle({ xs: 12, sm: 12, md: 6, lg: 4, xl: 3 });
    }
  }, [card_variant_selected]);

  const tabContextRef = useRef(null);
  const scrollRef = useRef(null);
  useEffect(() => {
    const secondCheck = () => {
      if (tabContextRef.current) {
        const distanceTop =
          tabContextRef.current.getBoundingClientRect().top + window.scrollY;
        if (tabValue > 0) {
          window.scrollTo({ top: distanceTop, behavior: "smooth" });
        }
      }
      if (scrollRef.current) {
        // Imposta lo scroll della sezione al top
        scrollRef.current.scrollTop = 0;
      }
    };

    // Utilizza un timeout per dare tempo al componente di montarsi
    const timeoutId = setTimeout(secondCheck, 0);

    // Pulizia del timeout al smontaggio del componente
    return () => clearTimeout(timeoutId);
  }, [tabValue]);

  const slideVariants = {
    enter: (direction) => ({
      x: tabDirection > 0 ? "100%" : "-100%", // Entra da destra se avanti, altrimenti da sinistra
      opacity: 0,
      transition: { duration: 0.5 },
      zIndex: 1,
    }),
    center: {
      x: 0,
      opacity: 1,
      transition: { duration: 0.5 },
      zIndex: 1,
    },
    exit: {
      scale: 0.5,
      opacity: 0,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
      zIndex: 0,
    },
  };

  // Variabile per tracciare la posizione iniziale dello swipe
  const [startX, setStartX] = useState(0);

  // Gestore per l'inizio del trascinamento
  const handleDragStart = (event, info) => {
    setStartX(info.point.x);
  };

  // Gestore per la fine del trascinamento
  const handleDragEnd = (event, info) => {
    const endX = info.point.x;
    const threshold = 50; // Soglia di sensibilità per lo swipe, regolabile
    if (startX - endX > threshold) {
      // Swipe verso sinistra
      if (tabValue < sectionsOrders.length - 1) {
        setTabDirection(1);
        setTabValue(tabValue + 1);
      }
    } else if (endX - startX > threshold) {
      // Swipe verso destra
      if (tabValue > 0) {
        setTabDirection(-1);
        setTabValue(tabValue - 1);
      }
    }
  };

  const [sectionsOrdersFiltered, setSectionsOrdersFiltered] = useState([])
  useEffect(() => {
    const filteredSectionsOrders = filterSectionsWithAvailableElements(
      sectionsOrders,
      menuData,
      productsIdHidden
    );
    setSectionsOrdersFiltered(filteredSectionsOrders);
  }, [sectionsOrders]);

  const indicatorColor = theme.palette.getContrastText(
    theme.palette.primary.dark
  );

  const AntTabs = {
    "& .MuiTabs-indicator": {
      height: "35px",
      marginBottom: "7px",
      width: "100%",
      backgroundColor: indicatorColor,
      opacity: "20%",
      borderRadius: "0px",
      transition: "0.5s ease-in-out",
    },
    "&.Mui-selected": {
      // Questo si applica quando il tab è selezionato
      textColor: "black", // Modifica questo con il codice colore desiderato
    },
  };

  return (
    <Grid container ref={tabContextRef}>
      <Grid item xs={4} md={2}>
        <AppBar position="static" sx={{ height: "100vh", overflowY: "scroll" }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={tabValue}
            onChange={handleChangeTab}
            sx={{ borderRight: 1, borderColor: "divider", ...AntTabs }} // Imposta una larghezza minima per i tab
          >
            {sectionsOrdersFiltered.map((section_id, index) => {
              const { elementsShowed, elementsAvaiable } =
                calculateVisibilityCounts(
                  menuData,
                  section_id,
                  productsIdHidden
                );

              return (
                <Tab
                  sx={{
                    display: !elementsAvaiable && "none",
                    color: tabValue === index ? indicatorColor : "inherit",
                    "&.Mui-selected": { color: indicatorColor },
                  }}
                  key={section_id}
                  label={section_names[section_id].section_name}
                />
              );
            })}
          </Tabs>
        </AppBar>
      </Grid>
      <Grid item xs={8} md={10}>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "100vh",
            overflowY: "scroll",
          }}
          ref={scrollRef}
        >
          <AnimatePresence initial={false}>
            {sectionsOrdersFiltered.map((section_id, index) => {
              if (index !== tabValue) return null;
              const section_values = section_names[section_id];
              const section_name = section_values.section_name;
              const section_color = section_values.section_color;
              const section_card_color = section_values.section_card_color;
              const section_color_contrast = section_color
                ? getContrastColors("#ffffff", section_color)
                : undefined;
              const direction = tabValue > index ? 1 : -1;

              return (
                <motion.div
                  key={section_id}
                  custom={direction}
                  variants={slideVariants}
                  initial="enter"
                  animate="center"
                  exit="exit"
                  drag="x"
                  dragConstraints={{ left: 0, right: 0 }}
                  onDragStart={handleDragStart}
                  onDragEnd={handleDragEnd}
                  style={{
                    position: "absolute",
                    width: "100%",
                    paddingBottom: "100px",
                  }} // Assicurati che il div occupi tutto lo spazio necessario
                >
                  <Box key={"tab-panel-" + section_id}>
                    {Object.keys(menuData).length === 0 ? (
                      <div>
                        {[
                          [1],
                          [2],
                          [3],
                          [4],
                          [5],
                          [6],
                          [7],
                          [8],
                          [9],
                          [10],
                        ].map(([value]) => (
                          <Zoom
                            key={"zoom-card-skeleton-" + value}
                            timeout={500}
                            in={true}
                            style={{
                              transitionDelay: `${50 * value}ms`,
                            }}
                            unmountOnExit
                          >
                            <Card style={{ padding: "5px", margin: "5px" }}>
                              <Skeleton variant="text" />
                              <Skeleton
                                variant="rectangular"
                                width={"100%"}
                                height={getRandomInt(50, 120)}
                              />
                            </Card>
                          </Zoom>
                        ))}
                      </div>
                    ) : (
                      <Box
                        sx={{
                          padding: "0px",
                          background: section_color,
                          minHeight: "100vh",
                        }}
                      >
                        <ProductsMenuSection
                          sectionMenuData={menuData[section_id]}
                          section_description={
                            section_values.section_description
                          }
                          section_id={section_id}
                          productsIdHidden={productsIdHidden}
                          isStaff={isStaff}
                          basket={basket}
                          setBasket={setBasket}
                          section_color={section_color}
                          section_color_contrast={section_color_contrast}
                          section_card_color={section_card_color}
                          section_name={section_name}
                          section_cover={section_values.section_cover}
                          card_variant_selected={card_variant_selected}
                          card_animation_variant_selected={
                            card_animation_variant_selected
                          }
                          getProductCover={getProductCover}
                          getSectionCover={getSectionCover}
                          responsiveStyle={responsiveStyle}
                          menuCardShadow={menuCardShadow}
                          menuAddProductDialogFullscreen={
                            menuAddProductDialogFullscreen
                          }
                          menuAddProductDialogAnimation={
                            menuAddProductDialogAnimation
                          }
                        />
                      </Box>
                    )}
                  </Box>
                </motion.div>
              );
            })}
          </AnimatePresence>
        </Box>
      </Grid>
    </Grid>
  );
}

MenuTab.propTypes = {
  sectionsOrders: PropTypes.array,
  section_names: PropTypes.object,
  productsIdHidden: PropTypes.array,
  menuData: PropTypes.object,
  isStaff: PropTypes.bool,
  menuDataIngredients: PropTypes.object,
  card_variant_selected: PropTypes.string,
  getProductCover: PropTypes.func,
};
