// TableActions.js
import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField } from '@mui/material';
import TableForm from './Dialogs/TableForm'
export default function TableActions({ open,roomName,roomTables, table, onClose, onDelete, onRename }) {
    const [action, setAction] = useState(null); // 'delete', 'rename', 'changeCapacity'
    const [inputValue, setInputValue] = useState('');

    const handleAction = (type) => {
        setAction(type);
    };

    function handleOnClose(){
        onClose()
        setAction(null)
    }

    return (
        <Dialog open={open} onClose={handleOnClose}>
            <DialogTitle>{table?.tableName}</DialogTitle>
            <DialogContent>
                {action === null && (
                    <DialogContentText>
                        Choose an action for table {table?.tableName}.
                    </DialogContentText>
                )}
                {action === 'delete' && (
                    <DialogContentText>
                        Are you sure you want to delete {table?.tableName}?
                    </DialogContentText>
                )}
                {(action === 'rename') && (
                    <TableForm
                    table={table}
                    roomTables={roomTables}
                    onChange={onRename}
                    roomName={roomName}
                    onDelete={() =>  onDelete(table)}
                    />
                )}
            </DialogContent>
            <DialogActions>
                {action === null && (
                    <>
                        <Button onClick={() => handleAction('delete')} color="primary">
                            Elimina
                        </Button>
                        <Button onClick={() => handleAction('rename')} color="primary">
                            Modifica
                        </Button>
                    </>
                )}
                {action === 'delete' && (
                    <>
                        <Button onClick={handleOnClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => {
                            onDelete(table);
                            handleOnClose();
                            setAction(null)
                            setInputValue('')
                        }} color="secondary">
                            Confirm Delete
                        </Button>
                    </>
                )}


            </DialogActions>
        </Dialog>
    );
}
