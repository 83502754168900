import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";

export const useParallaxScroll = (speed = 25) => {
  const ref = useRef(null);
  const [offsetY, setOffsetY] = useState(0);

  const handleScroll = () => {
    if (!ref.current) return;
    const rect = ref.current.getBoundingClientRect();
    const offsetTop = rect.top;
    const windowHeight = window.innerHeight;

    if (offsetTop < windowHeight && offsetTop + rect.height > 0) {
      const parallaxEffect =
        ((windowHeight - offsetTop) / windowHeight) * speed;
      setOffsetY(-speed + parallaxEffect);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return [ref, offsetY];
};

export function RenderParallaxImage({
  url,
  speed = 25,
  clipPath,
  children,
  height= undefined,

  ...rest
}) {
  const [cardRef, offsetY] = useParallaxScroll(speed);

  if (!url) return null;

  return (
    <div ref={cardRef} {...rest}>
      <div
        className="parallax-container"
        style={{
          clipPath,
        }}
      >
        <div
          {...rest}
          className="parallax-image"
          style={{
            transform: `translateY(${offsetY}%)`,
            backgroundImage: `url(${url})`,
          }}
        >

            {children}

        </div>
      </div>
    </div>
  );
}

RenderParallaxImage.propTypes = {
  url: PropTypes.string.isRequired,
  speed: PropTypes.number,
  clipPath: PropTypes.string,
  children: PropTypes.node,
};

export default RenderParallaxImage;
