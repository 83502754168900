import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  ButtonBase,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  Stack
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead
} from "@mui/material";

import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Trans, useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";


function RenderIngredientOption({
  ingredientOption,
  productIngredientsDefault,
  product_currency,
  onChange
}) {
  const theme = useTheme();
  let [productIngredientsVar, setProductIngredientsVar] = useState({});
  const [productPriceVar, setProductPriceVar] = useState(0);
  const [productIngredients, setProductIngredients] = useState([...productIngredientsDefault])

  const { i18n } = useTranslation();

  const accordionSummaryStyle = {
    padding: "0px",
    margin: "0px",
    minHeight: "8px",
    backgroundColor: theme.palette.secondary.dark,
    color: "white",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummaryExpandIconWrapper.MuiExpanded": {
      transform: "rotate(90deg)"
    },
    "& .MuiAccordionSummaryContent": {
      marginLeft: theme.spacing(1)
    }
  };
  const accordionDetailStyle = {
    backgroundColor: "white",
    padding: "5px"
  };

  const handleVariation = (
    ingredient_id,
    ingredientName,
    variation_on_add,
    variation_on_delete,
    remove = false
  ) => {
    setProductIngredientsVar((prevIngredientsVar) => {
      // Make a copy of the current state
      let newIngredientsVar = { ...prevIngredientsVar };

      // Get the current quantity, if not present, it will be 0
      let currentQuantity = newIngredientsVar[ingredient_id]?.quantity || 0;
      // Calculate the new quantity and prevent it from going negative
      let newQuantity = Math.max(
        ingredientOption === "Base" ? -1 : 0,
        remove ? currentQuantity - 1 : currentQuantity + 1
      );
      // Update the ingredientsVar state with the new values
      let newPriceVar;

      if (newQuantity > 0) {
        newPriceVar = newQuantity * variation_on_add;
      } else if (newQuantity < 0 && ingredientOption === "Base") {
        newPriceVar = newQuantity * Math.abs(variation_on_delete);


      } else {
        newPriceVar = 0
      }


      if (newQuantity === 0) {
        try {
          delete newIngredientsVar[ingredient_id];
        } catch { }
      } else {
        newIngredientsVar[ingredient_id] = {
          quantity: newQuantity,
          priceVariation: newPriceVar,
          ingredientName: ingredientName
        };
      }
      // Pass the updated ingredients state to the onChange function
      onChange(newIngredientsVar);

      // Update the product price variation state
      setProductPriceVar(newPriceVar);

      // Vibrate the device
      try {
        navigator.vibrate(50);
        console.log('vibrate')

      } catch {
        console.log('vibrate not avaiable')
      }

      return newIngredientsVar;
    });
  };

  function RenderSummaryTotal() {
    // Calculate the sum of the price variations
    let totalVariation = Object.values(productIngredientsVar).reduce(
      (total, current) => total + current.priceVariation,
      0
    );
    totalVariation = parseFloat(totalVariation.toFixed(2));
    return (
      <>
        {totalVariation !== 0 && (
          <Typography id="modal-modal-title" variant="body1" component="span">
            {totalVariation > 0 ? "+" : "-"}
            {Math.abs(totalVariation)}
            {product_currency}
          </Typography>
        )}
      </>
    );
  }

  function RenderQuantitySelector({ ingredient, ...rest }) {
    function getDisableRemoveCriteria() {
      if (ingredientOption === "Base") {
        if (
          productIngredientsVar[ingredient.ingredient_id] &&
          productIngredientsVar[ingredient.ingredient_id].quantity > 0
        ) {
          return false;
        }
        if (
          productIngredientsVar[ingredient.ingredient_id] &&
          productIngredientsVar[ingredient.ingredient_id].quantity === -1
        ) {
          return true;
        }
        if (
          ingredient.ingredient_price_variation_on_delete === "" ||
          ingredient.ingredient_price_variation_on_delete === null ||
          ingredient.ingredient_price_variation_on_delete === undefined
        ) {
          return true;
        }
        return false;
      } else {
        if (!productIngredientsVar[ingredient.ingredient_id]) {
          return true;
        }
        if (productIngredientsVar[ingredient.ingredient_id].quantity <= 0) {
          return true;
        }
      }

      if (
        productIngredientsVar[ingredient.ingredient_id] &&
        !productIngredientsVar[ingredient.ingredient_id].quantity
      ) {
        return true;
      }
    }

    const disableOnRemove = getDisableRemoveCriteria();

    function getDisableAddCriteria() {
      if (
        parseFloat(productIngredientsVar[ingredient.ingredient_id]?.quantity) <=
        -1
      ) {
        return false;
      } else {
        return (
          ingredient.ingredient_price_variation_on_add === "" ||
          ingredient.ingredient_price_variation_on_add === null ||
          ingredient.ingredient_price_variation_on_add === undefined
        );
      }
    }
    const disableOnAdd = getDisableAddCriteria();
    return (
      <Stack
        {...rest}
        direction="row"
        spacing={1}
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          variant="body2"
          sx={{ minWidth: "25px", textAlign: "right" }}
        >
          {ingredient.ingredient_price_variation_on_delete !== "" && (
            <>
              {product_currency}
              {ingredient.ingredient_price_variation_on_delete}
            </>
          )}
        </Typography>
        <Card
          sx={{
            display: "flex",
            boxShadow: 3,
            overflow: "hidden"
          }}
        >
          <ButtonBase
            variant="contained"
            disabled={disableOnRemove}
            sx={{
              color: disableOnRemove ? "#b3b3b3" : "white",
              backgroundColor: disableOnRemove
                ? "grey"
                : theme.palette.primary.dark,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              width: "100%"
            }}
            onClick={() =>
              handleVariation(
                ingredient.ingredient_id,
                ingredient.ingredientName,
                ingredient.ingredient_price_variation_on_add,
                ingredient.ingredient_price_variation_on_delete,
                true
              )
            }
          >
            <RemoveIcon />
          </ButtonBase>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "5px"
            }}
          >
            <Typography variant="body1">
              {ingredientOption === "Base"
                ? (productIngredientsVar[ingredient.ingredient_id]?.quantity ||
                  0) + 1
                : productIngredientsVar[ingredient.ingredient_id]?.quantity ||
                0}
            </Typography>
          </Box>
          <ButtonBase
            variant="contained"
            disabled={disableOnAdd}
            sx={{
              backgroundColor: disableOnAdd
                ? "grey"
                : theme.palette.primary.dark,
              color: disableOnAdd ? "#b3b3b3" : "white",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              width: "100%"
            }}
            onClick={() =>
              handleVariation(
                ingredient.ingredient_id,
                ingredient.ingredientName,
                ingredient.ingredient_price_variation_on_add,
                ingredient.ingredient_price_variation_on_delete,
              )
            }
          >
            <AddIcon />
          </ButtonBase>
        </Card>

        <Typography
          variant="body2"
          sx={{ minWidth: "25px", textAlign: "left" }}
        >
          {ingredient.ingredient_price_variation_on_add !== "" && (
            <>
              {product_currency}
              {ingredient.ingredient_price_variation_on_add}
            </>
          )}
        </Typography>
      </Stack>
    );
  }

  return (
    <Accordion>
      <AccordionSummary
        style={accordionSummaryStyle}
        color="primary"
        expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography id="modal-modal-title" variant="body1">
          {ingredientOption} <RenderSummaryTotal />
        </Typography>
      </AccordionSummary>

      <AccordionDetails style={accordionDetailStyle}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>

                <TableCell
                  align={"left"}
                  style={{
                    backgroundColor: theme.palette.secondary.dark,
                    color: "white"
                  }}
                >
                  <Trans>Nome</Trans>
                </TableCell>
                <TableCell
                  align={"center"}
                  style={{
                    backgroundColor: theme.palette.secondary.dark,
                    color: "white"
                  }}
                >
                  <Trans>Quantità</Trans>
                </TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {productIngredients.map((ingredient) => (
                <TableRow key={ingredient.ingredient_id} sx={{ textDecoration: ingredient.ingredientAvailable === false && 'line-through', opacity: ingredient.ingredientAvailable === false ? 0.5 : 1, width: '100%' }}>
                  <TableCell align={"left"} >
                    <Typography
                      variant="body2"
                      style={{
                        textDecoration:
                          productIngredientsVar[ingredient.ingredient_id]
                            ?.quantity === -1 && ingredientOption === "Base"
                            ? "line-through"
                            : "none"
                      }}
                    >

                      {ingredient.ingredientName[i18n.language] ?
                        ingredient.ingredientName[i18n.language] :
                        ingredient.ingredientName.it}
                    </Typography>
                  </TableCell>
                  <TableCell align={"center"} >
                    <RenderQuantitySelector sx={{ display: ingredient.ingredientAvailable === false && 'none' }} ingredient={ingredient} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
}

export default RenderIngredientOption;

RenderIngredientOption.propTypes = {
  onChange: PropTypes.func
};
