const moment = require("moment");


export function get_product_period(productsAvailabilityPeriods) {
    if (productsAvailabilityPeriods) {
      const current_time = moment().format("HH:mm:ss");

      let current_periods = [];
  
      for (let period in productsAvailabilityPeriods) {
        const times = productsAvailabilityPeriods[period];
        const start_time = moment(times.start, "YYYY-MM-DDTHH:mm.sssZ").format(
          "HH:mm:ss"
        );
        const end_time = moment(times.end, "YYYY-MM-DDTHH:mm.sssZ").format(
          "HH:mm:ss"
        );
  
        if (current_time >= start_time && current_time <= end_time) {
          current_periods.push(period);
        }
      }
  
      if (current_periods.length > 0) {
        return current_periods;
      }
    }
    
    return null;
  }

  
  let dayOfWeekDict = {
    "Monday": "Lunedì",
    "Tuesday": "Martedì",
    "Wednesday": "Mercoledì",
    "Thursday": "Giovedì",
    "Friday": "Venerdì",
    "Saturday": "Sabato",
    "Sunday": "Domenica"
  }
  
  export function checkLocalIsOpenTimes(current_time,local_times) {
    // If local_times is not provided, fetch it from the database
  
    // Get the current day of the week and time
    let now = moment(current_time, "ddd, DD MMM YYYY HH:mm:ss z")
    console.log('now:',now)
    let dayOfWeek = dayOfWeekDict[now.format('dddd')];
    let currentTime = now.format("HH:mm:ss");
    console.log('date day:',dayOfWeek,currentTime)
  
    // Check if the local has an opening time for the current day of the week
    if (dayOfWeek in local_times) {
      // Check if the current time is within the start and end time
      for (let timeRange of local_times[dayOfWeek]) {
        let startTime = moment(timeRange.start, "YYYY-MM-DDTHH:mm:ss.SSSZ").utc().format("HH:mm:ss");
        let endTime = moment(timeRange.end, "YYYY-MM-DDTHH:mm:ss.SSSZ").utc().format("HH:mm:ss");
        console.log('date start:',startTime,currentTime,endTime)

  
        // If the closing time is before the opening time, the local is open past midnight
        if (endTime < startTime) {
          // If the current time is before the closing time, add a day to the current time for comparison
          if (currentTime < endTime) {
            currentTime = moment.utc().add(1, 'days').format("HH:mm:ss");
          }
          // Add a day to the closing time for comparison
          endTime = moment.utc().add(1, 'days').format("HH:mm:ss");
        }
  
        // Check if the current time is within the start and end time
        if (startTime <= currentTime && currentTime <= endTime) {
          console.log('local is open')
          return true;
        }
      }
    }
  
    // If none of the above cases are met, the local is closed
    return false;
  }
  
  
  
