import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Slider } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";

function CustomSlider({
  defaultValue,
  value = 20,
  step = 10,
  min = 0,
  max = 60,
  backgroundColor = "#000000",
  color = "#000000",
  onChange,
  ...rest
}) {
  const theme = useTheme();

  const sliderStyle = {
    color: color,
    height: 8,
    "& .MuiSlider-track": {
      border: "none",
    },
    "& .MuiSlider-thumb": {
      height: 24,
      width: 24,
      backgroundColor: color,
      border: "2px solid currentColor",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
    "& .MuiSlider-valueLabel": {
      lineHeight: 1.2,
      fontSize: 12,
      background: "unset",
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: "50% 50% 50% 0",
      backgroundColor: color,
      transformOrigin: "bottom left",
      transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
      "&:before": { display: "none" },
      "&.MuiSlider-valueLabelOpen": {
        transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
      },
      "& > *": {
        transform: "rotate(45deg)",
      },
    },
  };

  return (
    <Slider
      sx={sliderStyle}
      style={{ width: "100%" }}
      aria-label="Frequenza in minuti"
      value={value}
      step={step}
      min={min}
      max={max}
      defaultValue={defaultValue}
      onChange={onChange}
      {...rest}
    />
  );
}

export default CustomSlider;

CustomSlider.propTypes = {
  value: PropTypes.number,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
  backgroungColor: PropTypes.string,
  color: PropTypes.string,
};

