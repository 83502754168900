import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  // we init with resources
  resources: {
    it: {
      translations: {
        "Torna al Menu":"Torna al Menù",
        "Lunedì":"Lunedì",
        "Martedi":"Martedì",
        "Mercoledi":"Mercoledì",
        "Giovedi":"Giovedì",
        "Venerdi":"Venerdì",
        "Menu":"Menù"
      },
    },
    en: {
      translations: {
        "Chiudi":"Close",
        "Seleziona la tua lingua":"Please select your language.",
        "Impostazioni Locale": "Local Settings",
        Servito: "Served",
        Categorie: "Categories",
        Allergeni: "Allergens",
        "I miei locali": "My locals",
        "Cerca locali": "Search locals",
        Contattaci: "Contact us",
        "Su di noi": "About us",
        "I miei ordini": "My orders",
        "Salva e chiudi": "Save and close",
        Prezzo: "Price",
        Aggiungi: "Add",
        "Aggiungi ingredienti extra": "Extra ingredients",
        Ingredienti: "ingredients",
        Messaggio: "Message",
        Servizio: "Service",
        "Modifica Locale": "Edit local",
        "Modifica Menù": "Edit Menù",
        "Filtri Menù": "Filter Menù",
        Condividi: "Share",
        Lingua: "Language",
        "Tavolo Qrcode": "Table Qrcode",
        "Gestione ordini": "Orders Handler",
        "Riepilogo Ordine": "Recap Order",
        Appunti: "Notes",
        Svuota: "Empty",
        Prosegui: "Next",
        Invia: "Send",
        "Cereali e derivati": "Cereals and derivatives",
        Crostacei: "Shellfish",
        Uova: "Eggs",
        Pesce: "Fish",
        Arachidi: "Peanuts",
        Soia: "Soy",
        Latticini: "Dairy",
        "Frutta a guscio": "Fruits and nuts",
        Sedano: "Celery",
        Senape: "Mustard",
        Sesamo: "Sesame",
        "Anidride solforosa e solfiti": "Sulfur dioxide and sulfites",
        Lupini: "Lupin",
        Molluschi: "Mollusks",
        Vegano: "Vegan",
        Vegetariano: "Vegetarian",
        Proteico: "Protein-rich",
        Vitaminico: "Vitamin-rich",
        Congelato: "Frozen",
        "Low Carb": "Low-carb",
        "Low Fat": "Low-fat",
        "Senza Glutine": "Gluten-free",
        "No alcool": "Non-alcoholic",
        "Low alcool": "Low-alcohol",
        "High alcool": "High-alcohol",
        "Il piu amato": "Most loved",
        "Il piu apprezzato": "Most appreciated",
        "Suggerito dallo chef": "Chef recommended",
        "Suggerito dal bartender": "Bartender recommended",
        Artigianale: "Artisan",
        Homemade: "Homemade",
        "Il piu venduto": "Bestseller",
        "Piatto del giorno": "Dish of the day",
        Totale: "Total",
        Quantità: "Quantity",
        "Nome ingrediente": "Ingredient name",
        "Privacy e Cookie Policy": "Privacy and Cookie Policy",
        Admin: "Admin",
        Dashboard: "Dashboard",
        "Non hai effettuato il login, per salvare il tuo ordine e monitorarlo ti consigliamo di effettuare l'accesso tramite il pulsante sottostante prima di inviare l'ordine":
          "You are not logged in, to save your order and monitor it we advise you to log in using the button below before sending your order",
        "Invia senza autenticazione": "Send without authentication",
        "Non sei associato a nessun tavolo, rivolgiti al personale per chiedere un nuovo":
          "You are not oassociate on a table, ask to staff a new",
        "L'aggiunta di ingredienti extra potrebbe comportare allergeni non indicati":
          "The addition of extra ingredients could lead to allergens not foreseen",
        "Siamo chiusi": "We are closed",

        "Cerca per ingrediente": "Search by ingredient",
        Filtri: "Filters",
        "Applica filtri": "Apply filters",
        "Cancella filtri": "Clear filters",
        "Escludi allergeni": "Exclude allergens",
        "Includi categorie": "Include categories",
        // traduci per le lingue en,fr,es,de,ja,zh da qui in poi quello sopra sono esempi nello stesso formato
        "Il più venduto": "Best Selling",
        Biologico: "Organic",
        Abbattuto: "Slaughtered",
        Piccante: "Spicy",
        "Il più amato": "Most Loved",
        "Il più apprezzato": "Most Appreciated",

        "Può contenere ingredienti precongelati":
          "May contain pre-frozen ingredients",
        "Sono presenti ingredienti biologici": "Contains organic ingredients",
        "Sono presenti ingredienti abbattuti":
          "Contains slaughtered ingredients",

        Contatti: "Contacts",
        "Installa App": "Install App",
        "Torna al Menu": "Back to menu",
        "Vai al menù": "Go to menu",
        "Modifica": "Edit activity",
        Contattaci: "Contact us",
        "Motivo del Contatto": "Reason for Contact",
        Nome: "Name",
        Cognome: "Surname",
        Email: "Email",
        Telefono: "Phone",
        "Descrizione dettagliata": "Detailed Description",
        Lunedi: "Monday",
        Martedi: "Tuesday",
        Mercoledi: "Wednesday",
        Giovedi: "Thursday",
        Venerdi: "Friday",
        Sabato: "Saturday",
        Domenica: "Sunday",
        "Ordina e ricevi direttamente a casa": "Order and receive directly at home",
        "Ordina e ritira al locale": "Order and pick up at the restaurant",
        "Prenota un tavolo": "Book a table",
        Carrello: "Cart",
        Note: "Notes",
        Opzioni: "Options",
        Altro: "More",
      },
    },
    fr: {
      translations: {
        "Chiudi":"fermer",
        "Seleziona la tua lingua": "Veuillez sélectionner votre langue.",
        "Impostazioni Locale": "Paramètres locaux",
        Servito: "Servi",
        Categorie: "Catégories",
        Allergeni: "Allergènes",
        "I miei ordini": "Mes commandes",
        "I miei locali": "Mes établissements",
        "Cerca locali": "Rechercher des établissements",
        Contattaci: "Contactez-nous",
        "Su di noi": "À propos de nous",
        "Salva e chiudi": "Enregistrer et fermer",
        Prezzo: "Prix",
        Aggiungi: "Ajouter",
        "Aggiungi ingredienti extra": "Ingrédients supplémentaires",
        Ingredienti: "Ingrédients",
        Messaggio: "Message",
        Servizio: "Service",
        "Modifica Locale": "Modifier l'établissement",
        "Modifica Menù": "Modifier le menu",
        "Filtri Menù": "Filtrer le menu",
        Condividi: "Partager",
        Lingua: "Langue",
        "Tavolo Qrcode": "Table Qrcode",
        "Gestione ordini": "Gestion des commandes",
        "Riepilogo Ordine": "Récapitulatif de commande",
        Appunti: "Notes",
        Svuota: "Vider",
        Prosegui: "Continuer",
        Invia: "Envoyer",
        "Cereali e derivati": "Céréales et dérivés",
        Crostacei: "Crustacés",
        Uova: "Œufs",
        Pesce: "Poisson",
        Arachidi: "Arachides",
        Soia: "Soja",
        Latticini: "Produits laitiers",
        "Frutta a guscio": "Fruits à coque",
        Sedano: "Céleri",
        Senape: "Moutarde",
        Sesamo: "Sésame",
        "Anidride solforosa e solfiti": "Dioxyde de soufre et sulfites",
        Lupini: "Lupin",
        Molluschi: "Mollusques",
        Vegano: "Végan",
        Vegetariano: "Végétarien",
        Proteico: "Riche en protéines",
        Vitaminico: "Riche en vitamines",
        Congelato: "Congelé",
        "Low Carb": "Faible en glucides",
        "Low Fat": "Faible en gras",
        "Senza Glutine": "Sans gluten",
        "No alcool": "Sans alcool",
        "Low alcool": "Faible en alcool",
        "High alcool": "Riche en alcool",
        "Il piu amato": "Le plus aimé",
        "Il piu apprezzato": "Le plus apprécié",
        "Suggerito dallo chef": "Recommandé par le chef",
        "Suggerito dal bartender": "Recommandé par le barman",
        Artigianale: "Artisanal",
        Homemade: "Fait maison",
        "Il piu venduto": "Meilleure vente",
        "Piatto del giorno": "Plat du jour",
        Totale: "Total",
        Quantità: "Quantité",
        "Nome ingrediente": "Nom de l'ingrédient",
        "Non hai effettuato il login, per salvare il tuo ordine e monitorarlo ti consigliamo di effettuare l'accesso tramite il pulsante sottostante prima di inviare l'ordine":
          "Vous n'êtes pas connecté, pour sauvegarder votre commande et la suivre, nous vous conseillons de vous connecter en utilisant le bouton ci-dessous avant d'envoyer votre commande",
        "Invia senza autenticazione": "Envoyer sans authentification",
        "Non sei associato a nessun tavolo, rivolgiti al personale per chiedere un nuovo":
          "Vous n'êtes associé à aucune table, demandez au personnel pour en obtenir une nouvelle",
        "L'aggiunta di ingredienti extra potrebbe comportare allergeni non indicati":
          "L'ajout d'ingrédients supplémentaires pourrait entraîner des allergènes non prévus",
        "Privacy e Cookie Policy": "Politique de confidentialité et de cookies",
        Admin: "Admin",
        Dashboard: "Tableau de bord",
        "Siamo chiusi": "Nous sommes fermés",

        "Cerca per ingrediente": "Rechercher par ingrédient",
        Filtri: "Filtres",
        "Applica filtri": "Appliquer les filtres",
        "Cancella filtri": "Effacer les filtres",
        "Escludi allergeni": "Exclure les allergènes",
        "Includi categorie": "Inclure les catégories",

        "Il più venduto": "Le plus vendu",
        Biologico: "Biologique",
        Abbattuto: "Abattu",
        Piccante: "Épicé",
        "Il più amato": "Le plus aimé",
        "Il più apprezzato": "Le plus apprécié",

        "Può contenere ingredienti precongelati":
          "Peut contenir des ingrédients pré-congelés",
        "Sono presenti ingredienti biologici":
          "Contient des ingrédients biologiques",
        "Sono presenti ingredienti abbattuti":
          "Contient des ingrédients abattus",
        Contatti: "Contacts",
        "Installa App": "Installer l'app",
        "Torna al Menu": "Retour au menu",
        "Vai al menù": "Aller au menu",
        "Modifica": "Modifier l'activité",
        "Motivo del Contatto": "Motif du contact",
        Nome: "Prénom",
        Cognome: "Nom de famille",
        Email: "E-mail",
        Telefono: "Téléphone",
        "Descrizione dettagliata": "Description détaillée",
        Lunedi: "Lundi",
        Martedi: "Mardi",
        Mercoledi: "Mercredi",
        Giovedi: "Jeudi",
        Venerdi: "Vendredi",
        Sabato: "Samedi",
        Domenica: "Dimanche",
        "Ordina e ricevi direttamente a casa": "Commandez et recevez directement à domicile",
        "Ordina e ritira al locale": "Commandez et récupérez sur place",
        "Prenota un tavolo": "Réservez une table",
        Carrello: "Panier",
        Note: "Notes",
        Opzioni: "Options",
        Altro: "Autre",
      },
    },
    es: {
      translations: {
        "Chiudi":"Cerran",
        "Seleziona la tua lingua": "Seleccione su idioma.",
        "Impostazioni Locale": "Configuración local",
        Servito: "Servido",
        Categorie: "Categorías",
        Allergeni: "Alérgenos",
        "I miei ordini": "Mis pedidos",
        "I miei locali": "Mis locales",
        "Cerca locali": "Buscar locales",
        Contattaci: "Contáctanos",
        "Su di noi": "Acerca de nosotros",
        "Salva e chiudi": "Guardar y cerrar",
        Prezzo: "Precio",
        Aggiungi: "Añadir",
        "Aggiungi ingredienti extra": "Añadir ingredientes extra",
        Ingredienti: "Ingredientes",
        Messaggio: "Mensaje",
        Servizio: "Servicio",
        "Modifica Locale": "Editar local",
        "Modifica Menù": "Editar Menú",
        "Filtri Menù": "Filtrar Menú",
        Condividi: "Compartir",
        Lingua: "Idioma",
        "Tavolo Qrcode": "Código QR de la mesa",
        "Gestione ordini": "Gestión de pedidos",
        "Riepilogo Ordine": "Resumen del Pedido",
        Appunti: "Notas",
        Svuota: "Vaciar",
        Prosegui: "Continuar",
        Invia: "Enviar",
        "Cereali e derivati": "Cereales y derivados",
        Crostacei: "Crustáceos",
        Uova: "Huevos",
        Pesce: "Pescado",
        Arachidi: "Cacahuetes",
        Soia: "Soja",
        Latticini: "Lácteos",
        "Frutta a guscio": "Frutos secos",
        Sedano: "Apio",
        Senape: "Mostaza",
        Sesamo: "Sésamo",
        "Anidride solforosa e solfiti": "Dióxido de azufre y sulfitos",
        Lupini: "Altramuces",
        Molluschi: "Moluscos",
        Vegano: "Vegano",
        Vegetariano: "Vegetariano",
        Proteico: "Rico en proteínas",
        Vitaminico: "Rico en vitaminas",
        Congelato: "Congelado",
        "Low Carb": "Bajo en carbohidratos",
        "Low Fat": "Bajo en grasa",
        "Senza Glutine": "Sin gluten",
        "No alcool": "Sin alcohol",
        "Low alcool": "Bajo en alcohol",
        "High alcool": "Alto en alcohol",
        "Il piu amato": "El más amado",
        "Il piu apprezzato": "El más apreciado",
        "Suggerito dallo chef": "Recomendado por el chef",
        "Suggerito dal bartender": "Recomendado por el bartender",
        Artigianale: "Artesanal",
        Homemade: "Hecho en casa",
        "Il piu venduto": "Más vendido",
        "Piatto del giorno": "Plato del día",
        Totale: "Total",
        Quantità: "Cantidad",
        "Nome ingrediente": "Nombre del ingrediente",
        "Non hai effettuato il login, per salvare il tuo ordine e monitorarlo ti consigliamo di effettuare l'accesso tramite il pulsante sottostante prima di inviare l'ordine":
          "No has iniciado sesión, para guardar tu pedido y monitorearlo te recomendamos iniciar sesión usando el botón de abajo antes de enviar el pedido",
        "Invia senza autenticazione": "Enviar sin autenticación",
        "Non sei associato a nessun tavolo, rivolgiti al personale per chiedere un nuovo":
          "No estás asociado a ninguna mesa, solicita al personal una nueva",
        "L'aggiunta di ingredienti extra potrebbe comportare allergeni non indicati":
          "La adición de ingredientes extra podría conllevar alérgenos no previstos",
        "Privacy e Cookie Policy": "Política de privacidad y de cookies",
        Admin: "Administrador",
        Dashboard: "Panel de control",
        "Siamo chiusi": "Estamos cerrados",

        "Cerca per ingrediente": "Buscar por ingrediente",
        Filtri: "Filtros",
        "Applica filtri": "Aplicar filtros",
        "Cancella filtri": "Borrar filtros",
        "Escludi allergeni": "Excluir alérgenos",
        "Includi categorie": "Incluir categorías",

        "Il più venduto": "Más Vendido",
        Biologico: "Orgánico",
        Abbattuto: "Sacrificado",
        Piccante: "Picante",
        "Il più amato": "Más Querido",
        "Il più apprezzato": "Más Apreciado",

        "Può contenere ingredienti precongelati":
          "Puede contener ingredientes precongelados",
        "Sono presenti ingredienti biologici":
          "Contiene ingredientes orgánicos",
        "Sono presenti ingredienti abbattuti":
          "Contiene ingredientes sacrificados",
        Contatti: "Contactos",
        "Installa App": "Instalar aplicación",
        "Torna al Menu": "Volver al menú",
        "Vai al menù": "Ir al menú",
        "Modifica": "Editar actividad",
        "Motivo del Contatto": "Motivo de contacto",
        Nome: "Nombre",
        Cognome: "Apellido",
        Email: "Correo electrónico",
        Telefono: "Teléfono",
        "Descrizione dettagliata": "Descripción detallada",
        Lunedi: "Lunes",
        Martedi: "Martes",
        Mercoledi: "Miércoles",
        Giovedi: "Jueves",
        Venerdi: "Viernes",
        Sabato: "Sábado",
        Domenica: "Domingo",
        "Ordina e ricevi direttamente a casa": "Ordena y recibe directamente en casa",
        "Ordina e ritira al locale": "Ordena y recoge en el local",
        "Prenota un tavolo": "Reserva una mesa",
        Contattaci: "Contáctanos",
        Carrello: "Carrito",
        Note: "Notas",
        Opzioni: "Opciones",
        Altro: "Más",
      },
    },
    de: {
      translations: {
        "Chiudi":"schließen",
        "Seleziona la tua lingua": "Bitte wählen Sie Ihre Sprache.",
        "Impostazioni Locale": "Lokale Einstellungen",
        Servito: "Serviert",
        Categorie: "Kategorien",
        Allergeni: "Allergene",
        "I miei ordini": "Meine Bestellungen",
        "I miei locali": "Meine Lokale",
        "Cerca locali": "Lokale suchen",
        Contattaci: "Kontaktiere uns",
        "Su di noi": "Über uns",
        "Salva e chiudi": "Speichern und schließen",
        Prezzo: "Preis",
        Aggiungi: "Hinzufügen",
        "Aggiungi ingredienti extra": "Zusätzliche Zutaten",
        Ingredienti: "Zutaten",
        Messaggio: "Nachricht",
        Servizio: "Service",
        "Modifica Locale": "Lokal bearbeiten",
        "Modifica Menù": "Menü bearbeiten",
        "Filtri Menù": "Menüfilter",
        Condividi: "Teilen",
        Lingua: "Sprache",
        "Tavolo Qrcode": "Tisch-QR-Code",
        "Gestione ordini": "Bestellverwaltung",
        "Riepilogo Ordine": "Bestellübersicht",
        Appunti: "Notizen",
        Svuota: "Leeren",
        Prosegui: "Weiter",
        Invia: "Senden",
        "Cereali e derivati": "Getreide und Derivate",
        Crostacei: "Krustentiere",
        Uova: "Eier",
        Pesce: "Fisch",
        Arachidi: "Erdnüsse",
        Soia: "Soja",
        Latticini: "Milchprodukte",
        "Frutta a guscio": "Schalenfrüchte",
        Sedano: "Sellerie",
        Senape: "Senf",
        Sesamo: "Sesam",
        "Anidride solforosa e solfiti": "Schwefeldioxid und Sulfite",
        Lupini: "Lupinen",
        Molluschi: "Weichtiere",
        Vegano: "Vegan",
        Vegetariano: "Vegetarisch",
        Proteico: "Proteinreich",
        Vitaminico: "Vitaminreich",
        Congelato: "Gefroren",
        "Low Carb": "Kohlenhydratarm",
        "Low Fat": "Fettarm",
        "Senza Glutine": "Glutenfrei",
        "No alcool": "Alkoholfrei",
        "Low alcool": "Niedriger Alkoholgehalt",
        "High alcool": "Hoher Alkoholgehalt",
        "Il piu amato": "Am beliebtesten",
        "Il piu apprezzato": "Am meisten geschätzt",
        "Suggerito dallo chef": "Vom Chef empfohlen",
        "Suggerito dal bartender": "Vom Barkeeper empfohlen",
        Artigianale: "Handwerklich",
        Homemade: "Hausgemacht",
        "Il piu venduto": "Bestseller",
        "Piatto del giorno": "Gericht des Tages",
        Totale: "Gesamt",
        Quantità: "Menge",
        "Nome ingrediente": "Zutatenname",
        "Non hai effettuato il login, per salvare il tuo ordine e monitorarlo ti consigliamo di effettuare l'accesso tramite il pulsante sottostante prima di inviare l'ordine":
          "Sie sind nicht eingeloggt, um Ihre Bestellung zu speichern und zu überwachen, empfehlen wir Ihnen, sich über den unten stehenden Button einzuloggen, bevor Sie die Bestellung absenden",
        "Invia senza autenticazione": "Ohne Authentifizierung senden",
        "Non sei associato a nessun tavolo, rivolgiti al personale per chiedere un nuovo":
          "Sie sind keinem Tisch zugeordnet, wenden Sie sich an das Personal, um einen neuen zu beantragen",
        "L'aggiunta di ingredienti extra potrebbe comportare allergeni non indicati":
          "Das Hinzufügen zusätzlicher Zutaten kann zu nicht angegebenen Allergenen führen",
        "Privacy e Cookie Policy": "Datenschutz- und Cookie-Richtlinie",
        "Siamo chiusi": "Wir sind geschlossen",

        "Cerca per ingrediente": "Nach Zutat suchen",
        Filtri: "Filter",
        "Applica filtri": "Filter anwenden",
        "Cancella filtri": "Filter löschen",
        "Escludi allergeni": "Allergene ausschließen",
        "Includi categorie": "Kategorien einschließen",

        "Il più venduto": "Meistverkauft",
        Biologico: "Biologisch",
        Abbattuto: "Geschlachtet",
        Piccante: "Würzig",
        "Il più amato": "Am meisten geliebt",
        "Il più apprezzato": "Am meisten geschätzt",

        "Può contenere ingredienti precongelati":
          "Kann vorgefrorene Zutaten enthalten",
        "Sono presenti ingredienti biologici": "Enthält biologische Zutaten",
        "Sono presenti ingredienti abbattuti": "Enthält geschlachtete Zutaten",
        Contatti: "Kontakte",
        "Installa App": "App installieren",
        "Torna al Menu": "Zurück zum Menü",
        "Vai al menù": "Zum Menü gehen",
        "Modifica": "Aktivität bearbeiten",
        "Motivo del Contatto": "Kontaktgrund",
        Nome: "Vorname",
        Cognome: "Nachname",
        Email: "E-Mail",
        Telefono: "Telefon",
        "Descrizione dettagliata": "Detaillierte Beschreibung",
        Lunedi: "Montag",
        Martedi: "Dienstag",
        Mercoledi: "Mittwoch",
        Giovedi: "Donnerstag",
        Venerdi: "Freitag",
        Sabato: "Samstag",
        Domenica: "Sonntag",
        "Ordina e ricevi direttamente a casa": "Bestellen und direkt nach Hause liefern lassen",
        "Ordina e ritira al locale": "Bestellen und im Lokal abholen",
        "Prenota un tavolo": "Einen Tisch reservieren",
        Contattaci: "Kontaktiere uns",
        Carrello: "Warenkorb",
        Note: "Notizen",
        Opzioni: "Optionen",
        Altro: "Mehr",
      },
    },
    ja: {
      translations: {
        "Chiudi":"閉じる",
        "Seleziona la tua lingua": "言語を選択してください",
        "Impostazioni Locale":"ローカル設定",
        Servito: "提供された",
        Categorie: "カテゴリ",
        Allergeni: "アレルゲン",
        "I miei ordini": "私の注文",
        "I miei locali": "私のローカル",
        "Cerca locali": "ローカルを探す",
        Contattaci: "お問い合わせ",
        "Su di noi": "私たちについて",
        "Salva e chiudi": "保存して閉じる",
        Prezzo: "価格",
        Aggiungi: "追加",
        "Aggiungi ingredienti extra": "追加の食材",
        Ingredienti: "材料",
        Messaggio: "メッセージ",
        Servizio: "サービス",
        "Modifica Locale": "ローカルの編集",
        "Modifica Menù": "メニューの編集",
        "Filtri Menù": "メニューフィルタ",
        Condividi: "共有",
        Lingua: "言語",
        "Tavolo Qrcode": "テーブルQRコード",
        "Gestione ordini": "注文管理",
        "Riepilogo Ordine": "注文の概要",
        Appunti: "ノート",
        Svuota: "空にする",
        Prosegui: "次へ",
        Invia: "送信",
        "Cereali e derivati": "穀物とその派生物",
        Crostacei: "甲殻類",
        Uova: "卵",
        Pesce: "魚",
        Arachidi: "ピーナッツ",
        Soia: "大豆",
        Latticini: "乳製品",
        "Frutta a guscio": "ナッツ類",
        Sedano: "セロリ",
        Senape: "マスタード",
        Sesamo: "ゴマ",
        "Anidride solforosa e solfiti": "二酸化硫黄と亜硫酸塩",
        Lupini: "ルピナス",
        Molluschi: "軟体動物",
        Vegano: "ビーガン",
        Vegetariano: "ベジタリアン",
        Proteico: "高タンパク質",
        Vitaminico: "ビタミン豊富",
        Congelato: "冷凍",
        "Low Carb": "低炭水化物",
        "Low Fat": "低脂肪",
        "Senza Glutine": "グルテンフリー",
        "No alcool": "ノンアルコール",
        "Low alcool": "低アルコール",
        "High alcool": "高アルコール",
        "Il piu amato": "最も愛される",
        "Il piu apprezzato": "最も評価される",
        "Suggerito dallo chef": "シェフのおすすめ",
        "Suggerito dal bartender": "バーテンダーのおすすめ",
        Artigianale: "手作り",
        Homemade: "自家製",
        "Il piu venduto": "ベストセラー",
        "Piatto del giorno": "本日の料理",
        Totale: "合計",
        Quantità: "数量",
        "Nome ingrediente": "食材名",
        "Non hai effettuato il login, per salvare il tuo ordine e monitorarlo ti consigliamo di effettuare l'accesso tramite il pulsante sottostante prima di inviare l'ordine":
          "ログインしていません。注文を保存し、追跡するには、注文を送信する前に下のボタンからログインすることをお勧めします",
        "Invia senza autenticazione": "認証なしで送信",
        "Non sei associato a nessun tavolo, rivolgiti al personale per chiedere un nuovo":
          "どのテーブルにも割り当てられていません。スタッフに新しいテーブルを依頼してください",
        "L'aggiunta di ingredienti extra potrebbe comportare allergeni non indicati":
          "余分な成分の追加により、予期しないアレルゲンが含まれる可能性があります",
        "Privacy e Cookie Policy": "プライバシーとクッキーポリシー",
        Admin: "管理者",
        Dashboard: "ダッシュボード",
        Home: "ホーム",
        "Siamo chiusi": "閉まっています",

        "Cerca per ingrediente": "材料で探す",
        Filtri: "フィルター",
        "Applica filtri": "フィルターを適用",
        "Cancella filtri": "フィルターをクリア",
        "Escludi allergeni": "アレルゲンを除外",
        "Includi categorie": "カテゴリーを含む",

        "Il più venduto": "最も売れている",
        Biologico: "オーガニック",
        Abbattuto: "屠殺",
        Piccante: "辛い",
        "Il più amato": "最も愛されている",
        "Il più apprezzato": "最も高く評価されている",

        "Può contenere ingredienti precongelati":
          "冷凍前の成分を含む可能性があります",
        "Sono presenti ingredienti biologici":
          "オーガニック成分が含まれています",
        "Sono presenti ingredienti abbattuti": "屠殺された成分が含まれています",
        Contatti: "連絡先",
        "Installa App": "アプリをインストールする",
        "Torna al Menu": "メニューに戻る",
        "Vai al menù": "メニューに進む",
        "Modifica": "活動を編集する",
        "Motivo del Contatto": "連絡理由",
        Nome: "名",
        Cognome: "姓",
        Email: "メール",
        Telefono: "電話",
        "Descrizione dettagliata": "詳細な説明",
        Lunedi: "月曜日",
        Martedi: "火曜日",
        Mercoledi: "水曜日",
        Giovedi: "木曜日",
        Venerdi: "金曜日",
        Sabato: "土曜日",
        Domenica: "日曜日",
        "Ordina e ricevi direttamente a casa": "自宅に直接注文して受け取る",
        "Ordina e ritira al locale": "店で注文して受け取る",
        "Prenota un tavolo": "テーブルを予約する",
        Contattaci: "お問い合わせ",
        Carrello: "カート",
        Note: "ノート",
        Opzioni: "オプション",
        Altro: "その他",
      },
    },
    zh: {
      translations: {
        "Chiudi":"关闭",
        "Seleziona la tua lingua": "请选择您的语言。",
        "Impostazioni Locale":"本地设置",
        Servito: "上菜",
        Categorie: "分类",
        Allergeni: "过敏原",
        "I miei ordini": "我的订单",
        "I miei locali": "我的场所",
        "Cerca locali": "搜索场所",
        Contattaci: "联系我们",
        "Su di noi": "关于我们",
        "Salva e chiudi": "保存并关闭",
        Prezzo: "价格",
        Aggiungi: "添加",
        "Aggiungi ingredienti extra": "添加额外配料",
        Ingredienti: "成分",
        Messaggio: "信息",
        Servizio: "服务",
        "Modifica Locale": "编辑场所",
        "Modifica Menù": "编辑菜单",
        "Filtri Menù": "菜单过滤",
        Condividi: "分享",
        Lingua: "语言",
        "Tavolo Qrcode": "桌子二维码",
        "Gestione ordini": "订单管理",
        "Riepilogo Ordine": "订单摘要",
        Appunti: "笔记",
        Svuota: "清空",
        Prosegui: "继续",
        Invia: "发送",
        "Cereali e derivati": "谷物及其制品",
        Crostacei: "甲壳类",
        Uova: "鸡蛋",
        Pesce: "鱼",
        Arachidi: "花生",
        Soia: "大豆",
        Latticini: "乳制品",
        "Frutta a guscio": "坚果",
        Sedano: "芹菜",
        Senape: "芥末",
        Sesamo: "芝麻",
        "Anidride solforosa e solfiti": "二氧化硫和亚硫酸盐",
        Lupini: "羽扇豆",
        Molluschi: "软体动物",
        Vegano: "素食",
        Vegetariano: "蔬食",
        Proteico: "高蛋白",
        Vitaminico: "富含维生素",
        Congelato: "冷冻",
        "Low Carb": "低碳水化合物",
        "Low Fat": "低脂",
        "Senza Glutine": "无麸质",
        "No alcool": "无酒精",
        "Low alcool": "低酒精",
        "High alcool": "高酒精",
        "Il piu amato": "最受欢迎",
        "Il piu apprezzato": "最受赞赏",
        "Suggerito dallo chef": "厨师推荐",
        "Suggerito dal bartender": "调酒师推荐",
        Artigianale: "手工制作",
        Homemade: "家制",
        "Il piu venduto": "畅销",
        "Piatto del giorno": "每日特色",
        Totale: "总计",
        Quantità: "数量",
        "Nome ingrediente": "成分名称",
        "Non hai effettuato il login, per salvare il tuo ordine e monitorarlo ti consigliamo di effettuare l'accesso tramite il pulsante sottostante prima di inviare l'ordine":
          "您尚未登录，为了保存您的订单并进行监控，我们建议您在发送订单前使用下面的按钮登录",
        "Invia senza autenticazione": "无需认证发送",
        "Non sei associato a nessun tavolo, rivolgiti al personale per chiedere un nuovo":
          "您未与任何桌子关联，请联系工作人员申请新的",
        "L'aggiunta di ingredienti extra potrebbe comportare allergeni non indicati":
          "添加额外成分可能导致未预见的过敏原",
        "Privacy e Cookie Policy": "隐私和Cookie政策",
        Admin: "管理员",
        Dashboard: "仪表板",
        Home: "首页",
        "Siamo chiusi": "我们已关闭",

        "Cerca per ingrediente": "按成分搜索",
        Filtri: "过滤器",
        "Applica filtri": "应用过滤器",
        "Cancella filtri": "清除过滤器",
        "Escludi allergeni": "排除过敏源",
        "Includi categorie": "包括类别",

        "Il più venduto": "最畅销",
        Biologico: "有机",
        Abbattuto: "宰杀",
        Piccante: "辣",
        "Il più amato": "最受喜爱",
        "Il più apprezzato": "最受欢迎",

        "Può contenere ingredienti precongelati": "可能包含预冻成分",
        "Sono presenti ingredienti biologici": "包含有机成分",
        "Sono presenti ingredienti abbattuti": "包含宰杀成分",
        Contatti: "联系方式",
        "Installa App": "安装应用",
        "Torna al Menu": "返回菜单",
        "Vai al menù": "前往菜单",
        "Modifica": "编辑活动",
        "Motivo del Contatto": "联系原因",
        Nome: "名字",
        Cognome: "姓",
        Email: "电子邮件",
        Telefono: "电话",
        "Descrizione dettagliata": "详细描述",
        Lunedi: "星期一",
        Martedi: "星期二",
        Mercoledi: "星期三",
        Giovedi: "星期四",
        Venerdi: "星期五",
        Sabato: "星期六",
        Domenica: "星期日",
        "Ordina e ricevi direttamente a casa": "订购并直接送货上门",
        "Ordina e ritira al locale": "订购并在店内取货",
        "Prenota un tavolo": "预订桌位",
        Contattaci: "联系我们",
        Carrello: "购物车",
        Note: "笔记",
        Opzioni: "选项",
        Altro: "更多",
      },
    },
  },
  fallbackLng: "it",
  debug: false,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
