import React, { useState, useRef, useEffect } from 'react';
import { Stack, Box } from '@mui/material';
import Slider from 'react-slick';


export default function CarouselCard({ backgroundColor, height = '200px', highlightColor = 'grey', title, contents, autoSlideDuration, ...rest }) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [progress, setProgress] = useState(0);
    const sliderRef = useRef(null);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false, // Disattiva i pulsanti laterali
        beforeChange: (current, next) => setCurrentIndex(next) // semplicemente imposta l'index corrente
    };

    useEffect(() => {
        let progressInterval; // dichiaralo qui in modo che possa essere utilizzato nell'intero effetto
        if (autoSlideDuration) {
            progressInterval = setInterval(() => {
                setProgress((prev) => {
                    if (prev < 100) return prev + 1;
                    return prev;
                });
            }, autoSlideDuration / 100);
        }
        return () => clearInterval(progressInterval); // pulisci l'intervallo quando il componente si smonta o autoSlideDuration cambia
    }, [autoSlideDuration]);

    useEffect(() => {
        if (progress >= 100) {
            sliderRef.current.slickNext();
            setProgress(0);
        }
    }, [progress]);

    const dotStyle = (index) => {
        const baseStyle = {
            width: 20,
            height: 5,
            margin: 5,
            cursor: 'pointer',
            borderRadius: '10px',
            backgroundColor: 'rgba(128, 128, 128, 0.5)',
            border: `0px solid ${highlightColor}`
        };

        if (index < currentIndex) {
            return { ...baseStyle, backgroundColor: highlightColor };
        } else if (index === currentIndex && autoSlideDuration) {
            return {
                ...baseStyle,
                background: `linear-gradient(to right, ${highlightColor} ${progress}%, rgba(128, 128, 128, 0.5) ${progress}%)`
            };
        }
        return baseStyle;
    };


    return (
        <Box sx={{ background: backgroundColor, padding: '0px', boxShadow: 0 }} {...rest}>
            <Stack direction='column' justifyContent="flex-end" alignItems="flex-start" spacing={1} sx={{ height: '100%' }}>
                {title &&
                    <Box sx={{padding: '2.5% 5% 0px 5%'}}>{title}</Box>
                }
                <Slider ref={sliderRef} {...settings} style={{ width: '100%', minHeight: height, maxHeight: '90vh' }}>
                    {contents.map((content, index) => (
                        <Box key={index} sx={{ height: '100%' }}>
                            <Box sx={{ margin: '5px 5% 0px 5%',height:height }}>
                                {content}
                            </Box>
                        </Box>
                    ))}
                </Slider>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start', marginTop: '10px', padding: '0% 5% 5px 5%' }}>
                    {contents.map((_, index) => (
                        <Box
                            key={index}
                            onClick={() => {
                                setProgress(0);
                                sliderRef.current.slickGoTo(index);
                            }}
                            sx={{ boxShadow: 1, backgroundColor: 'rgba(128, 128, 128, 0.5)', margin: '2px' }}
                            style={dotStyle(index)}
                        />
                    ))}
                </Box>
            </Stack>
        </Box>
    );


}
