import { useTranslation, Trans } from "react-i18next";


export function GetNameByLanguage(obj) {
    const { i18n } = useTranslation();
    const language =  i18n.language?i18n.language.toLowerCase():null
    // If a string is passed in, return the string
    if (!obj){
        return undefined
    }
    if (typeof obj === 'string') {
        return obj;
    }
    
    // Check if the preferred language is in the object
    if (obj[language]) {
        return obj[language];
    } 
    // If the preferred language is not in the object, check for English
    else if (obj['en']) {
        return obj['en'];
    } 
    // If English is also not in the object, check for Italian
    else if (obj['it']) {
        return obj['it'];
    } 
    // If none of the preferred languages are in the object,
    // return the value of the first populated language
    else {
        for (let key in obj) {
            if (obj[key]) {
                return obj[key];
            }
        }
    }
    // If no populated values are found, return a default value or null
    return null;
}
