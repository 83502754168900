import React, {useEffect} from "react";
import {
  Typography,
  SvgIcon,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const FlagIcon = ({ children, ...props }) => (
  <SvgIcon {...props}>{children}</SvgIcon>
);

export default function LanguageModal({ open, setOpen }) {
  useEffect(() => {
    if (open) {
      window.history.pushState("fake-route", document.title, "");
      window.addEventListener("popstate", () => setOpen(false));
    }
  }, [open]);

  const { i18n } = useTranslation();

  const [selectedLanguage, setSelectedLanguage] = React.useState(i18n.language);

  const handleClose = () => {
    setOpen(false);
  };

  const theme = useTheme();

  const handleChangeLanguage = (langCode) => {
    setSelectedLanguage(langCode);
    i18n.changeLanguage(langCode);
  };

  const languages = [
    {
      value: "it",
      label: "Italiano",
      flag: (
        <FlagIcon viewBox="0 0 640 480">
          <path fill="#008d46" d="M0 0h213.3v480H0z" />
          <path fill="#ffffff" d="M213.3 0h213.3v480H213.3z" />
          <path fill="#ce2b37" d="M426.7 0H640v480H426.7z" />
        </FlagIcon>
      ),
    },
    {
      value: "en",
      label: "English",
      flag: (
        <FlagIcon viewBox="0 0 640 480">
          <path fill="#012169" d="M0 0h640v480H0z" />
          <path fill="#FFF" d="m75 0 470 315.3V0H75zm0 480 470-315.3V480H75z" />
          <path
            fill="#C8102E"
            d="M214.4 0 640 276.7V0H214.4zM0 43.4l425.6 278.7H0V43.4zm0 393.2L425.6 120.6H0v316zM214.4 480 0 203.3V480h214.4z"
          />
        </FlagIcon>
      ),
    },
    {
      value: "fr",
      label: "Français",
      flag: (
        <FlagIcon viewBox="0 0 640 480">
          <path fill="#0055A4" d="M0 0h213.3v480H0z" />
          <path fill="#FFF" d="M213.3 0h213.3v480H213.3z" />
          <path fill="#EF4135" d="M426.7 0H640v480H426.7z" />
        </FlagIcon>
      ),
    },
    {
      value: "es",
      label: "Español",
      flag: (
        <FlagIcon viewBox="0 0 640 480">
          <path fill="#aa151b" d="M0 0h640v480H0z" />
          <path fill="#f1bf00" d="M0 160h640v160H0z" />
        </FlagIcon>
      ),
    },
    {
      value: "de",
      label: "Deutsch",
      flag: (
        <FlagIcon viewBox="0 0 640 480">
          <path fill="#ffce00" d="M0 320h640v160H0z" />
          <path d="M0 0h640v160H0z" />
          <path fill="#d00" d="M0 160h640v160H0z" />
        </FlagIcon>
      ),
    },
    {
      value: "ja",
      label: "日本語",
      flag: (
        <FlagIcon viewBox="0 0 640 480">
          <path fill="#ffffff" d="M0 0h640v480H0z" />
          <path
            fill="#bc002d"
            d="M320 240a120 120 0 1 1 0-240 120 120 0 0 1 0 240z"
          />
        </FlagIcon>
      ),
    },
    {
      value: "zh",
      label: "中文",
      flag: (
        <FlagIcon viewBox="0 0 640 480">
          <path fill="#DE2910" d="M0 0h640v480H0z" />
          <path
            fill="#FFDE00"
            d="M213.3 96l-19.1-62.9L158.1 96H98.6l46.3 33.7-17.9 61.8 48.1-35.1 48.1 35.1-17.9-61.8 46.3-33.7h-59.5z"
          />
        </FlagIcon>
      ),
    },
  ];

  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      <DialogTitle
        sx={{ background: theme.palette.primary.main, color: "white" }}
      >
        <Trans>Seleziona la tua lingua</Trans>
        <IconButton
          onClick={() => setOpen(false)}
          style={{
            zIndex: "100",
            display: "inline-block",
            position: "fixed",
            top: 5,
            right: 5,
            width: "35px",
            height: "35px",
            padding: "5px",
            background: "rgb(0 0 0 / 15%)",
          }}
        >
          <CloseRoundedIcon style={{ color: "white" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          {languages.map((lang) => (
            <Grid item xs={12} sm={6} md={4} key={lang.value}>
              <Button
                variant={
                  selectedLanguage === lang.value ? "contained" : "outlined"
                }
                fullWidth
                onClick={() => handleChangeLanguage(lang.value)}
                sx={{
                  justifyContent: "flex-start",
                  padding: "12px",
                  textTransform: "none",
                  backgroundColor:
                    selectedLanguage === lang.value ? "black" : "#dadada",
                  color: selectedLanguage === lang.value ? "white" : "black",
                  borderRadius: theme.shape.borderRadius,
                  transition: "all 0.3s",
                  "&:hover": {
                    backgroundColor:
                      selectedLanguage === lang.value
                        ? theme.palette.primary.dark
                        : "grey",
                  },
                }}
              >
                <Typography>
                  {lang.value.toUpperCase()} | {lang.label}
                </Typography>
              </Button>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{ padding: "16px", background: theme.palette.primary.main }}
      >
        <Button onClick={handleClose} color="secondary">
          <Trans>Chiudi</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
