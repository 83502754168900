import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Box } from "@mui/material";

import { motion, AnimatePresence, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";



export const ItemAnimation = ({
  delay = 0,
  animateInView = true,
  disabled,
  variant = 'left',
  threshold = 0.2,
  oneTime =  false,
  duration = 1,
  bounce = 0.3,
  children,
  ...rest
}) => {
  let [counterViews, setCounterViews] = useState(0);
  let initial = {};
  let animate = {};
  let transition = {};

  if (disabled || variant === "none") {
    initial = { x: 0, y: 0, opacity: 1 };
    animate = { x: 0, y: 0, opacity: 1 };
    transition = { type: "spring", duration: 0, bounce: 0 };
  } else if (variant === "left") {
    initial = { x: "-100%", opacity: 0 };
    animate = { x: 0, opacity: 1 };
    transition = { type: "spring", duration: duration, bounce: bounce };
  } else if (variant === "down") {
    initial = { x: 0, y: "100%", opacity: 0 };
    animate = { x: 0, y: 0, opacity: 1 };
    transition = { type: "spring", duration: duration, bounce: bounce };
  } else if (variant === "up") {
    initial = { x: 0, y: "-100%", opacity: 0 };
    animate = { x: 0, y: 0, opacity: 1 };
    transition = { type: "spring", duration: duration, bounce: bounce };
  } else if (variant === "right") {
    initial = { x: "100%", opacity: 0, rotate: 0 };
    animate = { x: 0, opacity: 1, rotate: 0 };
    transition = { type: "spring", duration: duration, bounce: bounce };
  } else if (variant === "vertical flip") {
    initial = { x: 0, opacity: 1, rotate: 0, rotateY: 270 };
    animate = { rotateY: 0 };
    transition = { type: "spring", duration: duration, bounce: bounce };
  } else if (variant === "horizontal flip") {
    initial = { x: 0, opacity: 1, rotate: 0, rotateX: 270 };
    animate = { rotateX: 0 };
    transition = { type: "spring", duration: duration, bounce: bounce };
  } else if (variant === "right down") {
    initial = { x: "160px", y: "80px", opacity: 0, rotate: -90 };
    animate = { x: 0, y: 0, opacity: 1, rotate: 0 };
    transition = { type: "spring", duration: duration, bounce: bounce };
  } else if (variant === "left down") {
    initial = { x: "-160px", y: "80px", opacity: 0, rotate: -90 };
    animate = { x: 0, y: 0, opacity: 1, rotate: 0 };
    transition = { type: "spring", duration: duration, bounce: bounce };
  } else if (variant === "zoom") {
    initial = { x: 0, y: 0, scale: 0, opacity: 0, rotate: 0 };
    animate = { x: 0, y: 0, opacity: 1, scale: 1, rotate: 0 };
    transition = {
      type: "spring",
      stiffness: 125,
      duration: duration,
      damping: 15,
    };
  } else if (variant === "pendolum") {
    initial = { x: -10, y: -10, scale: 1, opacity: 0, rotate: 20 };
    animate = { x: 0, y: 0, opacity: 1, scale: 1, rotate: 0 };
    transition = {
      type: "spring",
      duration: duration,
      bounce: 1,
      damping: 8,
      ease: "easeInOut",
    };
  } else if (variant === "crazy") {
    const maxX = 160;
    const maxY = 80;
    const minX = -160;
    const minY = -80;
    const maxR = 90;
    const minR = -90;
    const maxS = 1;
    const minS = 0;
    const randomNumberX = Math.floor(Math.random() * (maxX - minX + 1)) + minX;
    const randomNumberY = Math.floor(Math.random() * (maxY - minY + 1)) + minX;
    const randomRotate = Math.floor(Math.random() * (maxR - minR + 1)) + minR;
    const randomScale = Math.floor(Math.random() * (maxS - minS + 1)) + minS;
    initial = {
      x: randomNumberX,
      y: randomNumberY,
      opacity: 0,
      rotate: randomRotate,
      scale: randomScale,
    };
    animate = { x: 0, y: 0, opacity: 1, rotate: 0, scale: 1 };
    transition = { type: "spring", duration: 2, bounce: bounce };
  } else if (variant === "fade") {
    initial = { opacity: 0 };
    animate = { opacity: 1 };
    transition = {
      type: "spring",
      duration: duration,
      bounce: bounce,
    };
  } else {
    initial = { x: "-100%", opacity: 0 };
    animate = { x: 0, opacity: 1 };
    transition = { type: "spring", duration: duration, bounce: bounce };
  }

  const { ref, inView } = useInView({
    threshold: threshold,
  });
  const animation = useAnimation();

  useEffect(() => {
    //initial.transition = transition
    animate.transition = transition;
    if (counterViews > 0) {
      animate.transition.delay = 0;
    } else {
      animate.transition.delay = delay;
    }

    if (inView) {
      animation.start(animate);
      setCounterViews(counterViews + 1);
    }

    if (!inView) {
      if (!oneTime | (counterViews === 0)) {
        animation.start(initial);
      }
    }
  }, [variant]);

  return (
    <Box ref={ref} style={{ width: "100%" }} {...rest}>
      <AnimatePresence mode="wait">
        <motion.div
          key={variant}
          initial={initial}
          whileInView={animateInView && animate}
          animate={(!animateInView || inView) && animate}
          viewport={{ once: oneTime }}
          transition={{ ...transition, delay: delay }}
        >
          {children}
        </motion.div>
      </AnimatePresence>
    </Box>
  );
};

ItemAnimation.propTypes = {
  threshold: PropTypes.number,
  oneTime: PropTypes.bool,
  animateInView: PropTypes.bool,
  delay: PropTypes.number,
  duration: PropTypes.number,
  variant: PropTypes.oneOf([
    "left",
    "down",
    "up",
    "right",
    "none",
    "vertical flip",
    "horizontal flip",
    "left down",
    "right down",
    "zoom",
    "pendolum",
    'crazy',
    "fade"
  ]),
  bounce: PropTypes.number,
};

