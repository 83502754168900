import React from "react";
import "./YouTubeVideo.css";

const YouTubeVideo = ({
  videoId,
  autoplay = false,
  mute = false,
  loop = false,
  controls = true,
  width = "100%",
  height = "480",
  style
}) => {
  let autoplayParam = autoplay ? "1" : "0";
  const muteParam = mute ? "1" : "0";
  const loopParam = loop ? "1" : "0";
  if (loop) {
    autoplayParam = "1";
  }
  const controlsParam = controls ? "1" : "0";
  const playlistParam = loop ? `&playlist=${videoId}` : "";
  const modestbrandingParam = "1"; // Rimuove il logo di YouTube
  const relParam = "0"; // Non mostrare video correlati alla fine
  const showinfoParam = "0"; // Rimuove le informazioni in alto

  return (
    <div
      style={{
        pointerEvents: loop && "none",
      }}
    >
      <iframe
        width={width}
        height={height}
        src={`https://www.youtube.com/embed/${videoId}?autoplay=${autoplayParam}&mute=${muteParam}&loop=${loopParam}&controls=${controlsParam}&modestbranding=${modestbrandingParam}&rel=${relParam}&showinfo=${showinfoParam}${playlistParam}`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded YouTube"
        style={{
          ...style}}
      />
    </div>
  );
};

export default YouTubeVideo;
