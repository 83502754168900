import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import PropTypes from "prop-types";

function DeleteRoomDialog({ open, onClose, onConfirm, roomIndex, localTables }) {
    const handleConfirm = () => {
        const updatedTables = [...localTables];
        updatedTables[roomIndex].initialTables=[];
        console.log('updatedTables:',updatedTables)
        onConfirm(updatedTables);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>Conferma</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Sei sicuro di voler cancellare questa stanza e tutti i suoi tavoli?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Annulla
                </Button>
                <Button onClick={handleConfirm} color="primary" autoFocus>
                    Conferma
                </Button>
            </DialogActions>
        </Dialog>
    );
}

DeleteRoomDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    roomIndex: PropTypes.number.isRequired,
    localTables: PropTypes.array.isRequired
};

export default DeleteRoomDialog;
