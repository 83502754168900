import React from "react";
import {
  AppBar,
  Box,
  Typography,
  IconButton,
  Stack,
  ButtonBase,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { motion, AnimatePresence } from "framer-motion";
import { useTheme } from "@mui/material/styles";
import { calculateVisibilityCounts } from "../MenuFunctions";

const VerticalMenuTabs = ({
  sectionsOrdersFiltered,
  tabValue,
  handleChangeTab,
  handleToggleExpand,
  isExpanded,
  section_names,
  menuData,
  productsIdHidden,
}) => {
  const theme = useTheme();

  return (
    <AppBar
      position="static"
      style={{ position: "sticky", top: 0, zIndex: 10 }}
      component={motion.div}
      initial={{ opacity: 0, y: 0 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, ease: "easeOut" }}
    >
      <ButtonBase
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding="10px"
        component={motion.div}
        transition={{ duration: 0.3 }}
        onClick={handleToggleExpand}
        sx={{
          width: "100%",
          padding: "10px",
          bgcolor: "primary.main",
          color: theme.palette.getContrastText(theme.palette.primary.main),
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            position: "relative",
            marginLeft: "30px",
          }}
        >
          <AnimatePresence mode="wait">
            <motion.div
              key={sectionsOrdersFiltered[tabValue]}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.3 }}
            >
              <Typography variant="h6" align="center">
                {section_names[sectionsOrdersFiltered[tabValue]]?.section_name}
              </Typography>
            </motion.div>
          </AnimatePresence>
        </Box>
        <IconButton
          color="inherit"
          component={motion.div}
          animate={{ rotate: isExpanded ? 180 : 0 }}
          transition={{
            duration: 0.3,
            type: "spring",
            stiffness: 200,
            damping: 10,
          }}
          sx={{ marginLeft: "auto", width: "30px" }}
        >
          <ExpandMoreIcon />
        </IconButton>
      </ButtonBase>
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ opacity: 0, height: 0, overflow: "hidden" }}
            animate={{ opacity: 1, height: "auto", overflowY: "auto" }}
            exit={{ opacity: 0, height: 0, overflow: "hidden" }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
          >
            <Box
              sx={{
                maxHeight: `calc(100vh - 100px)`,
                overflowY: "auto",
                padding: "10px",
                paddingBottom: "80px",
              }}
            >
              <Stack
                direction="column"
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
                aria-label="main menu sections"
              >
                {sectionsOrdersFiltered.map((section_id, index) => {
                  const { elementsShowed, elementsAvaiable } =
                    calculateVisibilityCounts(
                      menuData,
                      section_id,
                      productsIdHidden
                    );
                  return (
                    elementsAvaiable && (
                      <ButtonBase
                        key={section_id}
                        onClick={() => {
                          handleChangeTab(null, index);
                          handleToggleExpand();
                        }}
                        sx={{
                          color:
                            tabValue === index
                              ? theme.palette.getContrastText(
                                  theme.palette.secondary.dark
                                )
                              : theme.palette.getContrastText(
                                  theme.palette.primary.main
                                ),
                          fontWeight: tabValue === index ? "bold" : "normal",
                          maxWidth: "100%",
                          bgcolor:
                            tabValue === index
                              ? theme.palette.secondary.dark
                              : theme.palette.primary.dark,
                          padding: "10px 20px 10px 20px",
                          borderRadius: "50px 0px 50px 0px",
                        }}
                        component={motion.div}
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.5 }}
                        transition={{ delay: index * 0.05, duration: 0.3 }}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        <Typography variant='h7'>
                          {section_names[section_id].section_name}
                        </Typography>
                      </ButtonBase>
                    )
                  );
                })}
              </Stack>
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </AppBar>
  );
};

export default VerticalMenuTabs;
