import React, { useState } from "react";
import { useMotionValue, Reorder, useDragControls } from "framer-motion";
import DragHandleRoundedIcon from "@mui/icons-material/DragHandleRounded";
import ArrowDropUpRoundedIcon from "@mui/icons-material/ArrowDropUpRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import {
  IconButton,
  Box,
  Stack,
  Typography,
  Grid,
  ButtonBase,
  Card,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Hidden } from "@mui/material";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import Locker from "../Forms/Locker";

export const ReorderMenuCard = ({
  item,
  index,
  section_card_color,
  onChange,
  subSection,
  setItems,
  items,
  onEdit,
  planValue,
  onDelete,
  ...rest
}) => {
  const theme = useTheme();
  const language = "it";

  const y = useMotionValue(0);
  const dragControls = useDragControls();

  function onHide(index) {
    items[index].product_hidden = !items[index].product_hidden;
    setItems([...items]);
    onChange([...items]);
  }

  function Fasce({
    selected: initialSelected = ["1", "2", "3", "4", "5"],
    ...rest
  }) {
    const [localSelected, setLocalSelected] = useState(initialSelected);

    const handleChange = (indexPeriods) => {
      let value = (indexPeriods + 1).toString();
      let newSelected = localSelected.includes(value)
        ? localSelected.filter((i) => i !== value)
        : [...localSelected, value];

      setLocalSelected(newSelected);

      if (onChange) {
        onChange(newSelected);
      }

      items[index].productPeriods = newSelected;
      setItems([...items]);
      onChange([...items]);
    };

    return (
      <Box {...rest}>
        <Locker
          locked={planValue < 1}
          message={
            "Le fasce per gestire fino a 5 menù in automatico in base agli orari è disponibile dal piano Business in poi"
          }
        >
          <Typography variant="h6">Fasce</Typography>
          <Grid container spacing={1}>
            {["1", "2", "3", "4", "5"].map((value, index) => (
              <Grid item key={index}>
                <IconButton
                  onClick={() => handleChange(index)}
                  sx={{
                    margin: "0px",
                    padding: "0px",
                    width: "25px",
                    height: "25px",
                    backgroundColor: localSelected.includes(value)
                      ? theme.palette.primary.dark
                      : theme.palette.grey["400"],
                    color: localSelected.includes(value) ? "#ffffff" : "black",
                    border: `2px solid ${
                      localSelected.includes(value) ? "#ffffff" : "black"
                    }`,
                    borderRadius: "50%",
                  }}
                >
                  <Typography variant="button">{value}</Typography>
                </IconButton>
              </Grid>
            ))}
          </Grid>
        </Locker>
      </Box>
    );
  }

  function handleMove(index, direction) {
    let newArray = [...items]; // Copia dell'array
    const targetIndex = direction === "up" ? index - 1 : index + 1;

    // Scambio gli elementi
    [newArray[index], newArray[targetIndex]] = [
      newArray[targetIndex],
      newArray[index],
    ];

    setItems(newArray);
    onChange(newArray);
  }

  function RenderCard({
    product_id,
    product_name,
    product_price,
    onClick,
    sub_section_id,
    product_ai,
  }) {
    const subSectionItem = subSection?.find(
      (i) => i.subSectionId === sub_section_id
    );
    return (
      <ButtonBase
        id={`button-reorder-product-${product_id}`}
        onClick={onClick}
        sx={{ width: "100%", height: "100%", borderRadius: "5px" }}
      >
        <Card
          sx={{
            padding: "5px",
            borderRadius: "5px",
            boxShadow: 3,
            backgroundColor: "#f7f7f7",
            color: "black",
            height: "100%",
            width: "100%",
            minHeight: "50px",
            alignContent: "center",
          }}
        >
          {subSectionItem?.subSectionName?.it && (
            <Card sx={{ padding: "5px", backgroundColor: "#e0e0e0" }}>
              <Typography>{subSectionItem?.subSectionName?.it}</Typography>
            </Card>
          )}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {product_ai && (
              <Box
                sx={{
                  boxShadow: 1,
                  backgroundColor: "#efefbd",
                  color: "black",
                  borderRadius: "5px",
                  padding: "3px",
                }}
              >
                <Typography variant="body2">Da approvare.</Typography>
              </Box>
            )}
            <Typography variant="subtitle2">{product_name}</Typography>
            <Typography variant="subtitle 1">{product_price}€</Typography>
          </Stack>
        </Card>
      </ButtonBase>
    );
  }

  return (
    <Reorder.Item
      value={item}
      id={item.product_name.it}
      style={{ y }}
      dragListener={false}
      dragControls={dragControls}
      as={"div"}
    >
      <Stack
        direction="row"
        spacing={0}
        justifyContent="center"
        alignItems="center"
        {...rest}
      >
        <IconButton
          style={{ padding: "5px" }}
          onClick={() => onDelete(item)}
          aria-label="rimuovi prodotto"
          component="div"
        >
          <DeleteIcon sx={{color:"error.main"}} />
        </IconButton>
        <Stack
          direction="column"
          justifyContent="space-around"
          style={{ padding: "5px" }}
        >
          <IconButton
            style={{ padding: "0px" }}
            disabled={index === 0}
            onClick={() => handleMove(index, "up")}
            aria-label="sposta in alto prodotto"
            component="div"
          >
            <ArrowDropUpRoundedIcon
              color={index === 0 ? "grey" : "text.paper"}
            />
          </IconButton>
          {planValue > 0 && (
            <IconButton
              style={{ padding: "5px" }}
              onClick={() => onHide(index)}
              aria-label="nacondi prodotto"
              component="div"
            >
              {item.product_hidden ? (
                <VisibilityOffRoundedIcon color="text.paper" />
              ) : (
                <VisibilityRoundedIcon color="text.paper" />
              )}
            </IconButton>
          )}

          <IconButton
            style={{ padding: "0px" }}
            disabled={index === items.length - 1}
            onClick={() => handleMove(index, "down")}
            aria-label="sposta in basso prodotto"
            component="div"
          >
            <ArrowDropDownRoundedIcon
              color={index === items.length - 1 ? "grey" : "text.paper"}
            />
          </IconButton>
        </Stack>

        <Hidden mdDown>
          <Fasce
            sx={{ marginRight: "1px" }}
            selected={
              item.productPeriods !== null ? item.productPeriods : undefined
            }
          />
        </Hidden>

        <RenderCard
          product_id={item.product_id}
          product_name={item.product_name[language]}
          product_price={item.product_price}
          sub_section_id={item.sub_section_id}
          product_ai={item.AI}
          onClick={() => onEdit({ product_id: item.product_id })}
        />
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ touchAction: "none", cursor: "grab" }}
          spacing={0}
          onPointerDown={(event) => dragControls.start(event)}
        >
          <ArrowDropUpRoundedIcon fontSize="large" />
          <DragHandleRoundedIcon fontSize="large" style={{ margin: "-25px" }} />
          <ArrowDropDownRoundedIcon fontSize="large" />
        </Stack>
      </Stack>
    </Reorder.Item>
  );
};
