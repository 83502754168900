import React, { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import {
  Alert,
  Button,
  Stack,
  Switch,
  FormControlLabel,
  Link,
} from "@mui/material";

const SubscriptionBanner = ({
  local_id,
  local_subscriptions,
  onSubscribe,
  showPopup = false,
  showToggle = false,
}) => {
  const [open, setOpen] = useState(false);
  const [subscribed, setSubscribed] = useState(false);

  useEffect(() => {
    const dismissedDate = localStorage.getItem(`dismissed_${local_id}`);
    const now = new Date();
    const sixMonthsLater = dismissedDate ? new Date(dismissedDate) : null;

    if (sixMonthsLater) {
      sixMonthsLater.setMonth(sixMonthsLater.getMonth() + 6);
    }

    const isSubscribed = local_subscriptions.some(
      (sub) => sub.local_id === local_id
    );
    setSubscribed(isSubscribed);

    if (showPopup && (!dismissedDate || now > sixMonthsLater)) {
      if (!isSubscribed) {
        setOpen(true);
      }
    }
  }, [local_id, local_subscriptions, showPopup]);

  const handleSubscribe = () => {
    if (showPopup===true) {
      onSubscribe(local_id);
    }
    setSubscribed(true);
    setOpen(false);
  };

  const handleDismiss = () => {
    localStorage.setItem(`dismissed_${local_id}`, new Date().toISOString());
    setOpen(false);
  };

  const handleToggleChange = (event) => {
    if (event.target.checked) {
      handleSubscribe();
    } else {
      if(showPopup===true){
        onSubscribe(local_id); // Update to unsubscribe
      }
      setSubscribed(false);
    }
  };

  return (
    <>
      {showToggle && (
        <FormControlLabel
          control={
            <Switch
              checked={subscribed}
              onChange={handleToggleChange}
              name="subscribeToggle"
              color="primary"
            />
          }
          label="Ricevi notifiche per offerte speciali"
        />
      )}
      {showPopup && (
        <Snackbar
          open={open}
          autoHideDuration={null}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleDismiss}
            severity="info"
            action={
              <Stack direction="column" spacing={1}>
                <Button color="inherit" size="small" onClick={handleSubscribe}>
                  Ok
                </Button>
                <Button color="inherit" size="small" onClick={handleDismiss}>
                  Rifiuto
                </Button>
              </Stack>
            }
          >
            Vuoi ricevere notifiche su offerte speciali ed eventi? Consulta i
            nostri
            <Link
              href="/terms-of-service"
              target="_blank"
              rel="noopener"
              underline="always"
            >
              Termini e Condizioni
            </Link>{" "}
            e
            <Link
              href="/privacy-policy"
              target="_blank"
              rel="noopener"
              underline="always"
            >
              Privacy
            </Link>
            .
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default SubscriptionBanner;
