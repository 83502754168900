import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import PropTypes from 'prop-types';
import TableForm from './TableForm'; // Assumendo che TableForm sia nello stesso percorso

const AddTableDialog = ({ onSave,roomTables, roomName, mode = 'create', tableDetails = null }) => {
    const [open, setOpen] = useState(false);
    const handleSubmit = (formState) => {
        let isValid = true;
        let errors = {
            tableName: false,
            capacity: false,
            shape: false,
            orientation: false,
        };

        Object.keys(errors).forEach(key => {
            if (formState[key] === '' || formState[key] === 0) {
                isValid = false;
                errors = { ...errors, [key]: true };
            }
        });

        if (isValid) {
            onSave(formState);
            setOpen(false);
        } else {
            // Qui potresti gestire gli errori, magari mostrandoli in qualche modo all'utente
            console.error("Errors in the form:", errors);
        }
    };

    return (
        <>
            <Button variant='contained' onClick={() => setOpen(true)}>
                {mode === 'create' ? "Aggiungi tavolo" : "Modifica tavolo"}
            </Button>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>
                    {mode === 'create' ? "Aggiungi un nuovo tavolo" : "Modifica tavolo"}
                </DialogTitle>
                <DialogContent>
                    <TableForm
                    roomName={roomName}
                        onChange={handleSubmit}
                        roomTables={roomTables}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};

AddTableDialog.propTypes = {
    onSave: PropTypes.func.isRequired,
    roomName: PropTypes.string.isRequired,
    mode: PropTypes.oneOf(['create', 'edit']),
    tableDetails: PropTypes.object
};

export default AddTableDialog;
