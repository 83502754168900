import React, { useState, useEffect } from 'react';

import { Stack, Divider, Fade, TypographyFade, Modal, Box, ButtonBase, Collapse, Backdrop } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import './Modal.css'
import RenderModal from './RenderModal'




function ModalButton(props) {
  const [openDefault, setOpenDefault] = useState(false)

  const {height=undefined,width=undefined, close = true, fullScreen=false, header, body, footer, open = openDefault, setOpen = setOpenDefault, onClick, children, ...rest } = props


  function handleOpen() {
    setOpen(true)
  }




  return (
    <>

      <ButtonBase style={{height:'100%',width:'100%'}} onClick={handleOpen} {...rest}>
        {children}
      </ButtonBase>

      <RenderModal height={height} width={width} header={header} body={body} footer={footer} open={open} setOpen={setOpen}  close={close} fullScreen={fullScreen} />


    </>
  )
}

export default ModalButton