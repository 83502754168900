import {
  Typography,
  Stack,
  Box,
  Card,
  Grid,
  ButtonBase,
  Button,
  Chip,
} from "@mui/material";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import StarIcon from "@mui/icons-material/Star";

export default function PlanCard({
  title = "Nome piano",
  subtitleLabel = undefined,
  promo = false,
  price = undefined,
  price2 = undefined,
  annualPrice = undefined,
  valuta = "€",
  planColor = "#D7E6FA",
  subtitle = undefined,
  features = undefined,
  active,
  description = undefined,
  onBuy,
  recommended = false,
  ...rest
}) {
  return (
    <Card
      sx={{
        boxShadow: 3,
        backgroundColor: "#ffffff90", //"#00000080",
        borderRadius: "10px",
        margin: "5px",
        color: "00000090",
        backdropFilter: "blur(3px)",
      }}
      {...rest}
    >
      <Stack
        spacing={{ xs: 2, lg: 2 }}
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        style={{ height: "100%" }}
      >
        <Box
          sx={{
            backgroundColor: planColor,
            width: "100%",
            padding: "5px",
            minHeight: "80px",
            alignContent: "center",
          }}
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography variant="h4" align="center" sx={{ color: "white" }}>
              {title}
            </Typography>
          </Box>

          {subtitleLabel && (
            <Typography
              variant="body"
              color="white"
              component="body"
              align="center"
            >
              {subtitleLabel}
            </Typography>
          )}
          {promo && (
            <Box sx={{ color: "white" }}>
              <Typography variant="body" component="body" align="center">
                Primi 2 mesi <b>gratis</b>
              </Typography>
              <Typography variant="body" component="body" align="center">
                (offerta a tempo limitato)
              </Typography>
            </Box>
          )}
        </Box>
        <Stack
          direction="column"
          sx={{ padding: "5px", height: "100%" }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            onClick={onBuy}
          >
            {price && (
              <Grid item>
                <ButtonBase
                  sx={{
                    display: "block",
                    borderRadius: "5px",
                    margin: "5px",
                    padding: "5px",
                    boxShadow: 1,
                    color: "white",
                    backgroundColor: planColor,
                  }}
                >
                  <Typography variant="h5">
                    {price} {valuta}
                  </Typography>
                  <Typography variant="h6" component="div">
                    al mese
                  </Typography>
                </ButtonBase>
              </Grid>
            )}
            {annualPrice && (
              <Grid item>
                <ButtonBase
                  sx={{
                    display: "block",
                    borderRadius: "5px",
                    margin: "5px",
                    padding: "5px",
                    boxShadow: 1,
                    backgroundColor: planColor,
                    color: "white",
                  }}
                >
                  <Typography variant="h5">{annualPrice}</Typography>
                  <Typography variant="h6" component="div">
                    all'anno
                  </Typography>
                </ButtonBase>
              </Grid>
            )}
          </Grid>
          {recommended && (
            <Chip
              icon={<StarIcon style={{ color: "gold" }} />}
              label="Il più scelto"
              sx={{
                backgroundColor: "#ffffffe0",
                color: "black",
                mr: 1, // Margine destro per distanziare il Chip dal titolo
              }}
            />
          )}

          <Typography
            variant="h6"
            align="center"
            color="primary"
            style={{ planColor }}
          >
            {price2}
          </Typography>

          <Typography
            variant="subtitle2"
            align="center"
            sx={{ fontWeight: "bold" }}
          >
            {subtitle}
          </Typography>
          {features}

          <Typography align="left" variant="body2">
            {description}
          </Typography>

          {active ? (
            <Button
              variant="contained"
              startIcon={<AssignmentTurnedInIcon />}
              onClick={onBuy}
              fullWidth
              sx={{ color: "white", backgroundColor: planColor }}
            >
              Richiedi
            </Button>
          ) : (
            <Button
              variant="contained"
              startIcon={<ContactSupportIcon />}
              onClick={onBuy}
              fullWidth
              sx={{ color: "white", backgroundColor: planColor }}
            >
              Contattaci
            </Button>
          )}
        </Stack>
      </Stack>
    </Card>
  );
}
