import React, { useState, useEffect } from "react";

import {
  Stack,
  Fade,
  Typography,
  Modal,
  Box,
  IconButton,
  Collapse,
  Card,
  Backdrop,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

function RenderModalLight(props) {
  const {
    close = false,
    open,
    setOpen,
    header,
    body,
    footer,
    children,
    ...rest
  } = props;

  useEffect(() => {
    if (open === false) {
    } else {
      /** add a back fake history **/
      window.history.pushState("fake-route", document.title, "");
      window.addEventListener("popstate", (event) => {
        setOpen(false);
      });
    }
  }, [open]);

  const theme = useTheme();
  return (
    <Modal
      id="color-keeper"
      keepMounted
      open={open}
      onClose={() => {
        close === true && setOpen(false);
      }}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Fade
        in={open}
        style={{
          transformOrigin: "0 0 0",
          transitionDelay: open ? "0ms" : "300ms",
        }}
        {...(open ? { timeout: 500 } : { timeout: 500 })}
        unmountOnExit
      >
        <Box
          className="standard_modal"
          style={{maxHeight: "97vh", borderRadius: "5px" }}
        >
          <Card style={{ maxHeight: "90vh", borderRadius: "5px" }}>
            <Stack
              direction="column"
              style={{ backgroundColor: "white", borderRadius: "5px" }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={0}
                style={{
                  minHeight: "30px",
                  padding: "5px",
                  background: theme.palette.primary.dark,
                  color: "white",
                  borderRadius: "5px 5px 0px 0px",
                }}
              >
                {header !== undefined && (
                  <Typography variant="h5">{header}</Typography>
                )}
                <IconButton
                  id='button-close-modal'
                  onClick={() => setOpen(false)}
                  style={{
                    zIndex: "100",
                    width: "35px",
                    height: "35px",
                    padding: "5px",
                    background: "#82828226",
                  }}
                >
                  <CloseRoundedIcon style={{ color: "white" }} />
                </IconButton>
              </Stack>

              <Box
                style={{
                  padding: "5px",
                  backgroundColor: "white",
                  maxHeight: "80vh",
                  overflowY: "auto",
                }}
              >
                {body}
              </Box>

              {footer !== undefined && (
                <Box
                  style={{
                    padding: "5px",
                    background: "white",
                    borderRadius: "0px 0px 5px 5px",
                  }}
                >
                  {footer}
                </Box>
              )}
            </Stack>
          </Card>
        </Box>
      </Fade>
    </Modal>
  );
}

export default RenderModalLight;

RenderModalLight.propTypes = {
  setOpen: PropTypes.func,
};
