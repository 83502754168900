
import { Skeleton, Grid, Box, Typography } from '@mui/material';

const fields_default = [
    { xs: 12, sm: 12, md: 4, lg: 3, xl: 2 },
    { xs: 12, sm: 6, md: 4, lg: 3, xl: 2 },
    { xs: 12, sm: 6, md: 4, lg: 3, xl: 2 },
    { xs: 12, sm: 12, md: 2, lg: 3, xl: 4 },
    { xs: 12, sm: 12, md: 2, lg: 3, xl: 2 },
    { xs: 12, sm: 6, md: 2, lg: 3, xl: 2 },
    { xs: 12, sm: 6, md: 6, lg: 2, xl: 4 },
    { xs: 12, sm: 12, md: 12, lg: 4, xl: 6 },
];

export default function Loadingform({ fields = fields_default }) {

    const form = fields.map((item, index) => (
        <Grid item key={index} {...item} >

            <Box >
                <Skeleton variant='rounded' height={'30vh'} />
            </Box>
        </Grid>
    ));

    return (
        <Box>
            <Typography variant='subtitle'>Caricamento...</Typography>

            <Grid container spacing={1}>
                {form}
            </Grid>

        </Box>

    )
}