import React, { useRef, useEffect, useContext } from 'react';
import { Typography, Button, Box, Grid } from '@mui/material';
import { UserContext } from '../../../../store/UserContext';

import Plans from './Plans';
import Particelles from '../../components/Particelles'


export default function PlansPage() {

    const { setDefaultTheme } = useContext(UserContext);

    useEffect(() => {
        setDefaultTheme()
    }, [])


    return (
        <Box>
            <Box className="alba-background" style={{background:'linear-gradient(to bottom,  #d7d7e8, #7c7785)', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1 }}>
                <Particelles />
            </Box>
            <Plans />
        </Box>

    )
}
