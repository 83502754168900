import React, { useState, useEffect } from "react";
import {
  Stack,
  Dialog,
  Box,
  IconButton,
  Grid,
  Typography,
  DialogContent,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SelectButton from "../Forms/SelectButton";
import {
  states,
  getTableStatusColor,
  getTableStatusTextColor,
} from "./TableFunctions";
import TableOrders from "./TableOrders";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";

export default function TableView({
  onChangeTableStatus,
  onChangeProductsStatus,
  onCompleteOrders,
  openTableView,
  table_room,
  ordersData,
  setOpenTableView = () => {},
  table = {},
}) {
  const [tableStatus, setTableStatus] = useState(table.tableStatus);
  const theme = useTheme();

  const handleClose = () => {
    setOpenTableView(false);
    window.history.back(); // rimuove la "fake-route" dalla history
  };

  useEffect(() => {
    setTableStatus(table.tableStatus);
  }, [table]);

  useEffect(() => {
    if (openTableView === false) {
      setOpenTableView(false);
    } else {
      window.history.pushState("fake-route", document.title, "");
      window.addEventListener("popstate", (event) => {
        setOpenTableView(false);
      });
    }
  }, [openTableView]);

  function handleChangeTableStatus(status) {
    setTableStatus(status);
    onChangeTableStatus({ status, tableReference: table.tableId });
  }
  return (
    <Dialog fullScreen open={openTableView} onClose={handleClose}>
      <DialogContent>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item xs={6} md={2} lg={1}>
            <IconButton
              sx={{
                boxShadow: 1,
                borderRadius: "50%",
                height: "50px",
                width: "50px",
                backgroundColor: theme.palette.primary.dark,
              }}
              color="white"
              onClick={handleClose}
              aria-label="close"
            >
              <ArrowBackIcon sx={{ color: "white" }} />
            </IconButton>
          </Grid>
          <Grid item xs={6} md={5} lg={4}>
            <Typography sx={{ width: "100%" }} variant="h6" align="center">
              {table_room}{" "}
            </Typography>
          </Grid>
          <Grid item xs={12} md={5} lg={4}>
            <Typography
              sx={{
                boxShadow: 3,
                borderRadius: "10px",
                padding: "5px",
                backgroundColor: getTableStatusColor(tableStatus),
                color: getTableStatusTextColor(tableStatus),
              }}
              variant="h4"
              align="center"
            >
              {table.tableName}{" "}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={2}>
            <SelectButton
              label={"Stato tavolo"}
              options={states}
              value={tableStatus}
              onChange={handleChangeTableStatus}
            />
          </Grid>
        </Grid>
        <Stack direction="column" spacing={1} sx={{ marginTop: "10px" }}>
          {Object.keys(ordersData).length > 0 &&
            table.tableName &&
            table_room && (
              <TableOrders
                onCompleteOrders={onCompleteOrders}
                table_name={table.tableName}
                table_room={table_room}
                ordersData={ordersData}
                onChange={onChangeProductsStatus}
              />
            )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

TableView.propTypes = {
  onChangeTableStatus: PropTypes.func,
  onChangeProductsStatus: PropTypes.func,
  onCompleteOrders: PropTypes.func,
  setOpenTableView: PropTypes.func,
};
