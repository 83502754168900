import React, { useState} from 'react';
import { useTheme } from '@mui/material/styles';
import CardMedia from '@mui/material/CardMedia';




export default function ProductImage({ local_id, product_name, product_cover, style }) {

    var styleImage = {
        width:'100%',
        height:'100%'
    }
    if (style != undefined) {
        styleImage = style
    }

    const [openModalCover, setOpenModalCover] = useState(false);

    const [product_coverUrl, setProductCoverUrl] = useState('');

    function handleOpenModalCover() {
        setOpenModalCover(true)
    }


    function getImageCover() {
        if (product_cover !== "" && product_cover !== null) {
            return product_cover
        }
        else {
            if (product_coverUrl === '') {
                return null
            } else {
                return product_coverUrl
            }
        }
    }





    return (
        <>
            {getImageCover() !== null && product_cover!=='' && product_cover!==null && product_cover!==undefined &&
                <CardMedia
                    component="img"
                    sx={styleImage}
                    image={getImageCover()}
                    alt={product_name}
                    draggable="false"
                    onContextMenu={(event) => event.preventDefault()}
                    onClick={() => handleOpenModalCover()}
                /> 
            }
        </>

    )
}