import React, {  useMemo } from 'react';

import { Fade, Grow, Zoom, Collapse,  Slide } from '@mui/material';


function getSlide(direction) {
    return React.forwardRef((props, ref) => {
        return <Slide direction={direction} ref={ref} {...props} />;
    });
}



export const transitions = {
    slideUp: getSlide('up'),
    slideDown: getSlide('down'),
    slideLeft: getSlide('left'),
    slideRight: getSlide('right'),
    fade: Fade,
    grow: Grow,
    zoom: Zoom,
    collapse: Collapse,
    // ...
};



export function GetTransitionComponent(transitionType){
    return useMemo(() => transitions[transitionType] || Fade, [transitionType]); //useMemo mantiene in memoria di react l'animazione così da non essere toccato da altri useState
}
