import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Box
} from "@mui/material";
import ProductStatusCard from '../../Products/ProductStatusCard'

export default function DialogProductsAlert({ dialogOpen, selectedProductInfo, ordersData, setDialogOpen, setSelectedProductInfo, handleCellClick }) {
    const handleDialogClose = (shouldProceed) => {
        setDialogOpen(false);

        if (shouldProceed) {
            // Incrementa lo product_status come al solito

            handleCellClick({
                product_name: selectedProductInfo.product_name,
                table_room: selectedProductInfo.table_room,
                table_name: selectedProductInfo.table_name,
                orders_id: selectedProductInfo.orders_id,
            })
        }

        // Resetta le informazioni sul prodotto selezionato
        setSelectedProductInfo({});
    };

    const [ordersProducts, setOrdersProducts] = useState({})

    function getProducts() {
        let products = {}
        if (selectedProductInfo.orders_id) {
            for (let order_id of selectedProductInfo.orders_id) {
                products[order_id] = []

                for (let product of ordersData[order_id].basket) {
                    if (product.product_name.it === selectedProductInfo.product_name) {
                        products[order_id].push(product)
                    }
                }
            }
            setOrdersProducts(products)
        }
    }

    useEffect(() => {
        getProducts()
    }, [ordersData,selectedProductInfo])

    return (
        <Dialog
            open={dialogOpen}
            onClose={() => handleDialogClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Conferma l'incremento"}
            </DialogTitle>

            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Vuoi davvero incrementare il prodotto{" "}
                    {selectedProductInfo && selectedProductInfo.product_name}?
                </DialogContentText>

                {Object.entries(ordersProducts).map(([order_id, orderProducts], index) => {
                    return (
                        <Box key={order_id}>
                            {Object.values(orderProducts).map(product => {
                                return (
                                    <ProductStatusCard key ={order_id+'-'+ product.product_id} product={product} />
                                )
                            })}
                        </Box>
                    )
                })}
            </DialogContent>

            <DialogActions>
                <Button onClick={() => handleDialogClose(false)} color="primary">
                    Annulla
                </Button>
                <Button
                    onClick={() => (
                        handleCellClick({
                            product_name: selectedProductInfo.product_name,
                            table_room: selectedProductInfo.table_room,
                            table_name: selectedProductInfo.table_name,
                            orders_id:selectedProductInfo.orders_id,
                        }
                        ),
                     
                        handleDialogClose(false)
                    )}
                    color="primary"
                    autoFocus
                >
                    Conferma
                </Button>
            </DialogActions>
        </Dialog>
    )
}

DialogProductsAlert.propTypes = {
    dialogOpen: PropTypes.bool,
    selectedProductInfo: PropTypes.object,
    ordersData: PropTypes.object,
    setDialogOpen: PropTypes.func,
    setSelectedProductInfo: PropTypes.func,
    handleCellClick: PropTypes.func
}