import React, { useState, useEffect } from "react";
import { Typography, Box, useTheme, Stack } from "@mui/material";
import {
  getTableStatusColor,
  getTableStatusTextColor,
  getTableStatusIcon,
} from "./TableFunctions";
import PropTypes from "prop-types";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import "./TableStyle.css";

function getShapeProps(
  shape,
  capacity,
  orientation,
  shapeView
) {
  if (!shapeView) {
    return {
      borderRadius: "10px",
      height: "30px",
      width: "30px",
      boxShadow: 1,
    };
  }

  const baseSize = 30;
  let sizePerCapacity = 10;

  if (shape === "round" || shape === "square" || shape === "diamond") {
    sizePerCapacity = sizePerCapacity / 2;
  }

  const size = baseSize + (capacity > 4 ? sizePerCapacity * (capacity - 4) : 0);
  const sizePx = `${size}px`;
  const baseSizePx = `${baseSize}px`;

  if (shape === "round") {
    return {
      borderRadius: shape === "round" ? "50%" : "10px",
      height: sizePx,
      width: sizePx,
    };
  } else if (shape === "diamond") {
    return {
      transform: "rotate(45deg)",
      height: sizePx,
      width: sizePx,
    };
  } else if (shape === "rectangular" || shape === "square" ) {
    if (orientation === "Vertical") {
      return {
        borderRadius: "10px",
        height: sizePx,
        width: shape === "square"?sizePx:baseSizePx,
      };
    } else if (orientation === "diagonal left-to-right") {
      // Diagonale da sinistra a destra
      return {
        borderRadius: "10px",
        height: sizePx,
        width: shape === "square"?sizePx:baseSizePx,
        transform: "rotate(45deg)", // Ruota di 45 gradi per una diagonale da sinistra a destra
      };
    } else if (orientation === "diagonal right-to-left") {
      // Diagonale da destra a sinistra
      return {
        borderRadius: "10px",
        height: sizePx,
        width: shape === "square"?sizePx:baseSizePx,
        transform: "rotate(-45deg)", // Ruota di -45 gradi per una diagonale da destra a sinistra
      };
    } else {
      // assuming horizontal orientation
      return {
        borderRadius: "10px",
        height: shape === "square"?sizePx:baseSizePx,
        width: sizePx,
      };
    }
  }
}

const semiTransparentBackgroundStyle = {
  backgroundColor: "rgba(0, 0, 0, 0.5)", // Sfondo nero semitrasparente
  padding: "0.2em 0.4em", // Piccolo padding intorno al testo
  borderRadius: "10px", // Angoli arrotondati
  color: "white", // Colore del testo bianco per contrasto
};

export default function Transferable({
  capacity,
  shapeView = true,
  orientation,
  shape,
  tableId,
  tableName,
  tableStatus,
  x,
  y,
  bookedName,
  productToDeliver,
}) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Box
        border={1}
        borderColor={theme.palette.divider}
        borderRadius={theme.shape.borderRadius}
        p={2}
        sx={{
          boxShadow: 1,
          backgroundColor: getTableStatusColor(tableStatus),
          color: getTableStatusTextColor(tableStatus),
          ...getShapeProps(shape, capacity, orientation, shapeView),
        }}
      >
        <Typography
          variant="body2"
          sx={{
            whiteSpace: "nowrap",
            position: "absolute",
            top: 0,
            left: 0,
            ...semiTransparentBackgroundStyle,
          }}
        >
          {tableName}
        </Typography>

        {bookedName && (
          <Typography
            variant="caption"
            sx={{
              whiteSpace: "nowrap",
              position: "absolute",
              bottom: 0,
              right: 0,
              ...semiTransparentBackgroundStyle,
            }}
          >
            {bookedName}
          </Typography>
        )}
        <Stack direction="row" spacing={1} justifyContent="center">
          {productToDeliver && (
            <div className="pulse-icon-container">
              <div className="pulse-circle"></div>
              <RoomServiceIcon className="icon" />
            </div>
          )}
          <Typography variant="h6" align="center">
            {capacity}
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
}

Transferable.propTypes = {
  capacity: PropTypes.number.isRequired, // Numero di posti a sedere o capacità
  orientation: PropTypes.oneOf(["Vertical", "Horizontal", 'diagonal left-to-right', 'diagonal right-to-left']).isRequired, // Orientamento del tavolo, verticale o orizzontale
  shape: PropTypes.oneOf(["round", "square", "rectangular", "diamond"])
    .isRequired, // Forma del tavolo
  tableId: PropTypes.string.isRequired, // Identificatore univoco del tavolo
  tableName: PropTypes.string.isRequired, // Nome del tavolo
  tableStatus: PropTypes.string.isRequired, // Lo stato attuale del tavolo, es. "Occupato", "Libero", ecc.
  x: PropTypes.number, // Posizione X del tavolo (se necessario)
  y: PropTypes.number, // Posizione Y del tavolo (se necessario)
  bookedName: PropTypes.string, // Nome della persona che ha prenotato (se il tavolo è prenotato)
  productToDeliver: PropTypes.bool,
  shapeView: PropTypes.bool,
};
