import React, {  useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions, Fade, IconButton, Stack,  useTheme } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {GetTransitionComponent} from '../Animations/DialogTransitions'


function RenderModal(props) {
  const { height = '80vh', width = undefined,transitionType, close = false, open = false, setOpen, header, body, footer, fullScreen, children, ...rest } = props

  useEffect(() => {
    if (open) {
      window.history.pushState('fake-route', document.title, '');
      window.addEventListener('popstate', () => setOpen(false));
    }
  }, [open]);

  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={() => { if (close) setOpen(false); }}
      fullScreen={fullScreen}
      maxWidth="md"
      {...rest}
      PaperProps={{
        style: {
          width: fullScreen ? '100%' : width,
          maxHeight: fullScreen ? '100vh' : undefined,
        },
      }}
      TransitionComponent={GetTransitionComponent(transitionType)}
      transitionDuration={open ? 500 : 300}
    >
      <DialogTitle sx={{ padding: '5px', background: theme.palette.primary.dark, color: 'white' }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={0}>

          {header}
          <IconButton onClick={() => setOpen(false)} style={{ width: '35px', height: '35px', padding: '5px', background: '#82828226' }}>
            <CloseRoundedIcon style={{ color: 'white' }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{padding:'5px'}}>
        {body ? body:children}
      </DialogContent>
      {footer && <DialogActions sx={{ padding: '5px', background: theme.palette.primary.dark, color: 'white' }}>{footer}</DialogActions>}
    </Dialog>
  );
}

RenderModal.propTypes = {
  setOpen: PropTypes.func.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
  close: PropTypes.bool,
  open: PropTypes.bool,
  modalTitle: PropTypes.node,
  modalContent: PropTypes.node,
  modalFooter: PropTypes.node,
  fullScreen: PropTypes.bool,
};

export default RenderModal;
