import React, { useEffect, useContext } from "react";
import { UserContext } from "./store/UserContext";
import { AlertApiContext } from "./store/AlertApiContext";

const PushNotificationManager = () => {
  const { api_request } = useContext(AlertApiContext);
  const { token } = useContext(UserContext);
  useEffect(() => {
    // Controlla se il browser supporta il service worker e le notifiche
    if ("serviceWorker" in navigator && "PushManager" in window && token) {
      // Registra il service worker
      navigator.serviceWorker
        .register("/service-worker.js")
        .then((registration) => {
          console.log(
            "Service Worker registrato con successo: ",
            registration.scope
          );
          // Chiedi il permesso per le notifiche push
          askForNotificationPermission(registration);
        })
        .catch((err) => {
          console.error("Errore nella registrazione del Service Worker: ", err);
        });
    }
  }, [token]);

  function sendSubscriptionToServer(subscription) {
    // Utilizza fetch o axios per inviare la sottoscrizione al tuo backend

    const body = {
      subscription: subscription,
      user: "user_identifier", // Assicurati di sostituire con un identificatore utente reale o un token se applicabile
    };

    api_request("POST",'api/users/notify-subscribe', { body })
      .then((data) => {
        console.log("Subscription sent to server:", data);
      })
      .catch((error) => {
        console.error("Failed to send subscription to server:", error);
      });
  }

  const askForNotificationPermission = (registration) => {
    // Chiede il permesso per le notifiche
    Notification.requestPermission().then((permission) => {
      if (permission !== "denied") {
        console.log("Permesso per notifiche push accordato");
        subscribeUserToPush(registration);
      } else {
        subscribeUserToPush(registration);
        console.log("Permesso per notifiche push rifiutato:", permission);
      }
    });
  };

  const subscribeUserToPush = (registration) => {
    const publicKey = process.env.REACT_APP_VAPID_PUBLIC_KEY; // Inserisci la chiave pubblica VAPID qui
    const subscribeOptions = {
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(publicKey),
    };

    registration.pushManager
      .subscribe(subscribeOptions)
      .then((subscription) => {
        console.log(
          "Sottoscrizione alle notifiche push riuscita: ",
          subscription
        );
        // Invia la sottoscrizione al server per salvarla
        sendSubscriptionToServer(subscription);
      })
      .catch((err) => {
        if (Notification.permission === "denied") {
          console.warn("Permesso per le notifiche negato:", err);
        } else {
          console.error(
            "Impossibile sottoscriversi alle notifiche push: ",
            err
          );
        }
      });
  };

  return;
};

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export default PushNotificationManager;
