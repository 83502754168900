import React from "react";
import { Box, Typography } from "@mui/material";
import { GetNameByLanguage } from '../Functions/LanguageFunctions'

function RenderIngredients({ productIngredientsVar, product_currency }) {
    return (
        <>
            {productIngredientsVar && Object.keys(productIngredientsVar).length > 0 && (
                <>
                    <Typography
                        variant="subtitle1"
                        color="text.paper"
                        sx={{ fontWeight: "bold" }}
                    >
                        Ingredienti:
                    </Typography>
                    {Object.entries(productIngredientsVar).map(([section, ingredients], sectionIndex) => (
                        <Box key={sectionIndex}> {/* Qui abbiamo aggiunto una chiave */}
                            {
                                Object.keys(ingredients).length > 0 &&
                                <>
                                    {section !== "Alternative" &&
                                        <Typography
                                            variant="subtitle2"
                                            sx={{ fontWeight: "bold" }}
                                        >
                                            {section}
                                        </Typography>
                                    }
                                    {Object.entries(ingredients).map(([ingredient_id, ingredient]) => {
                                        const ingredient_quantity = ingredient.quantity;
                                        var ingredientPriceVariation = ingredient.priceVariation;
                                        const ingredientName = GetNameByLanguage(ingredient.ingredientName);
                                        if (ingredientPriceVariation === undefined) {
                                            ingredientPriceVariation = 0;
                                        }
                                        const ingredientOption = GetNameByLanguage(ingredient.ingredientOption);

                                        return (
                                            <Box key={ingredient_id}>
                                                <Typography
                                                    align="left"
                                                    color="text.paper"
                                                    component="div"
                                                    sx={{
                                                        textDecoration: parseFloat(ingredient_quantity) < 0 && "line-through",
                                                        //whiteSpace: 'nowrap'
                                                    }}
                                                    variant="body2"
                                                >
                                                    {ingredientOption && (
                                                        <b>
                                                            {ingredientOption}
                                                            {":"}
                                                        </b>
                                                    )}
                                                    {ingredientName}

                                                    {parseFloat(ingredient_quantity) > 0 &&
                                                        " +" + ingredient_quantity}
                                                    {parseFloat(ingredient_quantity) < 0 &&
                                                        " " + ingredient_quantity}
                                                    {product_currency &&
                                                        <>
                                                            {" x " + ingredientPriceVariation}
                                                            {product_currency}
                                                        </>
                                                    }
                                                </Typography>
                                            </Box>
                                        );
                                    })}
                                </>
                            }
                        </Box>
                    ))}
                </>
            )}
        </>
    );
}


export default RenderIngredients;
