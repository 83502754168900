import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Box,
  IconButton,
  Button,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import UploadImage from "../Forms/UploadImage";
import ColorPicker from "../Forms/ColorPicker";
import CopyToClipboardButton from "../Forms/CopyToClipboardButton";
import Locker from "../Forms/Locker";

const SectionEditDialog = ({
  open,
  onClose,
  section_name,
  section_color,
  section_card_color,
  section_description,
  section_cover,
  onDeleteSection,
  onChange,
  planValue,
}) => {
  const [newSectionName, setNewSectionName] = useState(undefined);
  const [newSectionCover, setNewSectionCover] = useState(undefined);
  const [newSectionColor, setNewSectionColor] = useState(section_color);
  const [newSectionCardColor, setNewSectionCardColor] =
    useState(section_card_color);
  const [newSectionDescription, setNewSectionDescription] = useState(undefined);
  const [errorRenameSection, setErrorRenameSection] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const onChangeValues = {
    newSectionName,
    newSectionCover,
    newSectionColor,
    newSectionDescription,
    newSectionCardColor,
  };

  const handleRenameSection = () => {
    if (newSectionName !== "") {
      setErrorRenameSection(false);
    } else {
      setErrorRenameSection(true);
    }
  };

  const handleSectionCover = (value) => {
    if (value) {
      setNewSectionCover(value);
    } else {
      setNewSectionCover(" ");
      onChangeValues["newSectionCover"] = " ";
    }

    onChange(onChangeValues);
  };

  const handleSectionDescription = (value) => {
    if (value) {
      setNewSectionDescription(value);
    } else {
      setNewSectionDescription(" ");
      onChangeValues["newSectionDescription"] = " ";
    }

    onChange(onChangeValues);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{ zIndex: 1501 }}
    >
      <DialogTitle>Modifica sezione {section_name}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} sx={{ marginTop: "5px" }}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              error={errorRenameSection}
              label="Nome sezione"
              defaultValue={section_name}
              value={newSectionName}
              onChange={(event) => setNewSectionName(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Locker
              locked={planValue < 1}
              message={
                "La descrizione della sezione è disponibile dal piano starter in poi"
              }
            >
              <TextField
                fullWidth
                multiline
                label="Descrizione sezione"
                disabled={planValue < 1}
                defaultValue={section_description}
                value={newSectionDescription}
                onChange={(event) =>
                  handleSectionDescription(event.target.value)
                }
              />
            </Locker>
          </Grid>
          <Grid item xs={12}>
            <Locker
              locked={planValue < 2}
              message={
                "Il cambio del colore della sezione è disponibile dal piano Business in poi"
              }
            >
              <Box sx={{ display: "flex" }}>
                <IconButton onClick={() => setNewSectionColor("#ffffff")}>
                  <DeleteForeverIcon />
                </IconButton>
                <ColorPicker
                  color={newSectionColor || "#ffffff"}
                  buttonText="Colore sezione"
                  setColor={setNewSectionColor}
                />
                <CopyToClipboardButton
                  showIcon
                  value={newSectionColor}
                  copyMessage={`Codice colore ${newSectionColor} copiato`}
                />
              </Box>
            </Locker>
          </Grid>
          <Grid item xs={12}>
            <Locker
              locked={planValue < 2}
              message={
                "Il cambio del colore della card del prodotto è disponibile dal piano Business in poi"
              }
            >
              <Box sx={{ display: "flex" }}>
                <IconButton onClick={() => setNewSectionCardColor("#ffffff")}>
                  <DeleteForeverIcon />
                </IconButton>
                <ColorPicker
                  color={newSectionCardColor || "#ffffff"}
                  buttonText="Colore carte"
                  setColor={setNewSectionCardColor}
                />
                <CopyToClipboardButton
                  showIcon
                  value={newSectionCardColor}
                  copyMessage={`Codice colore ${newSectionCardColor} copiato`}
                />
              </Box>
            </Locker>
          </Grid>
          <Grid item xs={12}>
            <Locker
              locked={planValue < 1}
              message={
                "L'aggiunta dell'immagine alla sezione è disponibile dal piano Business in poi."
              }
            >
              <UploadImage
                defaultValue={section_cover}
                disableAlpha={false}
                onUpload={handleSectionCover}
                onUrl={handleSectionCover}
                maxSize={0.5 * 1024 * 1024}
              />
            </Locker>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          id='button-delete-section'
          onClick={() => setConfirmDeleteOpen(true)}
          startIcon={<DeleteForeverIcon />}
          color="error"
        >
          Cancella sezione
        </Button>
        <Button
          onClick={() => {
            handleRenameSection();
            onChange(onChangeValues);
            onClose(false);
          }}
          color="primary"
        >
          Conferma
        </Button>
      </DialogActions>

      {/* Dialog di conferma per la cancellazione */}
      <Dialog
        open={confirmDeleteOpen}
        sx={{ zIndex: 1501 }}
        onClose={() => setConfirmDeleteOpen(false)}
      >
        <DialogTitle>
          Conferma cancellazione sezione "{section_name}"
        </DialogTitle>
        <DialogContent>
          Sei sicuro di voler cancellare l'intera sezione{" "}
          <strong>"{section_name}"</strong> e tutti i suoi prodotti?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteOpen(false)} color="primary">
            Annulla
          </Button>
          <Button
            id='button-confirm-action'
            onClick={() => {
              setConfirmDeleteOpen(false);
              onDeleteSection();
            }}
            color="error"
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default SectionEditDialog;
