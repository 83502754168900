import React, { useState, useEffect } from 'react';

import {
    Grid, Button
} from '@mui/material';
import PropTypes from "prop-types";


import TableActions from './TableActions';
import AddTableForm from './Dialogs/AddTableForm'
import RoomView from './RoomView'
import DeleteRoomDialog from './Dialogs/DeleteRoomDialog'
import RenameRoomDialog from './Dialogs/RenameRoomDialog';




export default function RenderAccordionDetails({ draggable, localTables,shapeView, setLocalTables, room, roomIndex, onChangeTables,onClick }) {
    const [renameDialogOpen, setRenameDialogOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedTable, setSelectedTable] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const handleTableClick = (table, roomIndex) => {
        if(draggable){
            setSelectedTable(table);
            setOpen(true);
        }else{
            onClick({table,roomIndex})
        }

    };
    const handleDragEnd = (table, coordinates, roomIndex) => {
        let updatedTables = [...localTables]
        updatedTables[roomIndex].initialTables = localTables[roomIndex].initialTables.map(t => t.tableName === table.tableName ? { ...t, x: coordinates.x, y: coordinates.y } : t)
        setLocalTables([...updatedTables]);
        onChangeTables(updatedTables);
    };


    const handleOpenRenameDialog = (roomName) => {
        setRenameDialogOpen(true);
    };

    const handleCloseRenameDialog = () => {
        setRenameDialogOpen(false);
    };


    const handleTableSave = (newTable) => {
        const updatedTables = [...localTables];
        updatedTables[roomIndex].initialTables.push(newTable);
        setLocalTables(updatedTables);
        onChangeTables(updatedTables);
    };

    const deleteTable = (tableToDelete) => {
        const updatedTables = localTables.map(room => ({
            ...room,
            initialTables: room.initialTables.filter(table => table.tableName !== tableToDelete.tableName)
        }));
        setLocalTables(updatedTables);
        onChangeTables(updatedTables);
    };

    const handleDeleteRoom = (updatedTables) => {
        setLocalTables(updatedTables);
        onChangeTables(updatedTables);
        handleCloseDeleteDialog();
        setOpen(false);
    };

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
    };


    const handleClose = () => {
        setOpen(false);
        setSelectedTable(undefined);
    };
    const handleOpenDeleteDialog = (roomIndex) => {
        setDeleteDialogOpen(true);
    };

    const handleRenameRoom = (newName) => {
        let updatedTables = [...localTables]
        updatedTables[roomIndex].roomName = newName
        setLocalTables(updatedTables);
        onChangeTables(updatedTables);
    };

    const renameTable = (newTableValues) => {

        let updatedTables = [...localTables];

        const updatedRoomTables = updatedTables[roomIndex].initialTables.map(table =>
            table.tableName === selectedTable.tableName
                ? { ...newTableValues }  // crea una copia dell'oggetto
                : table
        );

        // Aggiorna initialTables per la stanza specificata
        updatedTables[roomIndex].initialTables = updatedRoomTables


        setLocalTables(updatedTables);
        onChangeTables(updatedTables);
        setOpen(false);
    };
    return (
        <>
            <Grid container spacing={1} justifyContent="center" alignItems="center">
                {draggable &&
                    <>
                        <Grid item >
                            <AddTableForm
                                roomName={room.roomName}
                                onSave={(newTable) => handleTableSave(newTable)}
                                roomTables={room.initialTables}
                                defaultForm={{
                                    roomName: room.roomName,
                                    tableName: '',
                                    capacity: 0,
                                    shape: '',
                                    orientation: ''
                                }}
                            />
                        </Grid>
                        <Grid item >
                            <Button
                                variant='contained'
                                color='warning'
                                onClick={() => handleOpenRenameDialog(room.roomName)}>
                                Rinomina stanza
                            </Button>
                        </Grid>
                        <Grid item >
                            <Button variant='contained'
                                color='error'
                                onClick={() => handleOpenDeleteDialog(roomIndex)}>
                                Cancella stanza
                            </Button>
                        </Grid>
                    </>
                }
                <Grid item xs={12} sx={{ textAlign: '-webkit-center' }}>
                    <RoomView room={room} handleDragEnd={(table, coordinates) => handleDragEnd(table, coordinates, roomIndex)} draggable={draggable} shapeView={shapeView} handleTableClick={(table) => handleTableClick(table, roomIndex)} />

                </Grid>
            </Grid>
            <DeleteRoomDialog
                open={deleteDialogOpen}
                onClose={handleCloseDeleteDialog}
                onConfirm={handleDeleteRoom}
                roomIndex={roomIndex}
                localTables={localTables}
            />

            <TableActions
                open={open}
                table={selectedTable}
                onClose={handleClose}
                roomTables={room.initialTables}
                onDelete={deleteTable}
                onRename={(newTable) => renameTable(newTable)}
                roomName={room.roomName}
            />
            <RenameRoomDialog
                open={renameDialogOpen}
                onClose={handleCloseRenameDialog}
                onRename={handleRenameRoom}
                currentRoomName={room.roomName}
            />
        </>
    )
}