// storageUtils.js

export const saveToLocal = (key, value) => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.error(`Impossibile salvare ${key} in localStorage: ${e}`);
    }
  };
  
  export const loadFromLocal = (key, defaultValue) => {
    try {
      const value = localStorage.getItem(key);
      return value !== null ? JSON.parse(value) : defaultValue;
    } catch (e) {
      console.error(`Impossibile recuperare ${key} da localStorage: ${e}`);
      return defaultValue;
    }
  };
  
  export const removeFromLocal = (key) => {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      console.error(`Impossibile rimuovere ${key} da localStorage: ${e}`);
    }
  };
  
  export const saveToSession = (key, value) => {
    try {
      sessionStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.error(`Impossibile salvare ${key} in sessionStorage: ${e}`);
    }
  };
  
  export const loadFromSession = (key, defaultValue) => {
    try {
      const value = sessionStorage.getItem(key);
      return value !== null ? JSON.parse(value) : defaultValue;
    } catch (e) {
      console.error(`Impossibile recuperare ${key} da sessionStorage: ${e}`);
      return defaultValue;
    }
  };
  
  export const removeFromSession = (key) => {
    try {
      sessionStorage.removeItem(key);
    } catch (e) {
      console.error(`Impossibile rimuovere ${key} da sessionStorage: ${e}`);
    }
  };
  