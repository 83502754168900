import React, { useState } from 'react';
import { Box, TextField, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import PropTypes from 'prop-types';
import Autocomplete from '../../Forms/Autocomplete';


const inputLabelProps = {backgroundColor:'white',padding:'0px 5px 0px 5px',marginLeft:'-5px'}

const TableForm = ({ onChange, roomName, roomTables, table = {} }) => {
    
    const initialState = {
        tableName: table.tableName || '',
        capacity: table.capacity || 4,
        covered: table.covered || '',
        shape: table.shape || 'square',
        orientation: table.orientation || 'Horizontal'
    };

    const [formState, setFormState] = useState(initialState);
    const [helper,setHelper] = useState(false)

    const [errorState, setErrorState] = useState({
        tableName: false,
        capacity: false,
        shape: false,
        orientation: false
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormState = {
            ...formState,
            [name]: value
        };
        setFormState(updatedFormState);
        setErrorState({
            ...errorState,
            [name]: value === '' ? true : false
        });
    };

    const handleConfirm = () => {
        setHelper(true);
        const tableNameExists = roomTables.some(
            t => t.tableName === formState.tableName && t.tableName !== table.tableName
        );
    
        if (tableNameExists) {
            setErrorState(prevErrorState => ({
                ...prevErrorState,
                tableName: true
            }));
            return;
        }
        
        // Verifica tutti i campi required
        let hasError = false;
        for (const key of Object.keys(errorState)) {
            let value=formState[key]
            if (value === '') {
                hasError = true;
                setErrorState(prevErrorState => ({
                    ...prevErrorState,
                    [key]: true
                }));
            }
        }
        if (hasError) return;
    
        const combinedData = {
            ...table,
            ...formState  
        };
        onChange(combinedData);
    };
    

    return (
        <Box>
            <TextField
                margin="dense"
                name="tableName"
                label="Nome del tavolo"
                type="text"
                value={formState.tableName}
                fullWidth
                required
                error={errorState.tableName}
                helperText={errorState.tableName && (formState.tableName !== "" ? "Nome del tavolo esiste già." : "Nome del tavolo è richiesto.")}
                onChange={handleChange}
            />

            <TextField
                margin="dense"
                name="capacity"
                label="Capacità"
                type="number"
                value={formState.capacity}
                fullWidth
                required
                error={!!errorState.capacity}
                helperText={errorState.capacity && "Capacità è richiesta."}
                onChange={handleChange}
            />
            <TextField
                margin="dense"
                name="covered"
                label="€/Coperto"
                placeholder='Valore del coperto per singola persona se vuoto non si applica'
                type="number"
                value={formState.covered}
                fullWidth
                onChange={handleChange}
            />
            <FormControl fullWidth required margin="dense" error={!!errorState.shape}>
                <InputLabel sx={inputLabelProps}>Forma</InputLabel>
                <Select
                    name="shape"
                    value={formState.shape}
                    onChange={handleChange}
                >
                    <MenuItem value={"rectangular"}>Rettangolare</MenuItem>
                    <MenuItem value={"square"}>Quadrato</MenuItem>
                    <MenuItem value={"round"}>Rotondo</MenuItem>
                    <MenuItem value={"diamond"}>Quadrato ribaltato</MenuItem>
                </Select>
                {errorState.shape && <p style={{ color: 'red', margin: '0' }}>Forma è richiesta.</p>}
            </FormControl>
            <FormControl fullWidth required margin="dense" error={!!errorState.orientation}>
                <InputLabel sx={inputLabelProps}>Orientamento</InputLabel>
                <Select
                    name="orientation"
                    value={formState.orientation}
                    onChange={handleChange}
                >
                    <MenuItem value={"Vertical"}>Verticale</MenuItem>
                    <MenuItem value={"Horizontal"}>Orizzontale</MenuItem>
                    <MenuItem value={"diagonal right-to-left"}>Diagonale a destra</MenuItem>
                    <MenuItem value={"diagonal left-to-right"}>Diagonale a sinistra</MenuItem>
                </Select>
                {errorState.orientation && <p style={{ color: 'red', margin: '0' }}>Orientamento è richiesto.</p>}
            </FormControl>
            <Button
                variant="contained"
                color="primary"
                onClick={handleConfirm}
                style={{ marginTop: '16px' }} // aggiunto solo per dare spazio al pulsante dal resto del form
            >
                Conferma
            </Button>
        </Box>
    );
};

TableForm.propTypes = {
    onChange: PropTypes.func.isRequired,
    table: PropTypes.object,
};

export default TableForm;
