import * as React from "react";
import { useMotionValue, Reorder, useDragControls } from "framer-motion";
import DragHandleRoundedIcon from "@mui/icons-material/DragHandleRounded";
import ArrowDropUpRoundedIcon from "@mui/icons-material/ArrowDropUpRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import { ButtonBase, Stack, Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { getContrastColors } from "../Functions/Colors";

export const ReorderMenuItem = ({
  item,
  index,
  maxLength,
  noMatchProductsId,
  onClick,
}) => {
  const y = useMotionValue(0);
  const dragControls = useDragControls();

  function returnFilterCount() {
    if (
      noMatchProductsId?.product_ids?.length !== 0 &&
      noMatchProductsId?.count
    ) {
      return (
        <>
          {noMatchProductsId?.count -
            noMatchProductsId?.product_ids?.length +
            "\\" +
            noMatchProductsId?.count}
        </>
      );
    } else if(noMatchProductsId?.count) {
      return <>{noMatchProductsId?.count}</>;
    }else{
      return <>0</>
    }
  }

  const counterProducts = returnFilterCount();

  return (
    <Reorder.Item
      value={item}
      id={item.section_name.it}
      style={{ y }}
      dragListener={false}
      dragControls={dragControls}
      as={"div"}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        sx={{
          boxShadow: 1,
          background: item.section_color ? item.section_color : "#ffffff",
          color: item.section_color
            ? getContrastColors("#ffffff", item.section_color)
            : "black",
        }}
        style={{
          marginBottom: "0px",
          width: "100%",
          padding: "0px 5px 0px 5px",
          borderRadius:
            index === 0
              ? "5px 5px 0px 0px"
              : index === maxLength
              ? "0px 0px 5px 5px"
              : "0px",
        }}
      >
        {counterProducts && (
          <Box
            sx={{
              borderRadius: "50px",
              boxShadow: 1,
              backgroundColor: "#f6f6f6b8",
              color: "black",
              padding: "5px",
            }}
          >
            {counterProducts}
          </Box>
        )}

        <ButtonBase
          id={'reorder-section-id-'+item.section_id}
          sx={{ width: "100%", height: "40px" }}
          onClick={() =>
            onClick(
              item.section_name.it,
              item.section_id,
              item.section_cover,
              item.section_color,
              item.section_card_color,
              item.section_descriptions?.it
            )
          }
          align="center"
        >
          <Typography align="center" variant="h5">
            {item.section_name.it}
          </Typography>
        </ButtonBase>

        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ touchAction: "none", cursor: "drag" }}
          spacing={0}
          onPointerDown={(event) => dragControls.start(event)}
        >
          <ArrowDropUpRoundedIcon fontSize="large" />
          <DragHandleRoundedIcon fontSize="large" style={{ margin: "-16px" }} />
          <ArrowDropDownRoundedIcon fontSize="large" />
        </Stack>
      </Stack>
    </Reorder.Item>
  );
};
