import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReorderSection from "./ReorderSection";
import { Reorder } from "framer-motion";
import RenderModalLight from "../Modals/RenderModalLight";

import { ReorderMenuItem } from "./ReorderMenuItem";
/*import {handleResponse} from '../../api/api'*/
import { Box, TextField, InputAdornment } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';


function ReorderMenu(props) {
  const {
    menuData,
    sections,
    language = "it",
    onChangeSections = console.log,
    onChangeSubSections = console.log,
    onChange = console.log,
    onChangeSectionName = console.log,
    onChangeSectionCover = console.log,
    onChangeSectionCardColor = console.log,
    onChangeSectionDescription = console.log,
    onChangeSectionColor = console.log,
    subSections,
    onAddProduct = console.log,
    onDelete = console.log,
    onDeleteSubSection = console.log,
    onEdit = console.log,
    onClick = console.log,
    onSave = console.log,
    children,
    planValue = 3,
    onDeleteProduct = console.log,
    ...rest
  } = props;
  const [sectionsMenuData, setSectionsMenuData] = useState({});
  const [items, setItems] = useState(sections);

  let [menuDataEdited, setMenuDataEdited] = useState({});

  function handleDrag(newItems) {
    setItems(newItems);
    onChangeSections(newItems);
  }

  useEffect(() => {
    const updatedSectionsMenuData = sections.reduce((acc, section) => {
      acc[section.section_id] = menuData.filter(
        (data) => data.section_id === section.section_id
      );
      return acc;
    }, {});
    setSectionsMenuData(updatedSectionsMenuData);
  }, [menuData]);

  useEffect(() => {
    setItems(sections);
  }, [sections]);

  const [itemsDeleted, setItemsDeleted] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [sectionData, setSectionData] = useState({});
  let [section_names, setSectionNames] = useState({});

  function handleClick(
    section_name,
    section_id,
    section_cover,
    section_color,
    section_card_color,
    section_description
  ) {
    setSectionData({
      section_name: section_name,
      section_id: section_id,
      section_cover: section_cover,
      section_color: section_color,
      section_card_color,
      section_description: section_description,
    });
    setOpenModal(true);
  }

  function handleChange(section_id, items) {
    if (menuDataEdited["menuData"] === undefined) {
      menuDataEdited["menuData"] = {};
    }
    menuDataEdited["menuData"][section_id] = items;
    sectionsMenuData[section_id] = [...items];
    setSectionsMenuData({ ...sectionsMenuData });
    onChange({ ...menuDataEdited });
  }

  function handleDeleteSection(section_id) {
    if (menuDataEdited["sectionsDeleted"] === undefined) {
      menuDataEdited["sectionsDeleted"] = [];
    }
    menuDataEdited["sectionsDeleted"].push(section_id);
    onChange({ ...menuDataEdited });
    setOpenModal(false);
    onSave({ ...menuDataEdited });
  }

  function handleChangeSectionName(values) {
    if (!section_names[values.section_id]) {
      section_names[values.section_id] = {};
    }
    section_names[values.section_id][language] = values.section_name;
    section_names[values.section_id][language] = values.section_name;

    onChangeSectionName(section_names);

    let newSections = [];
    for (let section of items) {
      if (section.section_id === values.section_id) {
        section.section_name[language] = values.section_name;
      }
      newSections.push(section);
    }
    sectionData.section_name = values.section_name;
    setSectionData({ ...sectionData });
    setSectionsMenuData({ ...sectionsMenuData });
    setItems(newSections);
  }

  const [sectionsCover, setSectionsCover] = useState({});
  const [sectionsDescriptions, setSectionsDescriptions] = useState({});
  const [sectionsColor, setSectionsColor] = useState({});

  function handleChangeSectionCover(values) {
    sectionData.section_cover = values.section_cover;
    setSectionData({ ...sectionData });
    sectionsCover[values.section_id] = values.section_cover;
    setSectionsCover({ ...sectionsCover });
    onChangeSectionCover(sectionsCover);
  }

  function handleChangeSectionDescription(values) {
    sectionData.section_description = values.section_description;
    setSectionData({ ...sectionData });
    sectionsDescriptions[values.section_id] = values.section_description;
    setSectionsDescriptions({ ...sectionsDescriptions });
    onChangeSectionDescription(sectionsDescriptions);
  }

  function handleChangeSectionColor(values) {
    sectionData.section_color = values.section_color;
    setSectionData({ ...sectionData });
    sectionsColor[values.section_id] = values.section_color;
    setSectionsColor({ ...sectionsColor });
    onChangeSectionColor(sectionsColor);
  }

  function handleChangeSectionCardColor(values) {
    sectionData.section_card_color = values.section_card_color;
    setSectionData({ ...sectionData });
    sectionsColor[values.section_id] = values.section_card_color;
    onChangeSectionCardColor(sectionsColor);
  }

  // cerca per descrizione e nome prodotto //
  const [noMatchProductsBySection, setNoMatchProductsBySection] = useState({});

  const handleSearch = (value) => {
    const searchQuery = value?.toLowerCase();
    const results = {};

    menuData.forEach((item) => {
      const productName = item.product_name?.it?.toLowerCase() || "";
      const productDescription = JSON.stringify(
        item.product_description?.it || ""
      ).toLowerCase();

      if (!results[item.section_id]) {
        results[item.section_id] = { product_ids: [], count: 0 };
      }
      if (
        !(
          productName.includes(searchQuery) ||
          productDescription.includes(searchQuery)
        )
      ) {
        results[item.section_id].product_ids.push(item.product_id);
      }
      results[item.section_id].count++;
    });

    setNoMatchProductsBySection(results);
  };

  useEffect(() => {
    handleSearch("");
  }, [menuData]);

  return (
    <Box {...rest}>
      <RenderModalLight
        open={openModal}
        setOpen={setOpenModal}
        header={sectionData.section_name}
        body={
          <ReorderSection
            sectionProducts={sectionsMenuData[sectionData.section_id]}
            section_cover={sectionData.section_cover}
            subSection={subSections[sectionData.section_id]}
            noMatchProductsId={noMatchProductsBySection[sectionData.section_id]}
            onChangeSubSection={(values) =>
              onChangeSubSections({
                section_id: sectionData.section_id,
                values: values,
              })
            }
            onDeleteSubSection={(values) =>
              onDeleteSubSection({ section_id: sectionData.section_id, values })
            }
            onChange={(items) => handleChange(sectionData.section_id, items)}
            onDelete={(itemDeleted) => (
              setItemsDeleted([...itemsDeleted, itemDeleted]),
              onDelete([...itemsDeleted, itemDeleted])
            )}
            onEdit={(itemEdited) => onEdit(itemEdited)}
            onChangeSectionName={(newSectionName) =>
              handleChangeSectionName({
                section_id: sectionData.section_id,
                section_name: newSectionName,
              })
            }
            onChangeSectionCover={(newSectionCover) =>
              handleChangeSectionCover({
                section_id: sectionData.section_id,
                section_cover: newSectionCover,
              })
            }
            onChangeSectionDescription={(values) =>
              handleChangeSectionDescription({
                section_id: sectionData.section_id,
                section_description: values,
              })
            }
            onChangeSectionColor={(values) =>
              handleChangeSectionColor({
                section_id: sectionData.section_id,
                section_color: values,
              })
            }
            onChangeSectionCardColor={(values) =>
              handleChangeSectionCardColor({
                section_id: sectionData.section_id,
                section_card_color: values,
              })
            }
            onDeleteSection={handleDeleteSection}
            section_name={sectionData.section_name}
            section_description={sectionData.section_description}
            section_color={sectionData.section_color}
            section_card_color={sectionData.section_card_color}
            section_id={sectionData.section_id}
            onAddProduct={(e) => (onAddProduct(e), setOpenModal(false))}
            planValue={planValue}
            onDeleteProduct={onDeleteProduct}
          />
        }
      />
      <Box sx={{padding:'10px 5px 5px 5px',backgroundColor:'white', boxShadow:1,borderRadius:'5px',marginTop:'10px'}}>
        <TextField
          fullWidth
          label="Cerca per nome o descrizione prodotto"
          variant="outlined"
          onChange={(event) => handleSearch(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {Object.keys(sectionsMenuData).length > 0 && (
        <Box sx={{ padding: "0px" }}>
          <Reorder.Group
            style={{ padding: "0px 10px 0px 0px" }}
            axis="y"
            values={items}
            onReorder={handleDrag}
          >
            {items.map((section, index) => {
              return (
                <ReorderMenuItem
                  key={section.section_id}
                  item={section}
                  index={index}
                  noMatchProductsId={
                    noMatchProductsBySection[section.section_id]
                  }
                  maxLength={items.length - 1}
                  onClick={handleClick}
                />
              );
            })}
          </Reorder.Group>
        </Box>
      )}
    </Box>
  );
}

export default ReorderMenu;

ReorderMenu.propTypes = {
  tables: PropTypes.array,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onChangeSubSection: PropTypes.func,
  onDeleteSubSection: PropTypes.func,
  onClick: PropTypes.func,
  onSave: PropTypes.func,
  onChangeSections: PropTypes.func,
  onChangeSubSections: PropTypes.func,
  onChangeSectionName: PropTypes.func,
  onAddProduct: PropTypes.func,
  onChangeSectionCover: PropTypes.func,
  onChangeSectionDescription: PropTypes.func,
  onChangeSectionColor: PropTypes.func,
  onChangeSectionCardColor: PropTypes.func,
  onDeleteProduct: PropTypes.func
  
};


