import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    TextField,
    Grid,
    Button,
    Typography
} from "@mui/material";

export default function IngredientDialogForm({
    onSave,
    ingredientType,
    open,
    setOpen
}) {
    const [currentIngredient, setCurrentIngredient] = useState({
            ingredientOption: { it: ingredientType },
            ingredientName: { it: "" },
            ingredientMeasure: "g",
            ingredient_quantity: 1,
            ingredient_price_variation_on_delete: "",
            ingredient_price_variation_on_add: 0
        }
    );

    const [isValid, setIsValid] = useState(false);


    useEffect(() => {
        validateForm();
    }, [currentIngredient]);

    const validateForm = () => {
        if (!ingredientType) {
            setIsValid(currentIngredient.ingredientOption.it !== "" && currentIngredient.ingredientName.it !== "");
        } else {
            setIsValid(currentIngredient.ingredientName.it !== "");
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        if (isValid) {
            onSave(currentIngredient);
            handleClose();
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth='md'>

            <DialogTitle>Aggiungi opzione alternativa</DialogTitle>

            <DialogContent>

                <Typography variant="body2">
                    <strong>Istruzioni:</strong>
                    <ul>
                        <li>
                            Aggiungi una nuova <strong>categoria</strong> o{" "}
                            <strong>variante alternativa</strong> al tuo prodotto.
                        </li>
                        <li>
                            Inserisci la <strong>prima opzione</strong> predefinita della categoria.
                        </li>
                        <li>
                            In seguito potrai aggiungere <strong>ulteriori opzioni (almeno 2)</strong>{" "}
                            con una variazione di prezzo <strong>positiva</strong> o{" "}
                            <strong>negativa</strong>.
                        </li>
                    </ul>
                    <strong>Esempi:</strong>
                    <ul>
                        <li>
                            <strong>Es 1 - Impasto per Pizza:</strong> Prima opzione "Normale"
                            (prezzo "0€"), seconda opzione "Integrale" (prezzo "+1€").
                        </li>
                        <li>
                            <strong>Es 2 - Dimensione per Pizza:</strong> Prima opzione "Normale"
                            (prezzo "0€"), seconda opzione "Maxi" (prezzo "+4€").
                        </li>
                        <li>
                            <strong>Es 3 - Formato di Vino:</strong> Prima opzione "Bottiglia"
                            (prezzo "0€"), seconda opzione "Bicchiere" (prezzo "-16€").
                        </li>
                    </ul>
                </Typography>


                <Grid container spacing={1} sx={{ marginTop: "5px" }}>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            placeholder="Es: Impasto, Formato, ..."
                            label="Categoria opzione"
                            value={currentIngredient.ingredientOption.it}
                            onChange={(e) =>
                                setCurrentIngredient({
                                    ...currentIngredient,
                                    ingredientOption: { it: e.target.value }
                                })
                            }
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            placeholder="Es: Normale,Integrale, Bottiglia, Bicchiere ..."
                            label={
                                ingredientType ? "Nome  opzione" : "Nome opzione di default"
                            }
                            value={currentIngredient.ingredientName.it}
                            onChange={(e) =>
                                setCurrentIngredient({
                                    ...currentIngredient,
                                    ingredientName: { it: e.target.value }
                                })
                            }
                        />
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleSave} disabled={!isValid}>
                    Aggiungi
                </Button>
            </DialogActions>
        </Dialog>
    );
}
