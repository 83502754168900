export   const borderColor = "#d9d9d9";
export const borderStyle = {
  borderLeft: `1px solid ${borderColor}`, // Bordo a sinistra
  borderRight: `1px solid ${borderColor}` // Bordo a destra
};

export const statusColor = {
    0: "white",
    1: "#9cc6ec",
    2: "#fff6dc",
    3: "#d7e6d2"
  };


export  const warningStyle = {
    backgroundColor: "rgba(0, 0, 0, 0.2)", // questo oscura l'80% del colore sottostante
    color: "white",
    borderRadius: "10px",
    padding: "4px"
  };