import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import ProductsMenuSubSections from "./ProductsMenuSubSections";
import { useTheme } from "@mui/material/styles";
import ProductsMenuList from "./ProductsMenuList";
import { adjustBrightness } from "../../Functions/Colors";
import { RenderParallaxImage } from "../../Animations/UseParallaxScroll";
import { heIL } from "@mui/x-date-pickers/locales";
import { Height } from "@mui/icons-material";

function getAllProductsFromSection(sectionMenuData) {
  // Ottieni i prodotti direttamente dalla sezione principale
  let allProducts = sectionMenuData.products.slice(); // Utilizza slice per clonare l'array se necessario

  // Aggiungi i prodotti dalle sotto-sezioni
  Object.values(sectionMenuData.subSections).forEach((subSection) => {
    allProducts = allProducts.concat(subSection.subSectionData);
  });
  allProducts = allProducts.filter((item) => item.product_hidden == false);

  return allProducts;
}

const ProductsMenuSection = ({
  sectionMenuData,
  productsIdHidden,
  isStaff,
  basket,
  setBasket,
  section_id,
  section_description,
  section_name,
  section_color,
  section_card_color,
  card_variant_selected,
  card_animation_variant_selected,
  getSectionCover = () => {},
  getProductCover = () => {},
  responsiveStyle,
  menuCardShadow,
  menuAddProductDialogFullscreen,
  menuAddProductDialogAnimation,
}) => {
  const theme = useTheme();
  const [sectionCover, setSectionCover] = useState(null);
  async function getCover() {
    const cover = await getSectionCover(section_id);
    console.log("cover received:", cover);
    setSectionCover(cover);
  }
  useEffect(() => {
    getCover();
  }, []);
  const productsSectionData = sectionMenuData.products
    ? sectionMenuData.products
    : [];
  const productsSubSectionData = sectionMenuData.subSections
    ? sectionMenuData.subSections
    : [];
  const allProducts = getAllProductsFromSection(sectionMenuData);

  const section_background_description = section_color
    ? adjustBrightness(section_color, 0.2, true)
    : theme.palette.primary.main;

  if (allProducts.length > 0) {
    return (
      <Box sx={{ overflow: "hidden"}}>
        {sectionCover && (
          <RenderParallaxImage
            url={sectionCover}
            speed={1}
            style={{ height: "300px", marginBottom: "5px" }}
          />
        )}
        <Box sx={{margin:'5px'}}>
          <Grid
            container
            spacing={card_variant_selected === "no-background" ? 0 : 2}
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: "50px" }}
          >
            {section_description && (
              <Grid item xs={12}>
                <Box
                  boxShadow={0}
                  sx={{
                    padding: "5px",
                    borderRadius: "5px",
                    color: theme.palette.getContrastText(
                      section_color
                        ? section_color
                        : section_background_description
                    ),
                    backgroundColor: section_color
                      ? "#fffffff"
                      : section_background_description,
                    margin: "0px",
                  }}
                >
                  <Typography variant="body2" component="div" align="center">
                    {section_description}
                  </Typography>
                </Box>
              </Grid>
            )}

            {productsIdHidden.length > 0 && (
              <Grid item xs={12}>
                <Alert sx={{ margin: "30px" }} severity="info">
                  <Typography variant="body" component="span">
                    {allProducts.length -
                      allProducts.filter((item) =>
                        productsIdHidden.includes(item.product_id)
                      ).length}{" "}
                    prodotti filtrati su {allProducts.length}
                  </Typography>
                </Alert>
              </Grid>
            )}

            {productsSubSectionData &&
              Object.keys(productsSubSectionData).length > 0 && (
                <Grid item xs={12}>
                  <ProductsMenuSubSections
                    subSectionsData={productsSubSectionData}
                    productsIdHidden={productsIdHidden}
                    isStaff={isStaff}
                    basket={basket}
                    setBasket={setBasket}
                    section_name={section_name}
                    section_card_color={section_card_color}
                    card_variant_selected={card_variant_selected}
                    card_animation_variant_selected={
                      card_animation_variant_selected
                    }
                    getProductCover={getProductCover}
                    responsiveStyle={responsiveStyle}
                    menuCardShadow={menuCardShadow}
                    menuAddProductDialogFullscreen={
                      menuAddProductDialogFullscreen
                    }
                    menuAddProductDialogAnimation={
                      menuAddProductDialogAnimation
                    }
                  />
                </Grid>
              )}

            <Grid item xs={12}>
              <ProductsMenuList
                sectionMenuData={productsSectionData}
                productsIdHidden={productsIdHidden}
                isStaff={isStaff}
                basket={basket}
                setBasket={setBasket}
                section_card_color={section_card_color}
                card_variant_selected={card_variant_selected}
                card_animation_variant_selected={
                  card_animation_variant_selected
                }
                getProductCover={getProductCover}
                responsiveStyle={responsiveStyle}
                menuCardShadow={menuCardShadow}
                menuAddProductDialogFullscreen={menuAddProductDialogFullscreen}
                menuAddProductDialogAnimation={menuAddProductDialogAnimation}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  }
};

export default ProductsMenuSection;
