import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MultiSelectSectionDialog from "../../Forms/MultiSelectDialog";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    ButtonBase,
    Typography,
    Badge
} from "@mui/material";
import { useTheme } from '@mui/material/styles';

import OrdersCollapsibleSectionRow from "./OrdersCollapsibleSectionRow";
import { transformOrders } from '../functions'
import { borderStyle } from '../style'
import DialogProductsAlert from './DialogProductsAlert'
import FilterListIcon from '@mui/icons-material/FilterList';

export default function OrdersCollapsibleTable({ ordersData, sectionNames, onChangeOrder,onUpdate }) {
    const theme = useTheme()
    const [roomTableGroup, setRoomTableGroup] = useState({})
    const [sectionGrouped, setSectionGrouped] = useState({})
    useEffect(() => {
        let data = transformOrders(ordersData)
        let sectionGrouped = data.sectionGrouped
        setRoomTableGroup(data.roomTableGrouped)
        setSectionGrouped(sectionGrouped)
    }, [ordersData])

    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedProductInfo, setSelectedProductInfo] = useState({});

    const [openSelectSections, setOpenSelectSections] = useState(false)
    const [sectionsIdsSelected, setSectionsIdsSelected] = useState(undefined)

    function handleConfirmSelectSections(sectionIds) {
        setSectionsIdsSelected([...sectionIds])
        setOpenSelectSections(false)
    }



    const handleCellClick = (data) => {
        onUpdate(true)
        const product = data.product_name;
        const table_room = data.table_room;
        const table_name = data.table_name;
        const orders_id = data.orders_id
        const reverse = data.reverse ? data.reverse : false;

        // Crea una copia profonda di sectionGrouped per evitare mutazioni accidentali
        //const updatedData = JSON.parse(JSON.stringify(sectionGrouped));
        const updatedData = { ...sectionGrouped };

        // Itera attraverso sectionGrouped e aggiorna l'elemento corrispondente
        for (const section in updatedData) {
            for (const room in updatedData[section]) {
                for (const table in updatedData[section][room]) {
                    for (const productName in updatedData[section][room][table]) {
                        let item = updatedData[section][room][table][productName];
                        if (
                            item.product_name === product &&
                            item.table_room === table_room &&
                            item.table_name === table_name
                        ) {
                            // Se product_status è undefined, consideralo come 0
                            const currentStatus = item.product_status || 0;
                            let updatedStatus;
                            if (reverse) {
                                updatedStatus = currentStatus > 0 ? currentStatus - 1 : currentStatus;
                            } else {
                                updatedStatus = currentStatus < 3 ? currentStatus + 1 : currentStatus;
                            }
                            item.product_status = updatedStatus;
                            handleChangeOrders({ ...data, updatedStatus })
                            break; // Usciamo dal ciclo poiché abbiamo trovato e aggiornato il prodotto
                        }
                    }
                }
            }
        }
        setSectionGrouped(updatedData);
    };

    function handleChangeOrders(data) {
        const { orders_id, product_name, table_name, table_room, updatedStatus } = data;
        // Itera attraverso ogni order_id
        for (const order_id of orders_id) {
            let order = ordersData[order_id];

            // Se esiste un basket per l'order_id corrente
            if (order && order.basket) {
                if (order.table_name === table_name && order.table_room === table_room) {
                    // Itera attraverso il basket per trovare il prodotto corrispondente
                    for (let item of order.basket) {
                        if (item.product_name.it === product_name) {
                            // Aggiorna lo product_status
                            item.product_status = updatedStatus;
                            item.edited = true
                        }
                    }
                }
            }
            onChangeOrder(order);
        }


    }





    return (
        <>
            <TableContainer component={Paper}>
                <Table size="small">

                    <TableHead style={{ backgroundColor: 'rgb(101 108 129)', color: 'white' }}>
                        <TableRow sx={{ padding: '0px', margin: '0px' }}>
                            <TableCell
                                rowSpan={2}
                                sx={{
                                    padding: '0px',
                                    margin: '0px',
                                    color: 'white',
                                    position: 'relative',
                                    background: 'linear-gradient(to bottom left, transparent 49%, #dedede 49%, #f3f3f3 51%, transparent 51%)'

                                }}
                                align='center'
                            >
                                <Typography
                                    variant='body'
                                    component='div'
                                    align='right'
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 2,
                                        fontWeight: 'bold'
                                    }}
                                >
                                    Aree
                                </Typography>

                                <ButtonBase
                                    onClick={() => setOpenSelectSections(true)}
                                    sx={{
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        padding: '5px',
                                        fontWeight: 'bold'
                                    }}>
                                    <Badge badgeContent={sectionsIdsSelected && sectionsIdsSelected.length !== sectionNames.length ? sectionsIdsSelected.length : 0} color="primary">
                                        <FilterListIcon />
                                    </Badge>

                                    <Typography
                                        variant='body'
                                        component='div'
                                        align='left'

                                    >
                                        Sezioni
                                    </Typography>
                                </ButtonBase>
                            </TableCell>

                            {Object.keys(roomTableGroup).map((room_name) => {
                                const tablesInSection = Object.keys(roomTableGroup[room_name])
                                return (
                                    <TableCell
                                        style={borderStyle}
                                        sx={{ padding: '0px', margin: '0px', color: 'white' }}
                                        key={room_name}
                                        align="center"
                                        colSpan={tablesInSection.length}
                                    >
                                        <b>{room_name}</b>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                        <TableRow>
                            {Object.values(roomTableGroup).map((room) => (
                                Object.keys(room).map((table) => (
                                    <TableCell
                                        sx={{ padding: '0px', margin: '0px', color: 'white' }}
                                        key={table}
                                        style={borderStyle}
                                        align="center"
                                    >
                                        <b>{table}</b>
                                    </TableCell>
                                ))
                            ))}
                        </TableRow>
                    </TableHead>

                        <TableBody>
                            {Object.entries(sectionGrouped).map(([section, dataSection]) => {

                                if (sectionsIdsSelected === undefined || (sectionsIdsSelected && (sectionsIdsSelected.length === 0 || sectionsIdsSelected.includes(section)))) {
                                    return (
                                        <OrdersCollapsibleSectionRow
                                            key={section}
                                            section={section}
                                            dataSection={dataSection}
                                            roomTableGroup={roomTableGroup}
                                            setDialogOpen={setDialogOpen}
                                            setSelectedProductInfo={setSelectedProductInfo}
                                            onClickCell={handleCellClick}
                                        />
                                    )
                                }

                            })}
                        </TableBody>
                        
                </Table>
            </TableContainer>
            
            
            <DialogProductsAlert
                dialogOpen={dialogOpen}
                setDialogOpen={setDialogOpen}
                handleCellClick={handleCellClick}
                setSelectedProductInfo={setSelectedProductInfo}
                selectedProductInfo={selectedProductInfo}
                ordersData={ordersData}
            />
            <MultiSelectSectionDialog open={openSelectSections} items={sectionNames} onClose={handleConfirmSelectSections} />
        </>
    )
}

OrdersCollapsibleTable.propTypes = {
    ordersData: PropTypes.object,
    onChangeOrder: PropTypes.func,
    onUpdate: PropTypes.func
};
