import React from "react";
import Button from "@mui/material/Button";
import { Box, Stack } from "@mui/material";
import LogoGoogle from "./LogoGoogle.png";
import LogoFacebook from "./FacebookLogo.webp";
import { GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

const SocialLoginButtons = ({ handleSocialLoginClick }) => {
  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Button
        startIcon={
          <img src={LogoGoogle} alt="Google" style={{ width: "20px" }} />
        }
        sx={{
          boxShadow: 1,
          marginTop: "10px",
          background: "white",
          color: "black",
          border: "solid 1px grey",
          maxWidth: "400px",
          borderRadius: "50px",
          "&:hover": {
            backgroundColor: "#adadad",
            color: "white",
            boxShadow: 3,
          },
          "&:active": {
            backgroundColor: "#adadad",
            color: "white",
            boxShadow: 1,
          },
        }}
        fullWidth
        variant="contained"
        onClick={() => handleSocialLoginClick(googleProvider)}
      >
        Accedi con Google
      </Button>
      {(process.env.REACT_APP_ENV === "DEV" ||
        process.env.REACT_APP_ENV === "TEST") && (
        <Button
          startIcon={
            <img src={LogoFacebook} alt="Facebook" style={{ width: "20px" }} />
          }
          sx={{
            boxShadow: 1,
            marginTop: "10px",
            backgroundColor: "#4267B2", // Colore blu classico di Facebook
            color: "white",
            border: "none",
            maxWidth: "400px",
            borderRadius: "50px",
            transition: "background-color 0.3s ease, box-shadow 0.3s ease",
            "&:hover": {
              backgroundColor: "#365899", // Colore più scuro al passaggio del mouse
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Effetto ombra al passaggio del mouse
              boxShadow: 3,
            },
            "&:active": {
              backgroundColor: "#2d4373", // Colore più scuro quando cliccato
              boxShadow: "none", // Rimuove l'ombra durante il click
            },
          }}
          fullWidth
          variant="contained"
          onClick={() => handleSocialLoginClick(facebookProvider)}
        >
          Accedi con Facebook
        </Button>
      )}
    </Stack>
  );
};

export default SocialLoginButtons;
