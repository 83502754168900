export function transformOrders(orders) {
    const sectionGroup = {};
    const roomTableGroup = {};

    for (const key in orders) {
        const order = orders[key];
        for (const product of order.basket) {
            const matchedSection = order.basket_sections.find(
                section => section.section_id === product.section_id
            );
            const sectionName = matchedSection ? matchedSection.section_name.it : "Unknown";
            const productName = product.product_name.it;

            if (product.product_status > 3) {
                // se product status >3 non viene visualizzato nella tabella
                continue; // skip this product and move to the next one
            }

            if (!sectionGroup[sectionName]) sectionGroup[sectionName] = {};
            if (!sectionGroup[sectionName][order.table_room]) sectionGroup[sectionName][order.table_room] = {};
            if (!sectionGroup[sectionName][order.table_room][order.table_name]) {
                sectionGroup[sectionName][order.table_room][order.table_name] = {};
            }
            if (!sectionGroup[sectionName][order.table_room][order.table_name][productName]) {
                sectionGroup[sectionName][order.table_room][order.table_name][productName] = {
                    section_name: sectionName,
                    product_name: productName,
                    quantity: 0,
                    orders_id: [],
                    product_status: product.product_status || 0,
                    section_id: product.section_id,
                    product_id: product.product_id,
                    table_name: order.table_name,
                    table_room: order.table_room,
                    table_reference: order.table_reference,
                    variation:
                        (product.product_message && product.product_message !== "" && product.product_message !== null) ||
                        (Object.keys(product.productIngredientsVar).length > 0)
                };
            }

            const groupedProduct = sectionGroup[sectionName][order.table_room][order.table_name][productName];

            groupedProduct.quantity += product.quantity;
            groupedProduct.orders_id.push(key);
            groupedProduct.product_status = Math.min(groupedProduct.product_status, product.product_status || 0);

            groupedProduct.variation = groupedProduct.variation || product.variation; // aggiunto qui


            if (!roomTableGroup[order.table_room]) roomTableGroup[order.table_room] = {};
            if (!roomTableGroup[order.table_room][order.table_name]) {
                roomTableGroup[order.table_room][order.table_name] = {};
            }

            // Ensure that roomTableGroup does not contain duplicate products
            roomTableGroup[order.table_room][order.table_name][productName] = groupedProduct;
        }
    }

    // Convert the roomTableGroup's product mapping into arrays
    for (const roomName in roomTableGroup) {
        for (const tableName in roomTableGroup[roomName]) {
            roomTableGroup[roomName][tableName] = Object.values(roomTableGroup[roomName][tableName]);
        }
    }

    return {
        sectionGrouped: sectionGroup,
        roomTableGrouped: roomTableGroup
    };
}
