import React, { useState } from "react";
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Grid,
  Select,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
  CircularProgress,
  Link,
  Tooltip,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import TimeSlotSelector from "./TimeSlotSelector";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SendIcon from "@mui/icons-material/Send";
import { Stepper, Step, StepLabel } from "@mui/material";
import PromotionDetails from "./PromotionDetails";

const steps = ["Step 1", "Step 2", "Step 3", "Step 4"];

const dayTranslation = {
  Sunday: "Domenica",
  Monday: "Lunedì",
  Tuesday: "Martedì",
  Wednesday: "Mercoledì",
  Thursday: "Giovedì",
  Friday: "Venerdì",
  Saturday: "Sabato",
};

const getTimeSlotsForSelectedDate = (date, closingTimes) => {
  const dayOfWeekInEnglish = dayjs(date).format("dddd");
  // se il componente è italiano arriva la data in italiano e non più in inglese
  const dayOfWeekInItalian = dayTranslation[dayOfWeekInEnglish] || dayOfWeekInEnglish.charAt(0).toUpperCase() + dayOfWeekInEnglish.slice(1);
  return closingTimes.days[dayOfWeekInItalian] || [];
};

function isTimeInRange(timeToCheck, startTime, endTime) {
  // Convert time strings to Date objects
  const timeCheck = new Date(`1970-01-01T${timeToCheck}:00`);
  const start = new Date(`1970-01-01T${startTime}:00`);
  const end = new Date(`1970-01-01T${endTime}:00`);

  if (start < end) {
    // Case 1: Range does not cross midnight
    return timeCheck >= start && timeCheck <= end;
  } else {
    // Case 2: Range crosses midnight
    return timeCheck >= start || timeCheck <= end;
  }
}

function isPromotionApplicable(bookingData, promotion) {
  const selectedDayOfWeek = bookingData.date
    ? bookingData?.date?.day() || 0 + 1
    : null;
  let isDayValid = true;
  if (promotion.available_weekdays) {
    isDayValid = promotion.available_weekdays.includes(selectedDayOfWeek);
  }
  let isTimeValid = true;
  if (promotion.valid_from_hour && promotion.valid_to_hour) {
    isTimeValid = bookingData.time
      ? isTimeInRange(
          bookingData.time,
          promotion.valid_from_hour,
          promotion.valid_to_hour
        )
      : false;
  }

  return isDayValid && isTimeValid;
}

const BookingForm = ({
  closingTimes,
  closingDates,
  promotions = [],
  onSubmit,
  closingBuffer = 1,
  marginBeforeClosing = 60,
  maxGuests = 30,
  consent = false,
  userName,
}) => {
  const [step, setStep] = useState(1);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [bookingData, setBookingData] = useState({
    date: null,
    time: null,
    guests: "",
    phone: "",
    name: userName,
    marketingConsent: false,
    dataProcessingConsent: false,
  });
  const [selectedPromotion, setSelectedPromotion] = useState("");
  const [showCoupon, setShowCoupon] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [timeRanges, setTimeRanges] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorFields, setErrorFields] = useState([]);

  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;
    setBookingData((prevData) => ({
      ...prevData,
      [name]:
        name === "marketingConsent" || name === "dataProcessingConsent"
          ? checked
          : value,
    }));
  };

  const handleDateChange = (date) => {
    setBookingData((prevData) => ({ ...prevData, date }));
    setTimeRanges(getTimeSlotsForSelectedDate(date, closingTimes));
  };

  const handleTimeChange = (time) => {
    setBookingData((prevData) => ({ ...prevData, time }));
  };

  const handlePromotionChange = (event) => {
    console.log("handlePromotionChange:", event.target.value);
    const promotion_id = event.target.value;
    setSelectedPromotion(promotion_id);
    setBookingData((prevData) => ({
      ...prevData,
      promotion_id_applied: promotion_id,
    }));
    setShowCoupon(event.target.value !== "");
  };

  const generateClosedDates = (closingTimes) => {
    const closedDates = [];
    const today = dayjs();
    const sixMonthsFromNow = today.add(6, "month");
    const daysOfWeek = [
      "Domenica",
      "Lunedì",
      "Martedì",
      "Mercoledì",
      "Giovedì",
      "Venerdì",
      "Sabato",
    ];

    for (
      let date = today;
      date.isBefore(sixMonthsFromNow);
      date = date.add(1, "day")
    ) {
      const dayName = daysOfWeek[date.day()];
      if (
        !closingTimes.days[dayName] ||
        closingTimes.days[dayName].length === 0
      ) {
        closedDates.push(date.startOf("day"));
      }
    }

    return closedDates;
  };

  const isDateClosed = (date, closingDates, closedDaysList) => {
    const today = dayjs().startOf("day");
    if (date.isBefore(today)) {
      return true;
    }

    const isInClosingDates = closingDates.some((closedDate) => {
      const startDate = dayjs(closedDate.local_closing_start_date).startOf(
        "day"
      );
      const endDate = dayjs(closedDate.local_closing_end_date).endOf("day");
      return date.isAfter(startDate) && date.isBefore(endDate);
    });

    if (isInClosingDates) {
      return true;
    }

    return closedDaysList.some((closedDate) => date.isSame(closedDate, "day"));
  };

  const closedDaysList = generateClosedDates(closingTimes);


  const validateStep = () => {
    let errorMessages = [];
    let errors = [];

    switch (step) {
      case 1:
        if (!bookingData.date) {
          errorMessages.push("Devi selezionare una data");
          errors.push("date");
        } else {
          // Verifica se la data è valida
          const dateObj = new Date(bookingData.date);
          if (isNaN(dateObj.getTime())) {
            errorMessages.push("La data selezionata non è valida");
            errors.push("date");
          }
        }
        break;

      case 2:
        if (!bookingData.time || bookingData.time === "") {
          errorMessages.push("Devi selezionare un orario");
          errors.push("time");
        }
        break;
      case 3:
        if (!bookingData.guests || bookingData.guests <= 0) {
          errorMessages.push("Devi inserire il numero di ospiti");
          errors.push("guests");
        }
        if (bookingData.guests > maxGuests) {
          errorMessages.push(
            `Il numero degli ospiti non deve superare ${maxGuests}`
          );
          errors.push("guests");
        }
        if (!bookingData.name) {
          errorMessages.push(
            `Devi inserire un nome associato alla prenotazione`
          );
          errors.push("name");
        }
        break;
      case 4:
        const validatePhoneNumber = (phoneNumber) => {
          // Rimuove spazi, trattini e parentesi
          const cleaned = phoneNumber.replace(/[\s\-\(\)]/g, "");

          // Controlla che sia solo composto da cifre e abbia una lunghezza accettabile (es. 10-15 cifre)
          const isValid = /^\d{10,15}$/.test(cleaned);

          return isValid;
        };
        if (!bookingData.phone) {
          errorMessages.push("Devi inserire il tuo numero di telefono");
          errors.push("phone");
        } else if (!validatePhoneNumber(bookingData.phone)) {
          errorMessages.push("Il numero di telefono inserito non è valido");
          errors.push("phone");
        }
        if (!bookingData.dataProcessingConsent) {
          errorMessages.push(
            "Devi acconsentire al trattamento dei dati personali"
          );
          errors.push("dataProcessingConsent");
        }
        break;

      default:
        break;
    }

    if (errorMessages.length > 0) {
      setSnackbarMessage(errorMessages[0]);
      setOpenSnackbar(true);
      setErrorFields(errors);
      return false;
    }

    return true;
  };

  const handleNextStep = () => {
    if (validateStep()) {
      setErrorFields([]);
      setStep(step + 1);
    }
  };

  const handlePrevStep = () => {
    setStep(step - 1);
    setErrorFields([]);
  };

  async function handleSubmit(event) {
    event.preventDefault();

    if (!validateStep()) return;

    setLoading(true);

    try {
      if (consent) {
        bookingData.marketingConsent = true;
      }
      await onSubmit({ bookingData, selectedPromotion });
    } catch (error) {
      console.error("Errore durante la prenotazione:", error);
      // Puoi mostrare un messaggio di errore all'utente qui, se necessario
    } finally {
      setLoading(false); // Questo verrà eseguito sia in caso di successo che di errore
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
      <Box
        elevation={3}
        style={{ padding: "5px", margin: "0px", textAlign: "center" }}
      >
        <Box sx={{ width: "100%", margin: "20px auto", maxWidth: "600px" }}>
          <Stepper activeStep={step - 1} alternativeLabel>
            {steps.map((label, index) => (
              <Step
                key={index}
                sx={{
                  "& .MuiStepLabel-root .Mui-completed": {
                    color: "#3a3c44", // colore per step completato
                  },
                  "& .MuiStepLabel-root .Mui-active": {
                    color: "#c9d0ec", // colore per step attivo
                  },
                  "& .MuiStepLabel-root .Mui-disabled": {
                    color: "#a5a9bb", // colore per step non ancora raggiunto
                  },
                }}
              >
                <StepLabel />
              </Step>
            ))}
          </Stepper>
        </Box>
        <form onSubmit={handleSubmit}>
          {step === 1 && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Seleziona una data per la tua prenotazione:
                </Typography>
                <DatePicker
                  label="Data"
                  value={
                    bookingData.date
                      ? dayjs(bookingData.date).startOf("day").add(12, "hour")
                      : null
                  }
                  onChange={(newDate) => {
                    const correctedDate = newDate
                      .startOf("day")
                      .add(12, "hour"); // Setta l'ora a mezzogiorno
                    handleDateChange(correctedDate);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={errorFields.includes("date")}
                      helperText={params?.inputProps?.placeholder}
                    />
                  )}
                  inputFormat="DD/MM/YYYY" // Ensure correct format
                  shouldDisableDate={(date) =>
                    isDateClosed(date, closingDates, closedDaysList)
                  }
                />
              </Grid>
            </Grid>
          )}
          {step === 2 && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Seleziona l'orario di prenotazione desiderato:
                </Typography>
                <FormControl fullWidth error={errorFields.includes("time")}>
                  <TimeSlotSelector
                    timeRanges={timeRanges}
                    onTimeSlotSelect={handleTimeChange}
                    marginBeforeClosing={marginBeforeClosing}
                  />
                </FormControl>
              </Grid>
            </Grid>
          )}
          {step === 3 && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    Inserisci un nome per la prenotazione
                  </Typography>
                  <TextField
                    fullWidth
                    label="Nome"
                    name="name"
                    value={bookingData.name}
                    onChange={handleInputChange}
                    required
                    error={errorFields.includes("name")}
                    inputProps={{ min: 1, max: maxGuests }}
                  />
                </Grid>
                <Typography variant="body1">
                  Inserisci il numero di ospiti
                </Typography>
                <TextField
                  fullWidth
                  label="Numero di ospiti"
                  name="guests"
                  type="number"
                  value={bookingData.guests}
                  onChange={handleInputChange}
                  required
                  error={errorFields.includes("guests")}
                  inputProps={{ min: 1, max: maxGuests }}
                />
              </Grid>
            </Grid>
          )}
          {step === 4 && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ marginBottom: "5px" }}>
                  Inserisci il tuo numero di telefono, ci servirà per
                  contattarti solo in caso di necessità in merito alla
                  prenotazione:
                </Typography>
                <TextField
                  fullWidth
                  label="* Telefono"
                  name="phone"
                  value={bookingData.phone}
                  onChange={handleInputChange}
                  error={errorFields.includes("phone")}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ marginBottom: "5px" }}>
                  Vuoi lasciarci richieste particolari?
                </Typography>
                <TextField
                  fullWidth
                  placeholder="Esempio: Siamo con un passeggino e avremmo bisogno di un seggiolino, oppure, siamo intolleranti al glutine ...."
                  name="special_requests"
                  value={bookingData.special_requests}
                  onChange={handleInputChange}
                />
              </Grid>

              {promotions.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="body1" sx={{ marginBottom: "5px" }}>
                    Hai dei coupon associati al tuo account, vuoi utilizzarne
                    uno?
                  </Typography>
                  <FormControl
                    fullWidth
                    error={errorFields.includes("promotion")}
                  >
                    <InputLabel>Promozione</InputLabel>
                    <Select
                      value={selectedPromotion}
                      onChange={handlePromotionChange}
                      label="Promozione"
                      fullWidth
                      sx={{ maxWidth: "90vw" }}
                    >
                      <MenuItem value="">
                        <Box
                          sx={{
                            padding: "20px",
                            backgroundColor: "#f5f5f5",
                            borderRadius: "8px",
                            display: "block",
                            width: "100%",
                          }}
                        >
                          <Typography variant="body1" textAlign="center">
                            <b>Nessuna promozione</b>
                          </Typography>
                        </Box>
                      </MenuItem>
                      {promotions.map((promo, index) => {
                        const isApplicable = isPromotionApplicable(
                          bookingData,
                          promo
                        );
                        if (isApplicable && promo.is_redeemed === false) {
                          return (
                            <MenuItem key={index} value={promo._id}>
                              <PromotionDetails promotion={promo} />
                            </MenuItem>
                          );
                        } else if (promo.is_redeemed === false) {
                          return (
                            <Tooltip
                              key={index}
                              title={
                                isApplicable
                                  ? "Promozione non applicabile"
                                  : "Promozione non valida per l’orario o la data selezionata"
                              }
                              placement="top"
                              arrow
                            >
                              <span>
                                <MenuItem
                                  value={promo._id}
                                  disabled={!isApplicable}
                                >
                                  {promo.promotion_description}
                                </MenuItem>
                              </span>
                            </Tooltip>
                          );
                        }
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12}>
                {!consent ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={bookingData.marketingConsent}
                        onChange={handleInputChange}
                        name="marketingConsent"
                      />
                    }
                    label="Acconsento al trattamento dei miei dati per finalità di marketing"
                  />
                ) : (
                  <Typography variant="subtitle2">
                    Hai già acconsentito all'iscrizione alla newsletter e le
                    offerte del locale
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={bookingData.dataProcessingConsent}
                      onChange={handleInputChange}
                      name="dataProcessingConsent"
                      required
                      error={errorFields.includes("dataProcessingConsent")}
                    />
                  }
                  label={
                    <span>
                      Acconsento al trattamento dei miei dati personali. Leggi i{" "}
                      <Link
                        sx={{ color: "blue" }}
                        href="/terms-of-service"
                        target="_blank"
                        rel="noopener"
                      >
                        Termini e Condizioni
                      </Link>
                    </span>
                  }
                />
              </Grid>
            </Grid>
          )}

          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item xs={6} sx={{ display: step === 1 ? "none" : "flex" }}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={step <= 1}
                onClick={handlePrevStep}
                startIcon={<ArrowBackIcon />}
              >
                Indietro
              </Button>
            </Grid>
            <Grid item xs={step === 1 ? 12 : 6}>
              {step < 4 ? (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleNextStep}
                  disabled={step >= 4}
                  endIcon={<ArrowForwardIcon />}
                >
                  Prosegui
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={loading}
                  endIcon={
                    loading ? <CircularProgress size={24} /> : <SendIcon />
                  }
                >
                  {loading ? "Prenotando..." : "Prenota"}
                </Button>
              )}
            </Grid>
          </Grid>
        </form>

        <Typography variant="body2" style={{ marginTop: "20px" }}>
          * Campo obbligatorio. Per ulteriori informazioni sul trattamento dei
          tuoi dati personali, consulta la nostra Informativa sulla Privacy.
        </Typography>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="warning">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </LocalizationProvider>
  );
};

export default BookingForm;
