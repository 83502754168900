export function isLight(color) {
  const hex = color.replace('#', '');
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);
  const lightness = (r * 0.299 + g * 0.587 + b * 0.114) / 255;

  return lightness > 0.5;
}

export function contrastColor(color) {
  const light = isLight(color)

  return light ? '#000000' : '#ffffff'
}

export function adjustBrightness(hex, percent, increase) {
  // converti la stringa hex in un array di valori RGB
  var r = parseInt(hex.substring(1, 3), 16);
  var g = parseInt(hex.substring(3, 5), 16);
  var b = parseInt(hex.substring(5, 7), 16);

  if (increase) {
    // aumenta la luminosità utilizzando la formula descritta prima
    r = parseInt(Math.min(255, r + (255 - r) * percent));
    g = parseInt(Math.min(255, g + (255 - g) * percent));
    b = parseInt(Math.min(255, b + (255 - b) * percent));
  } else {
    // diminuisci la luminosità utilizzando la formula descritta prima
    r = parseInt(Math.max(0, r - r * percent));
    g = parseInt(Math.max(0, g - g * percent));
    b = parseInt(Math.max(0, b - b * percent));
  }

  // converti nuovamente i valori RGB in una stringa hex
  r = r.toString(16).padStart(2, '0');
  g = g.toString(16).padStart(2, '0');
  b = b.toString(16).padStart(2, '0');

  return "#" + r + g + b;
}

export function getContrastColors(hexRender, hexContrast, sensibility = 0.75) {
  // Validazione dei colori
  if (!/^#[0-9A-F]{6}$/i.test(hexRender) || !/^#[0-9A-F]{6}$/i.test(hexContrast)) {
    console.error('Invalid hex color format ', hexRender, 'hexContrast:', hexContrast);
    return;
  }


  const r1 = parseInt(hexRender.substring(1, 3), 16);
  const g1 = parseInt(hexRender.substring(3, 5), 16);
  const b1 = parseInt(hexRender.substring(5, 7), 16);
  const luminanceRender = (0.2126 * r1) / 255 + (0.7152 * g1) / 255 + (0.0722 * b1) / 255;

  const r2 = parseInt(hexContrast.substring(1, 3), 16);
  const g2 = parseInt(hexContrast.substring(3, 5), 16);
  const b2 = parseInt(hexContrast.substring(5, 7), 16);
  const luminanceContrast = (0.2126 * r2) / 255 + (0.7152 * g2) / 255 + (0.0722 * b2) / 255;

  const difference = Math.abs(luminanceRender - luminanceContrast);

  if (difference > sensibility) {
    if (luminanceContrast > 0.5) {
      const result = adjustBrightness(hexRender, 0.9, false);
      return result;
    } else {
      const result = adjustBrightness(hexRender, 0.9, true);
      return result;
    }
  } else {
    if (luminanceContrast > 0.5) {
      return adjustBrightness(hexRender, 0.9, false);  // Scurendo il colore
    } else {
      return adjustBrightness(hexRender, 0.9, true);  // Schiarendo il colore
    }
  }


}

export function hexToRgba(hex, opacity) {
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);

  return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + opacity + ')';
}