import { useState, useEffect } from 'react';

// material-ui
import { useTheme, } from '@mui/material/styles';
import { Box,  Card, Typography, CircularProgress } from '@mui/material';
import {getContrastColors} from '../Functions/Colors'

// third-party

function formatNumber(value) {
    // Controllo se il valore è numerico
    if (typeof value !== 'number') {
        throw new Error('Il valore fornito non è un numero.');
    }

    // Formatta il numero con i decimali come virgole e le migliaia come punti
    const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
    const formattedNumber = value.toLocaleString('it-IT', options);

    if (Number.isInteger(value)) {
        return formattedNumber.split(',')[0]; // Restituisci solo la parte intera se il numero è intero
    } else {
        return formattedNumber;
    }
}



export default function SimpleKPICard({ value, title,isLoading,color='#f5f5f5' }) {
    const theme = useTheme();
    const [count, setCount] = useState(0);
    const [startTime, setStartTime] = useState(Date.now());

    useEffect(() => {
        setCount(0);
        setStartTime(Date.now());
    }, [value]);

    useEffect(() => {
        if (!isLoading) {
            const id = setInterval(() => {
                const elapsed = Date.now() - startTime;
                if (elapsed < 1000) {
                    setCount(easeOutQuad(elapsed, 0, value, 1000));
                } else {
                    setCount(value);
                    clearInterval(id);
                }
            }, 10);
            return () => clearInterval(id);
        }
    }, [value, isLoading, startTime]);

    function easeOutQuad(t, b, c, d) {
        t /= d;
        return -c * t*(t-2) + b;
    }

    return (
        <Card sx={{ textAlignLast: 'center',backgroundColor: color,padding:'5px'}}>
            <Typography color={getContrastColors('#000000',color)} align='center' variant='subtitle'>{title}</Typography>
            <Box sx={{ padding: '10px' }}>

                {isLoading?
                    <CircularProgress sx={{color:getContrastColors('#000000',color)}}/>   
                :
                <Typography color={getContrastColors('#000000',color)} variant='h4'>
                    {count?formatNumber(Math.round(count)):0}
                </Typography>
                }   

            </Box>
        </Card>
    )
}
