import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  useTheme,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "./BookingCalendar.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ClearIcon from "@mui/icons-material/Clear";

import BookingGanttRow from "./BookingGanttRow";
import "dayjs/locale/it";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc); // Configura il plugin

dayjs.locale("it");

const BookingCalendar = ({
  bookings,
  onBookingUpdate,
  onChangeDate,
  externalDate,
  onClickBooking = () => {},
  onClickPromotion = () => {},
  onConfirm = () => {},
  onReject = () => {},
}) => {
  const theme = useTheme();
  const threeVwInPixels = (window.innerWidth - 160) / 24;
  const widthCell = threeVwInPixels;

  const [selectedDate, setSelectedDate] = useState(externalDate || dayjs());
  useEffect(() => {
    if (externalDate) {
      setSelectedDate(externalDate);
    }
  }, [externalDate]);
  const [sortCriteria, setSortCriteria] = useState("booking_date");
  const [bookingsForDay, setBookingsForDay] = useState([]);
  const [expandedBookings, setExpandedBookings] = useState([]);
  const [hourStep, setHourStep] = useState(1);
  const [sortOrder, setSortOrder] = useState("asc"); // "asc" per crescente, "desc" per decrescente

  const statusColors = {
    confirmed: "#c0dfc0",
    cancelled: "#ffaeae",
    pending: "#ffda97",
    disabled: "#d3d3d3",
    semi_confirmed: '#c1d5c4'
  };

  const handleSortOrderChange = () => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  const handlePreviousDay = () => {
    setSelectedDate(selectedDate.subtract(1, "day"));
  };

  const handleNextDay = () => {
    setSelectedDate(selectedDate.add(1, "day"));
  };

  const handleSortChange = (event) => {
    setSortCriteria(event.target.value);
  };

  const sortBookings = (bookings) => {
    return bookings.sort((a, b) => {
      let comparison = 0;

      if (sortCriteria === "participants" || sortCriteria === "status") {
        comparison = a[sortCriteria] < b[sortCriteria] ? -1 : 1;
      } else {
        comparison = dayjs(a[sortCriteria]).isBefore(dayjs(b[sortCriteria]))
          ? -1
          : 1;
      }

      return sortOrder === "asc" ? comparison : -comparison;
    });
  };

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 350) {
        setHourStep(6);
      } else if (screenWidth < 400) {
        setHourStep(4);
      } else if (screenWidth < 600) {
        setHourStep(3);
      } else if (screenWidth < 850) {
        setHourStep(2);
      } else {
        setHourStep(1);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const filteredBookings = bookings
      .map((booking) => {
        const bookingDate = isNaN(Date.parse(booking.booking_date))
          ? new Date(booking.booking_date).toISOString()
          : booking.booking_date;

        return {
          ...booking,
          start: isNaN(Date.parse(booking.start)) ? bookingDate : booking.start,
          end: isNaN(Date.parse(booking.end))
            ? new Date(
                new Date(bookingDate).getTime() + 2 * 60 * 60 * 1000
              ).toISOString() // aggiunge 2 ore
            : booking.end,
          bookingDate,
        };
      })
      .filter((booking) => dayjs(booking.start).isSame(selectedDate, "day"));

    setBookingsForDay(sortBookings(filteredBookings));
  }, [selectedDate, bookings, sortCriteria, sortOrder]);

  useEffect(() => {
    if (externalDate === undefined) {
      onChangeDate(selectedDate);
    }
  }, [selectedDate]);

  const calculateMinutePosition = () => {
    const now = dayjs();
    if (selectedDate.isSame(now, "day")) {
      const hours = now.hour();
      const minutes = now.minute();
      const minutesSinceStart = hours * 60 + minutes;
      const pixelsFromStart =
        (minutesSinceStart / (24 * 60)) * (widthCell * 24);
      return 120 + pixelsFromStart;
    }
    return 0;
  };

  const [currentMinutePosition, setCurrentMinutePosition] = useState(
    calculateMinutePosition()
  );

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup per rimuovere l'evento di resize
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    setCurrentMinutePosition(calculateMinutePosition());
  }, [selectedDate, windowWidth]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentMinutePosition(calculateMinutePosition());
    }, 60000);
    return () => clearInterval(intervalId);
  }, []);

  const times = Array.from({ length: 24 }, (_, i) => i);

  const calculatePosition = (start, end) => {
    const startOfDay = dayjs(selectedDate).utc().startOf("day");
    const startMinutes = dayjs(start).utc().diff(startOfDay, "minute");
    const endMinutes = dayjs(end).utc().diff(startOfDay, "minute");
    const totalMinutes = 24 * 60;

    const leftPixels = (startMinutes / totalMinutes) * (24 * widthCell) + 120;
    const widthPixels =
      ((endMinutes - startMinutes) / totalMinutes) * (24 * widthCell);

    return { left: `${leftPixels}px`, width: `${widthPixels}px` };
  };

  const getBookingColor = (booking) => {
    if (booking.status === "confirmed") {
      return booking.table_id ? statusColors.confirmed : statusColors.semi_confirmed;
    } else if (booking.status === "pending") {
      return statusColors.pending;
    } else if (booking.status === "cancelled") {
      return statusColors.cancelled;
    }
    return "#90EE90";
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
      <Box>
        <Box sx={{ mb: 2 }}>
          <Box alignItems="center" gap={2}>
            {!externalDate && (
              <Box display="flex" >
                <IconButton onClick={handlePreviousDay}>
                  <ChevronLeftIcon />
                </IconButton>

                <DatePicker
                  label="Seleziona una data"
                  value={selectedDate}
                  onChange={(newValue) => setSelectedDate(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                  format="DD/MM/YYYY"
                />
                <IconButton onClick={handleNextDay}>
                  <ChevronRightIcon />
                </IconButton>
        
              </Box>
            )}

            {/* Select per ordinamento */}
            <Box display="flex" alignItems="center" gap={2}>
              {/* Select per il criterio di ordinamento */}
              <Select sx={{width:'180px'}} value={sortCriteria} onChange={handleSortChange}>
                <MenuItem value="booking_time">Ora Prenotazione</MenuItem>
                <MenuItem value="participants">Partecipanti</MenuItem>
                <MenuItem value="tableName">Tavolo</MenuItem>
                <MenuItem value="tableRoom">Sala</MenuItem>
                <MenuItem value="status">Stato</MenuItem>
              </Select>

              {/* Icona per ordinamento ascendente/discendente */}
              <IconButton onClick={handleSortOrderChange} sx={{borderRadius:'50%',width:'35px', height:'35px', borderRadius:'50%', boxShadow:1}}>
                {sortOrder === "asc" ? (
                  <ArrowUpwardIcon />
                ) : (
                  <ArrowDownwardIcon />
                )}
              </IconButton>
            </Box>
          </Box>
        </Box>

        <Box className="gantt-wrapper">
          <Box
            className="gantt-container"
            sx={{ minWidth: `calc(120px + ${widthCell}px * 24)` }}
          >
            <Box
              className="gantt-time-header"
              sx={{
                width: `calc(120px + ${widthCell}px * 24)`,
                gridTemplateColumns: `120px repeat(24, ${widthCell}px)`,
              }}
            >
              <Box className="gantt-booking-label-header">Prenotazioni</Box>
              {times.map((hour, index) =>
                index % hourStep === 0 ? (
                  <Box
                    key={hour}
                    className="gantt-time-cell"
                    style={{ width: `${widthCell}px` }}
                  >
                    {`${hour}:00`}
                  </Box>
                ) : (
                  <Box key={hour} style={{ width: `${widthCell}px` }} />
                )
              )}
            </Box>

            {bookingsForDay.map((booking) => {
              return (
                <BookingGanttRow
                  {...{
                    booking,
                    currentMinutePosition,
                    onBookingUpdate,
                    onClickBooking,
                    onClickPromotion,
                    calculatePosition,
                    getBookingColor,
                    onConfirm,
                    onReject,
                  }}
                />
              );
            })}
          </Box>
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default BookingCalendar;
