import React, {
  createContext,
  useMemo,
  useContext,
  useCallback,
  useState,
  useEffect,
} from "react";
import { useSnackbar } from "notistack";
import { UserContext } from "./UserContext";
import { RenderModal } from "../story-components/components";
import { Typography, Box, Alert } from "@mui/material";
import LoginButton from "../components/Login/LoginButton";
import { useTranslation } from "react-i18next";

//creazione del context a cui viene aggiunto il valore dal provider
export const AlertApiContext = createContext();

//Creazione del provider da inserire come padre per poi ottenere il context nei componenti figli
export const AlertApiContextProvider = ({ children }) => {
  const { setUserObject, token, logged, setOpenLoginModal } = useContext(UserContext);
  const { i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [openLogin, setOpenLogin] = useState(false);

  useEffect(() => {
    setOpenLogin(false);
  }, [token]);

  async function handleResponse(response) {
    if (!response.ok) {
      const errorData = await response.json();
      const errorMessage =
        errorData.message || errorData.text || "Errore dal server";
      enqueueSnackbar(errorMessage, { variant: "error" });
      throw new Error(errorMessage);
    }
    try {
      return response.json().then((data) => {
        if (data.text !== "" && data.text !== undefined) {
          enqueueSnackbar(data.text, { variant: data.status });
        }
        if (
          data.error_code === "gtoken expired" ||
          data.error_code === "gtoken expired no reload"
        ) {
          setOpenLogin(true);
          setUserObject({});
          setOpenLoginModal(true)

          return { status: "error", error_code: "t128" };
        }
        if (data.error_code === "login required") {
          setOpenLogin(true);
          setUserObject({});
          setOpenLoginModal(true)
          return { status: "error", error_code: "t129" };
        }
        try {
          if (data.text !== undefined) {
            if (data.text.includes("Token expired")) {
              window.alert("token expired handle response");
              setUserObject({});
              localStorage.removeItem("token");
              localStorage.removeItem("userObject");
              setOpenLoginModal(true)

            }
          }
        } catch {
          return { status: "error", error_code: "t123" };
        }

        if (data.error_code === "not allowed") {
          return { status: "error", error_code: "not allowed" };
        }
        if (data.text === "Utente non autorizzato") {
          window.location("/Home");
        }

        return data;
      });
    } catch (error) {
      window.alert("errore con il server");
    }
  }

  const MAX_RETRIES = 5;
  const RETRY_DELAY = 5000; // 20 secondi
  function generateUniqueId() {
    return "_" + Math.random().toString(36).substr(2, 9);
  }
  const api_request = useCallback(
    async (
      method,
      url,
      {
        body = null,
        queryParams = {},
        contentType = "application/json",
        customHeaders = {},
      } = {}
    ) => {
      // Costruisce la stringa dei parametri query
      const queryString = new URLSearchParams(queryParams).toString();

      // Aggiunge la stringa dei parametri query all'URL se presenti
      const fullUrl = queryString ? `${url}?${queryString}` : url;
      let uniqueId = localStorage.getItem("uniqueId");
      let cookieConsent = localStorage.getItem("cookieConsent")
      if (!uniqueId) {
        uniqueId = generateUniqueId();
        localStorage.setItem("uniqueId", uniqueId);
      }
      const defaultHeaders = {
        "Content-Type": contentType,
        Authentication: localStorage.getItem("token"),
        "Accept-Language": i18n.language,
        "Unique-id": uniqueId,
        "Cookie-consent":cookieConsent
      };

      const headers = { ...defaultHeaders, ...customHeaders };

      const requestOptions = {
        method,
        headers,
      };

      if (body) {
        requestOptions.body = JSON.stringify(body);
      }

      let retries = 0;
      while (retries < MAX_RETRIES) {
        try {
          const data = await fetch(fullUrl, requestOptions).then((response) =>
            handleResponse(response)
          );
          return data;
        } catch (error) {
          if (error.message === "Failed to fetch") {
            enqueueSnackbar("Impossibile stabilire una connessione...", {
              variant: "warning",
            });
            return { status: "error", text: error.message };
            await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
            retries += 1;
          } else {
            console.info(error.message);
            return { status: "error", text: error.message };
          }
          // Questa linea garantisce un ritardo tra i tentativi
          await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
        }
      }
    },
    []
  );

  const api_get = useCallback(async (url, queryParams = {}) => {
    // Costruisce la stringa dei parametri query
    const queryString = new URLSearchParams(queryParams).toString();

    // Aggiunge la stringa dei parametri query all'URL se presenti
    const fullUrl = queryString ? `${url}?${queryString}` : url;

    const requestOptions = {
      method: "GET",
      headers: { Authentication: localStorage.getItem("token") },
    };
    try {
      const data = await fetch(fullUrl, requestOptions).then((response) =>
        handleResponse(response)
      );
      return data;
    } catch (error) {
      console.error("Ops, sembra che qualcosa sia andato storto");
      return { status: "error" };
    }
  }, []);

  const api_post = useCallback(async (url, body) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": i18n.language,
        Authentication: localStorage.getItem("token"),
      },
      body: JSON.stringify(body),
    };

    try {
      const data = await fetch(url, requestOptions).then((response) =>
        handleResponse(response)
      );
      return data;
    } catch (error) {
      if (error.message === "Failed to fetch") {
        enqueueSnackbar("Ops, Sembra che tu sia senza connessione...", {
          variant: "warning",
        });
      } else {
        enqueueSnackbar(
          "Ops, Sembra che qualcosa sia andato storto con il server",
          { variant: "error" }
        );
      }
      return { status: "error", text: error.message };
    }
  }, []);

  const api_delete = useCallback(async (url, body) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authentication: localStorage.getItem("token"),
      },
      body: JSON.stringify(body),
    };
    try {
      const data = await fetch(url, requestOptions).then((response) =>
        handleResponse(response)
      );
      return data;
    } catch (error) {
      if (error.message === "Failed to fetch") {
        enqueueSnackbar("Ops, Sembra che tu sia senza connessione...", {
          variant: "warning",
        });
      } else {
        enqueueSnackbar(error.message, { variant: "error" });
      }
    }
  }, []);

  const api_custom = useCallback(
    async (url, body, method, contentType, requestOptions = null) => {
      if (contentType === undefined) {
        contentType = "application/json";
      }
      function getRequestOptionsToSend() {
        if (requestOptions === null) {
          return {
            method: method,
            headers: {
              "Content-Type": contentType,
              Authentication: localStorage.getItem("token"),
            },
            body: JSON.stringify(body),
          };
        } else {
          return requestOptions;
        }
      }
      const requestOptionsToSend = getRequestOptionsToSend();

      try {
        const data = await fetch(url, requestOptionsToSend).then((response) =>
          handleResponse(response)
        );
        return data;
      } catch (error) {
        if (error.message === "Failed to fetch") {
          enqueueSnackbar("Ops, Sembra che tu sia senza connessione...", {
            variant: "warning",
          });
        } else {
          enqueueSnackbar("Ops, qualcosa è andato storto", {
            variant: "error",
          });
        }
      }
    },
    []
  );




  const providerValue = useMemo(
    () => ({
      enqueueSnackbar,
      handleResponse,
      api_post,
      api_get,
      api_delete,
      api_custom,
      api_request,
    }),
    [
      enqueueSnackbar,
      handleResponse,
      api_post,
      api_get,
      api_delete,
      api_custom,
      api_request,
    ]
  );
  useEffect(() => {
    if (logged) {
      setOpenLogin(false);
    }
  }, [logged, token]);

  return (
    <AlertApiContext.Provider value={providerValue}>
      <div>{children}</div>
    </AlertApiContext.Provider>
  );
};
