import moment from 'moment';


export function convertLocalDate(date_value,format='DD/MM/YYYY HH:mm') {
    if (date_value !== undefined && date_value !== null && date_value !== '') {
        try {
            const date = new Date(date_value); // Esempio di data ricevuta dal backend
            const timeZoneOffsetInMs = new Date().getTimezoneOffset() * 60 * 1000; // Calcola la differenza in millisecondi tra l'ora locale e l'UTC
            const localDate = new Date(date.getTime() + timeZoneOffsetInMs); // Aggiunge la differenza all'ora UTC per ottenere l'ora locale
            const localDateWithOffset = new Date(localDate.getTime() - (localDate.getTimezoneOffset() * 60 * 1000)); // Aggiunge l'offset del fuso orario locale
            const dateString = moment(localDateWithOffset).format(format);
            return dateString
        } catch {
            return `Error: ${date_value}`
        }
    } else {
        return date_value
    }


}

export function getCurrentTimeDifference(backendDateString) {
    try {
        const backendDate = new Date(backendDateString); // Analizza la data nel formato fornito
        const currentDate = new Date(); // Ottiene la data e l'ora attuali
        const diffInMilliseconds = currentDate - backendDate; // Calcola la differenza in millisecondi
        const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60)); // Converte i millisecondi in minuti
        const diffInHours = Math.floor(diffInMinutes / 60); // Converte i minuti in ore

        if (diffInMinutes < 60) {
            return `${diffInMinutes} minuti`;
        } else {
            return `${diffInHours} ore e ${diffInMinutes % 60} minuti`;
        }

    } catch (e) {
        console.log('errore conversione data:', e)
        return 'Data non valida';
    }
}


export const formatNumericDate = (date) => {
    if (!date) {
        return '-';
    }
    const date_value = moment.unix(date);
    console.log('date_value:', date_value)
    return convertLocalDate(date_value)
};

export function safeRound(input, decimals = 2) {
    const number = parseFloat(input);

    // Se il numero non è valido (NaN), restituisci l'input originale
    if (isNaN(number)) {
        return 'INPUT ROUND FAIL';
    }

    const factor = Math.pow(10, decimals);
    const value = Math.round(number * factor) / factor;
    if(value){
        return value.toFixed(decimals);
    } else {
        return 0
    }
}