import React, { useState } from 'react';
import { Snackbar, Alert, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


import FileCopyIcon from '@mui/icons-material/FileCopy';

function CopyToClipboardButton({ children, showIcon = false,value, copyMessage='Test copiato', copyString  }) {
    const [open, setOpen] = useState(false);
    const [showCheckIcon, setShowCheckIcon] = useState(false);

    
    const handleCopy = async () => {
        const valueToCopy = value || copyString || children;
    
        try {
            await navigator.clipboard.writeText(valueToCopy);
    
            setOpen(true)
            if (showIcon) {
                setShowCheckIcon(true);
                setTimeout(() => {
                    setShowCheckIcon(false);
                }, 10000); // 10 secondi
            }
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    };
    
    

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <div style={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer' }} onClick={handleCopy}>
            {showIcon && (
                <Tooltip title="Copia">
                    {showCheckIcon ? <CheckCircleIcon style={{ marginLeft: '5px' }} /> : <FileCopyIcon style={{ marginLeft: '5px' }} />}
                </Tooltip>
            )}
            <span>{children}</span>

            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    {copyMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}

CopyToClipboardButton.propTypes = {
    children: PropTypes.node.isRequired, // children è spesso di tipo node perché può essere qualsiasi cosa renderizzabile
    showIcon: PropTypes.bool,
    copyMessage: PropTypes.string,
    value:PropTypes.string
};


export default CopyToClipboardButton;
