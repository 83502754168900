import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import PropTypes from "prop-types";

function NumericInput({
  value: actualValue,
  initialValue,
  max,
  min,
  showHelper = true,
  onChange,
  ...rest
}) {
  const [value, setValue] = useState(initialValue || "");
  const [helperText, setHelperText] = useState("");

  useEffect(() => {
    if (actualValue !== undefined) {
      setValue(actualValue); // Qui aggiorniamo il valore locale quando actualValue cambia
    }
  }, [actualValue]);

  return (
    <TextField
      className="noPaddingAndMargin"
      fullWidth
      type="number"
      value={value}
      onChange={(e) => {
        let newVal = parseInt(e.target.value, 10);
        if (newVal > max) {
          setHelperText(`Massimo consentito: ${max}`);
        } else if (newVal < min) {
          setHelperText(`Minimo consentito: ${min}`);
        } else {
          setHelperText("");
          setValue(newVal);
          onChange && onChange(newVal); // Invoca la funzione onChange solo se è fornita
        }
      }}
      error={helperText !== ""}
      helperText={showHelper && helperText}
      sx={{
        "& .MuiInputBase-input": {
          padding: '5px', // Imposta il margine a 0
        },
        "& .MuiOutlinedInput-input": {
          padding: '5px', // Imposta il margine a 0
        },
        width: "60px",
        margin: "0px",
        padding: "0px",
      }}
      {...rest}
    />
  );
}

export default NumericInput;

NumericInput.propTypes = {
  onChange: PropTypes.func,
  initialValue: PropTypes.number,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  value: PropTypes.number, // Aggiunto un PropTypes per value
  showHelper: PropTypes.bool,
};
