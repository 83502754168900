import React, { useState } from "react";
import PropTypes from "prop-types";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import {
    TableCell,
    TableRow,
    Box,
    ButtonBase
} from "@mui/material";

import { borderStyle, statusColor, warningStyle } from '../style'


export default function OrdersCollapsibleRow({ dataSection, product_name, section, roomTableGroup, onClickCell, setDialogOpen, setSelectedProductInfo }) {

    const [longPressTimeout, setLongPressTimeout] = useState(null);
    const [isLongPress, setIsLongPress] = useState(false);  // 1. Aggiungi uno stato per il long press

    function handleMouseDown(st) {
        const timeoutId = setTimeout(() => {
            onLongPress(st);
        }, 1000);
        setLongPressTimeout(timeoutId);
    }

    const handleMouseUp = () => {
        if (longPressTimeout) {
            clearTimeout(longPressTimeout);
            setLongPressTimeout(null);
        }
    };

    function onLongPress(st) {
        setIsLongPress(true);  // 2. Imposta isLongPress a true quando onLongPress viene chiamato
        if(st.product_status!==undefined && st.product_status>0){
            onClickCell({product_name, table_room:st.table_room,table_name: st.table_name,orders_id:st.orders_id, reverse:true});
        }
    }


    return (
        <TableRow>
            <TableCell>{product_name}</TableCell>

            {Object.keys(roomTableGroup).map((room) => (
                Object.keys(roomTableGroup[room]).map((table) => {
                   
                    let loc = undefined
                    if (dataSection?.[room]?.[table]?.[product_name]) {
                        loc = dataSection[room][table][product_name]
                    }
                    return (
                        <TableCell
                            key={section + "-" + room + "-" + table+" "+product_name}
                            style={{
                                ...borderStyle,
                                padding: "0px",
                                margin: "0px",
                                backgroundColor: loc && statusColor[loc.product_status]
                            }}
                            align="center"
                        >
                            <ButtonBase
                                style={{ width: "100%", minHeight: "30px" }}
                                onMouseDown={() => handleMouseDown(loc)}
                                onMouseUp={handleMouseUp}
                                onTouchStart={() => handleMouseDown(loc)}
                                onTouchEnd={handleMouseUp}
                                onClick={() => {

                                    if (!isLongPress && loc && loc.product_status<3) {
                                        if (loc) {
                                            if (loc.variation === true && loc.product_status === 1) {
                                                setSelectedProductInfo({product_name, table_room:room,table_name: table,orders_id:loc.orders_id});
                                                setDialogOpen(true);
                                            } else {
                                                onClickCell({product_name, table_room:room,table_name: table,orders_id:loc.orders_id})
                                            }
                                        }
                                    } else {
                                        setIsLongPress(false);
                                    }
                                }}
                            >
                                <Box style={loc && loc.variation ? warningStyle : {}}>
                                    {loc && loc.variation && (
                                        <PriorityHighIcon sx={{ fontSize: "10px" }} />
                                    )}
                                    {loc ? loc.quantity : "-"}
                                </Box>
                            </ButtonBase>
                        </TableCell>
                    );
                })))}
        </TableRow>
    );
}

OrdersCollapsibleRow.propTypes = {
    onClickCell: PropTypes.func,
    setDialogOpen: PropTypes.func,
    setSelectedProductInfo: PropTypes.func
};
