import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';

import { Button, FormHelperText, FormControl, InputLabel, Input, Stack, Chip, Box, Typography } from '@mui/material';
import ModalButton from '../Modals/ModalButton'

function MultipleSelects(props) {
    const { label, info = undefined, re = undefined, minLength = 0, array = [], mask = "(#00) 000-0000", definitions = { '#': /[1-9]/ }, children, onChange, ...rest } = props
    const [inputText, setInputText] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [open, setOpen] = useState(false)

    let [inputSelected, setInputSelected] = useState(array || [])
    useEffect(() => {
        setInputSelected(array || [])

    }, [array]);

    function AddInputText() {
        if (minLength <= inputText.length || minLength === undefined) {
            inputSelected.push(inputText)
            setInputSelected([...inputSelected])
            setInputText('')
            onChange(inputSelected)
            setErrorMessage('')
        } else {
            setErrorMessage(label + ' non valido')
        }

    }

    function HandleInput(value) {
        if (re === undefined) {
            setInputText(value)
        } else {
            if (re.test(value)) {
                setInputText(value)
            }
        }

    }

    function handleDelete(item) {
        const indexItem = inputSelected.indexOf(item)
        if (indexItem >= 0) {
            inputSelected.splice(indexItem, 1);
            setInputSelected([...inputSelected])
            onChange(inputSelected)
        }
    }


    return (

        <ModalButton
            open={open}
            setOpen={setOpen}
            body={
                <Stack direction='column' {...rest}>
                    <InputLabel htmlFor="formatted-text-mask-input">{label}</InputLabel>
                    <Typography variant='body'>{info}</Typography>
                    <Box>
                        {inputSelected.map((input) => {
                            return (
                                <Chip key={`chip-${input}`} style={{ margin: '5px' }} label={input} onDelete={() => handleDelete(input)} />
                            )
                        })
                        }


                    </Box>
                    <Stack direction='row'>

                        <FormControl error={errorMessage.length > 0} variant="outlined">

                            <Input value={inputText} id="outlined-multiple-select" onChange={(event) => HandleInput(event.target.value)} />
                            <FormHelperText id="component-error-text">{errorMessage}</FormHelperText>
                        </FormControl>

                        <Button variant='contained' onClick={() => AddInputText()}>Aggiungi</Button>
                        <Button variant='contained' onClick={() => setOpen(false)}>Chiudi</Button>
                    </Stack>
                </Stack >

            }
        >
            <Button fullWidth variant='contained'>
                {inputSelected.length} {label}
            </Button>
        </ModalButton>


    )
}

export default MultipleSelects

MultipleSelects.propTypes = {
    onChange: PropTypes.func,
    array: PropTypes.array
}
