import React, { useContext, lazy, Suspense, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
/* -- moduli --*/
import { UserContext } from "../store/UserContext";
import { AlertApiContext } from "../store/AlertApiContext";
import { useTheme } from "@mui/material/styles";
import { LoadingAnimations } from "../story-components/components";
import Backdrop from "@mui/material/Backdrop";
import PushNotificationManager from "../PushNotificationManager";
import LocalLandingPage from "../pages/Locals/Local/LocalLandingPage";
import LocalMenu from "../pages/Locals/Local/LocalMenu";
import CookieBanner from "../components/CookieBanner";
/* -- pages -- */
import Nav from "../components/Nav";
const Locals = lazy(() => import("../pages/Locals/Locals"));
const EditLocal = lazy(() =>
  import("../pages/Locals/Local/EditLocal/EditLocal")
);
const LocalContactUs = lazy(() =>
  import("../pages/Locals/Local/Contact/LocalContactUs")
);
const RedirectMenu = lazy(() => import("../pages/Locals/Local/RedirectMenu"));
const EditMenu = lazy(() => import("../pages/Locals/Local/EditMenu"));
const IngredientsTable = lazy(() =>
  import("../pages/Locals/Local/EditMenu/IngredientsTable")
);
const TranslateMenu = lazy(() =>
  import("../pages/Locals/Local/TranslateMenu/TranslateMenu")
);
const Home = lazy(() => import("../pages/Home/Home"));
const Tutorials = lazy(() => import("../pages/Home/Tutorials"));
const ContactUs = lazy(() => import("../pages/ContactUs"));
const AddProduct = lazy(() => import("../pages/Locals/Local/AddProduct"));
const AddProducts = lazy(() => import("../pages/Locals/Local/AddProducts"));
const LocalCoupons = lazy(() =>
  import("../pages/Locals/Local/Coupon/LocalCoupons")
);
const LocalCoupon = lazy(() =>
  import("../pages/Locals/Local/Coupon/LocalCoupon")
);
const QrcodePage = lazy(() => import("../pages/Locals/Local/QrcodePage"));
const OrderPage = lazy(() => import("../pages/Orders/OrderPage"));
const OrderTokenPage = lazy(() => import("../pages/Orders/OrderTokenPage"));
const MyOrderPage = lazy(() => import("../pages/Orders/MyOrdersPage"));
const LocalOrdersPage = lazy(() => import("../pages/Orders/LocalOrdersPage"));
const OrdersManagementPage = lazy(() =>
  import("../pages/Orders/OrdersManagementPage")
);
const DashboardsPage = lazy(() => import("../pages/Dashboard/DashboardsPage"));
const LocalDashboard = lazy(() => import("../pages/Dashboard/LocalDashboard"));
const AdminDashboard = lazy(() => import("../pages/Dashboard/AdminDashboard"));
const MyProfile = lazy(() => import("../pages/MyProfile"));
const MyNotifications = lazy(() => import("../pages/MyNotifications"));
import MyLocals from "../pages/MyLocals/MyLocals";
import Login from "../pages/Login/Login";
const CustomDashboard = lazy(() =>
  import("../pages/Dashboard/CustomDashboard")
);
const TablesManagement = lazy(() =>
  import("../pages/Orders/TablesManagementPage")
);

const Payment = lazy(() => import("../components/payment/Payment"));
const Completion = lazy(() => import("../components/payment/Completion"));
const AdminPage = lazy(() => import("../pages/Admin components/AdminPage"));
const CheckoutOrderPage = lazy(() =>
  import("../pages/Locals/Local/CheckoutOrderPage")
);
const UpdatePayment = lazy(() => import("../components/payment/UpdatePayment"));
const BookingFormPage = lazy(() =>
  import("../pages/Locals/Local/Booking/BookingFormPage")
);
const BookingDetails = lazy(() =>
  import("../pages/Locals/Local/Booking/BookingDetails")
);
const UserBookings = lazy(() =>
  import("../pages/Locals/Local/Booking/UserBookings")
);
const BookingManagement = lazy(() =>
  import("../pages/Locals/Local/Booking/BookingManagement")
);

import PlansPage from "../pages/Home/components/plans/PlansPage";
const MyCouponsPage = lazy(() => import("../pages/Coupons/UserActiveCoupons"));
const InstallPWAButton = lazy(() =>
  import("../pages/Locals/Local/EditLocal/components/InstallPWAButton")
);
const PrivacyPolicy = lazy(() =>
  import("../components/policies/PrivacyPolicy")
);
const TermsOfService = lazy(() =>
  import("../components/policies/TermsOfService")
);

const RedeemPromotion = lazy(() =>
  import(
    "../pages/Locals/Local/EditLocal/components/MarketingCampaign/RedeemPromotion"
  )
);
const PromoDetailsPage = lazy(() =>
  import(
    "../pages/Locals/Local/EditLocal/components/MarketingCampaign/PromoDetailsPage"
  )
);
const AdminQuestionPage = lazy(() =>
  import("../pages/Admin components/Q&A/AdminQuestionPage")
);
import QrcodeGeneratorPage from "../pages/QrcodeGenerator/QrcodeGeneratorPage";
import AiChat from "../pages/Locals/Local/AiChat/AiChat";

function RouteApp() {
  const { outerTheme, openBackDrop, logged, isAdmin } = useContext(UserContext);
  const { api_request } = useContext(AlertApiContext);

  const theme = useTheme();
  const location = useLocation();

  useEffect(() => {
    // Funzione per inviare il percorso al backend
    const logRouteChange = () => {
      const body = { path: window.location.pathname };

      api_request("POST", `/log-route`, { body });
    };
    logRouteChange();
    window.onpopstate = logRouteChange;
    return logRouteChange;
  }, [location]);

  //setOpenBackDrop(true)
  return (
    <ThemeProvider theme={outerTheme}>
      <PushNotificationManager />

      {openBackDrop && (
        <Backdrop
          sx={{ color: theme.palette.secondary.light, zIndex: 100 }}
          open={openBackDrop}
        >
          <LoadingAnimations variant="bouncing-ball" />
        </Backdrop>
      )}

      {
        !["/home", "/about_us", "/"].includes(
          location.pathname.toLowerCase()
        ) && <Nav /> /*<-- esclude la nav bar in alcune pagine*/
      }
      <CookieBanner />

      <Suspense
        fallback={
          <Backdrop
            sx={{ color: theme.palette.secondary.light, zIndex: 100 }}
            open={openBackDrop ? openBackDrop : false}
          >
            <LoadingAnimations variant="bouncing-ball" />
          </Backdrop>
        }
      >
        <Routes>
          <Route
            exact
            path="/local_landing_page/:local_id"
            element={<LocalLandingPage />}
          ></Route>
          <Route
            exact
            path="/qr-code-generator"
            element={<QrcodeGeneratorPage />}
          ></Route>
          <Route
            exact
            path="/t/:table_reference"
            element={<RedirectMenu />}
          ></Route>
          <Route
            exact
            path="/local/:type/:local_id"
            element={<LocalMenu />}
          ></Route>
          <Route
            exact
            path="/local/:type/:local_id/:table_reference"
            element={<LocalMenu />}
          ></Route>
          <Route
            exact
            path="/local/:type/:local_id/:table_reference/order_id/:order_id"
            element={<LocalMenu />}
          ></Route>
          <Route
            exact
            path="/local/:type/:local_id/:table_reference/order_token/:order_token"
            element={<LocalMenu />}
          ></Route>
          <Route
            exact
            path="/local/:local_id/:table_reference"
            element={<LocalMenu />}
          ></Route>
          <Route
            exact
            path="/local/checkout/:local_id/:local_name/:table_reference"
            element={<CheckoutOrderPage />}
          ></Route>
          <Route
            exact
            path="/local/edit_menu/:local_id"
            element={logged ? <EditMenu /> : <Login />}
          ></Route>
          <Route
            exact
            path="/local/edit_menu/:local_id/ingredients_table"
            element={logged ? <IngredientsTable /> : <Login />}
          ></Route>
          <Route
            exact
            path="/local/translate_menu/:local_id/:local_name"
            element={logged ? <TranslateMenu /> : <Login />}
          ></Route>
          <Route
            exact
            path="/local/ai-chat/:local_id"
            element={logged ? <AiChat /> : <Login />}
          ></Route>
          <Route
            path="/local/contact_us/:local_id"
            element={<LocalContactUs />}
          ></Route>
          <Route exact path="/" element={<Home />}></Route>
          <Route
            exact
            path="/Tutorials"
            element={logged ? <Tutorials /> : <Login />}
          ></Route>
          <Route exact path="/Home" element={<Home />}></Route>
          <Route exact path="/Home/:referrerLocalId" element={<Home />}></Route>
          <Route
            exact
            path="/install-app/:time"
            element={<InstallPWAButton />}
          ></Route>
          <Route exact path="/Subscriptions" element={<PlansPage />}></Route>
          <Route
            exact
            path="/Subscribe/:plan_name"
            element={<Payment />}
          ></Route>
          <Route
            exact
            path="/Subscribe/:plan_name/:couponAddress"
            element={<Payment />}
          ></Route>
          <Route
            exact
            path="/Subscribe/:plan_name/:couponAddress/:coupon_id"
            element={<Payment />}
          ></Route>{" "}
          {
            "TODO Da rimuovere, non più utile con la generazione dei nuovi coupon"
          }
          <Route
            exact
            path="/Subscribe/:plan_name/:couponAddress/:coupon_id/:interval"
            element={<Payment />}
          ></Route>
          <Route
            exct
            path="/update_subscription_payment_method/:subscriptionId"
            element={logged ? <UpdatePayment /> : <Login />}
          >
            {" "}
          </Route>
          <Route
            exact
            path="/completion"
            element={<Completion subscription={true} />}
          ></Route>
          <Route
            exact
            path="/order_paid"
            element={<Completion subscription={false} />}
          ></Route>
          <Route exact path="/Contact_us" element={<ContactUs />}></Route>
          <Route
            exact
            path="/require_demo"
            element={<ContactUs require_demo={true} />}
          ></Route>
          <Route exact path="/locals" element={<Locals />}></Route>
          <Route
            exact
            path="/login"
            element={<Login needAccess={false} />}
          ></Route>
          <Route
            exact
            path="/my_account"
            element={logged ? <MyProfile /> : <Login />}
          ></Route>
          <Route
            exact
            path="/my_locals"
            element={logged ? <MyLocals /> : <Login />}
          ></Route>
          <Route
            exact
            path="/add_product/:local_id"
            element={logged ? <AddProduct /> : <Login />}
          ></Route>
          <Route
            exact
            path="/add_products/:local_id/:local_name"
            element={logged ? <AddProducts /> : <Login />}
          ></Route>
          <Route
            exact
            path="/edit_local/:local_id"
            element={logged ? <EditLocal /> : <Login />}
          ></Route>
          <Route
            exact
            path="/qrcode_generator/:local_id/table"
            element={logged ? <QrcodePage /> : <Login />}
          ></Route>
          <Route
            exact
            path="/local/edit_product/local/:local_id/product/:product_id"
            element={logged ? <AddProduct edit={true} /> : <Login />}
          ></Route>
          <Route
            exact
            path="/my_order/:local_id/:orderType/:order_id"
            element={<OrderPage />}
          ></Route>
          <Route
            exact
            path="/my_orders"
            element={logged ? <MyOrderPage /> : <Login />}
          ></Route>
          <Route
            exact
            path="/:local_id/:local_name/local_orders"
            element={logged ? <LocalOrdersPage /> : <Login />}
          ></Route>
          <Route
            exact
            path="/dashboard"
            element={logged ? <DashboardsPage /> : <Login />}
          ></Route>
          <Route
            exact
            path="/orders/:local_id/:local_name"
            element={logged ? <OrdersManagementPage /> : <Login />}
          ></Route>
          <Route
            exact
            path="/orders/:local_id"
            element={logged ? <OrdersManagementPage /> : <Login />}
          ></Route>
          <Route
            exact
            path="/:local_id/tables"
            element={logged ? <TablesManagement /> : <Login />}
          ></Route>
          <Route
            exact
            path="/:local_id/booking_form"
            element={<BookingFormPage />}
          ></Route>
          <Route
            exact
            path="/bookings/:local_id/:booking_id"
            element={logged ? <BookingDetails /> : <Login />}
          ></Route>
          <Route
            exact
            path="/my_bookings"
            element={logged ? <UserBookings /> : <Login />}
          ></Route>
          <Route
            exact
            path="/booking_management/:local_id"
            element={logged ? <BookingManagement /> : <Login />}
          ></Route>
          <Route
            exact
            path="/redeem_promo/:promotionId/:local_id"
            element={<RedeemPromotion />}
          ></Route>
          <Route
            exact
            path="/promo/:promotionId/:local_id"
            element={logged ? <PromoDetailsPage /> : <Login />}
          ></Route>
          <Route
            exact
            path="/admin/dashboard"
            element={isAdmin ? <AdminDashboard /> : <Home />}
          ></Route>
          <Route
            exact
            path="/admin/question_page"
            element={isAdmin ? <AdminQuestionPage /> : <Home />}
          ></Route>
          <Route
            exact
            path="/admin/console"
            element={isAdmin ? <AdminPage /> : <Home />}
          ></Route>
          <Route
            exact
            path="/my_notifications"
            element={logged ? <MyNotifications /> : <Login />}
          ></Route>
          <Route
            exact
            path="/local/coupons/:local_id"
            element={<LocalCoupons />}
          ></Route>
          <Route
            exact
            path="/local/coupon/:local_id/:coupon_id"
            element={<LocalCoupon />}
          ></Route>
          <Route
            exact
            path="/dashboard/:local_id"
            element={logged ? <LocalDashboard /> : <Login />}
          ></Route>
          <Route
            exact
            path="/custom_dashboard/:dashboardId/:local_id"
            element={logged ? <CustomDashboard /> : <Login />}
          ></Route>
          <Route
            exact
            path="/order_token/:local_id/:local_name/:table_reference/:order_token"
            element={<OrderTokenPage />}
          ></Route>
          <Route exact path="/my_coupons" element={<MyCouponsPage />}></Route>
          <Route
            exact
            path="/privacy-policy"
            element={<PrivacyPolicy />}
          ></Route>
          <Route
            exact
            path="/terms-of-service"
            element={<TermsOfService />}
          ></Route>
          <Route path="*" element={<Home />}></Route>
        </Routes>
      </Suspense>
    </ThemeProvider>
  );
}

export default RouteApp;
