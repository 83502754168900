import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
  Typography,
  Accordion,
  Badge,
  AccordionSummary,
  AccordionDetails,
  Box,
  Stack,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import FilterListIcon from "@mui/icons-material/FilterList";
import { ItemAnimation } from "../../Animations/ItemAnimation";
import { getContrastColors, hexToRgba } from "../../Functions/Colors";
import ProductsMenuSection from "./ProductsMenuSection";
import { RenderParallaxImage } from "../../Animations/UseParallaxScroll";
import { calculateVisibilityCounts } from "./MenuFunctions";

export default function MenuAccordion({
  basket,
  setBasket,
  getProductCover,
  getSectionCover,
  section_names = {},
  sectionsOrders,
  productsIdHidden = [],
  menuData = {},
  isStaff = false,
  card_variant_selected = "default",
  card_animation_variant_selected,
  menuCardShadow,
  menuAddProductDialogFullscreen,
  menuAddProductDialogAnimation,
}) {
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();

  const [responsiveStyle, setResponsiveStyle] = useState({
    xs: 12,
    sm: 12,
    md: 6,
    lg: 4,
    xl: 3,
  });
  useEffect(() => {
    if (
      (card_variant_selected === "image") |
      (card_variant_selected === "glass-effect")
    ) {
      setResponsiveStyle({ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 });
    } else {
      setResponsiveStyle({ xs: 12, sm: 12, md: 6, lg: 4, xl: 3 });
    }
  }, [card_variant_selected]);

  let [sectionsCover, setSectionsCover] = useState({});
  useEffect(() => {
    const fetchCovers = async () => {
      const covers = {};

      for (const section_id of sectionsOrders) {
        const cover = await getSectionCover(section_id);
        covers[section_id] = { sectionCover: cover };
      }

      setSectionsCover(covers);
      console.log("covers:", covers);
    };

    fetchCovers();
  }, [sectionsOrders]);

  return (
    <Box>
      <Box>
        {sectionsOrders.map((section_id) => {
          const section_values = section_names[section_id];
          const section_name = section_values.section_name;
          const section_cover = sectionsCover[section_id]?.sectionCover;
          const section_color = section_values.section_color;
          const section_card_color = section_values.section_card_color;
          const section_color_contrast = section_color
            ? getContrastColors("#ffffff", section_color)
            : undefined;
          const { elementsShowed, elementsAvaiable } =
            calculateVisibilityCounts(menuData, section_id, productsIdHidden);

          if (elementsAvaiable > 0 > 0) {
            return (
              <ItemAnimation
                key={"section_id-" + section_id}
                variant={card_animation_variant_selected}
                animateInView
                duration={2}
                oneTime={true}
                style={{ placeContent: "center", padding: "5px 0px 5px 0px" }}
              >
                <Accordion
                  slotProps={{ transition: { unmountOnExit: true } }}
                  onChange={(event, expanded) => {
                    if (expanded) {
                      setTimeout(() => {
                        const scrollToElement = () => {
                          const summaryElement = document.getElementById(
                            "accordion-summary-" + section_id
                          );
                          if (summaryElement) {
                            const yOffset = 0; // Scendi di 0px in più
                            const y =
                              summaryElement.getBoundingClientRect().top +
                              window.pageYOffset +
                              yOffset;
                            window.scrollTo({ top: y, behavior: "smooth" });
                          }
                        };

                        scrollToElement();

                        // Secondo tentativo di scroll dopo un breve ritardo
                        setTimeout(() => {
                          scrollToElement();
                        }, 500); // Ritardo di 500 ms per il secondo tentativo
                      }, 0); // Attendi 50 ms prima di eseguire il primo scroll
                    }
                  }}
                >
                  <AccordionSummary
                    id={"accordion-summary-" + section_id}
                    sx={{
                      position: "sticky",
                      top: section_cover ? "-210px" : "-25px",
                      zIndex: 10,
                      boxShadow: "",
                      background: section_color
                        ? hexToRgba(section_color, 1)
                        : "rgba(255, 255, 255, 1)",
                      backgroundColor: section_color,
                      color: section_color && section_color_contrast,
                      display: "flex",
                      minHeigth: section_cover && "60px",
                      alignItems: "center",
                      backgroundPosition: "center",
                      overflow: "hidden", // Necessario per la transizione di altezza

                      height: "auto", // Altezza di default
                      "&.Mui-expanded": {
                        // Stile quando l'Accordion è espanso
                        height: section_cover ? "300px" : "auto", // Altezza quando espanso
                      },
                      "&:not(.Mui-expanded)": {
                        justifyContent: "center",
                      },
                      ".MuiAccordionSummary-content.Mui-expanded": {
                        marginTop: section_cover ? "225px" : "20px", // Il margin-top calcolato
                        transition:
                          "height 0.75s ease-out 0s, margin-top 0.75s ease-out 0s", // Aggiunta della transizione
                      },
                    }}
                  >
                    {/* Contenitore per l'immagine di sfondo */}
                    {section_cover && section_cover !== "" && (
                        <div
                          style={{
                            position: "absolute",
                            backgroundPosition: "center",
                            top: "0px", // Regola questo valore per far salire la copertina
                            left: 0,
                            width: "100%",
                            height: "100%",
                            zIndex: -1,
                            backgroundPosition: "center center",
                            background: section_color
                              ? `linear-gradient(to top, ${hexToRgba(
                                  section_color,
                                  1
                                )} 0px, rgba(0, 0, 0, 0))`
                              : "linear-gradient(to top, rgba(255, 255, 255, 1) 0px, rgba(0, 0, 0, 0))",
                            backgroundImage: `url(${section_cover})`,
                            backgroundSize: "cover",
                            transition: "height 2s, margin-top 2s",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              bottom: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              minHeight: "150px",
                              background: section_color
                                ? `linear-gradient(to top, ${hexToRgba(
                                    section_color,
                                    1
                                  )} 5px, rgba(0, 0, 0, 0))`
                                : "linear-gradient(to top, rgba(255, 255, 255, 1) 0px, rgba(0, 0, 0, 0))",
                              zIndex: 1, // Overlay sopra l'immagine
                            }}
                          />
                        </div>
                    )}

                    {/* Contenuti dell'AccordionSummary */}
                    <Stack
                      spacing={2}
                      direction="row"
                      justifyContent="center"
                      alignItems="flex-end"
                      sx={{
                        borderRadius: "5px",
                        padding: "0.5em",
                        color: section_color_contrast,
                        minWidth: "50%",
                        // Questo assicura che lo Stack sia sempre al centro verticalmente
                        margin: "auto",
                      }}
                    >
                      {productsIdHidden.length > 0 && (
                        <Badge
                          badgeContent={elementsShowed}
                          sx={{
                            ".MuiBadge-badge": {
                              bgcolor: "grey",
                              color: "white",
                            },
                          }}
                        >
                          <FilterListIcon sx={{ color: "grey" }} />
                        </Badge>
                      )}
                      <Typography sx={{ textAlign: "center" }} variant="h5">
                        {section_name}
                      </Typography>
                    </Stack>
                  </AccordionSummary>

                  <AccordionDetails
                    sx={{
                      padding: "15px 5px 5px 5px",
                      background: section_color,
                      minHeight: "75vh",
                    }}
                    key={"tab-panel-" + section_name}
                    value={tabValue}
                    index={section_id}
                    dir={theme.direction}
                  >
                    <ProductsMenuSection
                      sectionMenuData={menuData[section_id]}
                      section_description={section_values.section_description}
                      section_id={section_id}
                      productsIdHidden={productsIdHidden}
                      isStaff={isStaff}
                      basket={basket}
                      setBasket={setBasket}
                      section_color={section_color}
                      section_color_contrast={section_color_contrast}
                      section_card_color={section_card_color}
                      section_name={section_name}
                      section_cover={section_values.section_cover}
                      card_variant_selected={card_variant_selected}
                      card_animation_variant_selected={
                        card_animation_variant_selected
                      }
                      getProductCover={getProductCover}
                      responsiveStyle={responsiveStyle}
                      menuCardShadow={menuCardShadow}
                      menuAddProductDialogFullscreen={
                        menuAddProductDialogFullscreen
                      }
                      menuAddProductDialogAnimation={
                        menuAddProductDialogAnimation
                      }
                    />
                  </AccordionDetails>
                </Accordion>
              </ItemAnimation>
            );
          }
        })}
      </Box>
    </Box>
  );
}

MenuAccordion.propTypes = {
  section_names: PropTypes.object,
  productsIdHidden: PropTypes.array,
  isStaff: PropTypes.bool,
  card_variant_selected: PropTypes.string,
  getProductCover: PropTypes.func,
};
