

export function getImageCover(local_cover){
    if(local_cover!="" && local_cover!=null){
        return local_cover
    }
    else{
    }
}

export const getImageType = (url) => {
    if (url.startsWith("data:image/png")) return "image/png";
    if (url.startsWith("data:image/jpeg")) return "image/jpeg";
    if (url.startsWith("data:image/svg+xml")) return "image/svg+xml";
    if (url.startsWith("data:image/gif")) return "image/gif";
    // Aggiungi altri tipi di immagine se necessario
    return "image/x-icon"; // Default type
  };