import React from "react";
import { Box, Typography, Grid, Chip, ButtonBase } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// Funzione per formattare la data in dd/mm/yyyy
const formatDate = (dateString) => {
  if (!dateString) return "N/A";
  const date = new Date(dateString);
  return date.toLocaleDateString("it-IT");
};

// Funzione per mappare i giorni della settimana
const weekDayMap = {
  1: "Lunedì",
  2: "Martedì",
  3: "Mercoledì",
  4: "Giovedì",
  5: "Venerdì",
  6: "Sabato",
  7: "Domenica",
};

const PromotionDetails = ({ promotion }) => {
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  const isExpired =
    promotion.expiry_date && new Date(promotion.expiry_date) < now;
  return (
    <ButtonBase
      sx={{
        padding: "20px",
        backgroundColor: "#f5f5f5",
        borderRadius: "8px",
        display: "block",
        width: "100%",
      }}
    >
      {promotion.is_redeemed && (
        <Chip
          label="Coupon Utilizzato"
          color="success"
          icon={<CheckCircleIcon />}
          sx={{
            fontWeight: "bold",
            backgroundColor: "#e0f7fa",
            color: "#00695c",
            "& .MuiChip-icon": {
              color: "#00695c",
            },
          }}
        />
      )}
      {isExpired && !promotion.is_redeemed && (
        <Chip
          label="Coupon scaduto"
          icon={<CheckCircleIcon />}
          sx={{
            fontWeight: "bold",
            backgroundColor: "#efe1d7",
            color: "#aa6a36",
            "& .MuiChip-icon": {
              color: "#aa6a36",
            },
          }}
        />
      )}

      <Grid container spacing={2}>
        {promotion.promotion_description && (
          <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{ textWrap: "balance" }}>
              {promotion.promotion_description}
            </Typography>
          </Grid>
        )}

        <Grid item xs={12}>
          {promotion.expiry_date ? (
            <Typography variant="subtitle1">
              <strong>Data di Scadenza:</strong>{" "}
              {formatDate(promotion.expiry_date)}
            </Typography>
          ) : (
            <Typography cariant="subtitle1">
              <strong>Senza scadenza</strong>
            </Typography>
          )}
        </Grid>

        {promotion.minimum_order_value && (
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              <strong>Valore Minimo Ordine:</strong>{" "}
              {promotion.minimum_order_value
                ? `€${promotion.minimum_order_value}`
                : "N/A"}
            </Typography>
          </Grid>
        )}
        {promotion.applicable_menu_sections &&
          promotion.applicable_menu_sections.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <strong>Sezioni del Menù Applicabili:</strong>
              </Typography>
              {promotion.applicable_menu_sections.map((section, index) => (
                <Chip
                  key={index}
                  label={section}
                  sx={{ marginRight: "8px", marginTop: "8px" }}
                />
              ))}
            </Grid>
          )}
        {promotion.available_weekdays &&
          promotion.available_weekdays.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <strong>Giorni applicabili:</strong>
              </Typography>
              {promotion.available_weekdays.map((day, index) => (
                <Chip
                  key={index}
                  label={weekDayMap[day]}
                  sx={{ marginRight: "8px", marginTop: "8px" }}
                />
              ))}
            </Grid>
          )}
        {promotion.valid_from_hour && promotion.valid_to_hour && (
          <Grid item xs={12}>
            <Typography variant="body2">
              Utilizzabile dalle <b>{promotion.valid_from_hour}</b> alle{" "}
              <b>{promotion.valid_to_hour}</b>
            </Typography>
          </Grid>
        )}
        {promotion.applicable_once_per_user?.is_applicable_once && (
          <Grid item xs={12}>
            <Typography variant="body2">Utilizzabile solo una volta</Typography>
          </Grid>
        )}
        {promotion.only_via_booking && (
          <Grid item xs={12}>
            <Typography variant="body2">
              Utilizzabile solo tramite prenotazione
            </Typography>
          </Grid>
        )}
      </Grid>
    </ButtonBase>
  );
};

export default PromotionDetails;
