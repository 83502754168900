import * as React from "react";
import IconButton from "@mui/material/IconButton";
import { Stack, TextField, Grid, ButtonBase } from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from "prop-types";
import imageCompression from 'browser-image-compression';

function getImageString(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.readAsDataURL(blob);
    });
}

function base64ToBlob(base64String) {
    const parts = base64String.split(",");
    if (parts.length < 2) {
        throw new Error('Invalid base64 string');
    }
    const mimeType = parts[0].split(":")[1].split(";")[0];
    const byteString = atob(parts[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([arrayBuffer], { type: mimeType });
}

function base64ToImageUrl(base64String) {
    try {
        const blob = base64ToBlob(base64String);
        const imageUrl = URL.createObjectURL(blob);
        return imageUrl;
    } catch (e) {
        console.error(e)
        return undefined
    }
}

export default function UploadImage({ label, placeholder, defaultValue, onUpload, onUrl, maxSize = 0.085 * 1024 * 1024 }) {
    const [image, setImage] = React.useState("");

    React.useEffect(() => {
        if (defaultValue !== undefined && defaultValue !== null && defaultValue !== '') {
            if (!defaultValue.startsWith("http")) {
                // L'immagine è un oggetto Blob, convertilo in URL
                const imageUrl = base64ToImageUrl(defaultValue);
                setImage(imageUrl);
            } else if (
                typeof defaultValue === "string" &&
                defaultValue.startsWith("http")
            ) {
                // L'immagine è un URL, fai qualcos'altro
                setImage(defaultValue);
            }
        }
    }, [defaultValue]);

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                // Opzioni di compressione
                const options = {
                    maxSizeMB: 0.1,
                    maxWidthOrHeight: 800,
                    useWebWorker: true
                };
                // Comprimi l'immagine
                const compressedFile = await imageCompression(file, options);

                const imageUrl = URL.createObjectURL(compressedFile);
                setImage(imageUrl);

                const imageString = await getImageString(compressedFile);
                onUpload(imageString);
            } catch (error) {
                console.error("Error compressing file:", error);
            }
        }
    };

    const handleImageUrl = (event) => {
        setImage(event.target.value);
        onUrl(event.target.value);
    };

    const onDelete = () => {
        setImage('');
        onUrl('');
        onUpload('');
    };

    return (
        <Grid container spacing={1} justifyContent="flex-start" alignItems="center">
            <Grid item>
                <TextField
                    multiline
                    defaultValue={image}
                    value={image}
                    maxRows={15}
                    placeholder={placeholder}
                    fullWidth
                    error={false}
                    id="standard-local_cover"
                    label={label}
                    variant="outlined"
                    onChange={(event) => setImage(event.target.value)}
                    onBlur={handleImageUrl}
                />
            </Grid>
            <Grid item>
                <Stack direction={'column'}>
                    <IconButton
                        style={{ width: "45px", height: '45px' }}
                        color="text.paper"
                        aria-label="upload picture"
                        component="label"
                    >
                        <input
                            hidden
                            accept="image/*"
                            type="file"
                            onChange={handleImageUpload}
                        />
                        <PhotoCamera fontSize="large" />
                    </IconButton>
                    {image &&
                        <IconButton
                            style={{ width: "45px", height: '45px' }}
                            color="text.paper"
                            aria-label="delete picture"
                            component="label"
                            onClick={onDelete}
                        >
                            <DeleteIcon fontSize="large" />
                        </IconButton>
                    }
                </Stack>
            </Grid>
            <Grid item>
                {image && (
                    <ButtonBase aria-label="upload picture" component="label">
                        <input
                            hidden
                            accept="image/*"
                            type="file"
                            onChange={handleImageUpload}
                        />
                        <img
                            style={{ maxWidth: "60vw", maxHeight: "40vh", objectFit: 'cover' }}
                            src={image}
                            alt="uploaded image"
                        />
                    </ButtonBase>
                )}
            </Grid>
        </Grid>
    );
}

UploadImage.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    onUpload: PropTypes.func.isRequired,
    onUrl: PropTypes.func.isRequired,
    maxSize: PropTypes.number
};
