import React, { useState, useContext } from "react";
import {
  QrCodeComponent,
  ColorPicker,
  UploadImage,
} from "../../story-components/components";
import { TextField, Grid, Typography, Stack, Box, Slider } from "@mui/material";
import EyeRadiusSelector from "./EyeRadiusSelector";
import { UserContext } from "../../store/UserContext";
import { AlertApiContext } from "../../store/AlertApiContext";
import { useNavigate } from "react-router-dom";

export default function QrcodeGeneratorPage() {
  const { logged } = useContext(UserContext);
  const { api_request } = useContext(AlertApiContext);
  const navigate = useNavigate();

  const [url, setUrl] = useState("");
  const [bgColor, setBgColor] = useState("rgba(255, 255, 255, 0.7)");
  const [fgColor, setFgColor] = useState("#000000");
  const [qrStyle, setQrStyle] = useState("dots");
  const [brandName, setBrandName] = useState(undefined);
  const [eyeRadius, setEyeRadius] = useState({
    outerTopLeftTopLeft: 20,
    outerTopLeftTopRight: 20,
    outerTopLeftBottomLeft: 20,
    outerTopLeftBottomRight: 20,
    innerTopLeftTopLeft: 20,
    innerTopLeftTopRight: 20,
    innerTopLeftBottomLeft: 20,
    innerTopLeftBottomRight: 20,
    outerTopRightTopLeft: 20,
    outerTopRightTopRight: 20,
    outerTopRightBottomLeft: 20,
    outerTopRightBottomRight: 20,
    innerTopRightTopLeft: 20,
    innerTopRightTopRight: 20,
    innerTopRightBottomLeft: 20,
    innerTopRightBottomRight: 20,
    outerBottomLeftTopLeft: 20,
    outerBottomLeftTopRight: 20,
    outerBottomLeftBottomLeft: 20,
    outerBottomLeftBottomRight: 20,
    innerBottomLeftTopLeft: 20,
    innerBottomLeftTopRight: 20,
    innerBottomLeftBottomLeft: 20,
    innerBottomLeftBottomRight: 20,
  });
  const [selectedCorner, setSelectedCorner] = useState("outerTopLeft");
  const [logo, setLogo] = useState("");
  const [logoOpacity, setLogoOpacity] = useState(1);
  const [logoPosition, setLogoPosition] = useState("foreground");
  const [enableDownload] = useState(true);

  // Function to update eye radius
  const handleEyeRadiusChange = (key, value) => {
    setEyeRadius((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Generatore QrCode
      </Typography>
      <Grid container spacing={2}>
        {/* URL Input */}
        <Grid item xs={12}>
          <TextField
            label="Link"
            fullWidth
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder="Inserisci il link per il QR code"
          />
        </Grid>
        {/* Background Color Picker */}
        <Grid item xs={12}>
          <ColorPicker
            color={bgColor}
            setColor={setBgColor}
            buttonText="Colore sfondo QR code"
            enableAlpha
          />
        </Grid>
        {/* Foreground Color Picker */}
        <Grid item xs={12}>
          <ColorPicker
            color={fgColor}
            setColor={setFgColor}
            buttonText="Colore principale QR code"
            enableAlpha
          />
        </Grid>

        {/* QR Code Style */}
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            label="Stile QR Code"
            SelectProps={{ native: true }}
            value={qrStyle}
            onChange={(e) => setQrStyle(e.target.value)}
          >
            <option value="dots">Punti</option>
            <option value="squares">Quadrati</option>
            <option value="fluid">Fluido</option>
          </TextField>
        </Grid>

        {/* Logo Upload */}
        <Grid item xs={12}>
          <UploadImage
            defaultValue=""
            label="Logo"
            placeholder="Https://... o carica immagine"
            onUpload={setLogo}
          />
        </Grid>
        <Grid item xs={12}>
          <QrCodeComponent
            baseUrl={url}
            local_logo={logo}
            size={250} // Fixed size
            bgColor={bgColor}
            brandName={brandName}
            fgColor={fgColor}
            qrStyle={qrStyle}
            // in questo punto però c'è solo quello top left
            eyeRadius={[
              {
                outer: [
                  eyeRadius.outerTopLeftTopLeft,
                  eyeRadius.outerTopLeftTopRight,
                  eyeRadius.outerTopLeftBottomLeft,
                  eyeRadius.outerTopLeftBottomRight,
                ],
                inner: [
                  eyeRadius.innerTopLeftTopLeft,
                  eyeRadius.innerTopLeftTopRight,
                  eyeRadius.innerTopLeftBottomLeft,
                  eyeRadius.innerTopLeftBottomRight,
                ],
              },
              {
                outer: [
                  eyeRadius.outerTopRightTopLeft,
                  eyeRadius.outerTopRightTopRight,
                  eyeRadius.outerTopRightBottomLeft,
                  eyeRadius.outerTopRightBottomRight,
                ],
                inner: [
                  eyeRadius.innerTopRightTopLeft,
                  eyeRadius.innerTopRightTopRight,
                  eyeRadius.innerTopRightBottomLeft,
                  eyeRadius.innerTopRightBottomRight,
                ],
              },
              {
                outer: [
                  eyeRadius.outerBottomLeftTopLeft,
                  eyeRadius.outerBottomLeftTopRight,
                  eyeRadius.outerBottomLeftBottomLeft,
                  eyeRadius.outerBottomLeftBottomRight,
                ],
                inner: [
                  eyeRadius.innerBottomLeftTopLeft,
                  eyeRadius.innerBottomLeftTopRight,
                  eyeRadius.innerBottomLeftBottomLeft,
                  eyeRadius.innerBottomLeftBottomRight,
                ],
              },
            ]}
            logoOpacity={logoOpacity}
            enableDownload={enableDownload}
            logoPosition={logoPosition}
            optionalDownloadAction={!logged && (() => navigate("/login"))}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Nome brand"
            fullWidth
            value={brandName}
            onChange={(e) => setBrandName(e.target.value)}
            placeholder="Inserisci il nome del brand"
          />
        </Grid>
        {/* Logo Position */}
        <Grid item xs={6}>
          <TextField
            select
            fullWidth
            label="Posizione Logo"
            SelectProps={{ native: true }}
            value={logoPosition}
            onChange={(e) => setLogoPosition(e.target.value)}
          >
            <option value="foreground">In primo piano</option>
            <option value="background">Sul fondo</option>
          </TextField>
        </Grid>
        {/* Logo Opacity Slider */}
        <Grid item xs={6}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography>Opacità Logo</Typography>
            <Slider
              value={logoOpacity}
              min={0}
              max={1}
              step={0.1}
              onChange={(e, val) => setLogoOpacity(val)}
              style={{ width: "80%" }}
            />
          </Stack>
        </Grid>
        {/* Eye Radius Selector */}
        <Grid item xs={12}>
          <EyeRadiusSelector
            selectedCorner={selectedCorner}
            setSelectedCorner={setSelectedCorner}
            eyeRadius={eyeRadius}
            handleEyeRadiusChange={handleEyeRadiusChange}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
