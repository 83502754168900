import React, { useState, useEffect, useContext } from "react";

import { Helmet } from "react-helmet-async";

import { UserContext } from "../../../store/UserContext";
import { BasketContext } from "../../../store/BasketContext";
import { AlertApiContext } from "../../../store/AlertApiContext";
import BusinessCard from "./BusinessCard";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import PromotionalFooter from "./PromotionalFooter";

import { Box, Typography, Stack, Alert, Button } from "@mui/material";

import { get_product_period } from "./CheckLocalTimes";

//icons
import FooterLocalActions from "./FooterLocalActions";

import { useNavigate } from "react-router-dom";
import ModalFilterMenu from "./Forms/ModalFilterMenu";
/* share  */
import BackToHomePopup from "./BackToHomePopup";

import {
  MenuStyle,
  LoadingAnimations,
} from "../../../story-components/components";
import { useTranslation } from "react-i18next";

export default function LocalPage(props) {
  const { showBusinessCard = true } = props;
  const {
    IsAllowed,
    user_licenses,
    setUserLicenses,
    GetLocalIdParams,
    isAdmin,
    localData,
    token,
  } = useContext(UserContext);
  const { api_get, api_request, enqueueSnackbar } = useContext(AlertApiContext);
  const { basket, setBasket } = useContext(BasketContext);
  let navigate = useNavigate();
  const theme = useTheme();

  const { local_id, table_reference, order_token, order_id } = useParams();

  const { i18n } = useTranslation();
  const language = i18n.language ? i18n.language.toLowerCase() : "it";

  let [menuData, setMenuData] = useState(undefined);
  const [menuSections, setMenuSections] = useState({});

  const [section_names, setSectionNames] = useState({});
  const [openFiltersMenu, setOpenFiltersMenu] = useState(false);
  const [productsIdHidden, setProductsIdHidden] = useState([]);
  const [localIsOpen, setLocalIsOpen] = useState(true);
  const [table_referenceFinded, setTableReferenceFinded] = useState(true);
  var [appliedFilters, setAppliedFilters] = useState({
    product_name: "",
    allergens: {},
    categories: {},
    productsIdHidden: [],
  });
  const [isStaff, setIsStaff] = useState(false);

  const [card_variant_selected, setCardVariantSelected] = useState("default");
  const [menu_variant_selected, setMenuVariantSelected] = useState(null);
  const [card_animation_variant_selected, setCardAnimationVariantSelected] =
    useState("up");
  const [menuCardShadow, setMenuCardShadow] = useState(undefined);

  const [menuAddProductDialogFullscreen, setMenuAddProductDialogFullscreen] =
    useState(undefined);
  const [menuAddProductDialogAnimation, setMenuAddProductDialogAnimation] =
    useState(undefined);

  // Effetto per aggiornare le variabili di stato in base alle props ricevute dal padre
  useEffect(() => {
    // permette di gestire le proprietà ch earrivano da fuori
    if (props.menu_variant_selected !== undefined) {
      setMenuVariantSelected(props.menu_variant_selected);
    }

    if (props.card_animation_variant_selected !== undefined) {
      setCardAnimationVariantSelected(props.card_animation_variant_selected);
    }

    if (props.card_variant_selected !== undefined) {
      setCardVariantSelected(props.card_variant_selected);
    }
    if (props.menuCardShadow !== undefined) {
      setMenuCardShadow(props.menuCardShadow);
    } else {
      setMenuCardShadow(localData.menuCardShadow);
    }

    if (props.menuAddProductDialogFullscreen) {
      setMenuAddProductDialogFullscreen(
        props.menuAddProductDialogFullscreen ? true : false
      );
    } else {
      setMenuAddProductDialogFullscreen(
        localData.menuAddProductDialogFullscreen
      );
    }

    if (props.menuAddProductDialogAnimation !== undefined) {
      setMenuAddProductDialogAnimation(props.menuAddProductDialogAnimation);
    } else {
      setMenuAddProductDialogAnimation(localData.menuAddProductDialogAnimation);
    }
  }, [props, localData]); // Si presta attenzione sia alle props che a localData

  let [productsAvailabilityPeriods, setProductsAvailabilityPeriods] = useState(
    []
  );
  const [local_orders_mode, setLocalOrdersMode] = useState(0);

  let [sectionsOrders, setSectionsOrders] = useState([]);

  async function getSections() {
    const lastUpdatedCached = localStorage.getItem(`lastUpdated-${local_id}`);
    const queryParams = {
      local_id,
      table_reference,
      lastUpdatedCached,
    };
    const response = await api_request("GET", `/api/menu/get_menu_sections`, {
      queryParams,
    });

    if (
      response.error_code === "locale non trovato" ||
      response.error_code === "tavolo non trovato"
    ) {
      navigate("/Locals");
    } else {
      let sections = response.data.sections;
      setMenuSections(handleMenuSectionsLanguage(sections));
      sectionsOrders = response.data.sections_orders;
      setSectionsOrders([...sectionsOrders]);
      return sections;
    }
  }

  useEffect(() => {
    if (table_reference) {
      const isHexadecimal = /^[0-9a-fA-F]{24}$/.test(table_reference);
      setTableReferenceFinded(isHexadecimal);
    } else {
      setTableReferenceFinded(false);
    }
  }, [table_reference]);

  async function getBasketFromToken() {
    let order_tokenToConvert = "";

    if (order_token !== undefined) {
      order_tokenToConvert = order_token;
    } else if (order_id !== undefined) {
      const url = `/api/menu/${local_id}/${table_reference}/order_id/${order_id}/get_order_basket`;
      const response = await api_get(url);
      if (response.status === "success") {
        setBasket(response.data.basket);
      }
    }
    if (order_tokenToConvert !== "") {
      const Buffer = require("buffer").Buffer;
      try {
        setBasket({ ...newBasket });
      } catch (err) {
        console.error(err);
        enqueueSnackbar(
          "Si è verificato un errore, non è stato possibile recuperare il carrello",
          { variant: "error" }
        );
      }
    }
  }

  useEffect(() => {
    setIsStaff(IsAllowed(local_id, ["MANAGER", "SENIOR", "STAFF"]));
  }, [user_licenses]);

  useEffect(() => {
    GetLocalIdParams(local_id);
    getBasketFromToken();
  }, []);

  function getMenuDataSections(menuData, sections) {
    // ottengo un menu indicizzato per sezioni
    let menuDataSections = {};
    for (let section_id of sectionsOrders) {
      // Raggruppa per section_id e rimuove i filtri che non rientrano nella fascia
      fetchSectionImage(section_id); // carica già in cache tutte le immagini della sezione
      const productsPeriods = get_product_period(productsAvailabilityPeriods);
      menuDataSections[section_id] = { products: [], subSections: {} };

      // Itera su tutti i dati del menu
      [...menuData].forEach((data) => {
        if (data.section_id === section_id) {
          if (!data.sub_section_id) {
            // Verifica che il periodo del prodotto sia valido
            if (data.productPeriods && productsPeriods) {
              // Aggiungi a products solo se almeno un periodo è valido
              if (
                data.productPeriods.some((period) =>
                  productsPeriods.includes(period)
                )
              ) {
                menuDataSections[section_id].products.push(data);
              }
            } else if (
              !data.hasOwnProperty("productPeriods") ||
              data.productPeriods === null ||
              productsPeriods === null
            ) {
              // Se non ci sono periodi specificati, aggiungi il prodotto
              menuDataSections[section_id].products.push(data);
            }
          } else {
            // Gestisci i casi con sub_section_id
            if (
              !menuDataSections[section_id].subSections[data.sub_section_id]
            ) {
              // Se sub_section_id non esiste, inizializza la subSection
              const subSectionData = sections[
                section_id
              ]?.subSectionsMenuData.find(
                (item) => item.subSectionId === data.sub_section_id
              );

              if (subSectionData) {
                menuDataSections[section_id].subSections[data.sub_section_id] =
                  {
                    position: subSectionData.position,
                    subSectionName: subSectionData.subSectionName,
                    subSectionCover: subSectionData.subSectionCover,
                    subSectionDescription: subSectionData.subSectionDescription,
                    subSectionData: [],
                  };
              } else {
                menuDataSections[section_id].subSections[data.sub_section_id] =
                  { subSectionData: [] };
              }
            }

            // Aggiungi il prodotto alla corrispondente subSection se valido
            if (data.productPeriods && productsPeriods) {
              if (
                data.productPeriods.some((period) =>
                  productsPeriods.includes(period)
                )
              ) {
                menuDataSections[section_id].subSections[
                  data.sub_section_id
                ].subSectionData.push(data);
              }
            } else if (
              !data.hasOwnProperty("productPeriods") ||
              data.productPeriods === null ||
              productsPeriods === null
            ) {
              // Se non ci sono periodi specificati, aggiungi il prodotto alla subSection
              menuDataSections[section_id].subSections[
                data.sub_section_id
              ].subSectionData.push(data);
            }
          }
        }
      });
    }
    return menuDataSections;
  }

  function handleMenuDataLanguage(menuData) {
    for (let section_id in menuData) {
      let menuDataSection = menuData[section_id];

      if (menuDataSection !== undefined) {
        menuData[section_id] = menuDataSection;
      }
    }

    return menuData;
  }

  async function fetchLocalMenuData() {
    //get local info
    const checkIsStaff = IsAllowed(local_id, ["MANAGER", "SENIOR", "STAFF"]);
    const queryParams = {
      local_id,
      table_reference,
      checkIsStaff,
      _: new Date().getTime(), // non viene mai messo in cache il menu
    };
    const response = await api_request("GET", `/api/menu/local_menu_data`, {
      queryParams,
    });
    if (response.data) {
      // alla ricezione converto il menu in sezioni e correggo per la lingua
      let menuDataSectionsTemp = await getSections();
      productsAvailabilityPeriods = response.data.productsAvailabilityPeriods;
      setProductsAvailabilityPeriods(productsAvailabilityPeriods);
      setMenuData(
        handleMenuDataLanguage(
          getMenuDataSections(response.data.productsData, menuDataSectionsTemp)
        )
      ); // lo converte in menu suddiviso per sezioni
      setLocalIsOpen(response.data.isOpen);
      setCardAnimationVariantSelected(
        response.data.card_animation_variant_selected
      );
      setCardVariantSelected(response.data.card_variant_selected);
      setMenuVariantSelected(response.data.menu_variant_selected);
      setLocalOrdersMode(response.data.local_orders_mode);
      const lastUpdated = response.data.last_update;
      localStorage.setItem(`lastUpdated-${local_id}`, lastUpdated); // aggiunge in local data l'ultimo update per la versione del menu

      if (response.data.productsData.length === 0) {
        //forza il caricamento di current user quando il menu è vuoto per ottenere subito la licenza
        setUserLicenses((prev) => [...prev, []]);
      }
    }
  }

  function handleMenuSectionsLanguage(menuSections) {
    // corregge le sezioni in base alla lingua selezionata
    let sections = {};
    sectionsOrders = [];

    const lingua = i18n.language;
    for (let section_id of Object.keys(menuSections)) {
      const section = menuSections[section_id];

      const section_name = section.section_names;
      const section_description = section.section_descriptions ?? {};
      sections[section_id] = section;

      if (section_name[lingua]) {
        // If the field and the correct language version exist, use it
        sections[section_id].section_name = section_name[lingua];
      } else {
        // Otherwise, if the field exists in Italian, use it
        sections[section_id].section_name = section_name["it"];
      }

      if (section_description[lingua]) {
        // If the field and the correct language version exist, use it
        sections[section_id].section_description = section_description[lingua];
      } else {
        // Otherwise, if the field exists in Italian, use it
        sections[section_id].section_description = section_description["it"];
      }
    }
    setSectionNames({ ...sections });

    return { ...sections };
  }

  useEffect(() => {
    if (menuData !== undefined) {
      // cambia la lingua al menu solo se è già stato caricato una volta

      setMenuData(handleMenuDataLanguage(menuData));
      setMenuSections(handleMenuSectionsLanguage(menuSections));
    }
  }, [i18n.language, menuData]);

  useEffect(() => {
    if (localData.plan_active === true || isAdmin) {
      fetchLocalMenuData();
    }
  }, [local_id, localData, token]);

  async function fetchProductImage(product_id) {
    // Verifica se l'immagine del prodotto è già stata memorizzata in cache
    if (localData.plan_value > 0) {
      // Se non è in cache, fai la chiamata API
      const lastUpdatedCached = localStorage.getItem(`lastUpdated-${local_id}`);

      const queryParams = {
        product_id,
        lastUpdatedCached,
      };
      const result = await api_request(
        "GET",
        `/api/products/get_product_image`,
        {
          queryParams,
        }
      );
      if (result.status === "success") {
        const product_cover = result.data ? result.data.product_cover : null;
        if (product_cover !== undefined && product_cover !== null) {
          return product_cover;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }
  }

  async function fetchSectionImage(section_id) {
    // Verifica se l'immagine del prodotto è già stata memorizzata in cache
    if (localData.plan_value > 0) {
      // Se non è in cache, fai la chiamata API
      const lastUpdatedCached = localStorage.getItem(`lastUpdated-${local_id}`);

      const queryParams = {
        section_id,
        lastUpdatedCached,
      };
      const result = await api_request(
        "GET",
        `/api/products/get_section_image`,
        {
          queryParams,
        }
      );
      if (result.status === "success") {
        const section_cover = result.data ? result.data.section_cover : null;
        if (section_cover !== undefined && section_cover !== null) {
          return section_cover;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }
  }

  const canonicalUrl = `https://overlinemenu.it/local/menu/${local_id}`;

  if (
    isAdmin === false &&
    isStaff === false &&
    localData.plan_active === false
  ) {
    return navigate("/");
  } else {
    return (
      <>
        <Helmet>
          <title>{localData?.local_name}</title>
          <meta
            name="title"
            content={`Menù | ${localData?.local_name} | ${localData?.local_city}`}
          />
          <meta
            property="og:title"
            content={`Menù | ${localData?.local_name} | ${localData?.local_city}`}
          />
          <meta
            property="og:image"
            content={`${window.location.origin}/local_logo/${local_id}`}
          />

          <meta
            property="og:description"
            content={`${localData?.local_name} - ${localData?.local_city} - ${localData?.local_address}: ${localData?.local_description}. Menù QR Code, listino prezzi e ordini`}
          />
          <link rel="canonical" href={canonicalUrl} />
        </Helmet>

        <div>
          {showBusinessCard && localData.local_id === local_id && (
            <BusinessCard
              type={localData.business_card_type}
              image={localData.local_cover}
              name={localData.local_name}
              city={localData.local_city}
              cap={localData.local_cap}
              address={localData.local_address}
              phone={localData.local_phone}
              whatsapp={localData.local_whatsapp}
              facebook={localData.facebook}
              instagram={localData.instagram}
              onClickName={() =>
                navigate(`/local_landing_page/${local_id}`, {
                  state: { from: "/local/menu/:local_id/:table_reference" },
                })
              }
              latitude={localData.local_latitude}
              longitude={localData.local_longitude}
              isOpen={localIsOpen}
              navigateToLandingPage={true}
              onClickWeAreClosed={() =>
                navigate(`/local_landing_page/${local_id}`, {
                  state: { from: "/local/menu/:local_id/:table_reference" },
                })
              }
              table_booking={!table_reference && localData.table_booking}
            />
          )}
          {localData.stripe_customer_id === "dummy" && <BackToHomePopup />}

          {localData.plan_active === false && isStaff && (
            <Alert
              sx={{ position: "sticky", zIndex: 10, top: 0 }}
              severity="warning"
            >
              Il <b>piano</b> è stato <b>terminato definitivamente</b>.
            </Alert>
          )}

          {localData.menu_header_info && (
            <Typography
              sx={{ padding: "5px" }}
              variant="body2"
              align="center"
              color="text.background"
            >
              {localData.menu_header_info[language]
                ? localData.menu_header_info[language]
                : localData.menu_header_info.it}
            </Typography>
          )}

          {localData.subscription_charge_failed ===
          "subscription_charge_failed" ? (
            <>
              {isStaff ? (
                <Alert
                  severity="warning"
                  style={{
                    marginBottom: "15px",
                    position: "sticky",
                    zIndex: "20",
                    top: "0px",
                  }}
                >
                  Il <strong>servizio è stato momentaneamente sospeso</strong> a
                  causa di alcuni{" "}
                  <strong>pagamenti in attesa di essere saldati</strong>,
                  completa il pagamento in{" "}
                  <strong>impostazioni - pagamenti</strong> per riattivare il
                  servizio in modo immediato
                </Alert>
              ) : (
                <>
                  {localData.plan_active === true && (
                    <Alert
                      severity="info"
                      style={{
                        marginBottom: "15px",
                        position: "sticky",
                        zIndex: "20",
                        top: "0px",
                      }}
                    >
                      Il servizio è stato momentaneamente sospeso, rivolgiti al
                      personale per maggiori informazioni
                    </Alert>
                  )}
                </>
              )}
            </>
          ) : (
            <div style={{ minHeight: "70vh" }}>
              {menuData !== undefined &&
              Object.keys(menuData).length > 0 &&
              Object.keys(section_names).length > 0 ? (
                <Box sx={{ backgroundColor: theme.palette.background.default }}>
                  <MenuStyle
                    basket={basket}
                    getProductCover={fetchProductImage}
                    getSectionCover={fetchSectionImage}
                    setBasket={setBasket}
                    menuData={menuData}
                    sectionsOrders={sectionsOrders}
                    variant={menu_variant_selected}
                    section_names={section_names}
                    productsIdHidden={productsIdHidden}
                    isStaff={isStaff}
                    card_variant_selected={card_variant_selected}
                    card_animation_variant_selected={
                      card_animation_variant_selected
                    }
                    menuCardShadow={menuCardShadow}
                    menuAddProductDialogFullscreen={
                      menuAddProductDialogFullscreen
                    }
                    menuAddProductDialogAnimation={
                      menuAddProductDialogAnimation
                    }
                  />
                </Box>
              ) : (
                <div>
                  {menuData !== undefined &&
                  Object.keys(menuData).length === 0 &&
                  IsAllowed(local_id, ["MANAGER"]) ? (
                    <Alert severity="warning">
                      <b>Il menù è vuoto</b>, aggiungi prodotti per iniziare a
                      creare il tuo menù, vai su{" "}
                      <Button
                        sx={{ color: "#633c00", borderColor: "#633c00" }}
                        variant="outlined"
                        onClick={() => navigate(`/local/edit_menu/${local_id}`)}
                      >
                        Modifica Menù
                      </Button>{" "}
                      per iniziare ad aggiungere prodotti
                    </Alert>
                  ) : (
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <LoadingAnimations
                        color={theme.palette.text.background}
                        style={{ marginTop: "20vh" }}
                      />
                    </Stack>
                  )}
                </div>
              )}
            </div>
          )}

          {localData.menu_footer_info && (
            <Typography
              sx={{ padding: "5px" }}
              variant="body2"
              align="center"
              color="text.background"
            >
              {localData.menu_footer_info[language]}
            </Typography>
          )}

          <Box sx={{ height: "100px", width: "100%" }} />

          {Object.keys(props).length === 0 && (
            <>
              <div
                style={{
                  marginTop: "100px",
                  zIndex: 200,
                  position: "absolute",
                }}
              >
                <FooterLocalActions
                  local_id={local_id}
                  local_name={localData?.local_name}
                  setOpenFiltersMenu={setOpenFiltersMenu}
                  table_referenceFinded={table_referenceFinded}
                  local_orders_mode={local_orders_mode}
                  localIsOpen={localIsOpen}
                  isStaff={isStaff}
                />
              </div>
              <ModalFilterMenu
                open={openFiltersMenu}
                setOpen={setOpenFiltersMenu}
                menuData={menuData}
                setMenuData={setMenuData}
                appliedFilters={appliedFilters}
                setAppliedFilters={setAppliedFilters}
                setProductsIdHidden={setProductsIdHidden}
              />
            </>
          )}
        </div>
      </>
    );
  }
}
