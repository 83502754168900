import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import InputAdornment from '@mui/material/InputAdornment';

export default function MyAutocomplete({
  label,
  required,
  error,
  disableClearable = true,
  multiple,
  helperText,
  options: optionsProp = [],
  startIcon,
  freeSolo = false,
  onChange,
  onBlur = () => { },
  defaultValue,
  ...rest
}) {
  const [options, setOptions] = useState(optionsProp);
  const [value, setValue] = useState(defaultValue || ""); // Inizializza a stringa vuota se defaultValue è undefined
  useEffect(() => {
    setValue(defaultValue || ""); // Aggiorna il valore se defaultValue cambia
  }, [defaultValue]);
  

  const [key, setKey] = useState(Math.random().toString());

  useEffect(() => {
    setOptions(optionsProp);
    setKey(Math.random().toString());
  }, [optionsProp]);

  const handleSelect = (event, newValue) => {
    const selectedValue = Array.isArray(newValue) ? newValue.map(item => (typeof item === 'string' ? item : item.value)) : (typeof newValue === 'string' ? newValue : newValue?.value);
    setValue(selectedValue); // Aggiorno il valore
    onChange(selectedValue);
  };

  const handleChange = (event) => {
    setValue(event.target.value); // Aggiorno il valore
    onChange(event.target.value);
  };

  const handleBlur= (event) => {
    setValue(event.target.value); // Aggiorno il valore
    onBlur(event.target.value);
  };

  return (
    <Autocomplete
      {...rest}
      key={key}
      multiple={multiple}
      options={options}
      disableClearable={disableClearable}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
      getOptionSelected={(option, value) => (typeof option === 'string' ? option === value : option.value === value)}
      onChange={handleSelect}
      value={value} // Uso il valore dello state
      renderInput={(params) => (
        <TextField {...params}
          label={label}
          required={required}
          error={error}
          helperText={error && helperText}
          onChange={handleChange}
          onBlur={handleBlur}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                {startIcon}
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}

MyAutocomplete.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  freeSolo: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object]))
  ]),
};
