import React, { useContext, useState } from "react";
import { UserContext } from "../../store/UserContext";
import {
  Button,
  TextField,
  Typography,
  Stack,
  useMediaQuery,
  useTheme,
  InputAdornment,
  Alert,
  Box,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";

import SocialLoginButtons from "./SocialLoginButtons";
import { auth } from "../../configs";
import {
  signInWithPopup,
  signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
} from "firebase/auth";
import { Link } from "react-router-dom";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import PersonAddIcon from "@mui/icons-material/PersonAdd"; // Icona per la registrazione
import { Email, Lock } from "@mui/icons-material";
import { logAction } from "../functions/utils";

const buttonStyle = {
  boxShadow: 1,
  borderRadius: "5px",
  color: "black",
  bgcolor: "white",
  border: "1px solid black",
  "&:hover": {
    bgcolor: "#f0f0f0",
    color: "black",
    boxShadow: 0,
    border: "1px solid black",
  },
};

const LoginButtons = ({
  email,
  errorEmail,
  password,
  errorPassword,
  setEmail,
  setPassword,
  handleEmailLogin,
  handleRegister,
}) => {
  const theme = useTheme();

  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box>
      <TextField
        id="email_text_field"
        label="Email"
        variant="outlined"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        error={errorEmail}
        helperText={errorEmail && "Inserisci una email valida"}
        fullWidth
        margin="normal"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Email />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        id="password_text_field"
        label="Password"
        type="password"
        variant="outlined"
        value={password}
        error={errorPassword}
        helperText={errorPassword && "Inserisci una password valida"}
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
        margin="normal"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Lock />
            </InputAdornment>
          ),
        }}
      />
      <Stack
        direction={isMediumScreen ? "row" : "column"}
        sx={{
          justifyContent: "space-around",
          alignItems: "flex-start",
        }}
        spacing={2} // Spaziatura tra i pulsanti
      >
        {/* Pulsante per accedere */}
        <Button
          id="sign-in-button"
          fullWidth
          variant="contained"
          startIcon={<LoginIcon />} // Icona per il pulsante di accesso
          onClick={handleEmailLogin}
          sx={{
            ...buttonStyle,
            backgroundColor: theme.palette.primary.dark,
            color: "white",
          }}
        >
          Accedi
        </Button>

        {/* Pulsante per registrarsi */}
        <Button
          fullWidth
          variant="outlined"
          startIcon={<PersonAddIcon />} // Icona per il pulsante di registrazione
          onClick={handleRegister} // Registra immediatamente l'utente
          sx={{
            ...buttonStyle,
            color: "white",
            backgroundColor: theme.palette.secondary.dark,
          }}
        >
          Registrati
        </Button>
      </Stack>
    </Box>
  );
};

const Login = () => {
  const {
    setOpenLoginModal,
    userObject,
    setUserObject,
    setLogged,
    setUserNotifications,
    setToken,
    setOpenBackDrop,
  } = useContext(UserContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [emailUnverified, setEmailUnverified] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  const handleSocialLoginClick = (provider) => {
    provider.addScope("email");
    setOpenBackDrop(true);
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        const token = user.accessToken;

        setUserObject(user);
        setLogged(true);
        setToken(token);

        localStorage.setItem("userObject", JSON.stringify(user));
        localStorage.setItem("token", token);

        setUserNotifications({});
        setSuccessMessage("Login con successo.");
        setErrorMessage(""); // Pulisce eventuali messaggi di errore
        setOpenLoginModal(false)
        setOpenBackDrop(false);
      })
      .catch((error) => {
        setErrorMessage(
          "Accesso non riuscito. Per favore, riprova o utilizza un metodo alternativo: " +
            error.message
        );
        setSuccessMessage(""); // Pulisce eventuali messaggi di successo
        setOpenBackDrop(false);
      });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  function verifyLogin() {
    if (!email || !validateEmail(email)) {
      setErrorMessage("Inserisci un'email valida.");
      setSuccessMessage("");
      setErrorEmail(true);
      return false;
    }
    setErrorEmail(false);

    if (!password) {
      setErrorMessage("Inserisci una password");
      setSuccessMessage("");
      setErrorPassword(true);
      return false;
    }
    setErrorPassword(false);
    return true;
  }

  const handleEmailLogin = () => {
    if (!verifyLogin()) {
      return;
    }
    setOpenBackDrop(true);
    logAction('login-action')
    signInWithEmailAndPassword(auth, email, password)
      .then((result) => {
        const user = result.user;

        // Controlla se l'email è stata verificata
        if (user.emailVerified) {
          const token = user.accessToken;

          setUserObject(user);
          setLogged(true);
          setToken(token);

          localStorage.setItem("userObject", JSON.stringify(user));
          localStorage.setItem("token", token);
          setUserNotifications({});
          setSuccessMessage("Login con email avvenuto con successo.");
          setErrorMessage("");
          setOpenLoginModal(false);
        } else {
          setErrorMessage(
            "Email non verificata. Controlla la tua email per completare la verifica."
          );
          setEmailUnverified(true);
          setSuccessMessage("");
        }
        setOpenBackDrop(false);
      })
      .catch((error) => {
        let errorMessage = "Si è verificato un errore.";
        switch (error.code) {
          case "auth/user-not-found":
            errorMessage = "Utente non trovato.";
            break;
          case "auth/missing-password":
            errorMessage = "Password mancante";
            break;
          case "auth/popup-closed-by-user":
            errorMessage = "Sessione di autenticazione interrotta";
            break;
          case "auth/invalid-credential":
            errorMessage = "Password o nome utente errato";
            break;
          case "auth/wrong-password":
            errorMessage = "Password errata.";
            break;
          case "auth/email-already-in-use":
            errorMessage = "L'indirizzo email è già in uso.";
            break;
          case "auth/invalid-email":
            errorMessage = "L'indirizzo email non è valido.";
            break;
          case "auth/weak-password":
            errorMessage = "La password è troppo debole.";
            break;
          default:
            errorMessage = error.message;
        }
        setErrorMessage(errorMessage);
        setSuccessMessage("");
        setOpenBackDrop(false);
      });
  };

  const handleResendVerificationEmail = () => {
    const user = auth.currentUser;
    setOpenBackDrop(true);

    sendEmailVerification(user)
      .then(() => {
        setSuccessMessage(
          "Email di verifica inviata nuovamente. Controlla la tua email."
        );
        setOpenBackDrop(false);
      })
      .catch((error) => {
        setErrorMessage(
          "Errore durante l'invio dell'email di verifica: " + error.message
        );
        setOpenBackDrop(false);
      });
  };

  const handleRegister = () => {
    if (!verifyLogin()) {
      return;
    }
    setOpenBackDrop(true);

    createUserWithEmailAndPassword(auth, email, password)
      .then((result) => {
        const user = result.user;

        // Invia l'email di verifica
        sendEmailVerification(user)
          .then(() => {
            setSuccessMessage(
              "Registrazione avvenuta con successo. Controlla la tua email per verificare l'account."
            );
            setErrorMessage("");
          })
          .catch((error) => {
            setErrorMessage(
              "Errore durante l'invio dell'email di verifica: " + error.message
            );
            setSuccessMessage("");
          });
        setOpenBackDrop(false);
      })
      .catch((error) => {
        let errorMessage = error.code;
        if (error.code === "auth/weak-password") {
          errorMessage = "La password deve avere almeno 6 caratteri";
        } else if (error.code === "auth/email-already-in-use") {
          errorMessage = "L'indirizzo email è già in uso.";
        }
        setErrorMessage(errorMessage);
        setSuccessMessage("");
        setOpenBackDrop(false);
      });
  };
  const [passwordReset, setPasswordReset] = useState(true);
  const handlePasswordReset = () => {
    if (email) {
      setOpenBackDrop(true);

      sendPasswordResetEmail(auth, email)
        .then(() => {
          setSuccessMessage("Email di recupero password inviata con successo.");
          setErrorMessage("");
          setPasswordReset(false);
          setOpenBackDrop(false);
        })
        .catch((error) => {
          setErrorMessage("Errore nell'invio della mail di recupero");
          setSuccessMessage("");
          setOpenBackDrop(false);
        });
    } else {
      setErrorMessage("Inserisci una mail valida");
      setSuccessMessage("");
    }
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        localStorage.removeItem("userObject");
        localStorage.removeItem("token");
        localStorage.removeItem("userLicenses");
        localStorage.removeItem("userNotifications");

        setUserObject({});
        setLogged(false);
        setUserNotifications({});
        setToken(undefined);
        setSuccessMessage("Disconnessione avvenuta con successo.");
        setErrorMessage("");
      })
      .catch((error) => {
        setErrorMessage("Disconnessione fallita: " + error.message);
        setSuccessMessage("");
      });
  };

  const smallTextStyle = {
    fontSize: "0.8rem",
    color: "#555",
    textAlign: "center",
    marginTop: "10px",
  };

  return (
    <Stack
      spacing={1}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "5px",
        backgroundColor: "white",
        padding: "5px",
      }}
      direction="column"
    >
      {Object.keys(userObject).length > 0 ? (
        <Button
          startIcon={<LogoutIcon />}
          fullWidth
          variant={"contained"}
          onClick={handleSignOut}
          sx={buttonStyle}
        >
          Disconnetti
        </Button>
      ) : (
        <Box sx={{ padding: "1% 5%" }}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ color: theme.palette.primary.dark }}
          >
            Accedi o Registrati
          </Typography>
          <Alert severity="info">Per proseguire è necessario il login</Alert>
          <Stack
            direction={isMediumScreen ? "row" : "column"}
            sx={{
              justifyContent: "space-around",
              alignItems: "center",
              marginTop:'5px'
            }}
            spacing={2}
          >
            <SocialLoginButtons
              handleSocialLoginClick={handleSocialLoginClick}
            />
            <Box>
              {/* Mostra Alert se ci sono errori o messaggi di successo */}
              {/* Login/Registrazione con email e password */}
              <LoginButtons
                email={email}
                errorEmail={errorEmail}
                password={password}
                errorPassword={errorPassword}
                setEmail={setEmail}
                setPassword={setPassword}
                handleEmailLogin={handleEmailLogin}
                handleRegister={handleRegister}
              />
              <Box sx={{ marginTop: "10px" }}>
                {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                {successMessage && (
                  <Alert severity="success">{successMessage}</Alert>
                )}
              </Box>
              {emailUnverified && (
                <Button
                  startIcon={<MarkEmailReadIcon />}
                  sx={{ marginTop: "10px", maxWidth: "400px" }}
                  variant="contained"
                  fullWidth
                  onClick={handleResendVerificationEmail}
                >
                  Reinvia email di verifica
                </Button>
              )}
              {/* Testo piccolo per "Hai dimenticato la password?" */}
              {passwordReset && (
                <Typography
                  sx={smallTextStyle}
                  onClick={handlePasswordReset}
                  style={{ cursor: "pointer" }}
                >
                  Hai dimenticato la password?
                </Typography>
              )}
              <Typography
                variant="body1"
                style={{ color: "black" }}
                sx={smallTextStyle}
              >
                Hai bisogno?{" "}
                <Link style={{ color: "black" }} to={"/Contact_us"}>
                  contattaci
                </Link>{" "}
              </Typography>
              <Typography variant="body1"   sx={smallTextStyle}>
                Consulta la nostra{" "}
                <Link to="/privacy-policy"  style={{ color: "black" }}>
                  Privacy Policy
                </Link>{" "}
                e i{" "}
                <Link to="/terms-of-service"  style={{ color: "black" }}>
                  Termini e Condizioni
                </Link>
              </Typography >
            </Box>
          </Stack>
        </Box>
      )}
    </Stack>
  );
};

export default Login;
