import React, { useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Button
} from '@mui/material';
import PropTypes from "prop-types";

function AddRoomDialog({ open, onClose, onAdd, existingRoomNames }) {
    const [roomName, setRoomName] = useState("");
    const [error, setError] = useState(false);

    const handleChange = (e) => {
        setRoomName(e.target.value);
        setError(existingRoomNames.includes(e.target.value));
    };

    const handleAdd = () => {
        if (!error) {
            onAdd(roomName);
            setRoomName("");
            onClose();
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Nuova Stanza</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Inserisci il nome della nuova stanza.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Nome Stanza"
                    type="text"
                    fullWidth
                    value={roomName}
                    onChange={handleChange}
                    error={error}
                    helperText={error ? "Una stanza con questo nome esiste già." : ""}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Annulla
                </Button>
                <Button onClick={handleAdd} color="primary">
                    Aggiungi
                </Button>
            </DialogActions>
        </Dialog>
    );
}

AddRoomDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
    existingRoomNames: PropTypes.array.isRequired
};

export default AddRoomDialog;
