import React, { useContext, useEffect } from "react";
import { UserContext } from "../../store/UserContext";
import { useLocation } from "react-router-dom";

import { Button,  Grid, Typography, Box } from "@mui/material";

import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import LoginOptions from "../../components/Login/LoginOptions";
import Particelles from "../Home/components/Particelles";
import { enableGoogleConversion } from "../../components/GoogleConversion";

export default function Login() {
  let navigate = useNavigate();
  const { userObject, logged, setDefaultTheme } = useContext(UserContext);

  const location = useLocation();

  useEffect(() => {
    enableGoogleConversion({
      event: "login_page_view",
      event_label: "Login page viewed",
      value: 0.01,
    });

    setDefaultTheme();
    if (logged) {
      navigate("/my_locals");
    }
  }, [logged]);

  return (
    <Box sx={{ padding: "5px", color: "white" }} className="homePage">
      <Box
        className="alba-background"
        style={{
          background: "linear-gradient(to bottom,  #d7d7e8, #7c7785)",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: -1,
        }}
      >
        <Particelles />
      </Box>
      <Grid container justifyContent="center" alignItems="center" spacing={1}>
        <Grid item xs={12}>
          <Button
            startIcon={<ArrowBackIosIcon />}
            variant="contained"
            onClick={() => (logged ? navigate(-1) : navigate("/"))}
          >
            Torna indietro
          </Button>
        </Grid>

        {Object.keys(userObject).length > 0 && (
          <>
            <Grid item style={{ textAlign: "center" }}>
              <Box
                sx={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                  boxShadow: 3,
                  backgroundImage: `url(${userObject.picture})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              />
              <Typography variant="h6" align="center" gutterBottom>
                {userObject.name}
              </Typography>
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <LoginOptions />
        </Grid>
      </Grid>
    </Box>
  );
}
