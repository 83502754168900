import React, { useRef, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
//import { StylesProvider } from '@mui/styles';

import RouteApp from "./api/RouteApp";
import { Button } from "@mui/material";

/* context */
import { UserContextProvider } from "./store/UserContext";
import { AlertApiContextProvider } from "./store/AlertApiContext";
import { SnackbarProvider } from "notistack";
import { BasketContextProvider } from "./store/BasketContext";
import "./Style/Generic_style.css";
import "./Style/modal_style.css";
import { useTranslation } from "react-i18next";
import LoginModal from "./components/Login/LoginModal";
import { HelmetProvider } from "react-helmet-async";

function App() {
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage("it");
  }, []);

  const notistackRef = useRef();

  return (
    <UserContextProvider>
      <SnackbarProvider
        maxSnack={2}
        ref={notistackRef}
        action={(key) => (
          <Button
            onClick={() => notistackRef.current.closeSnackbar(key)}
            style={{ color: "#fff", fontSize: "15px" }}
          >
            ✖
          </Button>
        )}
      >
        <BrowserRouter>
          <AlertApiContextProvider>
            <BasketContextProvider>
              <HelmetProvider>
                <LoginModal />

                <RouteApp />
              </HelmetProvider>
            </BasketContextProvider>
          </AlertApiContextProvider>
        </BrowserRouter>
      </SnackbarProvider>
    </UserContextProvider>
  );
}

export default App;
