import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid, Card, Stack } from '@mui/material';
import PropTypes from "prop-types";
import { useTheme } from '@mui/material/styles';

export default function ColorPicker({ color, setColor, buttonText, enableAlpha = false }) {
  const theme = useTheme(); // Ottieni il tema MUI
  const [tempColor, setTempColor] = useState(color);
  const [originalColor, setOriginalColor] = useState(color);
  const [open, setOpen] = useState(false);

  function rgbaToHex(r, g, b, a = 1) {
    const toHex = (x) => x.toString(16).padStart(2, '0');
    const alpha = Math.round(a * 255);
    return `#${toHex(r)}${toHex(g)}${toHex(b)}${enableAlpha ? toHex(alpha) : ''}`;
  }

  function handleOpen() {
    setOpen(true);
    setOriginalColor(color);
  }

  function handleClose() {
    setOpen(false);
    setColor(originalColor);
  }

  function saveColor() {
    setColor(tempColor);
    setOpen(false);
  }

  function handleChange(color) {
    const { r, g, b, a } = color.rgb;
    setTempColor(rgbaToHex(r, g, b, a));
  }

  const fontColor = theme.palette.getContrastText(color);

  return (
    <>
      <Button
        variant="contained"
        fullWidth
        onClick={handleOpen}
        style={{
          height: '100%',
          justifyContent: 'left',
          backgroundColor: color,
          border: `2px solid ${fontColor}`,
        }}
      >
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <Typography variant="subtitle" color={fontColor}>
            {buttonText}
          </Typography>
        </Stack>
      </Button>

      <Modal
        id="color-keeper"
        keepMounted
        sx={{ zIndex: 1502 }}
        open={open}
        onClose={handleClose}
      >
        <Box
          className="standard_modal"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: '50%',
            left: '50%',
            zIndex: 1502,
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Card style={{ width: 'min-content', padding: '5px' }}>
            <Stack style={{ placeContent: 'center' }} direction="row" spacing={2}>
              <Typography style={{ textAlign: 'center', marginBottom: '10px' }} variant="h4">
                {buttonText}
              </Typography>
            </Stack>
            <SketchPicker
              color={tempColor}
              onChange={handleChange}
              disableAlpha={!enableAlpha} // Disabilita trasparenza di default
            />
            
            <Grid container spacing={1} style={{ marginTop: '5px' }}>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  style={{ height: '100%', backgroundColor: tempColor, color: theme.palette.getContrastText(tempColor) }}
                  variant="contained"
                  onClick={handleClose}
                >
                  Chiudi
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  style={{ height: '100%', backgroundColor: tempColor, color: theme.palette.getContrastText(tempColor) }}
                  variant="contained"
                  onClick={saveColor}
                >
                  Salva
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Box>
      </Modal>
    </>
  );
}

