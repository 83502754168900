  import HourglassTopRoundedIcon from '@mui/icons-material/HourglassTopRounded';
  import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
  import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
  import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';


export const productSteps = {
    0: {
      text: 'In attesa della preparazione',
      color: 'white',
      icon: <HourglassTopRoundedIcon />
    },
    1: {
      text: 'Pronto',
      color: '#9cc6ec',
      icon: <SettingsRoundedIcon className='icn-spinner' />
    },
    2: {
      text: 'In preparazione',
      color: 'rgb(248 226 160)',
      icon: <SettingsRoundedIcon className='icn-spinner' />
    },
    3: {
      text: 'Preparato',
      color: 'rgb(249 202 127)',
      icon: <DoneRoundedIcon />
    },
    4: {
      text: 'Consegnato',
      color: '#a4dc8b',
      icon: <DoneAllRoundedIcon />
    }
  }