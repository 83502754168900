import React, { useEffect, useState, useContext } from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import DrawerApp from "./DrawerApp";
import { UserContext } from ".././store/UserContext";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import { useNavigate } from "react-router-dom";
import LoginButton from "./Login/LoginButton";

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export default function Nav(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const { user, userObject, userNotifications, localData, logged } =
    useContext(UserContext);
  const [counterNotifications, setCounterNotifications] = useState(0);

  useEffect(() => {
    let counterNotificationsTemp = 0;

    if (userNotifications && Object.keys(userNotifications).length > 0) {
      const keys = Object.keys(userNotifications.activeOrders);
      for (let i = 0; i < keys.length; i++) {
        const local_id = keys[i];
        if (userNotifications.activeOrders[local_id].orders > 0) {
          counterNotificationsTemp +=
            userNotifications.activeOrders[local_id].orders;
        }
      }

      if (userNotifications.notifications) {
        for (let i = 0; i < userNotifications.notifications.length; i++) {
          if (!userNotifications.notifications[i].read) {
            counterNotificationsTemp += 1;
          }
        }
      }
    }

    const activePersonalOrders =
      counterNotificationsTemp + userNotifications?.activePersonalOrders || 0;
    if (activePersonalOrders > 0) {
      counterNotificationsTemp += userNotifications?.activePersonalOrders || 0;
    }

    setCounterNotifications(counterNotificationsTemp);
  }, [userNotifications]);

  let navigate = useNavigate();
  const isMenuOpen = Boolean(anchorEl);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {logged && [
        <MenuItem
          key="notifications"
          onClick={() => {
            navigate("my_notifications");
            handleMenuClose();
          }}
        >
          <Badge badgeContent={counterNotifications} color="error">
            Notifiche
          </Badge>
        </MenuItem>,
        <MenuItem
          key="profile"
          onClick={() => {
            navigate("my_account");
            handleMenuClose();
          }}
        >
          Il mio profilo
        </MenuItem>,
        <MenuItem
          key="orders"
          onClick={() => {
            navigate("my_orders");
            handleMenuClose();
          }}
        >
          I miei ordini
        </MenuItem>,
      ]}

      <LoginButton />
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1, boxShadow: 3 }}>
      <DrawerApp drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
      <HideOnScroll {...props}>
        <AppBar position="static">
          <Toolbar style={{ zIndex: 100 }}>
            <IconButton
              id="button-open-drawer"
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
              onClick={() => handleDrawerToggle()}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: "none", sm: "block" } }}
            ></Typography>

            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: "flex", md: "flex" } }}>
              <Badge badgeContent={counterNotifications} color="error">
                <IconButton
                  style={{ padding: "0px" }}
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  {userObject.picture ? (
                    <img
                      style={{
                        borderRadius: "50%",
                        height: "45px",
                        width: "45px",
                      }}
                      src={userObject.picture}
                      alt={user}
                    />
                  ) : (
                    <AccountCircle />
                  )}
                </IconButton>
              </Badge>
            </Box>
          </Toolbar>
        </AppBar>
      </HideOnScroll>

      {renderMenu}
    </Box>
  );
}
