import React, { useState, useContext } from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Card,
  CircularProgress,
  FormGroup,
  Typography,
} from "@mui/material";
import { AlertApiContext } from "../../../../store/AlertApiContext";
import { useParams } from "react-router-dom";
import { Trans } from "react-i18next";
import { ConsentButton } from "../../../../story-components/components";
import PrivacyContactPolicy from "../../../../components/Privacy/PrivacyContactPolicy";

const contactInfoDefault={
  contact_reason: "",
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  text: "",
  booking: {
    participants: "",
  }
}

const LocalContactForm = () => {
  const [contactInfo, setContactInfo] = useState({...contactInfoDefault});
  const { api_request } = useContext(AlertApiContext);
  const [sending, setSending] = useState(false);

  const [confirmedPolicy, setConfirmedPolicy] = useState(false);
  const [errorConfirmPolicy, setErrorConfirmedPolicy] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const [counter,setCounter] = useState(0)
  const { local_id } = useParams();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setContactInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleBookingChange = (event) => {
    const { name, value } = event.target;
    setContactInfo((prevState) => ({
      ...prevState,
      booking: {
        ...prevState.booking,
        [name]: value,
      },
    }));
  };

  async function handleSubmit(event) {
    event.preventDefault();

    if (!confirmedPolicy) {
      setErrorConfirmedPolicy(true);
      return;
    } else {
      setErrorConfirmedPolicy(false);
    }

    setSubmitted(true);
    if (
      !contactInfo.contact_reason ||
      !contactInfo.first_name ||
      !contactInfo.last_name ||
      !contactInfo.contact_text ||
      (!contactInfo.email && !contactInfo.phone)
    ) {
      return;
    }
    if (
      contactInfo.contact_reason === "Prenotazioni" &&
      !contactInfo.booking.participants
    ) {
      return;
    }
    const queryParams = {
      local_id,
    };
    setSending(true);
    const response = await api_request("POST", `/api/local/contact`, {
      queryParams,
      body: contactInfo,
    });
    if (response.status==='success'){
      setContactInfo({...contactInfoDefault})
      setSubmitted(false);
    }
    setSending(false);
  }

  return (
    <Card sx={{ padding: "5px" }}>
      <Typography variant="h4" align="center">
        <Trans>Contattaci</Trans>
      </Typography>
      <Typography variant="body1" align="center">
        Richiedi informazioni o richieste di prenotazioni
      </Typography>
      <form noValidate>
        <FormGroup>
          <FormControl fullWidth margin="normal">
            <InputLabel required>
              <Trans>Motivo del Contatto</Trans>
            </InputLabel>
            <Select
              name="contact_reason"
              value={contactInfo.contact_reason}
              label="Motivo del Contatto"
              onChange={handleChange}
              error={!contactInfo.contact_reason && submitted}
            >
              <MenuItem value="Informazioni">Informazioni</MenuItem>
              <MenuItem value="Altro">Altro</MenuItem>
            </Select>
          </FormControl>
          <TextField
            margin="normal"
            fullWidth
            label={<Trans>Nome</Trans>}
            name="first_name"
            value={contactInfo.first_name}
            onChange={handleChange}
            required
            error={!contactInfo.first_name && submitted}
          />
          <TextField
            margin="normal"
            fullWidth
            label={<Trans>Cognome</Trans>}
            name="last_name"
            value={contactInfo.last_name}
            onChange={handleChange}
            required
            error={!contactInfo.last_name && submitted}
          />
          <TextField
            margin="normal"
            fullWidth
            label={<Trans>Email</Trans>}
            name="email"
            value={contactInfo.email}
            onChange={handleChange}
            error={!contactInfo.email && !contactInfo.phone && submitted}
            helperText={
              !contactInfo.email && !contactInfo.phone && submitted
                ? "Email o telefono sono obbligatori"
                : ""
            }
          />
          <TextField
            margin="normal"
            fullWidth
            label={<Trans>Telefono</Trans>}
            name="phone"
            value={contactInfo.phone}
            onChange={handleChange}
            error={!contactInfo.email && !contactInfo.phone && submitted}
            helperText={
              !contactInfo.email && !contactInfo.phone && submitted
                ? "Email o telefono sono obbligatori"
                : ""
            }
          />
          <TextField
            margin="normal"
            fullWidth
            label={<Trans>Descrizione dettagliata</Trans>}
            name="contact_text"
            placeholder="Es: Possiamo prenotare per organizzare un matrimonio? A capodanno siete aperti? ..."
            value={contactInfo.contact_text}
            onChange={handleChange}
            required
            error={!contactInfo.contact_text && submitted}
          />

          {contactInfo.contact_reason === "Prenotazioni" && (
            <>
              <TextField
                margin="normal"
                fullWidth
                label={<Trans>Numero di partecipanti</Trans>}
                name="participants"
                type="number"
                value={contactInfo.booking.participants}
                onChange={handleBookingChange}
                required
                error={!contactInfo.booking.participants && submitted}
              />
            </>
          )}
          <ConsentButton
            title="Informativa sulla privacy"
            error={errorConfirmPolicy}
            onChange={setConfirmedPolicy}
            mustOpen={false}
            label="Acconsento l'informativa sulla privacy"
          >
            <PrivacyContactPolicy />
          </ConsentButton>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{ mt: 2 }}
          >
            {sending ? <CircularProgress /> : <Trans>Invia</Trans>}
          </Button>
        </FormGroup>
      </form>
    </Card>
  );
};

export default LocalContactForm;
