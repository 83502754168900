import React from "react";
import PropTypes from "prop-types";

import MenuTab from "./MenuStyles/MenuTab";
import MenuAccordion from "./MenuStyles/MenuAccordion";
import MenuStandard from "./MenuStyles/MenuStandard";
import MenuTabsScroll from "./MenuStyles/MenuTabsScroll";
import MenuVerticalTabls from "./MenuStyles/MenuVerticalTabs";

export default function MenuStyle({
  basket,
  getProductCover = () => {},
  getSectionCover = () => {},
  setBasket,
  variant = "tabs",
  section_names = {},
  sectionsOrders,
  productsIdHidden = [],
  menuData,
  isStaff,
  card_variant_selected = "default",
  card_animation_variant_selected,
  menuCardShadow,
  menuAddProductDialogFullscreen,
  menuAddProductDialogAnimation,
}) {
  const menuProps = {
    basket,
    setBasket,
    menuData,
    section_names,
    sectionsOrders,
    productsIdHidden,
    isStaff,
    card_variant_selected,
    getProductCover,
    getSectionCover,
    card_animation_variant_selected,
    menuCardShadow,
    menuAddProductDialogFullscreen,
    menuAddProductDialogAnimation,
  };

  if (variant === "tabs") {
    return <MenuTab {...menuProps} menuVariant='tabs'/>;
  } else if (variant === "tabs-scroll") {
    return <MenuTabsScroll {...menuProps} />;
  } else if (variant === "accordion") {
    return <MenuAccordion {...menuProps} />;
  } else if (variant === "tabs-vertical") {
    return <MenuVerticalTabls {...menuProps} />;
  } else if (variant === "etichetta-fissa") {
    return <MenuTab {...menuProps} menuVariant='etichetta-fissa' />;
  } else {
    return <MenuStandard {...menuProps} />;
  }
}

MenuStyle.propTypes = {
  section_names: PropTypes.object,
  setBasket: PropTypes.func,
  productsIdHidden: PropTypes.array,
  menuData: PropTypes.object,
  isStaff: PropTypes.bool,
  card_animation_variant_selected: PropTypes.oneOf([
    "left",
    "right",
    "down",
    "up",
    "vertical flip",
    "horizontal flip",
    "right down",
    "left down",
    "zoom",
    "crazy",
    "pendolum",
    "none",
  ]),
  card_variant_selected: PropTypes.oneOf([
    "default",
    "minimal",
    "split",
    "image",
    "standard",
    "no-image-standard",
    "no-background",
    "modern-parallax",
    "circular-top-image",
    "glass-effect",
  ]),
  variant: PropTypes.oneOf([
    "standard",
    "default",
    "tabs",
    "accordion",
    "tabs-scroll",
    "tabs-vertical",
    "etichetta-fissa"
  ]),
  menuCardShadow: PropTypes.oneOf([0, 1, 2, 3, 4, 5]),
  getProductCover: PropTypes.func,
  menuAddProductDialogAnimation: PropTypes.oneOf([
    "slideUp",
    "slideDown",
    "slideLeft",
    "slideRight",
    "fade",
    "grow",
    "zoom",
    "Collapse",
    "rotate",
  ]),
  menuAddProductDialogFullscreen: PropTypes.bool,
};
