import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from "prop-types";

const MultiSelectDialog = ({ items=[], open, onClose }) => {
    const [selectedItems, setSelectedItems] = useState(items);

    const handleToggle = (item) => {
        const currentIndex = selectedItems.indexOf(item);
        const newSelectedItems = [...selectedItems];

        if (currentIndex === -1) {
            newSelectedItems.push(item);
        } else {
            newSelectedItems.splice(currentIndex, 1);
        }

        setSelectedItems(newSelectedItems);
    };

    const handleClose = () => {
        onClose(selectedItems);
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Seleziona gli elementi</DialogTitle>
            <List>
                {items.map((item) => (
                    <ListItem key={item} button onClick={() => handleToggle(item)}>
                        <Checkbox checked={selectedItems.indexOf(item) !== -1} />
                        <ListItemText primary={item} />
                    </ListItem>
                ))}
            </List>
            <DialogActions>
                <Button onClick={handleClose} color="primary">Conferma</Button>
            </DialogActions>
        </Dialog>
    );
}

export default MultiSelectDialog;

MultiSelectDialog.propTypes = {
    open: PropTypes.bool,
    items: PropTypes.array,
    onClose: PropTypes.func
  };