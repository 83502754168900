import React, { useState,useEffect } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Button
} from '@mui/material';
import PropTypes from "prop-types";

function RenameRoomDialog({ open, onClose, onRename, currentRoomName }) {
    const [newName, setNewName] = useState(currentRoomName);
    const [error, setError] = useState(false);

    // Aggiorniamo il nome nel componente ogni volta che il dialogo viene aperto
    useEffect(() => {
        setNewName(currentRoomName);
    }, [currentRoomName]);

    const handleChange = (e) => {
        setNewName(e.target.value);
    };

    const handleRename = () => {
        if (!error) {
            onRename(newName);
            onClose();
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Rinomina Stanza</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Modifica il nome della stanza.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Nome Nuovo"
                    type="text"
                    fullWidth
                    value={newName}
                    onChange={handleChange}
                    error={error}
                    helperText={error ? "Una stanza con questo nome esiste già." : ""}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Annulla
                </Button>
                <Button onClick={handleRename} color="primary">
                    Salva
                </Button>
            </DialogActions>
        </Dialog>
    );
}

RenameRoomDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onRename: PropTypes.func.isRequired,
    currentRoomName: PropTypes.string.isRequired
};

export default RenameRoomDialog;
