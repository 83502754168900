import React, { useState, useEffect } from "react";
import { Typography, ButtonBase, Checkbox, Grid, Card } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { RenderIcon } from "../../story-components/components";

export function RenderHandlerCheckBox({
  title,
  arrayCheckBox,
  array: arrayProp,
  setArray,
}) {
  const [array, setInternalArray] = useState(arrayProp);
  const theme = useTheme();

  useEffect(() => {
    setInternalArray(arrayProp);
  }, []);

  const handleCheckBox = (item) => {
    setInternalArray((prevArray) => {
      if (prevArray.includes(item)) {
        return prevArray.filter((x) => x !== item);
      } else {
        return [...prevArray, item];
      }
    });
  };

  useEffect(() => {
    setArray(array);
  }, [array, setArray]);

  return (
    <>
      {arrayCheckBox && arrayCheckBox.length > 0 && (
        <Card style={{ padding: "5px" }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography
                style={{
                  textAlignLast: "center",
                }}
                id="modal-modal-title"
                variant="h6"
                component="h6"
              >
                {title}
              </Typography>
            </Grid>
            {arrayCheckBox.map((item, index) => {
              const checked = array.includes(item);
              return (
                <Grid
                  sx={{ justifyContent: "center" }}
                  key={"checkbox-" + item}
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  lg={4}
                  xl={3}
                >
                  <ButtonBase
                    id={`button-${title}-${item}`}
                    style={{
                      height: "100%",
                      backgroundColor: checked
                        ? theme.palette.primary.dark
                        : "rgb(226 226 226)",
                      borderRadius: "5px",
                      justifyContent: "left",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                    sx={{ boxShadow: 1 }}
                    onClick={() => handleCheckBox(item)}
                  >
                    <Checkbox
                      style={{
                        color: checked ? "white" : theme.palette.primary.dark,
                      }}
                      checked={checked ? true : false}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <RenderIcon
                      iconName={item}
                      backgroundColor={theme.palette.secondary.dark}
                      color={"white"}
                      showText={false}
                    />
                    <Typography
                      style={{
                        textAlignLast: "center",
                        color: checked ? "white" : theme.palette.primary.dark,
                      }}
                      variant="body"
                      component="body"
                    >
                      {item}
                    </Typography>
                  </ButtonBase>
                </Grid>
              );
            })}
          </Grid>
        </Card>
      )}
    </>
  );
}
