import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  TextField,
  Grid,
  Stack,
  Divider,
  List,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IngredientDialogForm from "./IngredientDialogForm";
import TableRowsIcon from "@mui/icons-material/TableRows";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import { useTheme } from '@mui/material/styles';
import { TransitionGroup, CSSTransition } from "react-transition-group";


// Helper function to convert array to object
const arrayToObject = (arr) => {
  const obj = {};
  arr.forEach((item) => {
    const key = item.ingredientOption.it;
    if (!obj[key]) {
      obj[key] = [];
    }
    obj[key].push(item);
  });
  return obj;
};

// Helper function to convert object to array
const objectToArray = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    return [...acc, ...obj[key]];
  }, []);
};



export default function AlternativeAddIngredientForm({
  onChange = (values) => {
  },
  initialIngredients = {}
}) {
  const [ingredients, setIngredients] = useState(arrayToObject(initialIngredients));
  const [openNewIngredient, setOpenNewIngredient] = useState(false);
  const [currentIngredient, setCurrentIngredient] = useState(null);
  const [dialogKey, setDialogKey] = useState(0);
  const theme = useTheme()

  async function handleSave(ingredient) {
    const newIngredients = { ...ingredients };
    const currentType = ingredient.ingredientOption.it;

    // Crea un oggetto vuoto con le proprietà che desideri
    const emptyIngredient = {
      ingredientOption: { it: currentType },
      ingredientName: { it: "" },
      ingredient_price_variation_on_add: "",
      ingredient_price_variation_on_delete: ""
    };

    if (!newIngredients[currentType]) {
      newIngredients[currentType] = [];
    }

    // Aggiungi l'ingrediente passato come parametro
    newIngredients[currentType].push(ingredient);

    // Aggiungi un nuovo ingrediente vuoto
    newIngredients[currentType].push(emptyIngredient);

    // Aggiorna lo stato
    setIngredients(newIngredients);
  }


  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const handleDeleteConfirm = () => {
    const newIngredients = { ...ingredients };

    if (itemToDelete.category === "") {
      delete newIngredients[""];
    }
    else if (itemToDelete.category && typeof itemToDelete.index === "undefined") {
      // Elimina l'intera categoria
      delete newIngredients[itemToDelete.category];
    }
    else if (itemToDelete.category && typeof itemToDelete.index !== "undefined") {
      // Elimina una singola opzione dalla categoria
      newIngredients[itemToDelete.category].splice(itemToDelete.index, 1);
      if (newIngredients[itemToDelete.category].length === 0) {
        delete newIngredients[itemToDelete.category];
      }
    }

    setIngredients(newIngredients);
    setOpenDeleteDialog(false);
  };

  const handleAddEmptyRow = (type) => {
    // Copia lo stato corrente
    const newIngredients = { ...ingredients };
    // Crea un oggetto vuoto con le proprietà che desideri
    const emptyIngredient = {
      ingredientOption: { it: type },
      ingredientName: { it: "" },
      ingredient_price_variation_on_add: ""
    };

    if (!newIngredients[type]) {
      newIngredients[type] = [];
    }

    // Aggiungi l'oggetto vuoto alla categoria
    newIngredients[type].push(emptyIngredient);

    // Aggiorna lo stato
    setIngredients(newIngredients);
  };

  useEffect(() => {
    setDialogKey((prevKey) => prevKey + 1);
  }, [openNewIngredient]);

  useEffect(() => {
    // Convert object to array before calling onChange
    const ingredientsArray = objectToArray(ingredients);
    onChange(ingredientsArray);
  }, [ingredients]);

  return (
    <>
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>{"Conferma cancellazione"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Sei sicuro di voler cancellare?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
            Annulla
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary">
            Conferma
          </Button>
        </DialogActions>
      </Dialog>




      <IngredientDialogForm
        key={dialogKey}
        onSave={handleSave}
        open={openNewIngredient}
        setOpen={setOpenNewIngredient}
      />

      {Object.keys(ingredients).map((type) => (
        <Accordion key={type}>
          <AccordionSummary sx={{
            background: theme.palette.secondary.dark,
            color: 'white', margin: '0px', padding: '0px',
            '& .MuiAccordionSummary-content': {
              margin: '0px'
            }
          }} expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <IconButton

                onClick={() => {
                  setItemToDelete({ category: type });
                  setOpenDeleteDialog(true);
                }}
              >
                <DeleteIcon sx={{ color: 'white' }} />
              </IconButton>
              {type}
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <List style={{ maxWidth: "100vw", overflowX: "auto" }}>
              <TransitionGroup>
                {ingredients[type].map((ing, index) => (
                  <Collapse key={index}>
                    <Grid container spacing={1} sx={{ padding: '5px 0px 5px 0px' }}>
                      <Grid item xs={1}>
                        {index > 1 &&
                          <IconButton
                            sx={{ marginLeft: '-5px' }}
                            onClick={() => {
                              setItemToDelete({ category: type, index: index });
                              setOpenDeleteDialog(true);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        }
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label={"Nome opzione"}
                          value={ing.ingredientName.it}
                          onChange={(e) => {
                            const newIngredients = { ...ingredients };
                            newIngredients[type][index].ingredientName.it =
                              e.target.value;
                            setIngredients({ ...newIngredients });
                          }}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          fullWidth
                          label={"€ variazione"}
                          type="number"
                          disabled={index === 0}
                          value={ing.ingredient_price_variation_on_add}
                          onChange={(e) => {
                            // Aggiorna lo stato qui
                            const newIngredients = { ...ingredients };
                            newIngredients[type][
                              index
                            ].ingredient_price_variation_on_add = e.target.value;
                            setIngredients({ ...newIngredients });
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Divider />
                  </Collapse>
                ))}
              </TransitionGroup>
            </List>
            <Button
              variant="contained"
              color="primary"
              sx={{ marginTop: "5px" }}
              onClick={() => handleAddEmptyRow(type)}
            >
              Aggiungi Ingrediente
            </Button>
          </AccordionDetails>
        </Accordion>
      ))}

      <Button
        sx={{ marginTop: '10px' }}
        variant="contained"
        color="primary"
        startIcon={<TableRowsIcon />}
        onClick={() => {
          setCurrentIngredient(null);
          setOpenNewIngredient(true);
        }}
      >
        Aggiungi
      </Button>
    </>
  );
}


AlternativeAddIngredientForm.propTypes = {

  onChange: PropTypes.func,

};