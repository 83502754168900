import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

function ConsentButton({
  onChange,
  title,
  label,
  error,
  children,
  mustOpen,
  color = "black",
}) {
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = (agree) => {
    setOpen(false);
    console.log("agree:", agree);
    if (agree) {
      setChecked(true);
      onChange(true);
    } else {
      setChecked(false);
      onChange(false);
    }
  };

  return (
    <Box sx={{ flexDirection: "row" }}>
      <IconButton
        onClick={() => (mustOpen ? handleOpen() : handleClose(!checked))}
        color="default"
        sx={{ color: color }}
      >
        {checked ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
      </IconButton>
      <Typography
        onClick={() => (mustOpen ? handleOpen() : handleClose(!checked))}
        sx={{ display: "contents", color: error && !checked ? "#dc7070" : color }}
      >
        {label}
      </Typography>
      {!mustOpen && (
        <Link
          onClick={handleOpen}
          sx={{ ml: 1, color: error && !checked ? "#dc7070" : color, textDecoration:'underline' }}
        >
          clicca qui per leggere l'informativa
        </Link>
      )}
      <Dialog open={open} onClose={() => handleClose(false)} fullScreen>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{children}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleClose(false)}
          >
            Rifiuta
          </Button>
          <Button
            variant="contained"
            onClick={() => handleClose(true)}
            autoFocus
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ConsentButton;
