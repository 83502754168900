import React from "react";
import { IconButton, Badge, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";

import PropTypes from "prop-types";

export function getActiveBoxShadow(theme) {
  return `
0 0 5px ${theme.palette.secondary.light},
0 0 10px ${theme.palette.secondary.light}
`;
}

export const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: "0px",
    top: "2px",
    border: `1px solid white`,
    padding: "0 0px",
    color: "white",
    backgroundColor: theme.palette.secondary.dark,
  },
}));

export const IconButtonContainer = ({
  tabValue,
  currentValue,
  onClick,
  children,
  badgeContent,
}) => {
  const theme = useTheme();

  return (
    <IconButton
      onClick={onClick}
      sx={{
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        background:
          tabValue === currentValue
            ? `${theme.palette.secondary.main} !important`
            : theme.palette.action.hover,
        color:
          tabValue === currentValue
            ? theme.palette.secondary.contrastText
            : theme.palette.primary.contrastText,
        boxShadow: tabValue === currentValue && getActiveBoxShadow(theme),
        "&:hover": {
          background:
            tabValue === currentValue
              ? `${theme.palette.secondary.main} !important`
              : theme.palette.action.hover,
          boxShadow: tabValue === currentValue && getActiveBoxShadow(theme),
        },
      }}
    >
      {badgeContent ? (
        <StyledBadge badgeContent={badgeContent} color="secondary">
          {children}
        </StyledBadge>
      ) : (
        children
      )}
    </IconButton>
  );
};

IconButtonContainer.propTypes = {
  tabValue: PropTypes.number.isRequired,
  currentValue: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  badgeContent: PropTypes.number,
  children: PropTypes.node.isRequired,
};
const CircleContainer = styled(Box)(({ theme }) => ({
  width: "60px",
  height: "60px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexShrink: 0,
}));

const Circle = styled(IconButton)(({ theme, activeValue, tabValue }) => ({
  width: "50px",
  height: "50px",
  backgroundColor:
    activeValue === tabValue
      ? theme.palette.secondary.main
      : theme.palette.primary.dark,
  color:
    activeValue === tabValue ? theme.palette.primary.contrastText : "white",
  borderRadius: "50%",
  padding: 10,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "&:hover": {
    backgroundColor: theme.palette.secondary.dark,
    color: "white",
  },
  boxShadow:
    activeValue === tabValue
      ? getActiveBoxShadow(theme)
      : "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
}));

export function getRectangleBarStyle(theme) {
  return {
    width: "calc(100% - 100px)",
    borderRadius: "0px",
    textAlign: "center",
    padding: "0px",
  };
}

export function getRectangleStyle(theme) {
  return {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex:1000
  };
}

export function RenderFabNavTab({
  tabValue,
  activeValue,
  setValue,
  sx,
  onClick,
  children,
  ...rest
}) {

    return (
    <CircleContainer
      sx={{
        position: "absolute",
        zIndex: 3,

        ...sx,
      }}
    >
      <Circle
        activeValue={activeValue}
        tabValue={tabValue}
        onClick={onClick ? onClick : () => setValue(tabValue)}
      >
        {children}
      </Circle>
    </CircleContainer>
  );
}

export function getCurveStyle(theme) {
  return {
    content: '""',
    position: "absolute",
    bottom: 0,
    width: "110px",
    height: "40px",
    background: `url("data:image/svg+xml,${encodeURIComponent(
      `<svg xmlns='http://www.w3.org/2000/svg' width='100%' height='80' viewBox='0 0 10 4' preserveAspectRatio='none'>
                <path d='M 0 0 C 3 0 2 3 5 3 C 8 3 7 0 10 0 L 10 0 L 10 4 L 0 4 L 0 0' fill='${theme.palette.primary.main}' />
              </svg>`
    )}") no-repeat bottom center`,
    backgroundSize: "100% 100%",
    zIndex: 1,
  };
}


export const NavbarContainer = styled(Box)(({ theme }) => ({
    position: "fixed",
    bottom: 0,
    width: "100%",
    height: 80,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px",
  }));
  