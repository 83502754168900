import React from 'react';

import { Grid } from '@mui/material';

import {
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  WhatsappShareButton
} from "react-share";

import CopyToClipboardButton from './CopyToClipboardButton'


import {
  EmailIcon,
  FacebookIcon,
  TelegramIcon,
  WhatsappIcon,
} from "react-share";

const domain = window.location.protocol + '//' + window.location.hostname + '/';




function SharePage(props) {
  const { shareMessage = 'Hey, guarda questo Menù!', hashtag = '#OVerlineMenu', shareUrl = window.location.pathname, children, ...rest } = props
  const url = domain + shareUrl
  return (
    <Grid {...rest} spacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }} style={{ textAlignLast: 'center' }} container justifyContent="center" alignItems="center">

      <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
        <FacebookShareButton url={url} quote={shareMessage} hashtag={hashtag}>
          <FacebookIcon size={40} round={true} />
        </FacebookShareButton>
      </Grid>

      <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
        <WhatsappShareButton url={url} title={shareMessage} >
          <WhatsappIcon size={40} round={true} />
        </WhatsappShareButton>
      </Grid>

      <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
        <EmailShareButton url={url} subject={shareMessage}>
          <EmailIcon size={40} round={true} />
        </EmailShareButton>
      </Grid>

      <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
        <TelegramShareButton url={url} title={shareMessage}>
          <TelegramIcon size={40} round={true} />
        </TelegramShareButton>
      </Grid>

      <Grid item xs={12} >
        <CopyToClipboardButton showIcon copyMessage={'Indirizzo copiato negli appunti'} copyString={url}>
           Copia link
        </CopyToClipboardButton>
      </Grid>


    </Grid>
  )
}

export default SharePage
