import React, {useState } from "react";
import PropTypes from "prop-types";
import {
  TableCell,
  TableRow,
  IconButton
} from "@mui/material";
import { useTheme } from '@mui/material/styles';


import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import OrdersCollapsibleRow from './OrdersCollapsibleRow'
import { borderStyle } from '../style'

export default function OrdersCollapsibleSectionRow({ section, dataSection, roomTableGroup, setDialogOpen,
  setSelectedProductInfo,
  onClickCell }) {

  let productNames = new Set();

  for (let room in dataSection) {
    for (let table in dataSection[room]) {
      let productKeys = Object.keys(dataSection[room][table]);
      for (let productName of productKeys) {
        productNames.add(productName);
      }
    }
  }

  let uniqueProductNames = [...productNames];
  const theme = useTheme();

  const [openSections, setOpenSections] = useState({  [section]: true  });
  const isOpen = openSections[section];

  const toggleSection = (section) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const getTotalQuantity = (roomName, tableName) => {
    if (dataSection?.[roomName]?.[tableName]) {
      const table = dataSection[roomName][tableName];
      let total = 0;
      for (let productName in table) {
        total += table[productName].quantity;
      }
      return total;
    } else {
      return "-"
    }
  };

  const backgroundColor='rgb(101 108 129)'

  return (
    <>
      <TableRow
        sx={{ padding: '0px', margin: '0px' }}
        style={{ backgroundColor:backgroundColor}}
        onClick={() => toggleSection(section)} // 2. Usa la funzione di toggle qui
      >

        <TableCell sx={{ padding: '0px', margin: '0px',color:'white' }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton size="small">
              {isOpen ? <KeyboardArrowUpIcon sx={{color:'white'}}/> : <KeyboardArrowDownIcon sx={{color:'white'}}/>}
            </IconButton>
            <b>{section}</b>
          </div>
        </TableCell>
        {Object.keys(roomTableGroup).map((room) => (
          Object.keys(roomTableGroup[room]).map((table) => (
            <TableCell
              sx={{ padding: '0px', margin: '0px',color:'white' }}
              key={section + "-" + room + "-" + table}
              style={borderStyle}
              align="center"
            >
              <b>{getTotalQuantity(room, table)}</b>  {/*TODO da visualizzare il totale delle quantità di tutti gli item nella stessa stanza e tavolo e sezione*/}
            </TableCell>
          ))
        ))}

      </TableRow>

      {isOpen && 
        uniqueProductNames.map((product_name) => (
          <OrdersCollapsibleRow
            key={product_name}
            dataSection={dataSection}
            product_name={product_name}
            roomTableGroup={roomTableGroup}
            setDialogOpen={setDialogOpen}
            setSelectedProductInfo={setSelectedProductInfo}
            onClickCell={onClickCell}
          />
        ))}
    </>
  );
}

OrdersCollapsibleSectionRow.propTypes =
{
  onClickCell: PropTypes.func

}
