import React from 'react';

import SharePage from './SharePage';
import ModalButton from '../Modals/ModalButton';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import {ButtonBase} from '@mui/material'

function ShareButton(props) {
  const {title='Condividi', shareMessage = 'Hey, guarda questo Menù!', shareUrl, ...rest } = props;

  const isShareApiSupported = navigator.share;

  const handleNativeShare = () => {
    const shareData = {
      title: title,
      text: shareMessage,
      url: shareUrl
    };

    navigator.share(shareData)
      .then(() => console.log('Condivisione riuscita'))
      .catch((error) => console.log('Errore nella condivisione', error));
  };

  return (
    isShareApiSupported ? 
      // Se Web Share API è supportata, mostra un bottone per la condivisione nativa
      <ButtonBase style={{ borderRadius: '50%', width: '40px', height: '40px' }} onClick={handleNativeShare}>

        <ShareRoundedIcon {...rest} />
      </ButtonBase>
      :
      // Altrimenti, mostra il ModalButton
      <ModalButton
        style={{ borderRadius: '50%', width: '40px', height: '40px' }}
        header={'Condividi'}
        body={<SharePage shareMessage={shareMessage} shareUrl={shareUrl} />}
      >
        <ShareRoundedIcon {...rest} />
      </ModalButton>
  );
}

export default ShareButton;
