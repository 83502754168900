import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import {
  DatePicker,
  TimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import BookingInfoCompact from "./BookingInfoCompact";

const BookingDetailsRow = ({
  booking,
  onBookingUpdate,
  onClickBooking,
  onClickPromotion,
  onConfirm = () => {},
  onReject = () => {},
}) => {
  const [bookingData, setBookingData] = useState({ ...booking });
  const [openModal, setOpenModal] = useState(false);

  useEffect(()=>{
    setBookingData(booking)
  },[booking])

  const handleChange = (field) => (event) => {
    setBookingData({ ...bookingData, [field]: event.target.value });
  };

  const handleDateChange = (newDate) => {
    const updatedStart = dayjs(newDate).toDate();
    const duration = dayjs(booking.end).diff(dayjs(booking.start), "minute");
    const updatedEnd = dayjs(updatedStart).add(duration, "minute").toDate();
    setBookingData({ ...bookingData, start: updatedStart, end: updatedEnd });
  };

  const handleTimeChange = (field) => (newTime) => {
    const newDate = dayjs(bookingData[field])
      .set("hour", newTime.hour())
      .set("minute", newTime.minute())
      .toDate();
    setBookingData({ ...bookingData, [field]: newDate });
  };

  const handleSave = () => {
    onBookingUpdate(bookingData);
    setOpenModal(false);
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "0px",
          backgroundColor: "white",
        }}
      >
        {/* Display informazioni compatta */}
        <BookingInfoCompact
          bookingData={bookingData}
          onEdit={handleOpenModal}
          onClickBooking={onClickBooking}
          onClickPromotion={onClickPromotion}
          onConfirm = {onConfirm}
          onReject = {onReject}
        />

        {/* Modale per modifica */}
        <Dialog
          open={openModal}
          onClose={handleCloseModal}
          fullWidth
          maxWidth="xl"
        >
          <DialogTitle>Modifica Prenotazione</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Email"
                  value={bookingData.email}
                  onChange={handleChange("email")}
                  size="small"
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Telefono"
                  value={bookingData.phone}
                  onChange={handleChange("phone")}
                  size="small"
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Partecipanti"
                  type="number"
                  value={bookingData.participants}
                  onChange={handleChange("participants")}
                  size="small"
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <DatePicker
                  label="Data"
                  value={dayjs(bookingData.start)}
                  onChange={handleDateChange}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TimePicker
                  label="Inizio"
                  value={dayjs(bookingData.start)}
                  onChange={handleTimeChange("start")}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TimePicker
                  label="Fine"
                  value={dayjs(bookingData.end)}
                  onChange={handleTimeChange("end")}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  {bookingData.promotion_description || ""}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Note"
                  value={bookingData.special_requests || ""}
                  onChange={handleChange("special_requests")}
                  size="small"
                  variant="standard"
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="secondary">
              Annulla
            </Button>
            <Button onClick={handleSave} variant="contained" color="primary">
              Salva
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </LocalizationProvider>
  );
};

export default BookingDetailsRow;
