import React, { useState, useEffect, useContext } from "react";
import { TextField, Button } from "@mui/material";
import { AlertApiContext } from "../../../../store/AlertApiContext";
import { useParams } from "react-router-dom";

function CustomAiAssistantForm() {
  const [instructions, setInstructions] = useState("");

  const { api_request } = useContext(AlertApiContext);
  const { local_id } = useParams();
  const queryParams = {
    local_id,
  };
  // Carica le istruzioni esistenti all'avvio del componente
  useEffect(() => {
    fetchInstructions();
  }, []);

  // Funzione per ottenere le istruzioni
  const fetchInstructions = async () => {
    try {
      const response = await api_request(
        "GET",
        `/api/ai/custom-ai-assistant-instructions`,
        { queryParams }
      );
      if (response.status === "success") {
        setInstructions(response.data.assistant_instruction);
      }
    } catch (error) {
      console.error("Errore nel recupero delle istruzioni:", error);
    }
  };

  // Funzione per aggiornare le istruzioni
  const updateInstructions = async () => {
    const body = {
      assistant_instruction: instructions,
    };
    try {
      const response = await api_request(
        "PUT",
        `/api/ai/custom-ai-assistant-instructions`,
        { queryParams, body }
      );
    } catch (error) {
      console.error("Errore nell'aggiornamento delle istruzioni:", error);
    }
  };

  return (
    <div style={{padding:'5px'}}>
      <TextField
        label="Istruzioni Assistant AI"
        multiline
        rows={4}
        fullWidth
        variant="outlined"
        value={instructions}
        onChange={(e) => setInstructions(e.target.value)}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={updateInstructions}
        style={{ marginTop: 20 }}
      >
        Salva Istruzioni
      </Button>
    </div>
  );
}

export default CustomAiAssistantForm;
