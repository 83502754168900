import React from "react";
import {
  Stack,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
} from "@mui/material";
import PropTypes from "prop-types";

export default function EyeRadiusSelector({
  selectedCorner,
  setSelectedCorner,
  eyeRadius,
  handleEyeRadiusChange,
}) {
  const corners = [
    {
      label: "Alto Sinistra",
      keyOuter: "outerTopLeft",
      keyInner: "innerTopLeft",
    },
    {
      label: "Alto Destra",
      keyOuter: "outerTopRight",
      keyInner: "innerTopRight",
    },
    {
      label: "Basso Sinistra",
      keyOuter: "outerBottomLeft",
      keyInner: "innerBottomLeft",
    },
  ];

  const selected = corners.find((corner) => corner.keyOuter === selectedCorner);

  return (
    <Stack
      spacing={2}
      sx={{ border: "1px solid #ddd", padding: 2, borderRadius: 2 }}
    >
      <Typography variant="subtitle1">Seleziona Angolo</Typography>

      <RadioGroup
        row
        value={selectedCorner}
        onChange={(e) => setSelectedCorner(e.target.value)}
      >
        {corners.map(({ label, keyOuter }) => (
          <FormControlLabel
            key={keyOuter}
            value={keyOuter}
            control={<Radio />}
            label={label}
          />
        ))}
      </RadioGroup>

      {selected && (
        <>
          <Typography
            variant="subtitle2"
            sx={{ mt: 2 }}
          >{`Angolo ${selected.label}`}</Typography>

          {/* Input per l'eye esterno */}
          <Typography variant="body2">Esterno</Typography>
          <Grid container spacing={2}>
            {["TopLeft", "TopRight", "BottomLeft", "BottomRight"].map((pos) => (
              <Grid item xs={6} md={6}>
                <TextField
                  key={`${selected.keyOuter}${pos}`}
                  label={pos}
                  type="number"
                  value={eyeRadius[`${selected.keyOuter}${pos}`] || ""}
                  onChange={(e) =>
                    handleEyeRadiusChange(
                      `${selected.keyOuter}${pos}`,
                      e.target.value === "" ? 0 : Number(e.target.value)
                    )
                  }
                  inputProps={{ min: 0, max: 50 }}
                  fullWidth
                />
              </Grid>
            ))}
          </Grid>

          {/* Input per l'eye interno */}
          <Typography variant="body2">Interno</Typography>

          <Grid container direction="row" spacing={2} sx={{ mt: 1 }}>
            {["TopLeft", "TopRight", "BottomLeft", "BottomRight"].map((pos) => (
              <Grid item xs={6} md={6}>
                <TextField
                  key={`${selected.keyInner}${pos}`}
                  label={pos}
                  type="number"
                  value={eyeRadius[`${selected.keyInner}${pos}`] || ""}
                  onChange={(e) =>
                    handleEyeRadiusChange(
                      `${selected.keyInner}${pos}`,
                      e.target.value === "" ? 0 : Number(e.target.value)
                    )
                  }
                  inputProps={{ min: 0, max: 50 }}
                  fullWidth
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Stack>
  );
}

EyeRadiusSelector.propTypes = {
  selectedCorner: PropTypes.string.isRequired,
  setSelectedCorner: PropTypes.func.isRequired,
  eyeRadius: PropTypes.object.isRequired,
  handleEyeRadiusChange: PropTypes.func.isRequired,
};
