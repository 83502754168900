import React from 'react';
import { Typography, Paper, Box, List, ListItem, ListItemText, styled } from '@mui/material';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(3),
  minWidth: '80vw',
  backgroundColor: theme.palette.background.paper,
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginTop: theme.spacing(2),
}));

const Paragraph = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
}));

function PrivacyContactPolicy() {
  return (
    <StyledPaper elevation={3}>
      <Typography variant="h5" gutterBottom>
        Informativa sulla Privacy
      </Typography>
      <SectionTitle variant="subtitle1" gutterBottom>
        Titolare del trattamento l'attività che riceverà la richiesta di contatto
      </SectionTitle>
      <SectionTitle variant="subtitle1" gutterBottom>
        Responsabile del trattamento: Overlinemenu | overline.menu@gmail.com
      </SectionTitle>

      <SectionTitle variant="h6">Finalità del trattamento</SectionTitle>
      <List dense>
        <ListItem>
          <ListItemText primary="Ricontattarti in merito alla tua richiesta." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Finalità statistiche e di marketing per migliorare i servizi e le comunicazioni." />
        </ListItem>
      </List>

      <SectionTitle variant="h6">Base giuridica del trattamento</SectionTitle>
      <Paragraph paragraph>Il trattamento dei tuoi dati personali è basato sul tuo consenso esplicito.</Paragraph>

      <SectionTitle variant="h6">Destinatari dei dati</SectionTitle>
      <Paragraph paragraph>I tuoi dati personali saranno accessibili al gestore della piattaforma e all'eventuale locale che stai contattando.</Paragraph>

      <SectionTitle variant="h6">Trasferimento di dati</SectionTitle>
      <Paragraph paragraph>I tuoi dati personali non saranno trasferiti al di fuori dell'Unione Europea.</Paragraph>

      <SectionTitle variant="h6">Diritti dell'utente</SectionTitle>
      <Paragraph paragraph>Hai diritto di accedere, rettificare, cancellare, limitare il trattamento e opporti al trattamento dei tuoi dati personali, oltre a riceverli in un formato strutturato e trasmetterli a un altro titolare del trattamento. Contattaci all'indirizzo email fornito per esercitare i tuoi diritti.</Paragraph>

      <SectionTitle variant="h6">Periodo di conservazione dei dati</SectionTitle>
      <Paragraph paragraph>I tuoi dati personali saranno conservati per il tempo necessario al raggiungimento delle finalità sopra indicate, o per un periodo superiore se previsto dalla legge.</Paragraph>

      <SectionTitle variant="h6">Misure di sicurezza</SectionTitle>
      <Paragraph paragraph>Adottiamo tutte le misure di sicurezza adeguate per proteggere i tuoi dati personali da accessi non autorizzati, uso improprio, divulgazione, alterazione o distruzione. I tuoi dati vengono conservati in database con le ultime tecnologie per mantenere alti standard di sicurezza.</Paragraph>

      <SectionTitle variant="h6">Processo decisionale automatizzato</SectionTitle>
      <Paragraph paragraph>I tuoi dati personali non saranno utilizzati per il processo decisionale automatizzato, compresa la profilazione.</Paragraph>

      <SectionTitle variant="h6">Origine dei dati</SectionTitle>
      <Paragraph paragraph>I tuoi dati personali sono raccolti direttamente da te tramite il form web.</Paragraph>
    </StyledPaper>
  );
}

export default PrivacyContactPolicy;
