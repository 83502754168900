import React from "react";
import { Box, Typography, Button, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { ItemAnimation } from "../../../story-components/components";

const BackToHomePopup = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/");
  };

  return (
    <Box
      sx={{
        margin: "20px",
        borderRadius: "10px",
        padding: "10px",
        position: "fixed",
        bottom: "0",
        zIndex: "100",
      }}
    >
      <ItemAnimation variant="fade" delay={10} oneTime={true} duration={2}>
        <Box className="cookie-banner" sx={{marginLeft:'55px', maxWidth:'160px'}}>
          <Typography variant="body2" align="center" sx={{ mb: 1 }}>
            Vuoi uscire dalla demo?
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<KeyboardArrowRightIcon />}
            onClick={handleNavigate}
            sx={{ mt: 1 }}
          >
            Esci
          </Button>
        </Box>
      </ItemAnimation>
    </Box>
  );
};

export default BackToHomePopup;
