import React, { useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import SubscriptionBanner from "../pages/Locals/Local/Forms/SubscriptionBanner";
import { UserContext } from "../store/UserContext";
import { AlertApiContext } from "../store/AlertApiContext";

const NotificationToggle = () => {
  const { logged, localData, localSubscriptions } = useContext(UserContext);
  const { api_request } = useContext(AlertApiContext);
  const [notificationPermission, setNotificationPermission] = useState(
    typeof Notification !== "undefined" ? Notification.permission : "denied"
  );
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isSupported, setIsSupported] = useState(
    typeof Notification !== "undefined" && "serviceWorker" in navigator
  );

  useEffect(() => {
    if (isSupported) {
      setNotificationPermission(Notification.permission);
    }
  }, [isSupported]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const requestNotificationPermission = () => {
    if (!isSupported) {
      setSnackbarMessage(
        "Le notifiche non sono supportate su questo dispositivo."
      );
      setSnackbarOpen(true);
      return;
    }

    Notification.requestPermission()
      .then((permission) => {
        setNotificationPermission(permission);
        if (permission === "granted") {
          setSnackbarMessage("Notifiche attivate con successo!");
        } else if (permission === "denied") {
          setSnackbarMessage(
            "Le notifiche sono state bloccate. Modifica le impostazioni del tuo browser."
          );
        } else {
          setSnackbarMessage("Le notifiche non sono state abilitate.");
        }
        setSnackbarOpen(true);
      })
      .catch((err) => {
        setSnackbarMessage("Il dispositivo non supporta le notifiche.");
        setSnackbarOpen(true);
      });
  };

  async function updateUserNotification(action) {
    const body = {
      local_id: localData.local_id,
      action,
    };
    const response = await api_request(
      "POST",
      `/api/users/manage_local_user_subscription`,
      { body }
    );
  }

  const toggleNotifications = () => {
    if (notificationPermission === "granted") {
      if (localData.collectUserNotification) {
        updateUserNotification(true);
      }
      // Disattivazione simulata: questa funzionalità non è supportata nativamente dai browser
      setSnackbarMessage(
        "Disattivazione delle notifiche non supportata nativamente dai browser."
      );
      setSnackbarOpen(true);
    } else {
      if (localData.collectUserNotification) {
        updateUserNotification(false);
      }
      requestNotificationPermission();
    }
  };

  return (
    <div>
      {logged && localData.local_id && (
        <>
          {isSupported && notificationPermission !== "granted" ? (
            <>
              <Button
                variant="contained"
                color="primary"
                startIcon={<NotificationImportantIcon />}
                onClick={toggleNotifications}
              >
                {notificationPermission === "granted"
                  ? "Disattiva Notifiche"
                  : "Attiva Notifiche"}
              </Button>
              <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
              />
              <SubscriptionBanner
                key={`local-banner-subscription-notification-${localData.local_id}`}
                local_id={localData.local_id}
                local_subscriptions={localSubscriptions}
                onSubscribe={(value) => updateUserNotification(true)}
                showToggle={false}
                showPopup={localData.collectUserNotification}
              />
            </>
          ) : (
            <SubscriptionBanner
              key={`local-banner-subscription-notification-${localData.local_id}`}
              local_id={localData.local_id}
              local_subscriptions={localSubscriptions}
              onSubscribe={(value) => console.log("value:", value)}
              showToggle={localData.collectUserNotification}
              showPopup={localData.collectUserNotification}
            />
          )}
        </>
      )}
    </div>
  );
};

export default NotificationToggle;
