import React from "react";
import {
  Box,
  IconButton,
  Stack,
  Divider,
  Chip,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import PeopleIcon from "@mui/icons-material/People";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import NotesIcon from "@mui/icons-material/Notes";
import EditIcon from "@mui/icons-material/Edit";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import TableBarIcon from "@mui/icons-material/TableBar";
import Grid3x3RoundedIcon from "@mui/icons-material/Grid3x3Rounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import dayjs from "dayjs";
import { useTheme } from "@mui/material/styles";

const chipStyle = {
  backgroundColor: "white",
  fontSize: "0.875rem",
  color: "#616161",
  height:'auto',
  whiteSpace:'pre-wrap'

};

const formatPhoneNumber = (phone) => {
  const cleaned = ("" + phone).replace(/\D/g, "");
  if (cleaned.length === 10) {
    return `${cleaned.slice(0, 3)} ${cleaned.slice(3, 6)} ${cleaned.slice(6, 10)}`;
  } else {
    return phone;
  }
};

const ChipWithTooltip = ({ icon, label, onClick, alwaysShowLabel = false }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Tooltip title={label} disableHoverListener={!isSmallScreen}>
      <Chip
        icon={icon}
        label={(alwaysShowLabel || !isSmallScreen) && label}
        variant="outlined"
        size="small"
        sx={chipStyle}
        onClick={onClick}
        onMouseDown={(e) => e.stopPropagation()} // Evita che onClick venga chiamato quando si preme e si tiene premuto
      />
    </Tooltip>
  );
};

const BookingInfoCompact = ({
  bookingData,
  onEdit,
  onConfirm = () => {},
  onReject = () => {},
  onClickBooking = () => {},
  onClickPromotion = () => {},
}) => {
  return (
    <Stack
      direction="row"
      divider={<Divider orientation="vertical" flexItem />}
    >
      <Stack direction={{ xs: "column", md: "row" }}>
        {bookingData.status !== "confirmed" && (
          <IconButton
            color="success"
            onClick={() => onConfirm(bookingData)}
            sx={{ marginLeft: "auto" }}
          >
            <CheckCircleIcon />
          </IconButton>
        )}
        <IconButton
          color="primary"
          onClick={onEdit}
          sx={{ marginLeft: "auto" }}
        >
          <EditIcon />
        </IconButton>
        {bookingData.status !== "cancelled" && (
          <IconButton
            color="error"
            onClick={() => onReject(bookingData)}
            sx={{ marginLeft: "auto" }}
          >
            <CancelIcon />
          </IconButton>
        )}
      </Stack>

      <Box
        sx={{
          maxWidth:'100%',
          display: "flex",
          flexWrap: "wrap",
          gap: 1,
          padding: "5px",
          backgroundColor: "white",
          borderRadius: "0px",
          alignItems: "center",
        }}
      >
        <ChipWithTooltip
          icon={<Grid3x3RoundedIcon />}
          label={bookingData._id?.slice(-6)}
          onClick={() => onClickBooking(bookingData._id)}
        />
        <ChipWithTooltip
          icon={<CalendarTodayIcon />}
          label={dayjs(bookingData.start).format("DD/MM/YYYY")}
        />
        <ChipWithTooltip
          icon={<AccessTimeIcon />}
          alwaysShowLabel
          label={`${dayjs(bookingData.start).utc().format("HH:mm")}-${dayjs(bookingData.end).utc().format("HH:mm")}`}
        />

        <ChipWithTooltip
          icon={<PeopleIcon />}
          label={bookingData.participants}
          alwaysShowLabel
        />

        <ChipWithTooltip
          icon={<MeetingRoomIcon />}
          label={bookingData.tableRoom}
        />
        <ChipWithTooltip
          icon={<TableBarIcon />}
          label={bookingData.tableName}
        />

        <ChipWithTooltip
          icon={<EmailIcon />}
          label={bookingData.email}
          onClick={() => window.open(`mailto:${bookingData.email}`, "_blank")}
        />
        <ChipWithTooltip
          icon={<PhoneIcon />}
          label={formatPhoneNumber(bookingData.phone)}
          onClick={() => window.open(`tel:${bookingData.phone}`, "_blank")}
        />

        {bookingData.promotion_id_applied && (
          <ChipWithTooltip
            icon={<LocalOfferIcon />}
            label={bookingData.promotion_description || "N/A"}
            onClick={() => onClickPromotion(bookingData.promotion_id_applied)}
          />
        )}
        {bookingData.special_requests && (
          <ChipWithTooltip
            icon={<NotesIcon />}
            label={bookingData.special_requests}
          />
        )}
      </Box>
    </Stack>
  );
};

export default BookingInfoCompact;
