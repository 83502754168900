import React from "react";

import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import Badge from "@mui/material/Badge";
import { Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useTheme } from "@mui/material/styles";

export default function SpeedDialLocal({ actions, counterNotifications }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => (setOpen(true), console.log("open"));
  const handleClose = () => setOpen(false);
  const theme = useTheme();

  return (
    <React.Fragment>
      <Backdrop
        open={open}
        onClick={handleClose}
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 899, // Assicurati che sia inferiore allo zIndex del SpeedDial
          color: "#fff",
          backgroundColor: "rgba(0, 0, 0, 0.7)", // Sfondo semitrasparente nero
        }}
      />
      <Box
        sx={{
          height: 320,
          transform: "translateZ(0px)",
          flexGrow: 1,
          zIndex: 900,
        }}
      >
        {/* Aggiungi uno stile al Backdrop per oscurare lo sfondo */}

        <SpeedDial
          id={'speed-dial-local-options'}
          ariaLabel="SpeedDial uncontrolled open example"
          sx={{ position: "absolute", bottom: 16, right: 16 }}
          icon={
            <SpeedDialIcon
              icon={
                <Badge badgeContent={counterNotifications} color="error">
                  <AddRoundedIcon />{" "}
                </Badge>
              }
              openIcon={<CloseRoundedIcon />}
            />
          }
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
        >
          {actions.map((action) => {
            if (!action.hide) {
              return (
                <SpeedDialAction
                  tooltipOpen
                  id={`action-${action.name}`}
                  key={action.key}
                  icon={
                    <Badge badgeContent={action.badge} color="error">
                      {action.icon}
                    </Badge>
                  }
                  tooltipTitle={
                    <Typography
                      color="text.paper"
                      variant="body"
                      align="center"
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      {action.name}
                    </Typography>
                  }
                  onClick={action.onClick}
                  FabProps={{
                    sx: {
                      "&:hover": {
                        bgcolor: "secondary.dark",
                        color: "white",
                      },
                      "& .MuiSpeedDialAction-staticTooltipLabel": {
                        width: "200px",
                      },
                    },
                  }}
                />
              );
            }
          })}
        </SpeedDial>
      </Box>
    </React.Fragment>
  );
}
