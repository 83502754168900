import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

import PrivacyPolicy from "./policies/PrivacyPolicy";
import TermsOfService from "./policies/TermsOfService";

function LegalInfo({ open, setOpen, type = "privacy" }) {
  return (
    <div>
      <Dialog
        open={open}
        PaperProps={{
          style: {
            width: "90%",
            maxWidth: "none",
          },
        }}
        sx={{ zIndex: 99999 }}
      >
        <DialogTitle>Informativa</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {type === "privacy" ? <PrivacyPolicy /> : <TermsOfService />}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default LegalInfo;
