import React from "react";
import { QRCode } from "react-qrcode-logo";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { Button, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

// Funzione per calcolare il colore di contrasto
const getContrastColor = (color) => {
  const rgb = color.match(/\d+/g).map(Number);
  const brightness = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
  return brightness > 125 ? "black" : "white";
};

export default function QrCodeComponent({
  baseUrl,
  local_id,
  booking_id,
  local_logo,
  brandName,
  size = 250,
  bgColor = "rgba(255, 255, 255, 0.7)", // Semi-transparent background color
  fgColor,
  qrStyle = "dots",
  eyeRadius,
  logoWidth = 50,
  logoHeight = 50,
  logoOpacity = 1, // Full opacity for the logo
  removeQrCodeBehindLogo = false,
  enableDownload = false,
  optionalDownloadAction = undefined,
  logoPosition = "foreground", // New prop for logo position
  ...props
}) {
  const theme = useTheme();

  const downloadQRCode = () => {
    const canvas = document.querySelector("canvas");
    const url = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.href = url;
    link.download = local_id && booking_id ?`QRCode-${local_id}-${booking_id}.png`: 'QrCode.png';
    link.click();
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        marginBottom: enableDownload && '80px'
      }}
    >
      <div
        style={{
          position: "relative",
          height: `${size + 20}px`,
          width: `${size + 20}px`,
        }}
      >
        {local_logo && logoPosition === "background" && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 0,
            }}
          >
            <img
              src={local_logo}
              alt="logo"
              style={{
                width: `${size + 20}px`,
                height: `${size + 20}px`,
                objectFit: "cover",
                opacity: logoOpacity,
                borderRadius: "5px",
              }}
            />
          </div>
        )}

        <QRCode
          style={{ zIndex: 1, position: "relative" }}
          value={local_id&&booking_id?`${baseUrl}/bookings/${local_id}/${booking_id}`:baseUrl}
          size={size}
          qrStyle={qrStyle}
          bgColor={bgColor}
          fgColor={fgColor || theme.palette.background.paper}
          eyeRadius={
            eyeRadius || [
              { outer: [20, 20, 20, 20], inner: [20, 20, 20, 20] },
              { outer: [20, 20, 20, 20], inner: [20, 20, 20, 20] },
              { outer: [20, 20, 20, 20], inner: [20, 20, 20, 20] },
            ]
          }
          removeQrCodeBehindLogo={removeQrCodeBehindLogo}
          {...props}
        />

        {local_logo && logoPosition === "foreground" && (
          <div
            style={{
              position: "absolute",
              top: `calc(50% - ${logoHeight / 2}px)`,
              left: `calc(50% - ${logoWidth / 2}px)`,
              width: `${logoWidth}px`,
              height: `${logoHeight}px`,
              borderRadius: "50%",
              overflow: "hidden",
              boxShadow: "0 0 5px rgba(0,0,0,0.3)",
              zIndex: 2,
            }}
          >
            <img
              src={local_logo}
              alt="logo"
              style={{
                opacity: logoOpacity,
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </div>
        )}
        {brandName && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              padding: "5px",
              backgroundColor: fgColor || theme.palette.background.paper,
              color: getContrastColor(
                fgColor || theme.palette.background.paper
              ),
              borderRadius: "10px",
              zIndex: 2,
            }}
          >
            <Typography variant="body1" align="center">
              {brandName}
            </Typography>
          </div>
        )}

        {enableDownload && (
          <Button
            fullWidth
            variant="contained"
            sx={{marginTop:'5px'}}
            startIcon={<DownloadIcon />}
            onClick={optionalDownloadAction ? optionalDownloadAction : downloadQRCode}
          >
            Download QR Code
          </Button>
        )}
      </div>
    </div>
  );
}

QrCodeComponent.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  local_id: PropTypes.string,
  booking_id: PropTypes.string,
  local_logo: PropTypes.string,
  brandName: PropTypes.string,
  size: PropTypes.number,
  bgColor: PropTypes.string,
  fgColor: PropTypes.string,
  qrStyle: PropTypes.oneOf(["dots", "squares", "fluid"]),
  eyeRadius: PropTypes.array,
  logoWidth: PropTypes.number,
  logoHeight: PropTypes.number,
  logoOpacity: PropTypes.number,
  removeQrCodeBehindLogo: PropTypes.bool,
  enableDownload: PropTypes.bool,
  logoPosition: PropTypes.oneOf(["foreground", "background"]),
};
