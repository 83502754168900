import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

export const domain =  window.location.protocol + '//' + window.location.hostname + '/';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "overline-404419.firebaseapp.com",
    projectId: "overline-404419",
    storageBucket: "overline-404419.appspot.com",
    messagingSenderId: "21404281215",
    appId: "1:21404281215:web:66b811f378136d780d524a"
  };
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
