import React, { useContext } from "react";
import { UserContext } from "../../store/UserContext";
import { Modal, Box, Button, Typography } from "@mui/material";
import LoginOptions from "./LoginOptions";

const LoginModal = () => {
  const {
    openLoginModal,
    setOpenLoginModal,
  } = useContext(UserContext);


  const handleClose = () => {
    setOpenLoginModal(false);
  };


  return (
        <Modal
      open={openLoginModal}
      onClose={handleClose}
      aria-labelledby="login-modal-title"
      aria-describedby="login-modal-description"
      disableScrollLock={false}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          maxWidth: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <LoginOptions />
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button onClick={handleClose} color="secondary">
            Chiudi
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default LoginModal;
