import React, { useState } from "react";
import { Typography, Tooltip, IconButton } from "@mui/material";
import { Trans } from "react-i18next";

/** icone allergeni **/
import Arachidi from "./images/icons/allergens/Arachidi.png";
import Cereali from "./images/icons/allergens/Cereali_e_derivati.png";
import Crostacei from "./images/icons/allergens/Crostacei.png";
import Uova from "./images/icons/allergens/Uova.png";
import Pesce from "./images/icons/allergens/Pesce.png";
import Soia from "./images/icons/allergens/Soia.png";
import Latte from "./images/icons/allergens/Latte.png";
import Frutta from "./images/icons/allergens/Frutta_a_guscio.png";
import Sedano from "./images/icons/allergens/Sedano.png";
import Senape from "./images/icons/allergens/Senape.png";
import Sesamo from "./images/icons/allergens/Sesamo.png";
import AnidrideSolforosa from "./images/icons/allergens/Anidride_solforosa_e_solfiti.png";
import Lupini from "./images/icons/allergens/Lupini.png";
import Molluschi from "./images/icons/allergens/Molluschi.png";
import Chef from "./images/icons/foodCategories/ChefApproved.png";
import ClickAwayListener from "@mui/material/ClickAwayListener";

/** Icone Categorie **/
import Vegano from "./images/icons/foodCategories/Vegano.png";
import Vegetariano from "./images/icons/foodCategories/Vegetariano.png";
import Proteico from "./images/icons/foodCategories/Proteico.png";
import LowCarb from "./images/icons/foodCategories/Low_Carb.png";
import LowFat from "./images/icons/foodCategories/Low_Fat.png";
import SenzaGlutine from "./images/icons/foodCategories/Senza_Glutine.png";
import LowAlcool from "./images/icons/foodCategories/Low_Alcool.png";
import HighAlcool from "./images/icons/foodCategories/High_Alcool.png";
import NoAlcool from "./images/icons/foodCategories/No_Alcool.png";
import Vitaminico from "./images/icons/foodCategories/Vitaminico.png";
import Congelato from "./images/icons/foodCategories/Congelato.png";
import Amato from "./images/icons/foodCategories/PiuAmato.png";
import Apprezzato from "./images/icons/foodCategories/PiuApprezzato.png";
import DayDish from "./images/icons/foodCategories/PiattoDelGiorno.png";
import TopSell from "./images/icons/foodCategories/PiuVenduto.png";
import Bartender from "./images/icons/foodCategories/BartenderApproved.png";
import Abbattuto from "./images/icons/foodCategories/Abbattuto.png";
import Biologico from "./images/icons/foodCategories/Biologico.png";
import Piccante from "./images/icons/foodCategories/Piccante.png";
import SenzaLattosio from "./images/icons/foodCategories/Senza_Lattosio.png";
import SuggeritoDalPizzaiolo from "./images/icons/foodCategories/ConsigliatoDalPizzaiolo.png";
import SuggeritoDalSommelier from "./images/icons/foodCategories/ConsigliatoDalSommelier.png";

export const allergens = [
  "Cereali e derivati",
  "Crostacei",
  "Uova",
  "Pesce",
  "Arachidi",
  "Soia",
  "Latticini",
  "Frutta a guscio",
  "Sedano",
  "Senape",
  "Sesamo",
  "Anidride solforosa e solfiti",
  "Lupini",
  "Molluschi",
];
export const categoriesFood = [
  "Vegano",
  "Vegetariano",
  "Proteico",
  "Vitaminico",
  "Congelato",
  "Abbattuto",
  "Biologico",
  "Piccante",
  "Low Carb",
  "Low Fat",
  "Senza Glutine",
  "No alcool",
  "Low alcool",
  "High alcool",
  "Il piu amato",
  "Il piu apprezzato",
  "Consigliato dallo chef",
  "Il piu venduto",
  "Piatto del giorno",
  "Suggerito dal bartender",
  "Suggerito Dal Pizzaiolo",
  "Suggerito Dal Sommelier",
  "Senza lattosio",
];

const iconsObject = {
  Arachidi: { icon: Arachidi, text: "Arachidi" },
  Uova: { icon: Uova, text: "Uova" },
  Latticini: { icon: Latte, text: "Latticini" },
  "Cereali e derivati": { icon: Cereali, text: "Cereali e derivati" },
  Crostacei: { icon: Crostacei, text: "Crostacei" },
  Pesce: { icon: Pesce, text: "Pesce" },
  Soia: { icon: Soia, text: "Soia" },
  "Frutta a guscio": { icon: Frutta, text: "Frutta a guscio" },
  Sedano: { icon: Sedano, text: "Sedano" },
  Senape: { icon: Senape, text: "Senape" },
  Sesamo: { icon: Sesamo, text: "Sesamo" },
  "Anidride solforosa e solfiti": {
    icon: AnidrideSolforosa,
    text: "Anidride solforosa e solfiti",
  },
  Lupini: { icon: Lupini, text: "Lupini" },
  Molluschi: { icon: Molluschi, text: "Molluschi" },
  Vegano: { icon: Vegano, text: "Vegano" },
  Vegetariano: { icon: Vegetariano, text: "Vegetariano" },
  Proteico: { icon: Proteico, text: "Proteico" },
  "Low Carb": { icon: LowCarb, text: "Low Carb" },
  "Low Fat": { icon: LowFat, text: "Low Fat" },
  "Senza Glutine": { icon: SenzaGlutine, text: "Senza Glutine" },
  "No alcool": { icon: NoAlcool, text: "No alcool" },
  "Low alcool": { icon: LowAlcool, text: "Low alcool" },
  "High alcool": { icon: HighAlcool, text: "High alcool" },
  Vitaminico: { icon: Vitaminico, text: "Vitaminico" },
  Congelato: {
    icon: Congelato,
    text: "Può contenere ingredienti precongelati",
  },
  Abbattuto: { icon: Abbattuto, text: "Sono presenti ingredienti abbattuti" },
  Biologico: { icon: Biologico, text: "Sono presenti ingredienti biologici" },
  Piccante: { icon: Piccante, text: "Piccante" },
  "Il piu amato": { icon: Amato, text: "Il più amato" },
  "Il piu apprezzato": { icon: Apprezzato, text: "Il più apprezzato" },
  "Consigliato dallo chef": { icon: Chef, text: "Suggerito dallo chef" },
  "Suggerito dal bartender": {
    icon: Bartender,
    text: "Suggerito dal bartender",
  },
  "Piatto del giorno": { icon: DayDish, text: "Piatto del giorno" },
  "Il piu venduto": { icon: TopSell, text: "Il più venduto" },
  "Suggerito Dal Pizzaiolo": {
    icon: SuggeritoDalPizzaiolo,
    text: "Suggerito dal pizzaiolo",
  },
  "Suggerito Dal Sommelier": {
    icon: SuggeritoDalSommelier,
    text: "Suggerito dal sommelier",
  },
  "Senza lattosio": { icon: SenzaLattosio, text: "Senza lattosio" },
};

export function RenderIcon({ iconName, backgroundColor, color, showText }) {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const typographyStyle = {
    padding: showText === true ? "0px 5px 0px 5px" : "3px",
    backgroundColor: backgroundColor,
    color: color,
    borderRadius: "20px",
    margin: "5px 5px 0px 0px",
    whiteSpace: "nowrap",
  };

  if (iconsObject[iconName] != undefined) {
    return (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          title={<Trans>{iconsObject[iconName].text}</Trans>}
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          onClick={handleTooltipOpen}
        >
          <IconButton
            sx={{ boxShadow: 2, width: "25px", height: "25px", margin: "0px" }}
            variant="subtitle1"
            component="span"
            style={typographyStyle}
          >
            <img
              style={{
                boxShadow: 2,
                width: "20px",
                height: "20px",
                margin: "0px",
              }}
              loading="lazy"
              src={iconsObject[iconName].icon}
              onContextMenu={(event) => event.preventDefault()}
              alt={iconsObject[iconName].text}
            />

            <Trans>{showText === true && iconsObject[iconName].text}</Trans>
          </IconButton>
        </Tooltip>
      </ClickAwayListener>
    );
  } else {
    return (
      <>
        {iconsObject[iconName] !== undefined && (
          <Typography
            sx={{ boxShadow: 2 }}
            variant="subtitle1"
            component="span"
            style={typographyStyle}
          >
            {iconsObject[iconName].text}
          </Typography>
        )}
      </>
    );
  }
}
