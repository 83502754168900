import React, { useEffect, useState, useRef } from 'react';
import {
    Grid, ButtonBase
} from '@mui/material';
import PropTypes from "prop-types";
import { motion } from 'framer-motion';
import Table from './Table';

export default function RoomView({ room: initialRoom, draggable, shapeView = true, handleDragEnd = (values, coordinates) => console.log('coordinates:', coordinates), handleTableClick }) {
    const [localRoom, setLocalRoom] = useState(initialRoom);

    useEffect(() => {
        console.log('set newInitialRoom:', initialRoom)
        setLocalRoom(initialRoom);
    }, [initialRoom]);

    const accordionDetailsRef = useRef(null);
    const [isLongPress, setIsLongPress] = useState(false);
    const [dragStartPoint, setDragStartPoint] = useState(null);

    const handleDragStart = (_, info) => {
        setDragStartPoint(info.point);
    };

    let pressTimer;

    const handleMouseDown = () => {
        pressTimer = window.setTimeout(() => {
            setIsLongPress(true);
        }, draggable ? 250 : 2000);
    };

    const handleMouseUp = () => {
        clearTimeout(pressTimer);
    };

    const handleClick = (table) => {
        if (!isLongPress) {
            handleTableClick(table);
        }
        setIsLongPress(false);
    };

    const handleTableDragEnd = (table, point) => {
        if (!dragStartPoint) return;

        const dx = point.x - dragStartPoint.x;
        const dy = point.y - dragStartPoint.y;

        const tableX = table.x || 0;
        const tableY = table.y || 0;

        const finalX = tableX + dx;
        const finalY = tableY + dy;

        handleDragEnd(table, { x: finalX, y: finalY });
    };

    return (
        <div style={{ overflowX: 'auto', position: 'relative' }}>
            <div className='inc-shadow' ref={accordionDetailsRef} style={{padding:shapeView?'0px':'15px 5px 15px 5px', height: shapeView?'500px':'auto', width: shapeView?'950px':'auto', background: '#e2e2e2', position: 'relative' }}>
                {shapeView && localRoom.initialTables.map((table, tableIndex) => (


                    <motion.div
                        key={tableIndex + table.x + table.y}
                        drag={draggable}
                        shapeView
                        dragConstraints={accordionDetailsRef}
                        onDragStart={handleDragStart}
                        onDragEnd={(_, info) => handleTableDragEnd(table, info.point)}
                        initial={{ x: table.x || 0, y: table.y || 0 }}
                        dragMomentum={false}
                        style={{ position: 'absolute', zIndex: tableIndex }}
                    >
                        <ButtonBase
                            onMouseDown={handleMouseDown}
                            onMouseUp={handleMouseUp}
                            onClick={() => handleClick(table)}
                        >
                            <Table {...table} />
                        </ButtonBase>
                    </motion.div>
                ))}

                {!shapeView &&
                    <Grid container spacing={1}>
                        {localRoom.initialTables
                            .sort((a, b) => a.tableName.localeCompare(b.tableName)) // Ordina in base a tableName
                            .map((table, tableIndex) => (
                                <Grid item key={tableIndex + table.x + table.y}>
                                    <ButtonBase
                                        onMouseDown={handleMouseDown}
                                        onMouseUp={handleMouseUp}
                                        onClick={() => handleClick(table)}
                                    >
                                        <Table {...table} shapeView={false} />
                                    </ButtonBase>
                                </Grid>
                            ))
                        }
                    </Grid>
                }




            </div>
        </div>
    );
}

RoomView.propTypes = {
    room: PropTypes.object,
    draggable: PropTypes.bool,
    handleDragEnd: PropTypes.func,
    handleTableClick: PropTypes.func,
    shapeView: PropTypes.bool
};
