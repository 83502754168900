import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, Box, ButtonGroup, ClickAwayListener, Dialog, List, ListItem, Stack, Typography } from '@mui/material';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTranslation } from "react-i18next";

const ButtonGroupComponent = ({ optionName, options, selected, setSelected }) => {

    const [open, setOpen] = useState(false);

    const handleToggle = () => setOpen((prevOpen) => !prevOpen);
    const handleClose = () => setOpen(false);

    return (
        <React.Fragment>
            <ButtonGroup style={{ width: '100%' }} variant="contained" aria-label="split button">
                <Button onClick={handleToggle} fullWidth>{optionName + ': ' + selected.name}</Button>
                <Button
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>

            <Dialog
                open={open}
                onClose={handleClose}
                sx={{zIndex:1501}}
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <List style={{ maxHeight: '90vh', overflowY: 'auto' }}>
                        {options.map((option) => (
                            <ListItem
                                button
                                key={option.name}
                                selected={option.name === selected.name}
                                onClick={() => { setSelected(option); handleClose(); }}
                            >
                                <Stack justifyContent="space-between"
                                    alignItems="center"
                                    spacing={1}
                                    sx={{width:'100%'}}
                                    direction='row' >
                                        <Typography>
                                        {option.name}
                                        </Typography>
                                        <Typography variant='body2'>
                                        {(option.price > 0 ? ` +${option.price}€` : ` ${option.price}€`)}

                                        </Typography>

                                </Stack>

                            </ListItem>
                        ))}
                    </List>
                </ClickAwayListener>
            </Dialog>
        </React.Fragment>
    );
}

const SelectAlternativeIngredient = ({ productIngredientsDefault, onChange }) => {


    const { i18n } = useTranslation();
    const language = i18n.language ? i18n.language.toLowerCase() : 'it';

    const [productIngredients, setProductIngredients] = useState([...productIngredientsDefault])
    const ingredientOptions = [...new Set(productIngredients.map(ingredient => ingredient.ingredientOption[language] || ingredient.ingredientOption.it))];
    const [selectedIngredients, setSelectedIngredients] = useState(
        ingredientOptions.reduce((acc, option) => {
            const ingredient = productIngredients.find(ingredient => (ingredient.ingredientOption[language] || ingredient.ingredientOption.it) === option);
            acc[option] = { name: ingredient.ingredientName[language] || ingredient.ingredientName.it, price: ingredient.ingredient_price_variation_on_add };
            return acc;
        }, {})
    );

    const generateResult = useCallback(() => {
        let result = {};

        productIngredients.forEach((ingredient) => {
            const option = ingredient.ingredientOption[language] || ingredient.ingredientOption.it;
            const name = ingredient.ingredientName[language] || ingredient.ingredientName.it;
            const ingredient_id = ingredient.ingredient_id
            const priceVariation = ingredient.ingredient_price_variation_on_add;

            if (name === selectedIngredients[option].name) {
                result[ingredient_id] = {
                    ingredientOption: ingredient.ingredientOption,
                    ingredient_id: ingredient_id,
                    ingredientName: ingredient.ingredientName,
                    priceVariation: priceVariation,
                    quantity: 1
                };
            }
        });

        return result;
    }, [productIngredients, selectedIngredients]);

    const isMounted = useRef(false);
    useEffect(() => {
        if (isMounted.current) {
            onChange(generateResult());
        } else {
            isMounted.current = true;
        }
    }, [selectedIngredients]);



    const handleClickMenuItem = (optionName, ingredient) => {
        setSelectedIngredients(prevSelected => ({
            ...prevSelected,
            [optionName]: ingredient
        }));
    }

    return (
        <div>
            {ingredientOptions.map((optionName) => {
                const options = productIngredients.filter(
                    (ingredient) => (ingredient.ingredientOption[language] || ingredient.ingredientOption.it) === optionName
                ).map((ingredient) => ({ name: ingredient.ingredientName[language] || ingredient.ingredientName.it, price: ingredient.ingredient_price_variation_on_add }));

                return (
                    <Box key={optionName} sx={{ padding: '5px' }}>
                        <ButtonGroupComponent
                            key={optionName}
                            optionName={optionName}
                            options={options}
                            selected={selectedIngredients[optionName]}
                            setSelected={(ingredient) => handleClickMenuItem(optionName, ingredient)}
                        />
                    </Box>
                );
            })}
        </div>
    );
}

export default SelectAlternativeIngredient;

