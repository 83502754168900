import React, { useState } from 'react'
import { Stack, Divider, Card, CardActionArea, CardContent, Grid, Box, Rating, Typography, ButtonBase } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTheme } from '@mui/material/styles';
import ThumbDownAltRoundedIcon from '@mui/icons-material/ThumbDownAltRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import './OrderCard.css'
import PropTypes from 'prop-types';
import {convertLocalDate, safeRound} from '../Functions/FormatValues'



function OrderCard(props) {
    const { local_name = 'Nome locale', order_id = 1, datetime_order_started = '12/06/2022', order_rating_value = 4, order_status_id = 0, order_price = 0,  variant = 'user',order_completed,order_rejected, onClick, ...rest } = props
    const theme = useTheme();

    function getHeaderColor(order_status_id) {
        if (order_status_id === -1) {
            return '#c83232'
        } else {
            if (order_status_id === 0) {
                return theme.palette.secondary.dark
            } else {
                return theme.palette.primary.dark
            }
        }
    }

    function StatusOrder({ order_status_id}) {
        if (order_rejected) {
            return (<ThumbDownAltRoundedIcon />)
        }
        if (order_completed) {
            return (<CheckRoundedIcon />)
        } else {
            return (<SettingsRoundedIcon className='icn-spinner' />)
        }


    }

    function ReturnStatus(order_status_id) {
        if (order_rejected) {
            return 'Rifiutato'
        }
        if (order_completed) {
            return 'Completato'
        } else {
            return 'In corso'
        }
    }


    return (
        <Card {...rest} id={`order-card-${order_id}`}>
            <CardActionArea onClick={onClick}>
                <Stack spacing={0} direction='row' divider={<Divider orientation={'vertical'} flexItem />}>
                    <Stack direction='column' style={{ width: '100%' }}>

                        <Grid container spacing={1} direction='row' justifyContent="space-between"   alignItems="center" style={{  backgroundColor: getHeaderColor(order_status_id), color: 'white', padding: '5px' }} >
                            <Grid item>
                                <Stack direction='row' spacing={1} style={{ alignItems: 'center', width: '100%' }}>
                                    <StatusOrder order_status_id={order_status_id}  />
                                    <Typography variant="h6" component="h6">
                                        #{order_id.slice(-6)}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item  style={{ textAlign: 'right' }}>
                                <Typography style={{whiteSpace:'nowrap'}} variant="h6" component="h6">
                                    {safeRound(order_price,2)} €
                                </Typography>
                            </Grid>


                        </Grid>

                        <Stack spacing={{ xs: 0, sm: 1 }} direction={{ xs: 'column', sm: 'row' }} style={{ padding: '5px' }} divider={<Divider orientation={'vertical'} flexItem />}>
                            {variant === 'user' &&
                                <Typography variant="body" >
                                    <b>Presso: </b>{local_name}
                                </Typography>
                            }
                            <Typography variant="body" >
                                <b>{ReturnStatus(order_status_id)}: </b>{convertLocalDate(datetime_order_started)}
                            </Typography>

                            {order_status_id === 1 &&
                                <>
                                    {(order_rating_value === undefined | order_rating_value === null) ?
                                        <Typography variant="body" >
                                            <b>Da valutare</b>
                                        </Typography> :
                                        <Rating name="rating" value={order_rating_value} precision={0.5} readOnly />

                                    }
                                </>}

                            <Stack />


                        </Stack>


                    </Stack>
                    <Stack style={{ alignSelf: 'center', textAlign: 'center' }} >
                        <ChevronRightIcon />
                    </Stack>
                </Stack>
            </CardActionArea>

        </Card>
    )
}

export default OrderCard

OrderCard.propTypes = {
    order_status_id: PropTypes.number,

}
