import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  DialogContentText
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import UploadImage from '../../Forms/UploadImage';

const SubSectionEditDialog = ({
  open,
  onClose,
  subSection,
  onDeleteSubSection,
  onSave
}) => {
  const [newName, setNewName] = useState(subSection.subSectionName.it);
  const [newDescription, setNewDescription] = useState(subSection.subSectionDescription?.it);
  const [newCover, setNewCover] = useState(subSection.subSectionCover);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const handleSave = () => {
    onSave({
      ...subSection,
      subSectionName: { it: newName },
      subSectionDescription: {it: newDescription},
      subSectionCover: newCover
    });
    onClose();
  };

  const handleSectionCover = (value) => {
    setNewCover(value || ' ');
  };

  const openConfirmDeleteDialog = () => {
    setConfirmDeleteOpen(true);
  };

  const closeConfirmDeleteDialog = () => {
    setConfirmDeleteOpen(false);
  };

  const handleConfirmDelete = () => {
    onDeleteSubSection();
    closeConfirmDeleteDialog();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Modifica Sotto-Sezione</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              sx={{marginTop:'10px'}}
              fullWidth
              label="Nome"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Descrizione"
              multiline
              rows={4}
              value={newDescription || ''}
              onChange={(e) => setNewDescription(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <UploadImage defaultValue={newCover} disableAlpha={false} onUpload={handleSectionCover} onUrl={handleSectionCover} maxSize={0.5 * 1024 * 1024} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button startIcon={<DeleteForeverIcon />} onClick={openConfirmDeleteDialog} color="error">
          Elimina
        </Button>
        <Button onClick={handleSave} color="primary">
          Salva
        </Button>
      </DialogActions>

      {/* Dialog di conferma per la cancellazione */}
      <Dialog open={confirmDeleteOpen} onClose={closeConfirmDeleteDialog}>
        <DialogTitle>Conferma cancellazione</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sei sicuro di voler eliminare questa sotto-sezione? Questa azione è irreversibile.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmDeleteDialog} color="primary">
            Annulla
          </Button>
          <Button onClick={handleConfirmDelete} color="error">
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default SubSectionEditDialog;
