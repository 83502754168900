import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Card,
  Skeleton,
  Box,
  Tab,
  Zoom,
  Tabs,
  AppBar
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import { AnimatePresence, motion } from "framer-motion";
import ProductsMenuSection from "./ProductsMenuSection";
import { getContrastColors } from "../../Functions/Colors";
import {
  calculateVisibilityCounts,
  filterSectionsWithAvailableElements,
} from "./MenuFunctions";
import VerticalMenuTabs from "./menu-components/VerticalMenuTabs";


export default function MenuTab({
  basket,
  setBasket,
  getSectionCover = () => {},
  getProductCover,
  section_names = {},
  sectionsOrders = [],
  productsIdHidden = [],
  menuData,
  isStaff = false,
  card_variant_selected = 'default',
  card_animation_variant_selected,
  menuCardShadow,
  menuAddProductDialogFullscreen,
  menuAddProductDialogAnimation,
  menuVariant,
}) {
  const [tabValue, setTabValue] = useState(0);
  const [tabDirection, setTabDirection] = useState(1);
  const [sectionsOrdersFiltered, setSectionsOrdersFiltered] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleChangeTab = (event, newValue) => {
    setTabDirection(tabValue < newValue ? 1 : -1);
    setTabValue(newValue);
  };

  const theme = useTheme();

  const [responsiveStyle, setResponsiveStyle] = useState({
    xs: 12,
    sm: 12,
    md: 6,
    lg: 4,
    xl: 3,
  });
  useEffect(() => {
    if (
      (card_variant_selected === "image") |
      (card_variant_selected === "glass-effect")
    ) {
      setResponsiveStyle({ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 });
    } else {
      setResponsiveStyle({ xs: 12, sm: 12, md: 6, lg: 4, xl: 3 });
    }
  }, [card_variant_selected]);

  const [containerHeight, setContainerHeight] = useState(0);
  const refContainer = useRef();

  useEffect(() => {
    if (refContainer.current) {
      setContainerHeight(refContainer.current.offsetHeight);
    }
  }, [tabValue]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentSection = document.getElementById(`section-${tabValue}`);
      if (currentSection) {
        const sectionHeight = currentSection.clientHeight;
        setContainerHeight(sectionHeight);
      }
    }, 1000); // Controlla ogni secondo

    // Pulizia
    return () => clearInterval(intervalId);
  }, [tabValue]);

  const tabContextRef = useRef(null);
  useEffect(() => {
    const secondCheck = () => {
      if (tabContextRef.current) {
        const distanceTop =
          tabContextRef.current.getBoundingClientRect().top + window.scrollY;
        if (tabValue > 0 || isExpanded) {
          window.scrollTo({ top: distanceTop, behavior: "smooth" });
        }
      }
    };

    // Utilizza un timeout per dare tempo al componente di montarsi
    const timeoutId = setTimeout(secondCheck, 0);

    // Pulizia del timeout al smontaggio del componente
    return () => clearTimeout(timeoutId);
  }, [tabValue, isExpanded]);

  const slideVariants = {
    enter: (direction) => ({
      x: tabDirection > 0 ? "100%" : "-100%", // Entra da destra se avanti, altrimenti da sinistra
      opacity: 0,
      transition: { duration: 0.5 },
      zIndex: 1,
    }),
    center: {
      x: 0,
      opacity: 1,
      transition: { duration: 0.5 },
      zIndex: 1,
    },
    exit: {
      scale: 0.5,
      opacity: 0,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
      zIndex: 0,
    },
  };

  const zoomVariants = {
    enter: {
      scale: 0.1,
      opacity: 0,
    },
    center: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.7,
        ease: "easeOut",
      },
    },
    exit: {
      scale: 0.9,
      opacity: 0,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  };

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  // Variabile per tracciare la posizione iniziale dello swipe
  const [startX, setStartX] = useState(0);

  // Gestore per l'inizio del trascinamento
  const handleDragStart = (event, info) => {
    setStartX(info.point.x);
  };

  // Gestore per la fine del trascinamento
  const handleDragEnd = (event, info) => {
    const endX = info.point.x;
    const threshold = 50; // Soglia di sensibilità per lo swipe, regolabile
    if (startX - endX > threshold) {
      // Swipe verso sinistra
      if (tabValue < sectionsOrdersFiltered.length - 1) {
        setTabDirection(1);
        setTabValue(tabValue + 1);
      }
    } else if (endX - startX > threshold) {
      // Swipe verso destra
      if (tabValue > 0) {
        setTabDirection(-1);
        setTabValue(tabValue - 1);
      }
    }
  };

  // Funzione per filtrare sectionsOrders

  // Chiamata della funzione
  useEffect(() => {
    const filteredSectionsOrders = filterSectionsWithAvailableElements(
      sectionsOrders,
      menuData,
      productsIdHidden
    );
    setSectionsOrdersFiltered(filteredSectionsOrders);
  }, [sectionsOrders]);

  const indicatorColor = theme.palette.getContrastText(
    theme.palette.primary.dark
  );

  const tabsRef = useRef(null);
  const [tabsHeight, setTabsHeight] = useState(35);

  useEffect(() => {
    if (tabsRef.current) {
      setTabsHeight(tabsRef.current.offsetHeight);
    }
  }, [tabValue, sectionsOrdersFiltered]);

  const AntTabs = {
    "& .MuiTabs-indicator": {
      height: tabsHeight - 10 + "px",
      marginBottom: "5px",
      backgroundColor: indicatorColor,
      opacity: "20%",
      borderRadius: "20px",
      transition: "0.5s ease-in-out",
    },
    "&.Mui-selected": {
      textColor: "black", // Modifica questo con il codice colore desiderato
    },
  };

  return (
    <Box ref={tabContextRef}>
      {menuVariant === "etichetta-fissa" ? (
        <VerticalMenuTabs
          sectionsOrdersFiltered={sectionsOrdersFiltered}
          tabValue={tabValue}
          handleChangeTab={handleChangeTab}
          handleToggleExpand={handleToggleExpand}
          isExpanded={isExpanded}
          section_names={section_names}
          menuData={menuData}
          productsIdHidden={productsIdHidden}
        />
      ) : (
        <AppBar
          position="static"
          style={{ position: "sticky", top: 0, zIndex: 10 }}
        >
          <Tabs
            ref={tabsRef}
            value={tabValue}
            sx={{ ...AntTabs }}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            textColor="inherit"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable tabs menu"
          >
            {sectionsOrdersFiltered.map((section_id, index) => {
              const { elementsShowed, elementsAvaiable } =
                calculateVisibilityCounts(
                  menuData,
                  section_id,
                  productsIdHidden
                );

              return (
                <Tab
                  sx={{
                    maxWidth: "50vw",
                    display: !elementsAvaiable && "none",
                    color: tabValue === index ? indicatorColor : "inherit",
                    fontWeight: tabValue === index && "bold",
                  }}
                  key={section_id}
                  label={section_names[section_id].section_name}
                />
              );
            })}
          </Tabs>
        </AppBar>
      )}
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: containerHeight,
          backgroundColor: (() => {
            const keys = Object.keys(section_names);
            if (keys.length > tabValue) {
              const currentKey =
                sectionsOrdersFiltered[tabValue] ?? "undefined";
              if (section_names.hasOwnProperty(currentKey)) {
                return section_names[currentKey]?.section_color || "#ffffff00";
              } else {
                return "#ffffff00";
              }
            }
            return "#ffffff00"; // Colore di default nel caso in cui tabValue non viene trovato
          })(),
          transition: "background-color 1.5s ease-in-out", // Aggiunge una transizione
        }}
      >
        <AnimatePresence initial={false}>
          {sectionsOrdersFiltered.map((section_id, index) => {
            if (index !== tabValue) return null;
            const section_values = section_names[section_id];
            const section_name = section_values.section_name;
            const section_color = section_values.section_color;
            const section_card_color = section_values.section_card_color;
            const section_color_contrast = section_color
              ? getContrastColors("#ffffff", section_color)
              : undefined;
            const direction = tabValue > index ? 1 : -1;

            return (
              <motion.div
                ref={index === tabValue ? refContainer : null}
                id={`section-${tabValue}`}
                key={section_id}
                custom={tabValue > index ? 1 : -1} // 1 per avanti, -1 per indietro
                variants={
                  menuVariant === "etichetta-fissa"
                    ? zoomVariants
                    : slideVariants
                }
                initial="enter"
                animate="center"
                exit="exit"
                direction={direction}
                drag="x"
                dragConstraints={{ left: 0, right: 0 }}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                style={{
                  position: "absolute",
                  width: "100%",
                }} // Assicurati che il div occupi tutto lo spazio necessario
              >
                <Box key={"tab-panel-" + section_id}>
                  {Object.keys(menuData).length === 0 ? (
                    <div>
                      {[[1], [2], [3], [4], [5], [6], [7], [8], [9], [10]].map(
                        ([value]) => (
                          <Zoom
                            key={"zoom-card-skeleton-" + value}
                            timeout={500}
                            in={true}
                            style={{
                              transitionDelay: `${50 * value}ms`,
                            }}
                            unmountOnExit
                          >
                            <Card style={{ padding: "5px", margin: "5px" }}>
                              <Skeleton variant="text" />
                              <Skeleton
                                variant="rectangular"
                                width={"100%"}
                                height={getRandomInt(50, 120)}
                              />
                            </Card>
                          </Zoom>
                        )
                      )}
                    </div>
                  ) : (
                    <Box
                      sx={{
                        padding: "0px",
                        //background: section_color,
                        minHeight: "100vh",
                      }}
                    >
                      {/* Contenitore per l'immagine di sfondo */}

                      <ProductsMenuSection
                        sectionMenuData={menuData[section_id]}
                        section_description={section_values.section_description}
                        section_id={section_id}
                        productsIdHidden={productsIdHidden}
                        isStaff={isStaff}
                        basket={basket}
                        setBasket={setBasket}
                        section_color={section_color}
                        section_color_contrast={section_color_contrast}
                        section_card_color={section_card_color}
                        section_name={section_name}
                        section_cover={section_values.section_cover}
                        card_variant_selected={card_variant_selected}
                        card_animation_variant_selected={
                          card_animation_variant_selected
                        }
                        getProductCover={getProductCover}
                        getSectionCover={getSectionCover}
                        responsiveStyle={responsiveStyle}
                        menuCardShadow={menuCardShadow}
                        menuAddProductDialogFullscreen={
                          menuAddProductDialogFullscreen
                        }
                        menuAddProductDialogAnimation={
                          menuAddProductDialogAnimation
                        }
                      />
                    </Box>
                  )}
                </Box>
              </motion.div>
            );
          })}
        </AnimatePresence>
      </Box>
    </Box>
  );
}

MenuTab.propTypes = {
  sectionsOrders: PropTypes.array,
  section_names: PropTypes.object,
  productsIdHidden: PropTypes.array,
  menuData: PropTypes.object,
  isStaff: PropTypes.bool,
  menuDataIngredients: PropTypes.object,
  card_variant_selected: PropTypes.string,
  getProductCover: PropTypes.func,
};

