import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import {
  Button,
  Stack,
  ButtonBase,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { productSteps } from "../../story-components/Products/ProductFunctions";
import { convertLocalDate } from "../Functions/FormatValues";
import NumericInput from "../Forms/NumericInput";

import DoneIcon from "@mui/icons-material/Done";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { saveToLocal, loadFromLocal } from "../Functions/storageUtils";

import "./style.css";
import "../Style/Loading.css";

async function getTableBasket(ordersData, table_room, table_name) {
  let combinedBasket = [];
  let uniqueId = 0; // Aggiunto un indice incrementale

  // Loop through each order
  for (let orderId in ordersData) {
    const order = ordersData[orderId];

    // Check if the order's table_name matches the given table_name
    if (order.table_name === table_name && order.table_room === table_room) {
      for (let item of order.basket) {
        // Retrieve the section_name based on the section_id of the current item
        let sectionNameObj = order.basket_sections.find(
          (section) => section.section_id === item.section_id
        );

        // If the section is found, add its name to the item
        let sectionName = sectionNameObj
          ? sectionNameObj.section_name.it
          : "Sezione non trovata";

        // If not, add the product to the combinedBasket
        combinedBasket.push({
          ...item,
          sectionName: sectionName, // Adding the section name
          orderId: orderId,
          data: order.datetime_order_started,
          user_name: order.user_name,
          id: uniqueId++,
        });
      }
    }
  }

  return combinedBasket;
}

export default function TableOrders({
  table_name,
  table_room,
  ordersData,
  onCompleteOrders,
  onChange = (values) => {
    console.log("onChange:", values);
  },
}) {
  let [products, setProducts] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [allProductsCompleted, setAllProductsCompleted] = useState(false);
  const [ordersPaid, setOrdersPaid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const prevProductsRef = useRef("");

  async function getProducts() {
    const basketForTable = await getTableBasket(
      ordersData,
      table_room,
      table_name
    );
    setProducts(basketForTable);
    console.log("basketForTable:", basketForTable);

    setIsLoading(false);
  }

  useEffect(() => {
    getProducts();
  }, [ordersData, table_name, table_room]);

  useEffect(() => {
    setAllProductsCompleted(
      products.every((product) => product.product_status === 4)
    );
    setOrdersPaid(
      products.every((product) => product.quantityPaid === product.quantity)
    );
    //todo aggiungere controllo se tutti i quantity sono guali a quantityPaid allora setOrderPaid=True altrimenti false
  }, [products]);

  function transformArrayToOrdersObject(array) {
    // Filtra gli elementi con "edited: true"
    const editedItems = array.filter((item) => item.edited === true);

    // Usa "reduce" per accumulare gli elementi nell'oggetto desiderato
    return editedItems.reduce((accumulator, item) => {
      if (!accumulator[item.orderId]) {
        accumulator[item.orderId] = {
          order_status_id: 1, // Metti il valore che desideri qui
          basket: [],
        };
      }

      accumulator[item.orderId].basket.push({
        product_status: item.product_status,
        product_index: item.product_index,
        quantityPaid: item.quantityPaid,
        edited: true,
      });

      return accumulator;
    }, {});
  }

  useEffect(() => {
    let timer;

    // Verifica se i prodotti sono cambiati
    const currentProductsStr = JSON.stringify(products);
    if (prevProductsRef.current === currentProductsStr) {
      return;
    }

    // Aggiorna il riferimento con il valore corrente dei prodotti
    prevProductsRef.current = currentProductsStr;

    // Avvia un nuovo timer ogni volta che i prodotti cambiano
    timer = setTimeout(() => {
      onChange(transformArrayToOrdersObject(products));
    }, 2 * 1000); // 2 secondi

    // Pulizia: cancella il timer se i prodotti cambiano
    return () => {
      clearTimeout(timer);
    };
  }, [products, onChange]);

  const handleNext = (params) => {
    // Qui puoi fare ciò che vuoi con le informazioni della riga
    let product_status = products[params.row.id].product_status || 0;
    if (product_status < 4) {
      product_status = product_status + 1;
      products[params.row.id].product_status = product_status;
      products[params.row.id].edited = true;
      setProducts([...products]);
    }
  };

  const handleBack = (params) => {
    // Qui puoi fare ciò che vuoi con le informazioni della riga
    let product_status = products[params.row.id].product_status || 0;
    if (product_status > 0) {
      product_status = product_status - 1;
      products[params.row.id].product_status = product_status;
      products[params.row.id].edited = true;
      setProducts([...products]);
    }
  };

  const [columnsState, setColumnsState] = useState(
    loadFromLocal("columnsState", {})
  );
  const handleColumnVisibilityChange = (params) => {
    console.log('columnsState["data"]:', columnsState["data"]);
  };

  const columns = [
    {
      field: "data",
      headerName: "Data",
      width: 60,
      renderCell: (params) => (
        <div>
          {params.row.data ? convertLocalDate(params.row.data, "HH:mm") : "N/D"}
        </div>
      ),
    },
    {
      field: "orderId",
      headerName: "ID Ordine",
      width: 90,
      renderCell: (params) => <div>{params.row.orderId.slice(-6)}</div>,
    },
    {
      field: "user_name",
      headerName: "Ordinante",
      width: 90,
      renderCell: (params) => <div>{params.row.user_name}</div>,
    },
    {
      field: "sectionName",
      headerName: "Sezione",
      width: 90,
      renderCell: (params) => <div>{params.row.sectionName}</div>,
    },
    {
      field: "product_name",
      headerName: "Nome Prodotto",
      width: 150,
      renderCell: (params) => <div>{params.row.product_name.it}</div>,
    },
    {
      field: "product_status",
      headerName: "Stato prodotto",
      width: 200,
      cellClassName: "noPaddingAndMargin",
      renderCell: (params) => (
        <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
          {params.row.product_status > 0 && (
            <ButtonBase
              variant="outlined"
              sx={{
                borderRadius: "5px",
                backgroundColor: productSteps[params.row.product_status]?.color,
                color: "black",
                border: "solid 1px grey",
              }}
              onClick={() => handleBack(params)}
            >
              {productSteps[params.row.product_status]?.icon}
            </ButtonBase>
          )}
          <Button
            fullWidth
            variant="outlined"
            onClick={() => handleNext(params)}
            sx={{
              backgroundColor: productSteps[params.row.product_status]?.color,
              color: "black",
              border: "solid 1px grey",
            }}
          >
            {productSteps[params.row.product_status]?.text || "In attesa"}
          </Button>
        </Stack>
      ),
    },
    {
      field: "product_price",
      headerName: "€/u",
      flex: 1,
      renderCell: (params) => <div>{params.row.product_price}</div>,
    },
    {
      field: "quantity",
      headerName: "Qtà",
      type: "number",
      flex: 1,
      renderCell: (params) => <div>{params.row.quantity}</div>,
    },
    {
      field: "quantityPaid",
      headerName: "Q.tà pagate",
      width: 60,
      cellClassName: "noPaddingAndMargin",
      renderCell: (params) => (
        <NumericInput
          showHelper={false}
          value={params.row.quantityPaid}
          onChange={(newValue) => {
            setProducts((prev) =>
              prev.map((product) =>
                product.id === params.row.id
                  ? { ...product, quantityPaid: newValue, edited: true }
                  : product
              )
            );
          }}
          max={params.row.quantity}
          min={0}
        />
      ),
    },
    {
      field: "product_paid",
      headerName: "Pagato",
      width: 60,
      renderCell: (params) => {
        const style =
          params.row.quantityPaid === params.row.quantity
            ? {
                backgroundColor: "#bdeabd",
                width: "100%",
                height: "100%",
                textAlignLast: "center",
              }
            : {};

        return (
          <div style={style}>
            {params.row.quantityPaid && params.row.quantityPaid !== ""
              ? params.row.product_price * params.row.quantityPaid
              : 0}
          </div>
        );
      },
    },
    {
      field: "Totale da pagare",
      headerName: "Tot. €",
      flex: 1,
      renderCell: (params) => (
        <div>{params.row.product_price * params.row.quantity}</div>
      ),
    },
  ];

  async function handleCompleteOrders() {
    await onChange(transformArrayToOrdersObject(products));

    const orderIds = Array.from(
      new Set(products.map((product) => product.orderId))
    );
    onCompleteOrders(orderIds);
  }

  function handlePaidOrder() {
    // Create a deep copy of products to manipulate.

    // Iterate over each product object in the products array.
    for (const product of products) {
      let quantity = product.quantity || 0;

      if (quantity > 0) {
        product.quantityPaid = quantity;
        product.edited = true;
      }
    }

    // Update the state.
    setProducts([...products]);
  }

  function handleProductsCompleted() {
    // Iterate over each product object in the products array.
    for (const product of products) {
      product.product_status = 4;
      product.edited = true;
    }
    setProducts([...products]);
  }

  return (
    <Box>
      {products.length > 0 ? (
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={4}>
            <Button
              startIcon={
                allProductsCompleted ? (
                  <CheckBoxIcon />
                ) : (
                  <CheckBoxOutlineBlankIcon />
                )
              }
              onClick={handleProductsCompleted}
              color={allProductsCompleted ? "success" : "primary"}
              variant="contained"
              fullWidth
            >
              Consegnato
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Button
              startIcon={
                ordersPaid ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />
              }
              onClick={handlePaidOrder}
              color={ordersPaid ? "success" : "primary"}
              variant="contained"
              fullWidth
            >
              Ordini pagati
            </Button>
          </Grid>
          <Grid item xs={12} md={4}>
            <Button
              fullWidth
              startIcon={<DoneIcon />}
              variant="contained"
              disabled={!allProductsCompleted || !ordersPaid}
              onClick={handleCompleteOrders}
            >
              Concludi
            </Button>
          </Grid>
          <Grid item xs={12}>
            <div style={{ height: "100%", width: "100%" }}>
              <DataGrid
                density="compact"
                rows={products}
                columns={columns}
                pageSizeOptions={[5, 10]}
                getRowId={(row) => row.id}
                onCellClick={() => console.log("cell click")}
                hideFooter
                getRowClassName={(params) => {
                  return params.row.edited ? "effect-wave" : "";
                }}
                onSelectionModelChange={(newSelection) => {
                  console.log(newSelection.selectionModel);
                }}
                onColumnVisibilityModelChange={handleColumnVisibilityChange}
                onCellEditStart={(params, event) => {
                  console.log("onEdit start", params);
                }}
                onCellEditStop={(params, event) => {
                  console.log("onEdit stop", params);
                }}
                onCellKeyDown={(params, event) => {
                  console.log("keyout:", params);
                }}
              />
            </div>
          </Grid>
        </Grid>
      ) : (
        <>
          {isLoading ? (
            <Typography variant="h4">Caricamento...</Typography>
          ) : (
            <Typography variant="h4">
              Non ci sono ordini attivi al tavolo
            </Typography>
          )}
        </>
      )}
    </Box>
  );
}

TableOrders.propTypes = {
  onChange: PropTypes.func,
  onCompleteOrders: PropTypes.func,
  table_name: PropTypes.string,
  table_room: PropTypes.string,
  ordersData: PropTypes.object,
};
