import React from "react";
import "./style.css";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";


export default function LoadingAnimations({
  variant = "bouncing-ball",
  color = undefined,
  ...rest
}) {
  const theme = useTheme();
  const commonStyles = {
    backgroundColor: color === undefined ? theme.palette.primary.light : color,
  };

  if (variant === "bouncing-ball") {
    return (
      <div className="bouncing-ball-container" {...rest}>
        <div className="bouncing-ball" style={{ ...commonStyles }}></div>
        <div className="bouncing-ball" style={{ ...commonStyles }}></div>
        <div className="bouncing-ball" style={{ ...commonStyles }}></div>
      </div>
    );
  }

  if (variant === "rotating-square") {
    return (
      <div className="rotating-square-container" {...rest}>
        <div className="rotating-square" style={commonStyles}></div>
      </div>
    );
  }

  if (variant === "pulsing-circle") {
    return (
      <div className="pulsing-circle-container" {...rest}>
        <div className="pulsing-circle" style={commonStyles}></div>
      </div>
    );
  }

  if (variant === "circular-ball") {
    return (
      <div style={{ padding: "50px" }} {...rest}>
        <div className="circular-ball-container">
          <div
            className="circular-ball"
            style={{
              ...commonStyles,
              backgroundColor: color && theme.palette.primary.light,
            }}
          ></div>
          <div
            className="circular-ball"
            style={{
              ...commonStyles,
              backgroundColor: color && theme.palette.primary.main,
            }}
          ></div>
          <div
            className="circular-ball"
            style={{
              ...commonStyles,
              backgroundColor: color && theme.palette.primary.dark,
            }}
          ></div>
        </div>
      </div>
    );
  }
  return null;
}

LoadingAnimations.propTypes = {
  variant: PropTypes.oneOf([
    "bouncing-ball",
    "rotating-square",
    "pulsing-circle",
    "circular-ball",
  ]),
  color: PropTypes.string,
};
