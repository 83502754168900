import React from "react";
import { Typography, Stack, Box, Grid } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import PlanCard from "./PlanCard";

import { useNavigate, Link } from "react-router-dom";
import { ItemAnimation } from "../../../../story-components/components";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const featuresZeroData = [
  { activity: "QRCODE Menù", avaiable: true },
  { activity: "Gestione allergeni", avaiable: true },
  { activity: "Menù multilingua (trad. auto e manuale)", avaiable: true },
  { activity: "Filtraggio menù per il cliente", avaiable: true },
  { activity: "Riferimenti social, tel. e whatsapp", avaiable: true },
  { activity: "Inserimento fino a 50 prodotti", avaiable: true },
  { activity: "Menù facilmente trovabile sul web", avaiable: true },
  { activity: "Sempre aggiornabile", avaiable: true },
];

export const featuresStarterData = [
  { activity: "Inserimento prodotti senza limiti", avaiable: true },
  { activity: "Aggiunta di immagini a prodotti e sezioni", avaiable: true },
  { activity: "Aggiunta di descrizioni a prodotti e sezioni", avaiable: true },
  { activity: "Sotto sezioni dei prodotti", avaiable: true },
  { activity: "Pagina web dedicata", avaiable: true },
  { activity: "Gestione ingredienti", avaiable: true },
  { activity: "Controllo visibilità prodotti", avaiable: true },
  { activity: "Assistenza clienti", avaiable: true },
];

export const featuresBusinessData = [
  { activity: "Personalizzazione stile menù", avaiable: true },
  { activity: "Orari di apertura e calendario", avaiable: true },
  { activity: "Gestione staff", avaiable: true },
  { activity: "Fino a 5 menù per fasce orarie", avaiable: true },
  { activity: "Form dedicato per prenotazioni o informazioni", avaiable: true },
  { activity: "Monitoraggio sale/tavoli", avaiable: true },
  { activity: "Dashboard analisi menù", avaiable: true },
  { activity: "Consulente IA", avaiable: true },
];

export const featuresPremiumData = [
  { activity: "Gestione ordini multi-modali", avaiable: true },
  { activity: "Delivery", avaiable: true },
  { activity: "Take Away", avaiable: true },
  { activity: "Pagamenti in-app", avaiable: true },
  { activity: "Promozioni, coupon e gift card", avaiable: true },
  { activity: "Automazione marketing", avaiable: true },
  { activity: "Gestione sale, tavoli e prenotazioni", avaiable: true },
  { activity: "Monitoraggio scorte e materiali consumabili", avaiable: true },
  { activity: "Dashboard avanzata per analisi dati", avaiable: true },
  { activity: "Consulente IA dedicato", avaiable: true },
  { activity: "Food pairing automatico per i tuoi clienti", avaiable: true },
];

const dataPlans = [
  {
    name: "Zero",
    subtitleLabel:'Gratis per sempre',
    subtitle:
      "Piano per avere subito un menù facile da utilizzare con l'essenziale.",
    price: "0.00",
    active: true,
    features: featuresZeroData,
    planColor: "#78787890",
  },
  {
    name: "Starter",
    price: "19.99",
    promo: true,
    annualPrice: (
      <Box>
        <Typography
          style={{ textDecoration: "line-through", color: "white" }}
          variant="body"
        >
          240€{" "}
        </Typography>{" "}
        <Typography variant="body" style={{ color: "white" }}>
          179.99€
        </Typography>
      </Box>
    ),
    active: true,
    features: featuresStarterData,
    subtitle: "Tutte le funzionalità del piano Zero, in più:",
    planColor: "rgb(87 156 150 / 85%)",
  },
  {
    name: "Business",
    price: "29.99",
    promo: true,
    annualPrice: (
      <Box>
        <Typography
          style={{ textDecoration: "line-through", color: "white" }}
          variant="body"
        >
          360€{" "}
        </Typography>{" "}
        <Typography variant="body" style={{ color: "white" }}>
          249.99€
        </Typography>
      </Box>
    ),
    active: true,
    subtitle: "Tutte le funzionalità del piano Starter, in più:",
    features: featuresBusinessData,
    planColor: "rgb(77 114 177 / 90%)",
    recommended: true,
  },
  {
    name: "Premium",
    price: undefined,
    price2: undefined,
    active: false,
    subtitle: "Tutte le funzionalità del piano Business, in più:",
    features: featuresPremiumData,
    planColor: "rgb(161 0 125 / 90%)",
    description: undefined,
  },
];

// Funzione generica per rappresentare le caratteristiche di un piano
const PlanDescription = ({ title, description, nextPlan, features }) => {
  return (
    <Box
      sx={{ padding: "20px", backgroundColor: "#f4f4f4", borderRadius: "8px" }}
    >
      {description}

      {/* Griglia per le funzionalità */}
      <Grid
        container
        spacing={1}
        sx={{
          marginTop: "5px",
          background: "#fbfbfb",
          padding: "5px",
          borderRadius: "5px",
        }}
      >
        {features.map((feature, index) => (
          <Grid item sm={12} md={6} lg={4} xl={3} key={index}>
            <Typography variant="body2" sx={{ marginBottom: "8px" }}>
              ✔️ {feature.activity}
            </Typography>
          </Grid>
        ))}
      </Grid>

      {nextPlan}
    </Box>
  );
};

export const PlanZeroDescription = () => {
  const description = (
    <Typography variant="body1" sx={{ marginBottom: "12px" }}>
      Inizia gratuitamente con il <strong>Piano ZERO</strong> e digitalizza la
      tua attività. Offri ai clienti un menù <strong>dinamico</strong>{" "}
      accessibile tramite <strong>QR Code</strong>, aumentando la tua{" "}
      <strong>visibilità sui motori di ricerca</strong>. Gestisci facilmente{" "}
      <strong>allergeni</strong>, crea un <strong>menù multilingua</strong> con
      traduzioni automatiche e manuali, e collega i tuoi{" "}
      <strong>social, telefono e WhatsApp</strong>. Iscrizione e interruzione
      sempre <strong>gratuite</strong>.
    </Typography>
  );
  const nextPlan = (
    <Typography
      variant="body2"
      sx={{ marginTop: "10px", fontStyle: "oblique" }}
    >
      Per funzionalità avanzate come <strong>Pagina web dedicata</strong>,
      gestione <strong>ingredienti</strong>, controllo{" "}
      <strong>visibilità prodotti</strong>, e una{" "}
      <strong>dashboard di analisi</strong>, considera i piani superiori come{" "}
      <Link to="/Subscribe/Starter">Starter</Link>,{" "}
      <Link to="/Subscribe/Business">Business</Link>, o{" "}
      <Link to="/Subscribe/Premium">Premium</Link>.
    </Typography>
  );
  return (
    <PlanDescription
      title="Piano ZERO"
      description={description}
      nextPlan={nextPlan}
      features={featuresZeroData}
    />
  );
};

export const PlanStarterDescription = () => {
  const description = (
    <Typography variant="body1" sx={{ marginBottom: "12px" }}>
      Il <strong>Piano Starter</strong> espande il piano Zero, eliminando i suoi
      limiti e permettendo la <strong>personalizzazione</strong> del menù
      digitale, inclusa la rimozione della firma a fine menù. Potrai aggiungere{" "}
      <strong>immagini</strong> e descrizioni, suddividere il menù in{" "}
      <strong>sotto-sezioni</strong> e creare una{" "}
      <strong>pagina web dedicata</strong>. Inoltre, avrai il pieno controllo su{" "}
      <strong>ingredienti</strong> e <strong>visibilità dei prodotti</strong> in
      tempo reale.
    </Typography>
  );
  const nextPlan = (
    <Typography
      variant="body2"
      sx={{ marginTop: "10px", fontStyle: "oblique" }}
    >
      Per funzionalità ancora più avanzate come la gestione delle{" "}
      <strong>sale e tavoli</strong>, il monitoraggio con{" "}
      <strong>dashboard di analisi</strong>, e un <strong>consulente IA</strong>
      , esplora i nostri piani <Link to="/Subscribe/Business">Business</Link>, o{" "}
      <Link to="/Subscribe/Premium">Premium</Link>.
    </Typography>
  );
  return (
    <PlanDescription
      title="Piano Starter"
      description={description}
      nextPlan={nextPlan}
      features={featuresStarterData}
    />
  );
};

export const PlanBusinessDescription = () => {
  const description = (
    <Typography variant="body1" sx={{ marginBottom: "12px" }}>
      Il <strong>Piano Business</strong> include tutte le funzionalità del piano
      Starter, con l'aggiunta di <strong>personalizzazione del menù</strong>,
      gestione degli <strong>orari di apertura</strong> tramite un{" "}
      <strong>calendario dettagliato</strong>, e monitoraggio di{" "}
      <strong>sale e tavoli</strong>. Potrai gestire lo <strong>staff</strong>,
      offrire fino a 5 menù per <strong>fasce orarie</strong>, e accedere a una{" "}
      <strong>dashboard avanzata</strong> e un <strong>consulente IA</strong>{" "}
      per supportare la tua attività con <strong>food pairing</strong> e altro
      ancora.
    </Typography>
  );

  const nextPlan = (
    <Typography
      variant="body2"
      sx={{ marginTop: "10px", fontStyle: "oblique" }}
    >
      Per una gestione ancora più completa, il Piano{" "}
      <Link to="/Subscribe/Premium">Premium</Link> offre gestione{" "}
      <strong>ordini multi-modali</strong>, <strong>pagamenti in-app</strong>, e
      funzionalità di <strong>delivery, take away e prenotazioni</strong>, oltre
      a promozioni e coupon.
    </Typography>
  );

  return (
    <PlanDescription
      title="Piano Business"
      description={description}
      nextPlan={nextPlan}
      features={featuresBusinessData}
    />
  );
};

export const PlanPremiumDescription = () => {
  const description = (
    <Typography variant="body1" sx={{ marginBottom: "12px" }}>
      Il <strong>Piano Premium</strong> ti offre il massimo controllo sulla tua
      attività, con la gestione degli <strong>ordini</strong> dallo{" "}
      <strong>staff</strong> o direttamente dai <strong>clienti</strong>,{" "}
      <strong>delivery</strong> e <strong>take away</strong>. Includi{" "}
      <strong>pagamenti in-app</strong> per una gestione fluida delle
      transazioni e offri ai tuoi clienti{" "}
      <strong>promozioni, coupon e gift card</strong>. Gestisci le tue{" "}
      <strong>sale e prenotazioni</strong> con precisione e monitora le{" "}
      <strong>scorte</strong> con facilità. Approfitta di una{" "}
      <strong>dashboard avanzata</strong> e di un{" "}
      <strong>consulente IA dedicato</strong> per ottimizzare la tua offerta.
    </Typography>
  );
  return (
    <PlanDescription
      title="Piano Premium"
      description={description}
      features={featuresPremiumData}
    />
  );
};

function Features({ featuresData, color = "rgba(0, 109, 36, 1)" }) {
  return (
    <Stack direction="column" style={{ width: "fit-content" }}>
      {featuresData.map((item, index) => {
        return (
          <Stack direction="row" key={index}>
            <CheckCircleIcon style={{ color: 'white', backgroundColor:color,borderRadius:'50%',marginRight:'3px',fontSize:'20px'}} />
            <Typography align="left" variant="body1">
              {item.activity}
            </Typography>
          </Stack>
        );
      })}
    </Stack>
  );
}

export default function Plans() {
  let navigate = useNavigate();

  return (
    <Stack
      spacing={{ xs: 0, md: 1 }}
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ padding: "5px" }}
    >
      <ItemAnimation
        variant="down"
        delay={0}
        oneTime={true}
        duration={1}
        style={{ textAlign: "center" }}
      >
        <Typography variant="body" color="white" align="center">
          (Abbonamenti mensili, rinnovo automatico)
        </Typography>
      </ItemAnimation>

      <Stack
        direction={{ xs: "column", lg: "row" }}
        spacing={{ xs: 2, md: 2, xl: 3 }}
      >
        {dataPlans.map((item) => (
          <PlanCard
            key={`wide-${item.name}`}
            subtitleLabel = {item.subtitleLabel}
            title={item.name}
            promo={item.promo} 
            style={{ maxWidth: "400px", minWidth: "22vw" }}
            subtitle={item.subtitle}
            active={item.active}
            price={item.price}
            annualPrice={item.annualPrice}
            planColor={item.planColor}
            price2={item.price2}
            onBuy={() =>
              item.active
                ? navigate(`/Subscribe/${item.name}`)
                : navigate(`/contact_us`)
            }
            description={item.description}
            features={
              <Features featuresData={item.features} color={item.planColor} />
            }
            recommended={item.recommended}
          />
        ))}
      </Stack>
    </Stack>
  );
}
