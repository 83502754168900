import React from 'react';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';


function DecimalInput(props) {
    const handleChange = (e) => {
        const val = e.target.value;

        // Controllo per assicurarsi che ci siano al massimo 2 decimali
        if (val.includes('.')) {
            const decimalParts = val.split('.');
            if (decimalParts[1].length > 2) {
                return;  // Non aggiornare il valore se supera i 2 decimali
            }
        }

        // Chiama onChange esterno, se fornito
        if (props.onChange) {
            props.onChange(e);
        }
    };

    return (
        <TextField
            {...props}
            onChange={handleChange}
            inputProps={{
                ...props.inputProps,
                step: 1.00,  // permette incrementi di 0.01 quando si utilizzano le frecce su/giù
                type: 'number',
                pattern: "\\d*\\.?\\d{0,2}"  // Regex per limitare a 2 decimali
            }}
        />
    );
}

export default DecimalInput;


DecimalInput.propTypes = {
    onChange: PropTypes.func,
}
