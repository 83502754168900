import React, { useState } from 'react'
import './ImageCard.css'
import { Typography, ButtonBase } from '@mui/material';
import { getImageCover } from '../Functions/Images'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';


function ImageCard(props) {

    const ImageButton = styled(ButtonBase)(({ theme }) => ({
        position: 'relative',
        height: 200,
        [theme.breakpoints.down('sm')]: {
            width: '100% !important', // Overrides inline-style
            height: 100,
        },
        '&:hover, &.Mui-focusVisible': {
            zIndex: 1,
            '& .MuiImageBackdrop-root': {
                opacity: 0.15,
            },
            '& .MuiImageMarked-root': {
                opacity: 0,
            },
            '& .MuiTypography-root': {
                border: '4px solid currentColor',
            },
        },
    }));

    const ImageSrc = styled('span')({
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        borderRadius: '5px',
        backgroundPosition: 'center 40%',
    });

    const Image = styled('span')(({ theme }) => ({
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
    }));

    const ImageBackdrop = styled('span')(({ theme }) => ({
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0.6,
        transition: theme.transitions.create('opacity'),
    }));

    const ImageMarked = styled('span')(({ theme }) => ({
        height: 3,
        width: 18,
        backgroundColor: 'white',
        borderRadius: '5px',
        position: 'absolute',
        bottom: -2,
        left: 'calc(50% - 9px)',
        transition: theme.transitions.create('opacity'),
    }));

    const [loading, setLoading] = useState(false)
    const delay = ms => new Promise(res => setTimeout(res, ms));


    async function onClickDefault() {
        await delay(5000);
    }

    async function handleClick() {
        await onClick()

    }

    const {local_address = 'Indirizzo...', imgUrl = 'https://mui.com/static/images/cards/paella.jpg',children, onClick = onClickDefault, ...rest } = props
    return (

            <ImageButton
                style={{ borderRadius: '5px', width: '100%' }}
                focusRipple
                onClick={() => handleClick()}>

                <ImageSrc style={{ backgroundImage: `url(${getImageCover(imgUrl)})` }} />

                <ImageBackdrop sx={{ boxShadow: 3 }} style={{ borderRadius: '5px' }} className="MuiImageBackdrop-root" />

                <Image>
                    <Typography
                        component="h4"
                        variant="h4"
                        color="inherit"
                        sx={{
                            position: 'relative',
                            p: 4,
                            pt: 2,
                            pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                        }}
                    >
                        {children}
                        <ImageMarked className="MuiImageMarked-root" />
                    </Typography>
                </Image>

            </ImageButton>

    )
}

export default ImageCard

ImageCard.propTypes = {
    local_address: PropTypes.string,  // Indirizzo locale
    imgUrl: PropTypes.string,         // URL dell'immagine
    children: PropTypes.node,         // Contenuto o elementi figli, ad esempio il testo da visualizzare
    onClick: PropTypes.func           // Funzione chiamata al click sull'immagine
};



