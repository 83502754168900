import React, { useState, useEffect } from "react";
import "./cookieBanner.css"; // Per aggiungere stile personalizzato
import {
  Button,
  Typography,
  Box,
  Modal,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Link } from "react-router-dom";
import { ItemAnimation } from "../story-components/components";
const ga_id = "G-Y03LYRFEHS";
const gtag_id = "AW-16549231968";
const CONSENT_EXPIRATION_TIME = 6 * 30 * 24 * 60 * 60 * 1000;

const CookieBanner = () => {
  const [cookieConsent, setCookieConsent] = useState(false);
  const [openPreferences, setOpenPreferences] = useState(false);
  const [analyticsConsent, setAnalyticsConsent] = useState(true); // Preselezionata

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    const consentDate = localStorage.getItem("cookieConsentDate");
    const googleCookieExists = checkCookieExists("_ga");

    const now = new Date().getTime();

    if (
      !consent ||
      (consentDate && now - consentDate > CONSENT_EXPIRATION_TIME) ||
      (!googleCookieExists && consent === "true")
    ) {
      localStorage.removeItem("cookieConsent");
      localStorage.removeItem("cookieConsentDate");
      setCookieConsent(null);
    } else if (consent === "true" && googleCookieExists) {
      setCookieConsent(true);
      setAnalyticsConsent(true);
      enableGoogleTag();
    } else if (consent === "false") {
      setCookieConsent(false);
      disableGoogleAnalytics();
    }
  }, []);

  const handleAcceptAllCookies = () => {
    localStorage.setItem("cookieConsent", "true");
    localStorage.setItem("cookieConsentDate", new Date().getTime());
    setCookieConsent(true);
    setAnalyticsConsent(true);
    enableGoogleTag();
  };

  const handleSavePreferences = () => {
    localStorage.setItem("cookieConsent", analyticsConsent ? "true" : "false");
    localStorage.setItem("cookieConsentDate", new Date().getTime());
    setCookieConsent(true);
    if (analyticsConsent) {
      enableGoogleTag();
    } else {
      disableGoogleAnalytics();
    }
    setOpenPreferences(false);
  };

  const validPaths = [
    "/",
    "/Home",
    "/home",
    "/Tutorials",
    "/contact_us",
    "/subscriptions",
  ];

  if (!validPaths.includes(window.location.pathname)) {
    return null;
  }

  return (
    <>
      {cookieConsent === null && (
        <Box
          sx={{
            margin: "0px",
            borderRadius: "10px",
            padding: "10px",
            position: "fixed",
            bottom: "0",
            zIndex: "100",
          }}
        >
          <Box className="cookie-banner">
            <Typography variant="body2" sx={{ margin: "10px" }}>
              Per un'esperienza ottimale, utilizziamo i cookie in conformità al
              GDPR. Accetta tutti i cookie o gestisci le tue preferenze. Scopri
              di più nella nostra{" "}
              <Link style={{ color: "white" }} to="/privacy-policy">
                privacy-policy
              </Link>
            </Typography>
            <Box
              className="cookie-banner-buttons"
              sx={{
                padding: "5px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => setOpenPreferences(true)}
                className="preferences-button"
              >
                Preferenze
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={handleAcceptAllCookies}
                className="accept-button"
                sx={{ marginLeft: "auto" }} // Spostato a destra
              >
                Accetta
              </Button>
            </Box>
          </Box>
          <Modal
            open={openPreferences}
            onClose={() => setOpenPreferences(false)}
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxWidth: 400,
                width:'80vw',
                bgcolor: "background.paper",
                borderRadius: "10px",
                boxShadow: 24,
                p: 4,
              }}
            >
              <Typography variant="h6" sx={{ mb: 2 }}>
                Gestisci le preferenze dei cookie
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Utilizziamo cookie per raccogliere dati di utilizzo del sito e
                per migliorare la tua esperienza. Puoi scegliere di accettare
                tutti i cookie o gestire le tue preferenze.
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={analyticsConsent}
                    onChange={() => setAnalyticsConsent(!analyticsConsent)}
                  />
                }
                label="Consentire i cookie per migliorare l'esperienza e le statistiche del sito."
              />
              <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSavePreferences}
                >
                  Salva preferenze
                </Button>
              </Box>
            </Box>
          </Modal>
        </Box>
      )}
    </>
  );
};
// Funzione per abilitare Google Analytics 4 (GA4)
const enableGoogleTag = () => {
  const script = document.createElement("script");
  script.src = `https://www.googletagmanager.com/gtag/js?id=${ga_id}`;
  script.async = true;
  document.head.appendChild(script);

  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", gtag_id);
    gtag("config", ga_id, { anonymize_ip: true });
  };
};

// Disabilitare Google Analytics (Consenso non accettato)
const disableGoogleAnalytics = () => {
  window[`ga-disable-${ga_id}`] = true;
};

// Funzione per verificare se il cookie di Google Analytics esiste
const checkCookieExists = (cookieName) => {
  return document.cookie
    .split(";")
    .some((item) => item.trim().startsWith(`${cookieName}=`));
};

export default CookieBanner;
