import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Divider,
  ButtonBase,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

function AIProductCard({ product }) {
  const { new_product } = product;

  const { local_id } = useParams();

  let navigate = useNavigate();

  return (
    <ButtonBase
      onClick={() =>
        navigate(
          `/local/edit_product/local/${local_id}/product/${new_product.product_id}`
        )
      }
    >
      <Card sx={{ maxWidth: 345, m: 1 }}>
        <CardContent>
          {new_product.section_name?.it && (
            <>
              <Typography variant="h5" component="div">
                Nome sezione: {new_product.section_name.it}
              </Typography>
              <Divider sx={{ my: 1 }} />
            </>
          )}
          {new_product.sub_Section_name?.it && (
            <>
              <Typography variant="h5" component="div">
                Nome sottosezione: {new_product.sub_Section_name.it}
              </Typography>
              <Divider sx={{ my: 1 }} />
            </>
          )}
          {new_product.product_name?.it && (
            <>
              <Typography variant="h5" component="div">
                Nome prodotto: {new_product.product_name.it}
              </Typography>
              <Divider sx={{ my: 1 }} />
            </>
          )}
          {new_product.product_description?.it && (
            <>
              <Typography variant="body2" color="text.secondary">
                Descrizione: {new_product.product_description.it}
              </Typography>
              <Divider sx={{ my: 1 }} />
            </>
          )}
          {new_product.product_perfect_service?.it && (
            <>
              <Typography variant="body2" color="text.secondary">
                Servizio: {new_product.product_perfect_service.it}
              </Typography>
              <Divider sx={{ my: 1 }} />
            </>
          )}
          {new_product.product_storytelling?.it && (
            <>
              <Typography variant="body2" color="text.secondary">
                Storytelling: {new_product.product_storytelling.it}
              </Typography>
              <Divider sx={{ my: 1 }} />
            </>
          )}
        </CardContent>
      </Card>
    </ButtonBase>
  );
}

export default AIProductCard;
