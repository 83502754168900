import React from "react";
import Chart from "react-apexcharts";
import { Skeleton, Card, Typography, Box } from "@mui/material";
import PropTypes from "prop-types";
import './chartstyle.css'

function formatNumber(value) {
  // Formatta il numero con il punto come separatore delle migliaia e la virgola come separatore dei decimali
  const options = { minimumFractionDigits: 0, maximumFractionDigits: 2 };
  try {
    const formattedNumber = value.toLocaleString("it-IT", options);
    if (Number.isInteger(value)) {
      return formattedNumber.split(",")[0]; // Restituisci solo la parte intera se il numero è intero
    } else {
      return formattedNumber;
    }
  } catch {
    return value;
  }
}

const MyApexChart = ({
  isLoading,
  height = 350,
  tooltipLabel = "views",
  showToolbar = false,
  series,
  horizontal = false,
  stacked = true,
  showDataLabels = true,
  categories,
  type = "area",
  title,
  yTitle,
  xTitle,
  color = "#161716",
  colors = ["#161716"],
  monochrome = true,
  useSecondYAxis = false,
  ...rest
}) => {
  const options = {
    chart: {
      type: type,
      height: 350,
      zoom: {
        enabled: showToolbar, // Abilita lo zoom solo se la barra degli strumenti è visibile
        type: "x",
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: "zoom",
        show: showToolbar, // Imposta questa proprietà su false per nascondere la barra degli strumenti
      },
      stacked: stacked,
    },
    dataLabels: {
      enabled: showDataLabels, // Usa la prop showDataLabels per controllare la visualizzazione

      style: {
        fontSize: "12px",
        fontWeight: "bold",
        colors: ["#000"],
      },
      background: {
        enabled: true,
        foreColor: "#fff", // Colore del testo
        borderRadius: 2,
        padding: 4,
        opacity: 0.9,
        borderWidth: 1,
        borderColor: "#000", // Colore del bordo, può essere uguale al colore del testo per coerenza
      },
    },
    labels: categories,
    plotOptions: {
      bar: {
        horizontal: horizontal, // Questo rende le barre orizzontali
        stacked: stacked,
      },
    },
    xaxis: {
      categories: categories,
      title: {
        text: xTitle,
      },
    },

    yaxis: useSecondYAxis
      ? [
          {
            labels: {
              formatter: function (val) {
                return formatNumber(val);
              },
            },
            title: {
              text: yTitle,
            },
          },
          {
            opposite: true,
            labels: {
              formatter: function (val) {
                return formatNumber(val);
              },
            },
            title: {
              text: "Secondary Axis",
            },
          },
        ]
      : {
          labels: {
            formatter: function (val) {
              return formatNumber(val);
            },
          },
          title: {
            text: yTitle,
          },
        },
    theme: {
      monochrome: {
        enabled: monochrome,
        color: color,
        shadeTo: "light",
        shadeIntensity: 0.65,
      },
    },
    theme: {
      monochrome: {
        enabled: monochrome,
        color: color, // Your base color
        shadeTo: "light", // 'light' or 'dark'
        shadeIntensity: 0.65, // 0 to 1
      },
    },
    colors: colors,
    tooltip: {
      theme: "dark", // Imposta il tema del tooltip a scuro, che rende il testo bianco
      y: {
        formatter: function (val) {
          return `${formatNumber(val)} ${tooltipLabel}`;
        },
      },
      x: {
        formatter: function (val) {
          return formatNumber(val);
        },
      },
    },
  };

  return (
    <div>
      {isLoading || !series ? (
        <Skeleton
          sx={{ height: "350px", minWidth: "200px", maxWidth: "80vw" }}
          {...rest}
        />
      ) : (
        <Card sx={{ backgroundColor: "#f5f5f5" }} {...rest}>
          <Box sx={{ padding: "5px" }}>
            <Typography variant="h6">{title}</Typography>
          </Box>
          <Chart
            options={options}
            series={series}
            type={type}
            height={height}
            width="100%"
          />
        </Card>
      )}
    </div>
  );
};

MyApexChart.propTypes = {
  isLoading: PropTypes.bool,
  height: PropTypes.number,
  tooltipLabel: PropTypes.string,
  showToolbar: PropTypes.bool,
  series: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(PropTypes.number).isRequired,
    })
  ).isRequired,
  horizontal: PropTypes.bool,
  stacked: PropTypes.bool,
  showDataLabels: PropTypes.bool,
  categories: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  type: PropTypes.oneOf(["area", "bar", "line", "pie"]),
  title: PropTypes.string,
  yTitle: PropTypes.string,
  xTitle: PropTypes.string,
  color: PropTypes.string,
  colors: PropTypes.arrayOf(PropTypes.string),
  monochrome: PropTypes.bool,
  useSecondYAxis: PropTypes.bool,
};

export default MyApexChart;
