import React, { useState } from "react";
import "../Style/Loading.css";
import { Button as ButtonMat } from "@mui/material";

function Button(props) {
  const [loading, setLoading] = useState(false);
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  async function onClickDefault() {
    await delay(5000);
  }

  async function handleClick() {
    setLoading(true);
    await onClick();
    setLoading(false);
  }
  const {
    variant = "primary",
    loadingType,
    children,
    onClick = onClickDefault,
    ...rest
  } = props;
  return (
    <ButtonMat
      variant={variant}
      onClick={() => !loading && handleClick()}
      {...rest}
      className={loading ? "effect-wave" : undefined}
    >
      {children}
    </ButtonMat>
  );
}

export default Button;
