import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ErrorIcon from '@mui/icons-material/Error';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

function SelectButton({ options = [], value, onChange = () => { }, startIcon, required = false, label, ...rest }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const getOptionLabel = (option) => (typeof option === 'string' ? option : option.label);
  const getOptionStyle = (option) => (typeof option === 'string' ? {} : option.style);
  const defaultOptionIndex = options.findIndex(option => (typeof option === 'string' ? option : option.value) === value);

  const [selectedIndex, setSelectedIndex] = React.useState(defaultOptionIndex);

  useEffect(()=>{
    setSelectedIndex(options.findIndex(option => (typeof option === 'string' ? option : option.value) === value))
  },[value])

  const handleClick = () => {
    setOpen(!open);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
    onChange(typeof options[index] === 'string' ? options[index] : options[index].value);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <FormControl  fullWidth {...rest}>
      <FormLabel sx={{height:label&&'25px'}}>{selectedIndex !== -1 && label}</FormLabel>
      <ButtonGroup fullWidth variant="contained" ref={anchorRef} aria-label="split button">
        <Button fullWidth onClick={handleClick}>
          {selectedIndex === -1 ?
            <>
              {required && <InputAdornment position="start"><ErrorIcon color="warning" /></InputAdornment>}
              {label?label:"Seleziona un'opzione"}
            </> :
            getOptionLabel(options[selectedIndex])
          }
        </Button>
        <Button
          sx={{ width: '20px' }}
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 700,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper sx={{maxHeight:'50vh',overflowY:'auto'}}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={getOptionLabel(option)}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                      sx={getOptionStyle(option)}
                    >
                      {getOptionLabel(option)}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </FormControl>
  );
}

export default SelectButton;

SelectButton.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    style: PropTypes.object,
  })])),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),  
  startIcon: PropTypes.node,
  label: PropTypes.string,
};
