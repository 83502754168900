import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../store/UserContext";
import { AlertApiContext } from "../../store/AlertApiContext";
import { Grid, Typography, Button, Card, Box, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import InsightsOutlinedIcon from "@mui/icons-material/InsightsOutlined";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import EventIcon from "@mui/icons-material/Event";
import {
  ItemAnimation,
  ImageCard,
  Locker,
} from "../../story-components/components";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";

export default function MyLocals() {
  const {
    setOpenBackDrop,
    openBackDrop,
    token,
    logged,
    setDefaultTheme,
    isAdmin,
  } = useContext(UserContext);
  useEffect(() => {
    setDefaultTheme();
  }, []);

  let navigate = useNavigate();

  const { api_request } = useContext(AlertApiContext);
  const [dashboardsAvaiables, setDashboardsAvaiables] = useState([]);

  async function fetchUrl() {
    setOpenBackDrop(true);
    const response = await api_request(
      "GET",
      "/api/dashboards/avaiable_locals"
    );
    if (response.status === "success") {
      setDashboardsAvaiables(response.data);
      setOpenBackDrop(false);
    }
  }
  useEffect(() => {
    fetchUrl();
  }, [token, logged]);

  return (
    <div style={{ padding: "5px", marginBottom: "10px" }}>
      <Typography variant="h3" component="h3" color="text.background" textAlign='center'>
        I miei locali
      </Typography>

      {isAdmin && (
        <Button
          variant="contained"
          style={{ borderRadius: "5px" }}
          key={"dashboard-admin"}
          fullWidth
          onClick={() => navigate(`/admin/dashboard`)}
        >
          Dashboard Admin
        </Button>
      )}

      <Grid sx={{ marginTop: "10px" }} spacing={4} container>
        {dashboardsAvaiables.length > 0 ? (
          <>
            {dashboardsAvaiables.map((item) => (
              <Grid key={item._id} item xs={12} sm={12} md={6} lg={4} xl={4}>
                <ItemAnimation
                  key={item._id + "-" + item.local_name}
                  variant="fade"
                  oneTime={true}
                  delay={0}
                  style={{ width: "100%" }}
                >
                  <Box sx={{ backgroundColor: "#f5f7f9", padding: "0px",borderRadius:'5px',boxShadow:2 }}>
                    <ImageCard
                      onClick={() =>
                        navigate(`/local_landing_page/${item._id}`)
                      }
                      imgUrl={item.local_cover}
                    >
                      {item.local_name}
                      {item.subscription_charge_failed && (
                        <Alert severity="error">
                          {item.subscription_charge_failed}
                        </Alert>
                      )}
                      {item.subscription_canceled_at && (
                        <Alert severity="warning">
                          {"Il servizio terminerà: "}
                          {new Date(
                            item.subscription_canceled_at
                          ).toLocaleString("it-IT")}
                        </Alert>
                      )}
                    </ImageCard>
                    <Box sx={{ padding: "10px" }}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Button
                            style={{
                              marginTop: "5px",
                              width: "100%",
                              height: "100%",
                            }}
                            variant="contained"
                            onClick={() => navigate(`/edit_local/${item._id}`)}
                            startIcon={<SettingsRoundedIcon color="white" />}
                          >
                            Impostazioni
                          </Button>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                          <Button
                            style={{
                              marginTop: "5px",
                              width: "100%",
                              height: "100%",
                            }}
                            variant="contained"
                            onClick={() =>
                              navigate(`/local/Edit_Menu/${item._id}`)
                            }
                            startIcon={<CreateRoundedIcon color="white" />}
                          >
                            Modifica Menù
                          </Button>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                          <Button
                            style={{
                              marginTop: "5px",
                              width: "100%",
                              height: "100%",
                            }}
                            variant="contained"
                            startIcon={<AutoStoriesIcon color="white" />}
                            onClick={() => navigate(`/local/menu/${item._id}`)}
                          >
                            Menù
                          </Button>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                          <Locker
                            locked={item.plan_value < 2}
                            message={
                              "I report sono disponibili solo per il piano Business e Premium"
                            }
                          >
                            <Button
                              style={{
                                marginTop: "5px",
                                width: "100%",
                                height: "100%",
                              }}
                              variant="contained"
                              startIcon={<InsightsOutlinedIcon color="white" />}
                              onClick={() => navigate(`/dashboard/${item._id}`)}
                            >
                              Report
                            </Button>
                          </Locker>
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={6}>
                          <Locker
                            locked={item.plan_value < 3}
                            message={
                              "I coupon sono disponibili solo dal piano Premium"
                            }
                          >
                            <Button
                              style={{
                                marginTop: "5px",
                                width: "100%",
                                height: "100%",
                              }}
                              variant="contained"
                              onClick={() =>
                                navigate(`/local/coupons/${item._id}`)
                              }
                              startIcon={<CardGiftcardIcon color="white" />}
                            >
                              Carte e buoni
                            </Button>
                          </Locker>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Locker
                            locked={item.plan_value < 2}
                            message={
                              "I report sono disponibili solo per il piano Business e Premium"
                            }
                          >
                            <Button
                              style={{
                                marginTop: "5px",
                                width: "100%",
                                height: "100%",
                              }}
                              variant="contained"
                              startIcon={<EventIcon color="white" />}
                              onClick={() => navigate(`/booking_management/${item._id}`)}
                            >
                              Prenotazioni
                            </Button>
                          </Locker>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </ItemAnimation>
              </Grid>
            ))}
          </>
        ) : (
          <Grid item>
            {openBackDrop ? (
              <Typography color="text.background" variant="h4">
                Caricamento...
              </Typography>
            ) : (
              <Card sx={{ padding: "5px" }}>
                <Alert severity="info">
                  Non hai nessun locale associato al tuo account
                </Alert>
                <Typography
                  align="center"
                  color="text.paper"
                  variant="subtitle"
                >
                  Vuoi anche tu un menù innovativo per il tuo locale?
                </Typography>
                <Typography
                  align="center"
                  color="text.paper"
                  variant="subtitle"
                >
                  Richiedilo subito e inizia ad usarlo in 5 minuti
                </Typography>
                <Box>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => navigate("/Subscriptions")}
                  >
                    Richiedi
                  </Button>
                </Box>
              </Card>
            )}
          </Grid>
        )}
        <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => navigate("/Subscriptions")}
          >
            Aggiungi attività
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
