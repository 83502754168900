import React from 'react';
import FreeIcon from '@mui/icons-material/FreeBreakfast';
import BookedIcon from '@mui/icons-material/EventNote';
import BusyIcon from '@mui/icons-material/ReportProblem';
import InProgressIcon from '@mui/icons-material/AccessTime';
import DeliveredIcon from '@mui/icons-material/RoomService';
import PaidIcon from '@mui/icons-material/Payment';
import CompletedIcon from '@mui/icons-material/Done';
import ToBeCleanedIcon from '@mui/icons-material/CleaningServices';

export function getTableStatusIcon(status) {
    switch(status) {
        case 'FREE':
            return <FreeIcon />;
        case 'BOOKED':
            return <BookedIcon />;
        case 'BUDY':
            return <BusyIcon />;
        case 'IN_PROGRESS':
            return <InProgressIcon />;
        case 'COMPLETED':
            return <CompletedIcon />;
        case 'TO_BE_CLEANED':
            return <ToBeCleanedIcon />;
        default:
            return null;
    }
}


export function getTableStatusColor(status) {
    switch(status) {
        case 'FREE':
            return 'white'; // Green
        case 'BOOKED':
            return 'rgb(200 188 85)'; // Yellow
        case 'BUSY':
            return '#FF5722'; // Deep Orange
        case 'IN_PROGRESS':
            return '#3F51B5'; // Indigo
        case 'COMPLETED':
            return '#8BC34A'; // Light Green
        case 'TO_BE_CLEANED':
            return '#FF9800'; // Orange
        default:
            return '#9E9E9E'; // Grey
    }
}

export function getTableStatusTextColor(status) {
    switch(status) {
        case 'FREE':
            return 'grey'; // Green
        default:
            return 'white'; // Grey
    }
}

export const statesMap={
    'FREE':{name:'Libero',backgroundColor:'white',color:'grey'}, 
    'BOOKED':{name:'Prenotato',backgroundColor:'rgb(200 188 85)',color:'white'}, 
    'BUSY':{name:'Occupato',backgroundColor:'#FF5722',color:'white'}, 
    'IN_PROGRESS':{name:'In corso',backgroundColor:'#3F51B5',color:'white'}, 
    'COMPLETED':{name:'Completato',backgroundColor: '#8BC34A',color:'white'}, 
    'TO_BE_CLEANED':{name:'Da pulire',backgroundColor: '#FF9800',color:'white'}
}

export const states = Object.keys(statesMap)