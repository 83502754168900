import React, { useState, useEffect } from "react";
import { Box, Stack, Collapse } from "@mui/material";
import dayjs from "dayjs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BookingDetailsRow from "./BookingDetailsRow"; // Assicurati di avere il percorso corretto per il componente
import "./BookingGanttRow.css"; // Aggiungi il CSS per l’animazione e lo stile
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";

const statusIcons = {
  confirmed: <CheckCircleIcon />,
  cancelled: <CancelIcon />,
  pending: <HourglassEmptyIcon />,
};

const BookingGanttRow = ({
  booking,
  currentMinutePosition,
  onBookingUpdate,
  onClickBooking,
  onClickPromotion,
  calculatePosition,
  getBookingColor,
  onConfirm = () => {},
  onReject = () => {},
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [position, setPosition] = useState(
    calculatePosition(booking.start, booking.end)
  );

  const handleRowClick = () => {
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup per rimuovere l'evento di resize
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    setPosition(calculatePosition(booking.start, booking.end));
  }, [windowWidth]);

  const color = getBookingColor(booking);

  return (
    <React.Fragment key={booking._id}>
      <Box className="gantt-row" onClick={handleRowClick}>
        <Box className="gantt-booking-label">
          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Box
              sx={{
                transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.3s ease",
              }}
            >
              <ExpandMoreIcon />
            </Box>
            {booking.name ? booking.name : "Prenotazione"}
          </Stack>
        </Box>

        <Box
          className="gantt-booking-bar"
          style={{ ...position, backgroundColor: color }}
        >
          {booking.table_id
            ? booking.table_id
            : `${dayjs(booking.start).utc().format("HH:mm")} - ${dayjs(booking.end).utc().format("HH:mm")}`}
        </Box>
        <Box
          className="current-minute-line"
          style={{ left: `${currentMinutePosition}px` }}
        />
      </Box>

      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <Box className="booking-details-fixed" sx={{ position: "relative" }}>
          <BookingDetailsRow
            booking={booking}
            onBookingUpdate={onBookingUpdate}
            onClickBooking={onClickBooking}
            onClickPromotion={onClickPromotion}
            onConfirm={onConfirm}
            onReject={onReject}
          />
        </Box>
      </Collapse>
    </React.Fragment>
  );
};

export default BookingGanttRow;
