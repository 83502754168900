import React from 'react';
import { Chip, Typography, Box } from '@mui/material';
import { getTableStatusColor, getTableStatusIcon, getTableStatusTextColor,states,statesMap } from './TableFunctions'



export default function Legend({...rest}) {
    return (
        <Box {...rest} sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '10px', color: 'white' }}>
            {states.map((state) => (
                <Chip
                    key={state}
                    sx={{ '& .MuiSvgIcon-root': { color: getTableStatusTextColor(state) } ,border:'1px solid '+getTableStatusTextColor(state)}}
                    icon={getTableStatusIcon(state)}
                    label={<Typography variant="body2" color={getTableStatusTextColor(state)}>{statesMap[state]?statesMap[state].name:'FREE'}</Typography>}
                    style={{ backgroundColor: getTableStatusColor(state) }}
                />
            ))}
        </Box>
    );
}
