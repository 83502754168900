export function enableGoogleConversion({event='conversion',event_category='engagement',event_label='payment_start', value = 1}) {
  try {
    const script = document.createElement("script");
    const id_gtag = "AW-16549231968";
    script.src = `https://www.googletagmanager.com/gtag/js?id=${id_gtag}`;
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      const consent = localStorage.getItem("cookieConsent");
      if (consent === "true") {
        gtag("event",event, {
          send_to: `${id_gtag}/LsVfCNCGstoZEOD6pNM9`,
          event_category: event_category,
          event_label: event_label,
          value: value, 
        });
        console.log("inviato evento google tag",{event,event_category,event_label,value});
      } else{
        gtag("consent", "update", {
          ad_storage: "denied",
          analytics_storage: "denied",
        });
        // Invio dell'evento senza utilizzare cookie
        gtag("event", event, {
            send_to: `${id_gtag}/LsVfCNCGstoZEOD6pNM9`,
            event_category: event_category,
            event_label: event_label,
            value: value,
            non_interaction: true  // Indica che non è un'interazione con l'utente per evitare il tracciamento
        });
        console.log("inviato evento senza cookie google tag",{event,event_category,event_label,value});

      }
    };
  } catch (e) {
    console.error(e);
  }
}
