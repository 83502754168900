import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Card, IconButton, Typography } from "@mui/material";
import { useMotionValue, Reorder, useDragControls } from "framer-motion";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import SubSectionEditDialog from "./SubSectionEditDialog";

function SubSectionCard({ subSection,item, onClick }) {
  const y = useMotionValue(0);
  const dragControls = useDragControls();
  return (
    <Reorder.Item
      value={item}
      id={item.subSectionId}
      style={{ y }}
      dragListener={false}
      dragControls={dragControls}
      as={"div"}
    >
      <Card
        sx={{
          display: "flex",
          alignItems: "center",
          border: "1px solid lightgray",
          borderRadius: "4px",
          padding: "8px",
          marginBottom: "8px",
        }}
      >
        <Box
          onClick={(e) => onClick(subSection)}
          sx={{ flexGrow: 1, width: "100%", minHeight: "40px" }}
        >
          <Typography variant="h6"> {subSection.subSectionName.it}</Typography>
        </Box>
        <IconButton
          style={{ touchAction: "none", cursor: "grab" }}
          onPointerDown={(event) => dragControls.start(event)}
        >
          <DragHandleIcon />
        </IconButton>
      </Card>
    </Reorder.Item>
  );
}

function ReorderSubSections({
  subSection,
  onChange = () => console.warn("onChange subSection non impostato"),
  onDelete = () => console.warn("non è stato impostata una funzione onDelete"),
}) {
  const [items, setItems] = useState(subSection);
  const [editData, setEditData] = useState(null);

  const handleDrag = (newItems) => {
    setItems(newItems);
    onChange(newItems);
  };

  const openEditDialog = (data) => {
    setEditData(data);
  };

  const closeEditDialog = () => {
    setEditData(null);
  };

  const handleDelete = (id) => {
    setItems(items.filter((item) => item.subSectionId !== id));
    onDelete({ subSectionId: id });
    closeEditDialog();
  };

  const handleSave = (data) => {
    const index = items.findIndex(
      (item) => item.subSectionId === data.subSectionId
    );
    const newItems = [...items];
    newItems[index] = data;
    setItems(newItems);
    onChange(newItems);
    closeEditDialog();
  };

  if (subSection) {
    return (
      <Card sx={{ padding: "5px" }}>
        <Typography variant="h5" align="center">
          Sotto sezioni
        </Typography>
        <Reorder.Group
          axis="y"
          as={"div"}
          values={items}
          onReorder={handleDrag}
        >
          {items.map((item) => (
            <SubSectionCard
              key={item.subSectionId}
              subSection={item}
              item={item}
              onClick={openEditDialog}
            />
          ))}
        </Reorder.Group>

        {editData && (
          <SubSectionEditDialog
            open={Boolean(editData)}
            onClose={closeEditDialog}
            subSection={editData}
            onDeleteSubSection={() => handleDelete(editData.subSectionId)}
            onSave={handleSave}
          />
        )}
      </Card>
    );
  }
}

ReorderSubSections.propTypes = {
  subSection: PropTypes.arrayOf(
    PropTypes.shape({
      subSectionCover: PropTypes.string,
      subSectionId: PropTypes.string.isRequired,
      subSectionName: PropTypes.shape({
        it: PropTypes.string.isRequired,
      }).isRequired,
      subSectionDescription: PropTypes.shape({}),
    })
  ).isRequired,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};

export default ReorderSubSections;
