// LoginOptions.js
import React from "react";
import { DialogContent, DialogContentText, Box } from "@mui/material";
import LoginButton from "../Login/LoginButton";

const LoginOptions = ({}) => {
  return (
    <DialogContent dividers sx={{ overflow: "hidden" }}>

      <Box sx={{ width: "100%", textAlign: "-webkit-center" }}>
        <LoginButton />
      </Box>

      {/* Puoi aggiungere altre opzioni di login qui */}
    </DialogContent>
  );
};

export default LoginOptions;
