export function calculateVisibilityCounts(menuData, section_id, productsIdHidden) {
    // Calcolo degli elementi mostrati, escludendo quelli nascosti

    let elementsShowed = menuData[section_id].products.filter(
      (product) => !productsIdHidden.includes(product.product_id)
    ).length;

    // Calcolo degli elementi disponibili, escludendo quelli nascosti
    let elementsAvailable = menuData[section_id].products.filter(
      (product) => product.product_hidden === false
    ).length;

    // Includi i prodotti delle sotto-sezioni nel conteggio
    Object.values(menuData[section_id].subSections).forEach(
      (subSection) => {
        elementsShowed += subSection.subSectionData.filter(
          (product) => !productsIdHidden.includes(product.product_id)
        ).length;
        elementsAvailable += subSection.subSectionData.filter(
          (product) => product.product_hidden === false
        ).length;
      }
    );

    return {
      elementsShowed: elementsShowed ?? 0,
      elementsAvaiable: elementsAvailable ?? 0
  };
  
}

export  function filterSectionsWithAvailableElements(
  sectionsOrders,
  menuData,
  productsIdHidden
) {
  const filteredSections = sectionsOrders.filter((section_id) => {
    const { elementsAvaiable } = calculateVisibilityCounts(
      menuData,
      section_id,
      productsIdHidden
    );
    return elementsAvaiable > 0; // Mantieni solo i section_id con elementsAvaiable > 0
  });
  return filteredSections;
}
