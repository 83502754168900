import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import { Chip, TextField, Alert } from '@mui/material';

function Select({ values, label, multiple, onChange, value, valuesSelected, max, ...rest }) {
  const theme = useTheme();
  
  // Stato locale per tenere traccia dei valori selezionati
  const [localValuesSelected, setLocalValuesSelected] = useState(valuesSelected || (multiple ? [] : null));
  
  // Stato per il messaggio di errore
  const [error, setError] = useState('');

  // Aggiorna lo stato locale quando le props cambiano
  useEffect(() => {
    setLocalValuesSelected(valuesSelected || (multiple ? [] : null));
  }, [valuesSelected, multiple]);

  const handleChangeCategory = (event, newValue) => {
    if (multiple && max && newValue.length > max) {
      // Se il numero di selezioni supera il massimo, non aggiungiamo l'elemento e impostiamo l'errore
      setError(`Puoi selezionare al massimo ${max} elementi.`);
      // Non aggiorniamo lo stato locale né chiamiamo onChange
    } else {
      // Puliamo eventuali errori precedenti
      setError('');
      setLocalValuesSelected(newValue);
      onChange(multiple ? newValue : newValue[0]);
    }
  };

  return (
    <>
      <Autocomplete
        {...rest}
        multiple={multiple}
        options={values}
        value={localValuesSelected}
        onChange={handleChangeCategory}
        getOptionLabel={(option) => option}
        isOptionEqualToValue={(option, value) => option === value}
        getOptionDisabled={(option) => {
          return multiple && max && localValuesSelected.length >= max && !localValuesSelected.includes(option);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            error={Boolean(error)}
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
          ))
        }
      />
      {error && (
        <Alert severity="warning" style={{ marginTop: '8px' }}>
          {error}
        </Alert>
      )}
    </>
  );
}

export default Select;
